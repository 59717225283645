import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import InterStyled from '../styles/InterStyled';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabelMUI from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '../styles/Button';
import split_query from '../utils/helper/split_query';
import { useLocation } from 'react-router-dom';
import { change_email_settings } from '../utils/helper/change_email_settings';

const EmailSettingsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

const FormControlLabel = styled(FormControlLabelMUI)`
  display: flex;
  align-items: center;
`;

export default function EmailSettingsWithoutLogin() {
  const { t } = useTranslation();

  const [orderRelatedEmails, setOrderRelatedEmails] = useState(true);
  const [reminderEmails, setReminderEmails] = useState(true);
  const [shoppingActivityEmails, setShoppingActivityEmails] = useState(true);
  const [newsletter, setNewsletter] = useState(true);
  const [disableSave, setDisableSave] = useState(false);

  const location = useLocation();
  const query_params = split_query(location.search);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setDisableSave(true);

    const emailSettings = {
      order_emails: orderRelatedEmails,
      reminder_emails: reminderEmails,
      shopping_activity_emails: shoppingActivityEmails,
      subscribed_to_newsletter: newsletter,
    };
    try {
      const response = await change_email_settings(
        query_params.email,
        query_params.contact_id,
        emailSettings,
      );

      const data = await response.json();

      if (response.status === 200) {
        toast.success(t('Email Settings Changed Successfully'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      } else {
        toast.error(data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    } catch (e) {
      console.error(e);
      // Send a notification
      toast.error(t('Failed to Change Settings, Please try Again'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    }

    setDisableSave(false);
  };

  return (
    <EmailSettingsContainer>
      <div>
        <InterStyled interStyle='Label1'>{t('Email Settings')}</InterStyled>
      </div>

      <form onSubmit={handleSubmit}>
        <FormGroup sx={{ marginLeft: '10px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={orderRelatedEmails}
                onChange={(event) =>
                  setOrderRelatedEmails(event.target.checked)
                }
                size='medium'
                sx={{
                  '&.Mui-checked': {
                    color: '#7DD29A',
                  },
                }}
              />
            }
            label={
              <InterStyled interStyle='P2' color='greytext'>
                {t('Order related emails')}
              </InterStyled>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={reminderEmails}
                onChange={(event) => setReminderEmails(event.target.checked)}
                size='medium'
                sx={{
                  '&.Mui-checked': {
                    color: '#7DD29A',
                  },
                }}
              />
            }
            label={
              <InterStyled interStyle='P2' color='greytext'>
                {t('Reminder Emails')}
              </InterStyled>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={shoppingActivityEmails}
                onChange={(event) =>
                  setShoppingActivityEmails(event.target.checked)
                }
                size='medium'
                sx={{
                  '&.Mui-checked': {
                    color: '#7DD29A',
                  },
                }}
              />
            }
            label={
              <InterStyled interStyle='P2' color='greytext'>
                {t(
                  'Shopping Activity emails (informing about your purchases at partners)',
                )}
              </InterStyled>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newsletter}
                onChange={(event) => setNewsletter(event.target.checked)}
                size='medium'
                sx={{
                  '&.Mui-checked': {
                    color: '#7DD29A',
                  },
                }}
              />
            }
            label={
              <InterStyled interStyle='P2' color='greytext'>
                {t('Newsletter? (100 extra Keepoalas)')}
              </InterStyled>
            }
          />
        </FormGroup>

        <Button
          color='primary'
          mt={2}
          type='submit'
          style={{ width: '50%' }}
          disabled={disableSave}
        >
          {t('Save')}
        </Button>
      </form>
    </EmailSettingsContainer>
  );
}
