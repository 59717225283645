import React, { useState, useEffect, useContext } from 'react';
import { db } from '../firebase';
import styled from 'styled-components';
import { AuthContext } from '../utils/providers/AuthProvider';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { Chip as MuiChip } from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import InterStyled from '../styles/InterStyled';

const Chip = styled(MuiChip)`
  margin-bottom: 6px;
  margin-right: 6px;
  height: 32px;
  border: 1px solid #748080;
  border-radius: 8px;
  background-color: #fff;
  font-weight: 500;
  font-size: 12px;
  color: #515959;
`;
const ShopDataContainer = styled.div``;

export default function ShopData() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [shops, setShops] = useState({});
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState([]);

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function setsubscriber(mounted) {
    return (
      db
        .collection('Orders')
        .orderBy('datenum', 'desc')
        // without the where clause this will not get any data
        .where('user_id', '==', user.uid)
        .onSnapshot(
          (querySnapshot) => {
            const shopcounts = {};
            const emails = [user.email];
            // push the data to the dictionary
            querySnapshot.forEach((documentSnapshot) => {
              var data = documentSnapshot.data();

              if (
                data.shopify !== undefined &&
                data.shopify.shop !== undefined
              ) {
                if (shopcounts[data.shopify.shop] !== undefined) {
                  shopcounts[data.shopify.shop] =
                    shopcounts[data.shopify.shop] + 1;
                } else {
                  shopcounts[data.shopify.shop] = 1;
                }
              }

              if (
                data.shopify !== undefined &&
                data.shopify.email !== undefined
              ) {
                emails.push(data.shopify.email);
              }
            });

            console.log(shopcounts);
            // Check if this is mounted before setting anything
            if (mounted) {
              if (emails.length > 0) {
                setEmails(emails.filter(onlyUnique));
                setShops(shopcounts);
              }
              setLoading(false);
            }
          },
          (err) => {
            console.log(`Encountered error: ${err}`);
          },
        )
    );
  }

  useEffect(() => {
    var mounted = true;
    // Use a FireStore subscription
    // to derive the data from FireBase/FireStore
    var subscriber = setsubscriber(mounted);

    if (!loading) {
      // Unsubscribe from events when no longer in use
      // if (!loading) {
      return () => {
        subscriber();
      };
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <ShopDataContainer>
      {/* Beginning text section */}
      <div>
        <InterStyled interStyle='P2' color='greytext'>
          {t(
            "At Keepoala we care about your privacy, that's why we show you which email addresses we have collected from you and give you an overview of your registered orders",
          )}
          .
        </InterStyled>
      </div>

      {/* Collected Emails section */}
      <div style={{ margin: '15px 0px' }}>
        <div>
          <InterStyled interStyle='Label1'>
            {t('Collected Email Addresses')}
          </InterStyled>
        </div>

        <div style={{ marginTop: '15px' }}>
          {emails.length > 0
            ? emails.map((address, index) => (
                <Chip key={index} label={address} />
              ))
            : null}
        </div>
      </div>

      {/* Shop Orders Table section */}
      <div style={{ margin: '15px 0px' }}>
        <div>
          <InterStyled interStyle='Label1'>{t('Order Overview')}</InterStyled>
        </div>

        <TableContainer style={{ marginTop: '10px', maxWidth: '720px' }}>
          <Table aria-label='simple table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    paddingLeft: '0px',
                    paddingBottom: '0px',
                    borderBottom: '1px solid #B6BBBB',
                  }}
                >
                  <InterStyled interStyle='Label1' color='greytext'>
                    {t('Shop')}
                  </InterStyled>
                </TableCell>
                <TableCell
                  align='right'
                  style={{
                    paddingBottom: '0px',
                    borderBottom: '1px solid #B6BBBB',
                  }}
                >
                  <InterStyled interStyle='P1' color='greytext'>
                    {t('Orders')}
                  </InterStyled>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.keys(shops).map((key) => (
                <TableRow key={key + 'row'}>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      paddingLeft: '0px',
                      border: '0',
                      paddingBottom: '0px',
                    }}
                  >
                    <InterStyled interStyle='P1' color='greytext'>
                      {key}
                    </InterStyled>
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{ border: '0', paddingBottom: '0px' }}
                  >
                    <InterStyled interStyle='P1' color='greytext'>
                      {shops[key]}
                    </InterStyled>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </ShopDataContainer>
  );
}
