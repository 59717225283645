import React, { useEffect, useState, useCallback } from 'react';
import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
} from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { spacing } from '@material-ui/system';
import OrderLineItem from './OrderLineItem';
import { LineHorizontal, ReturnProductFeedbackType } from './ReturnFeedback';
import Buttonn from '../styles/Button';
import ReturnQuestions, { AnswerType } from './ReturnQuestions';
import Colors from '../utils/helper/Colors';
import InterText from '../styles/Inter';
import { Alert as MuiAlert } from '../styles/MUIOverwrite';
import { ReturnLabelType, ShopifyLineItemForPortal, ShopifyProduct } from '../utils/helper/LineItemTypes';
import ReturnExchange from './ReturnExchange';
import ReturnLabelTinyCollection from './ReturnLabelTinyCollection';
import { analytics } from '../firebase';
const Alert = styled(MuiAlert)(spacing);

const FullWidth = styled.div`
  width: 100%;
`;



export type TextFeedback = { index: number; rating: string };
export type RatingFeedback = { index: number; rating: number };

const useStyles = makeStyles((theme) => ({
  btn: {
    marginTop: 20,
  },
  disabled: {
    marginTop: 20,
    color: Colors.keepoala.lightgrey + ' !important',
    backgroundColor: Colors.keepoala.grey + ' !important',
    boxShadow: ' !important',
    border: 'none !important',
    '&:hover, &:focus': {
      boxShadow: ' !important',
      color: Colors.keepoala.lightgrey + ' !important',
      backgroundColor: Colors.keepoala.grey + ' !important',
    },
  },
  myAlert: {
    marginTop: 15,
    width: '90%',
  },
  hide: {
    display: 'none',
  },
  FormControl: {
    marginRight: 12,
  },
}));

export default function ReturnProductFeedback(props: {
  validateHandler: (
    products: {
      item: ShopifyLineItemForPortal;
      quantity: number;
      answers: AnswerType[] | undefined;
      exchange: (ShopifyProduct | undefined)[];
    }[],
  ) => void;
  line_items: ShopifyLineItemForPortal[];
  shop: string | undefined;
  defaultValue: ReturnProductFeedbackType[] | undefined;
  withLabel: boolean | undefined;
  labelPrice: number;
  labelPriceText: string;
  labelCarrier?: string;
  order_id: string | number | undefined;
  order_name: string | undefined;
  shop_pretty: string;
  NPSandTreeCategory: { nps: boolean; tree: any } | undefined;
  support?: {
    email: string | undefined,
    link: string | undefined,
  },
  show_prices: boolean,
  label_data?: ReturnLabelType[] | null
  specialCarrierInfo?: string,
}) {
  const Styleddiv = styled.div`
    background-color: transparent;
  `;

  const { t } = useTranslation();

  const Divspacing = styled(Styleddiv)(spacing);

  const keep_disable = props.line_items.filter((x) => x.quantity > 0).length < 1;

  const [dialog_open, setDialogOpen]: [boolean, any] = useState(false);
  const [feedbackItem, setFeedbackItem]: [ShopifyLineItemForPortal | undefined, any] =
    useState(undefined);
  const [feedbackCategory, setFeedbackCategory]:[string, any] = useState('shirt');
  const [feedbackItemIndex, setFeedbackItemIndex]: [number, any] = useState(-1);
  const [gettingFeedback, setGettingFeedback]: [boolean, any] = useState(false);
  const [gettingExchange, setGettingExchange]: [boolean, any] = useState(false);
  const [has_exchange, setHasExchange]:[boolean, any] = useState(false);

  const [anyFeedback, setAnyfeedback]: [boolean, any] = useState(false);

  const classes = useStyles();

  // initialize defaults
  const x: { [key: number]: number } = {};
  for (let i = 0; i < props.line_items.length; i++) {
    x[i] = 0;
  }

  const y: { [key: number]: AnswerType[] | undefined } = {};
  for (let i:number = 0; i < props.line_items.length; i++) {
    y[i] = undefined;
  }

  if (props.defaultValue !== undefined) {
    for (let i = 0; i < props.defaultValue.length; i++) {
      x[i] = props.defaultValue[i].quantity;
      y[i] = props.defaultValue[i].answers;
      if (y[i] !== undefined) {
        setAnyfeedback(true);
      }
    }
  }

  const [returnQuantity, setReturnQuantity]: [
    { [key: number]: number },
    React.Dispatch<React.SetStateAction<{}>>,
  ] = React.useState(x);

  const [returnPay, setReturnPay]: [number[], any] = React.useState(
    props.line_items.map((x) => 0),
  );


  const [returnAnswers, setReturnAnswers]: [
    { [key: number]: AnswerType[] | undefined },
    React.Dispatch<React.SetStateAction<{}>>,
  ] = React.useState(y);

  const [returnAnswersChecker, setReturnAnswersChecker]: [
    { [key: number]: AnswerType[] | undefined },
    React.Dispatch<React.SetStateAction<{}>>,
  ] = React.useState({});

  // a handler to store answers in case someone goes 
  // from exchange to answers
  const [backAnswers, setBackAnswers]:[AnswerType[] | undefined, any] = useState(undefined);

  const z: { [key: number]: (ShopifyProduct | undefined)[] } = {};
  for (let i = 0; i < props.line_items.length; i++) {
    z[i] = [];
  }
  const [exchangeWishes, setExchangeWishes]: [
    { [key: number]: (ShopifyProduct| undefined)[] },
    any
  ] = React.useState(z)

  const [exchangeWishesChecker, setExchangeWishesChecker]: [
    { [key: number]: (ShopifyProduct| undefined)[]},
    any
  ] = React.useState({})

  const handleItemAnswerWish = (
    quantity: number,
    item: ShopifyLineItemForPortal,
    index: number,
    exchange: boolean,
  ) => {

    setFeedbackItem(item);
    console.log("setFeedbackItem")
    console.log(getFeedbackItemProperty(item, 'product_type'))
    setFeedbackCategory((props.NPSandTreeCategory?.tree[(getFeedbackItemProperty(item, 'product_type') ?? 'default')] ?? props.NPSandTreeCategory?.tree.default))
    setFeedbackItemIndex(index);
    const current_return_quantities = returnQuantity;
    current_return_quantities[index] = quantity;
    setReturnQuantity(current_return_quantities);

    // tell the UI to go to exchange, in case there is one
    setGettingExchange(exchange);

    // tell the ui to handle exchanges for now
    setHasExchange(exchange);
    setGettingFeedback(true);
  };

  const handleProductFeedbackFinish = () => {
    if (anyFeedback) {
      if (props.line_items.length > 1) {
        setDialogOpen(true);
      } else {
        handleFinishDialog();
      }
    }
  };

  const handleCloseFinishDialog = () => {
    setDialogOpen(false);
  };

  const handleFinishDialog = useCallback(() => {
    const return_val = props.line_items.map(
      (item: ShopifyLineItemForPortal, index: number) => {
        return {
          item: item,
          quantity: returnQuantity[index],
          answers: returnAnswers[index],
          exchange: exchangeWishes[index],
        };
      },
    );
    props.validateHandler(return_val);
  }, [returnQuantity, returnAnswers, exchangeWishes, props])

  useEffect(() => {

    // workaround as array setters do not work in React
    if (Object.keys(returnAnswersChecker).length !== 0) {
      analytics.logEvent('returnfeedback_answers_finished',{
        feature: 'returnfeedback',
        event: 'returnfeedback_answers_finished'
      })
      setReturnAnswers(returnAnswersChecker)
      setReturnAnswersChecker({});
    }
    
    if (Object.keys(exchangeWishesChecker).length !== 0) {
      analytics.logEvent('returnfeedback_exchange_finished',{
        feature: 'returnfeedback',
        event: 'returnfeedback_exchange_finished'
      })
      setExchangeWishes(exchangeWishesChecker)
      setExchangeWishesChecker({});
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnAnswersChecker, exchangeWishesChecker]);

  function getFeedbackItemProperty(
    x: ShopifyLineItemForPortal | undefined,
    property:
      | 'id'
      | 'variant_id'
      | 'price'
      | 'title'
      | 'variant_title'
      | 'quantity' 
      | 'product_type',
  ): string | number | undefined {
    if (x === undefined) {
      return '';
    } else {
      return (x as ShopifyLineItemForPortal)[property];
    }
  }

  return (
    <FullWidth>
      {(gettingFeedback || gettingExchange) ? (
        gettingFeedback ? 
          <ReturnQuestions
            orderID={props.order_id}
            productVariantID={(getFeedbackItemProperty(
              feedbackItem,
              'variant_id',
            ) ?? '').toString()}
            category={feedbackCategory}
            category_fallback={props.NPSandTreeCategory?.tree?.default}
            shop={props.shop_pretty}
            shopId={props.shop}
            productName={(getFeedbackItemProperty(
              feedbackItem,
              'title',
            ) ?? '').toString()}
            skipStart
            skipEnd
            orderName={props.order_name}
            successHandler={(answers: AnswerType[]) => {
              const current_answers = returnAnswers;
              current_answers[feedbackItemIndex] = answers;
              setReturnAnswersChecker(current_answers);
              setAnyfeedback(true);
              setGettingFeedback(false);
              setBackAnswers(undefined);
              setGettingExchange(has_exchange)
            }}
            userID=''
            startBackHandler={() => {
              setGettingFeedback(false);
              setBackAnswers(undefined);
              setGettingExchange(false);
            }}
            default_answers={backAnswers}
          /> : 
          <ReturnExchange 
            line_item={feedbackItem}
            successHandlerEx={
              (product: (ShopifyProduct | undefined)[]) => {
                const current_exchange_wishes = exchangeWishes;
                current_exchange_wishes[feedbackItemIndex] = product;
                setExchangeWishesChecker(current_exchange_wishes);

                const current_repayment = returnPay;
                current_repayment[feedbackItemIndex] = 
                  // number of refunds
                  product.filter((x) => x === undefined).length *
                  parseFloat(props.line_items[feedbackItemIndex].price.toString());
                setReturnPay(current_repayment);
                
                // set view to go to next step
                setGettingExchange(false);

                setAnyfeedback(true);
                
                // stop handling exchanges
                setHasExchange(false);
              }
            }
            quantity={returnQuantity[feedbackItemIndex]}
            backHandler={() => {
              console.log("BACKHANDLER for exchange")
              // hand old answers back
              setBackAnswers(returnAnswers[feedbackItemIndex]);
              // reset answers
              const current_answers = returnAnswers;
              current_answers[feedbackItemIndex] = undefined;
              setReturnAnswersChecker(current_answers);

              // refresh view
              setGettingFeedback(true);
              setGettingExchange(false);
            } }
          />
      ) : (
        <div>
          {
            // In case the user is above the keep days of the shop, show a message dependent on how much above the
            // user is
          }
          {
            // No line items to return
            keep_disable ? 
              <><Alert mb={4} mt={4} severity='warning'>
                 <InterText>
                  {t('There are no items left to return')}

                     <br/>
                      {t('You can contact the support at') + ' '}{
                          <a  rel='noreferrer'
                          target='_blank' href={'mailto:'+ props.support?.email ?? "retourenportal@keepoala.com"}>{props.support?.email ?? 'retourenportal@keepoala.com'}</a>
                      }
                    </InterText>
                </Alert>
                <LineHorizontal mt={2} mb={2}/>
                <InterText size='l' weight={600} align='center'>{t('Returned Items')}</InterText>
                <ul>
                  {
                    props.line_items.map((x: ShopifyLineItemForPortal) => {
                      return <li><InterText size='s'>{x.title} ({x.returned}x)</InterText></li>
                    })
                  }
                </ul>
                </>
            : null
          }
          {
            !keep_disable && props.line_items.length > 0 ? <>
              <InterText
                mt={6}
                mb={0}
                align={'center'}
                ml={0}
                size={'l'}
                weight={600}
              >
                {t('Which items do you wish to return?')}
              </InterText>
              <InterText
                mt={0}
                mb={2}
                align={'center'}
                ml={0}
                size={'m'}
                weight={300}
              >
                {t('Please add how many you wish to return')}
              </InterText>
              {props.line_items?.map((item, index) => {
                return (
                  <Divspacing key={item.id} ml={0} mb={2}>
                    <OrderLineItem
                      item={item}
                      index={index}
                      withReturn={false}
                      validateHandler={handleItemAnswerWish}
                      validationFinished={returnAnswers[index] !== undefined}
                      defaultQuantity={
                        returnQuantity[index] !== undefined
                          ? returnQuantity[index]
                          : 0
                      }
                      showPrice={props.show_prices}
                      exchangeWish={
                        exchangeWishes[index]
                      }
                    />
                  </Divspacing>
                );
              })}
              {
                anyFeedback ? 
                <InterText ml={4} mr={4} align='center' weight={600}>
                  {t('You nearly made it, click the button underneath to continue')}
                </InterText> : null
              }
              <Buttonn
                variant='contained'
                color={'default'}
                mb={2}
                size='medium'
                id='submit'
                type='submit'
                value='Submit'
                className={!anyFeedback ? classes.disabled : classes.btn}
                fullWidth
                onClick={handleProductFeedbackFinish}
                disabled={!anyFeedback || keep_disable}
              >
                {props.withLabel
                  ? t('GET THE RETURN LABEL')
                  : t('FINISH PRODUCT FEEDBACK')}
              </Buttonn>
          </> : null
          }{
            <LineHorizontal mt={2} mb={2}/>
          }
          {/* Show former returns in case there are some */}
          {props.label_data === undefined || props.label_data === null ?
                        null :
                        <ReturnLabelTinyCollection data={props.label_data} open_default={keep_disable}/>
                    }
          {props.withLabel && !keep_disable  ? (
            <>
              {props.labelPriceText !== ''
                ? <Alert mb={4} mt={4} severity='warning'>
                    <InterText size='s'><span>{props.labelPriceText}</span></InterText></Alert>
                : null
              }
              
            <InterText align={'left'} mt={2} size='s'>
              
              {props.labelCarrier !== undefined ? 
              <span>{t('You will receive a label that you need to ship via') + ' '}</span>
              : null}
              {props.labelCarrier !== undefined ? 
                 <b>{t(props.labelCarrier.toLowerCase())}</b>: null
              }
            </InterText>
            </>
          ) : null}
          {props.specialCarrierInfo !== undefined ? 
                 <InterText align={'center'} mt={2}>{props.specialCarrierInfo.split("\\n").map((x) => (
                  <span>
                    {x}
                    <br />
                  </span>
                ))}</InterText>
              : null
          }
          {props.withLabel && !keep_disable && props.support !== undefined ? (
            props.support.link !== undefined && props.support.link !== null && props.support.link != '' ?
            
            <InterText align={'left'} mt={2} size='s'>
              {t('We would like to help you if you get stuck here, please contact the support at')} <a href={props.support.link}>{t('this link')}</a>
            </InterText>
            :
            <InterText align={'left'} mt={2} size='s'>
              {t('We would like to help you if you get stuck here, please contact the support at') + ' '}<a href={'mailto:' + props.support.email}>{props.support.email}</a>
            </InterText>
          ) : null}
          {/*  */}
          {/* In case a price should be shown, calculate the total */}
          {(returnPay.reduce((partialSum, a) => partialSum + a, 0) > 0 && props.show_prices) ? (
            <InterText align='left' mt={2} size='s'>
              {t('In case everything is ok, your repayment will be')}&nbsp;
              <b>
                {(
                  returnPay.reduce((partialSum, a) => partialSum + a, 0) -
                  (props.withLabel === true ? props.labelPrice : 0)
                ).toFixed(2)}
                &nbsp;EUR
              </b>
            </InterText>
          ) : // Do not show a message, in case no repayment is done
          null}
        </div>
      )}
      <Dialog
        open={dialog_open}
        onClose={handleCloseFinishDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {t('Did you mention all items you want to return')}?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseFinishDialog}>{t('No, back')}</Button>
          <Button onClick={handleFinishDialog} autoFocus>
            {t('Yes, continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </FullWidth>
  );
}

ReturnProductFeedback.defaultProps = {
  withLabeL: false,
};
