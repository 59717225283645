import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

export async function firestore_get_nps(shop) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'application/json',
      'Keepoala-Cors-Header': 'enabled',
      Signature:
        'sha256=' +
        Base64.stringify(hmacSHA256(shop, process.env.REACT_APP_G_CLOUD_KEY)),
      shop: shop,
    },
  };
  //   return await fetch('http://localhost:8100/0.0.0.0:5001/keepoala/europe-west1/shopify_orders_move', requestOptions)
  return await fetch(
    (window.location.hostname === 'localhost'
      ? `http://localhost:8100/europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net:443`
      : 'https://' +
        (process.env.REACT_APP_HOST !== undefined
          ? process.env.REACT_APP_HOST
          : 'app.keepoala.com') +
        '/europe-west1-' +
        process.env.REACT_APP_PROJECT_ID +
        '.cloudfunctions.net:443') + '/firestore_get_nps',
    requestOptions,
  );
}
