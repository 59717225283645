import React, { useState } from 'react'
import { ReturnLabelType } from '../utils/helper/LineItemTypes';
import { useTranslation } from 'react-i18next';
import ReturnLabelTiny from './ReturnLabelTiny';
import InterText from '../styles/Inter';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import { ExpandLess } from '@material-ui/icons';
import Colors from '../utils/helper/Colors';
import { LineHorizontal } from './ReturnFeedback';

const FullWidth = styled.div`
  width: 100%;
`

const TopBar = styled.div`
  display: flex;
  justify-content: center;
`
const Spacer = styled.div`
  height: 30px;
`
const ExpandMoreIconStyled = styled(ExpandMoreIcon)`
  &:hover{
    color:  ${(props) => (props.theme.action_text_hover_primary ?? Colors.keepoala.greytext)};
    background-color: ${(props) => props.theme.bg_hover_primary ?? Colors.keepoala.actionGreendark};
  }
  align-self: center
`
const ExpandLessStyled = styled(ExpandLess)`
  &:hover{
    color:  ${(props) => (props.theme.action_text_hover_primary ?? Colors.keepoala.greytext)};
    background-color: ${(props) => props.theme.bg_hover_primary ?? Colors.keepoala.actionGreendark};
  }
  align-self: center
`
export default function ReturnLabelTinyCollection(props:{data: ReturnLabelType[], open_default: boolean}) {
  const {data, open_default} = props;
  const {t} = useTranslation()
  const [open, setOpen] = useState(open_default);
  return <FullWidth>
    <TopBar onClick={() => setOpen(!open)}>
  <InterText size={'l'} mb={0} mt={0} align='center' weight={600}>{t('Former returns')}</InterText>
  {
    open ?
    <ExpandLessStyled  /> :
    <ExpandMoreIconStyled />
  } 
  </TopBar>
  {
    (data === null || !open ? [] : data).map((returnLabel: ReturnLabelType) => (<ReturnLabelTiny returnLabel={returnLabel} />))
  }
  {!open ? <LineHorizontal mt={2} mb={2}/> : <Spacer /> }
</FullWidth>
}