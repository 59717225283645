import { ProductType } from "../../../types";
import { AddressFormData } from "../AddressForm";
import hmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";
import getEndpointUrl from "../../../utils/helper/getEndpointUrl";

function makeid(length:number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export async function setOrderInFirestore(
  email: string,
  shop: string,
  address_details: AddressFormData,
  product: ProductType,
  date_of_purchase: Date,
  platform: string
): Promise<{
  success: boolean;
  message: string;
  order_id: string;
}> {
  const first_name = address_details.name.trim().split(" ").at(0);
  const last_name = address_details.name.trim().split(" ").at(-1);

  const split_number_match =
    address_details.address1.match(/(\d+\s{0,1}\w{0,2})/g);

  let split_number = "1";
  if (split_number_match !== null && split_number_match.length >= 1) {
    split_number = split_number_match[0];
  }

  const shipping_address = {
    first_name: first_name,
    last_name: last_name,
    country: address_details.country === "DE" ? "Germany" : "Austria",
    city: address_details.city,
    zip: address_details.zip,
    address1: address_details.address1,
    address2: address_details.address2,
    latitude: null,
    longitude: null,
    name: address_details.name,
    country_code: address_details.country,
    houseNumber: split_number,
    street: address_details.address1.replace(/\d+\s{0,1}\w{0,2}/g, ""),
  };

  const id = `${platform}_${date_of_purchase.getTime()}_${last_name}_${makeid(3)}`

  const request_body = JSON.stringify({
    name: id,
    id: id,
    customer: {
      id: null,
      email: email,
    },
    created_at: date_of_purchase.toISOString(),
    email: email,
    currency: "EUR",
    note: null,
    discount_codes: [],
    fulfillment_status: "fulfilled",
    line_items: [Object.assign(product, {
      id: product.variant_id + Date.now().toString(),
      product_id: product.id,
      quantity: 1,
      total_discount: "0.00",
      total_discount_allocations: 0,
      discount_allocations: null
    })],
    shipping_address: shipping_address,
    shipping_lines: [],
    country: address_details.country,
    total_price: 0,
    total_shipping_costs: 0,
    Seller: {
      Platform: platform,
    },
    shop: shop,
    tags: ["Offline", platform]
  });

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-requested-with": "application/json",
      "Keepoala-Cors-Header": "enabled",
      Signature:
        "sha256=" +
        Base64.stringify(
          hmacSHA256(request_body, process.env.REACT_APP_G_CLOUD_KEY)
        ),
      shop: shop,
    },
    body: request_body,
  };

  return fetch(getEndpointUrl() + "/firestore_set_order", requestOptions).then(
    (response) => {
      if (response.status === 200) {
        return response.json().then((result) => {
          if (result.text.match("added") !== null) {

            return {
              success: true,
              message: result.text ?? "Upload successfull",
              order_id: id,
            };
          } else {
            return {
              success: false,
              message: result.text,
              order_id: ""
            }
          }
        });
      } else {
        try {
          return response.json().then((result) => {
            return {
              success: false,
              message: result.error ?? "Upload failed",
              order_id: ""
            };
          });
        } catch (error) {
          return {
            success: false,
            message: "Upload failed",
            order_id: ""
          };
        }
      }
    }
  );
}
