import React from "react";
import InterStyled from "../../styles/InterStyled";
import { ProductType } from "../../types";
import { PreviewImage } from "../OrderLineItem";

export default function ProductPreview(props: {
  product: ProductType;
  with_tick?: boolean;
}) {
  return (
    <div
      style={{
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      {props.product.image !== undefined ? (
        <PreviewImage
          src={props.product.image}
          style={{ height: 70, width: 70, padding: 0, margin: 0 }}
        />
      ) : props.product.image_preview !== undefined ? (
        <PreviewImage
          src={props.product.image_preview}
          style={{ height: 70, width: 70, padding: 0, margin: 0 }}
        />
      ) : null}
      <div style={{ width: 10 }} />
      <InterStyled interStyle="Label2">{props.product.title}</InterStyled>
    </div>
  );
}
