const ourusers = [
    "6cLJ2Cu8BEStXGmeinOeCTbuMHa2", //Seb
    "2XZKy1WGxbX1lKoiWlfKjTHFdfN2", //Omar
    "Bkkiwwhnw1QsscQTZ92XcHvVMNj2", // Eik
    "CJmgVF2JvJQotlLTyKleGRgPUhg1", // Viola
    "G0G9xxM0rQYy8T4UcGJy1spyzcf1", // Tjark
    // "LKz46MmqlVUczracrcBRhxWwBFl1", // Seb
    "Ohy62lHD3zaqCgIbAw8ty4w0Cek2", // Tjark
    "THZyjAayPsgxfSp5Mstn4GHSwzt2", // Seb
    "Vi8Q2VSjcJXfVuvqkAPiOVwLhCB3", // Sakshi
    "Z80QHT742IVEUwBhCnQLWal0vQj1", // Seb
    "gjPOl32Or1eUJfOFwzm6U4HNMMP2", // Seb
    "kfXGShDhsaNF97w7TFLlb7kh5XJ2", // Seb
    "vt8TWep5noft9VQCCV3ETsaFgTf1", // Merve
    "h74q6f3lQZacmHCyVpfUszLM5CD2", // Eik
];
export default ourusers;