import { ProductType } from "../../../types";
import { AddressFormData } from "../AddressForm";
import firebase from "firebase";
import { AnswerType } from "../../ReturnQuestions";
import { UrlAnswerType } from "../ReceiptUpload";
import { ShopifyProduct } from "../../../utils/helper/LineItemTypes";

export async function createTypeform(
  shop: string,
  user_email: string,
  address_details: AddressFormData,
  product: ProductType,
  date_of_purchase: Date,
  platform: string,
  receipt_url: UrlAnswerType,
  return_reasons_answers: AnswerType[],
  exchange_wishes: (ShopifyProduct | undefined)[],
  order_id: string,
): Promise<boolean> {
  const db = firebase.firestore();
  const last_name = address_details.name.trim().split(" ").at(-1);

  return db
    .collection("typeform")
    .add({
      email: user_email,
      name: order_id,
      shoporders_id: order_id,
      shop: shop,
      category: "product_questions",
      is_return: true,
      productName: product.title,
      productVariantID: product.variant_id ?? null,
      quantity: 1,
      exchange_wishes: exchange_wishes.map((x) => (x) ?? null),
      answers: [
        ...return_reasons_answers,
        {
          id: "L0001-Cat0101-Answ0001",
          question: "Where did you buy?",
          text: platform,
        },
        {
          id: "L0001-Cat0101-Answ0002",
          question: "productVariantId",
          text: product.variant_id,
        },
        {
          id: "L0001-Cat0101-Answ0002",
          question: "datenum",
          text: date_of_purchase.getTime() / 1000,
        },
        {
          id: "L0001-Cat0101-Answ0003",
          question: "address",
          text: Object.values(address_details).join(" "),
          obj: address_details,
        },
        {
          id: "L0001-Cat0101-Answ0004",
          question: "receipt",
          text: receipt_url.urls[0],
        },
        {
          id: "L0001-Cat0101-Answ0005",
          question: "receipt_ocr",
          text: receipt_url.text,
        },
      ],
      user_id: ""
    })
    .then(() => {
      return true;
    })
    .catch((e) => {
      console.error(e);
      return false;
    });
}
