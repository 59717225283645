import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init



// console.log(common_de["Go Profile"])
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .init({
    fallbackLng: "de",
    // lng: "de",
    whitelist: ['en', 'de'],
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;