import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Colors from '../utils/helper/Colors';
import {default as styled_component} from 'styled-components';

const ProgressQuestionsLinear = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#eaeaf0',
  },
  bar: {
    borderRadius: 5,
  },
}))(LinearProgress);

const ProgressQuestionsLinearStyled = styled_component(ProgressQuestionsLinear)`
  & .MuiLinearProgress-bar{
    background-color: ${(props) => {return props.theme.main ?? Colors.keepoala.keepoalaGreendefault}}
  }
`

export default ProgressQuestionsLinearStyled