import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import InterStyled from '../styles/InterStyled';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabelMUI from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '../styles/Button';
import { AuthContext } from '../utils/providers/AuthProvider';
import firebase from '../firebase';

const EmailSettingsContainer = styled.div`
  width: 400px;
  @media only screen and (max-width: 800px) {
    width: 300px;
  }
  @media only screen and (max-width: 400px) {
    width: 100%;
  }
`;

const FormControlLabel = styled(FormControlLabelMUI)`
  display: flex;
  align-items: center;
`;

export default function EmailSettings(props = {is_start:false}) {
  const { t } = useTranslation();

  const { userDetail, user } = useContext(AuthContext);
  const db = firebase.firestore();

  const [orderRelatedEmails, setOrderRelatedEmails] = useState(false);
  const [reminderEmails, setReminderEmails] = useState(false);
  const [shoppingActivityEmails, setShoppingActivityEmails] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    setOrderRelatedEmails(userDetail?.order_emails ? userDetail?.order_emails : (props.is_start ? true : true));
    setReminderEmails(userDetail?.reminder_emails ? userDetail?.reminder_emails : (props.is_start ? true : true));
    setShoppingActivityEmails(userDetail?.shopping_activity_emails ? userDetail?.shopping_activity_emails : (props.is_start ? true : true));
    setNewsletter(userDetail?.subscribed_to_newsletter ? userDetail?.subscribed_to_newsletter : true);
  }, [userDetail, props.is_start]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setDisableSave(true);

    const emailSettings = {
      order_emails: orderRelatedEmails,
      reminder_emails: reminderEmails,
      shopping_activity_emails: shoppingActivityEmails,
      subscribed_to_newsletter: newsletter,
      uid: user.uid
    }
    try {

      // Create document
      await db.collection("UserChange").doc(firebase.auth().currentUser?.uid + '_email').set(emailSettings);

      toast.success(t('Email Settings Changed Successfully'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    } catch (e) {
      console.error(e);
      // Send a notification
      toast.error(t('Failed to Change Settings, Please try Again'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    }

    setDisableSave(false);

  };

  return (
    <EmailSettingsContainer>
      <div>
        <InterStyled interStyle='Label1'>{t('Email Settings')}</InterStyled>
      </div>

      <form onSubmit={handleSubmit}>
        <FormGroup sx={{ marginLeft: '10px' }}>
          <FormControlLabel
            control={<Checkbox checked={orderRelatedEmails} onChange={(event) => setOrderRelatedEmails(event.target.checked)} size="medium" sx={{
              '&.Mui-checked': {
                color: "#7DD29A",
              },
            }} />}
            label={<InterStyled interStyle='P2' color="greytext" >{t('Order related emails')}</InterStyled>}
          />
          <FormControlLabel
            control={<Checkbox checked={reminderEmails} onChange={(event) => setReminderEmails(event.target.checked)} size="medium" sx={{
              '&.Mui-checked': {
                color: "#7DD29A",
              },
            }} />}
            label={<InterStyled interStyle='P2' color="greytext" >{t('Reminder Emails')}</InterStyled>}
          />
          <FormControlLabel
            control={<Checkbox checked={shoppingActivityEmails} onChange={(event) => setShoppingActivityEmails(event.target.checked)} size="medium" sx={{
              '&.Mui-checked': {
                color: "#7DD29A",
              },
            }} />}
            label={<InterStyled interStyle='P2' color="greytext" >{t('Shopping Activity emails (informing about your purchases at partners)')}</InterStyled>}
          />
          <FormControlLabel
            control={<Checkbox checked={newsletter} onChange={(event) => setNewsletter(event.target.checked)} size="medium" sx={{
              '&.Mui-checked': {
                color: "#7DD29A",
              },
            }} />}
            label={<InterStyled interStyle='P2' color="greytext" >{t('Newsletter? (100 extra Keepoalas)')}</InterStyled>}
          />
        </FormGroup>

        <Button
          color='primary'
          mt={2}
          type='submit'
          style={{ width: '50%' }}
          disabled={disableSave}
        >
          {t('Save')}
        </Button>
      </form>

    </EmailSettingsContainer>
  );
}
