/* eslint import/no-webpack-loader-syntax: off */
import React from 'react';
import Colors from '../utils/helper/Colors';
import Leaves from '!file-loader!../assets/img/Leaves.svg';
import LatoText, { LatoTextSpaced } from '../styles/lato';
import { useTranslation } from 'react-i18next';
import Button from '../styles/Button';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import split_query from '../utils/helper/split_query';
import {
  LinearProgress as MuiLinearProgress,
  Container,
} from '@material-ui/core';
import { shopify_get_email } from '../utils/helper/shopify_get_email';
import { AuthContext } from '../utils/providers/AuthProvider';
import community from '../assets/img/community.png';
import carbon from '../assets/img/carbon.png';
import saved from '../assets/img/saved.png';
import { BatchIMG, Batch, TopRow, BannerLogo } from './Banner1_Welcome';
import PermanentMarkerText from '../styles/PermanentMarker';
import KeepoalaWhite from '!file-loader!../assets/img/Keepoala_white.svg';
import { shopify_max_keepoalas } from '../utils/helper/shopify_max_keepoalas';
import { user_exists } from '../utils/helper/user_exists';
import { FormControl, Input, InputLabel } from '@material-ui/core';
import { BoxSpaced } from './Box';
import ALink from '../styles/ALink';
import StartScreen from './StartScreen.js';

const useStyles = makeStyles({
  outer: {
    minHeight: '90vh',
    backgroundColor: Colors.keepoala.main,
    paddingBottom: '20px',
  },
  inner: {
    minHeight: '90vh',
    content: '',
    backgroundImage: `url(${Leaves})`,
    backgroundSize: '100% auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  logo: {
    textAlign: 'center',
    maxWidth: '400px',
    width: '70%',
    marginTop: '1em',
  },
  tab: {
    position: 'fixed',
    bottom: '2em',
  },
  btn: {
    marginTop: 20,
    minWidth: '280px',
  },
});

const LinearProgressKeepoala = styled(MuiLinearProgress)`
  &.MuiLinearProgress-colorPrimary {
    background-color: ${Colors.keepoala.main};
    color: ${Colors.keepoala.main};
  }
  &.MuiLinearProgress-colorPrimary > .MuiLinearProgress-barColorPrimary {
    background-color: ${Colors.keepoala.bright};
  }
`;
const StyledLabel = styled(InputLabel)`
  &.MuiInputLabel-root {
    color: ${Colors.keepoala.lightgrey};
  }
`;
const StyledInput = styled(Input)`
  &.MuiInput-underline::before {
    border-bottom: 1px solid ${Colors.keepoala.greengrey};
  }
  &.MuiInputBase-input {
    color: white;
  }
  &.MuiInput-underline::after {
    border-bottom: 2px solid ${Colors.keepoala.main};
  }
`;

const FormBox = styled(BoxSpaced)`
  padding: 1.4em;
  max-width: 370px;
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default function TapToStart() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const [intializing, setInitializing] = React.useState(true);
  const [hasQuery, setHasQuery] = React.useState(false);
  const [generateAccount, setGenerateAccount] = React.useState(false);
  const [max_keepoalas, setMaxKeepoalas] = React.useState(0);
  const [email, setEmail] = React.useState('');

  const { signUpWithEmail, checkEmail } = React.useContext(AuthContext);

  const handleLogin = () => {
    var randomstring =
      Math.random().toString(36).slice(-8) +
      Math.random().toString(36).slice(-8);
    signUpWithEmail(email, randomstring, 'KeepoalaUser', i18n.language);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  React.useEffect(() => {
    async function getemail(query_params) {
      // retrive email
      const xx = await shopify_get_email(
        query_params.shop,
        query_params.order_id,
      );

      if (xx !== undefined) {
        return xx.json().then(async (res) => {
          const tester = res.test.split('@').length;
          console.log(tester);
          if (tester > 1) {
            const new_user = await checkEmail(res.test);
            if (new_user > 0) {
              return 'already_has_account';
            } else {
              return res.test;
            }
          } else {
            return 'order_no_exist';
          }
        });
      }
    }

    async function user_exists_call(email) {
      const xx = await user_exists(email);
      if (xx !== undefined) {
        return xx.json().then(async (res) => {
          return res.test === 'true';
        });
      }
    }

    async function setKeepoalas(email, shop) {
      const xx = await shopify_max_keepoalas(email, 'xx', shop);
      return xx;
    }

    console.log(location.search);
    if (location.search.length > 2) {
      const query_params = split_query(location.search);
      if (query_params.order_id !== undefined) {
        setHasQuery(true);
        localStorage.setItem('shopify_order_id', query_params.order_id);
        localStorage.setItem('shopify_shop', query_params.shop);

        if (query_params.email === undefined) {
          getemail(query_params).then((res) => {
            if (res === 'already_has_account') {
              navigate('/login');
            } else {
              if (res === 'order_no_exist') {
                location.search = '';
                window.history.replaceState({}, document.title, '/');
                setInitializing(false);
                setGenerateAccount(false);
              } else {
                setGenerateAccount(true);
                setEmail(res);
                setKeepoalas(res, query_params.shop).then((res) => {
                  setMaxKeepoalas(res.max_keepoalas);
                  setInitializing(false);
                  location.search = '';
                  window.history.replaceState({}, document.title, '/');
                });
                //
              }
            }
          });
        } else {
          user_exists_call(query_params.email).then((res) => {
            // TRUE MEANS USER EXISTS
            if (res) {
              navigate('/login');
            } else {
              // FALSE, we generate one
              setGenerateAccount(true);
              setEmail(query_params.email);
              setKeepoalas(query_params.email, query_params.shop).then(
                (res) => {
                  setMaxKeepoalas(300); // TODO: Get from DB
                  setInitializing(false);
                  location.search = '';
                  window.history.replaceState({}, document.title, '/');
                },
              );
            }
          });
        }
      } else {
        setInitializing(false);
        location.search = '';
        window.history.replaceState({}, document.title, '/');
      }
    } else {
      setInitializing(false);
    }
  }, [location, i18n.language]);

  // Return the old design if it has a query
  if (location.search.length > 2 || hasQuery) {
    return (
      <div className={classes.outer}>
        <div className={classes.inner}>
          {intializing ? (
            <div>
              <LinearProgressKeepoala />
              <br />
              <Container>
                <LatoText fontSize='xl' color='white'>
                  {t('Retrieving order information')}...
                </LatoText>
              </Container>
            </div>
          ) : (
            <div>
              <PermanentMarkerText
                mt={4}
                ml={2}
                color={Colors.keepoala.forestGreendefault}
                size={'32px'}
              >
                {t('Welcome at')}
              </PermanentMarkerText>
              <BannerLogo src={KeepoalaWhite} />
              {generateAccount ? (
                <FormContainer>
                  <FormBox mt={2}>
                    <LatoTextSpaced
                      mt={2}
                      ml={3}
                      fontSize={'xl'}
                      color='black'
                      align='left'
                    >
                      {t('Collect your')} <b>{max_keepoalas}</b>{' '}
                      {t('Keepoalas')}{' '}
                      {t('and make online-shopping more sustainable')}.
                    </LatoTextSpaced>
                    <FormControl margin='normal' required>
                      <StyledLabel htmlFor='email'>
                        {t('Email Address')}
                      </StyledLabel>
                      <StyledInput
                        id='email'
                        name='email'
                        value={email}
                        autoComplete='email'
                        autoFocus
                        onChange={handleEmailChange}
                      />
                      <Button
                        size='large'
                        mb={1}
                        mt={1}
                        color='primary'
                        className={classes.btn}
                        onClick={handleLogin}
                      >
                        {t("Let's go")}
                      </Button>
                    </FormControl>
                    <LatoText size='s' color='grey'>
                      {t('You accept the')}{' '}
                      <ALink href='/agb'>{t('Terms and conditions')}</ALink>
                    </LatoText>
                  </FormBox>
                </FormContainer>
              ) : null}
              <br />
              <br />
              {hasQuery ? (
                <LatoTextSpaced mt={2} ml={3} fontSize={'xl'} color='white'>
                  {t(
                    'With registering your purchase you made the first step to let the environment benefit from you not-returning',
                  )}
                  .
                </LatoTextSpaced>
              ) : (
                <LatoTextSpaced mt={2} ml={3} fontSize={'xl'} color='white'>
                  {t(
                    'With Keepoala you can let the environment benefit from you not-returning',
                  )}
                  .
                </LatoTextSpaced>
              )}
              <Container>
                <TopRow>
                  <Batch>
                    <BatchIMG src={saved} />
                    <LatoTextSpaced
                      fontSize={'xl'}
                      color='white'
                      fontWeight={700}
                    >
                      {t('Vouchers')}
                    </LatoTextSpaced>
                  </Batch>
                  <Batch>
                    <BatchIMG src={carbon} />
                    <LatoTextSpaced
                      fontSize={'xl'}
                      color='white'
                      fontWeight={700}
                    >
                      {t('save CO₂')}
                    </LatoTextSpaced>
                  </Batch>
                  <Batch>
                    <BatchIMG src={community} />
                    <LatoTextSpaced
                      fontSize={'xl'}
                      color='white'
                      fontWeight={700}
                    >
                      {t('Community')}
                    </LatoTextSpaced>
                  </Batch>
                </TopRow>
              </Container>
              {!generateAccount ? (
                <Button
                  size='large'
                  mb={2}
                  mt={8}
                  className={classes.btn}
                  onClick={() => navigate('/login')}
                >
                  {t('Sign In')}
                </Button>
              ) : null}
              <br />
              <Button
                size='large'
                color='secondary'
                mb={2}
                mt={4}
                type='submit'
                onClick={() => navigate('/signup')}
                className={classes.btn}
              >
                {generateAccount ? t('Register via social') : t('Register')}{' '}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
  // Return the new design
  else {
    return <StartScreen />;
  }
}
