import React, { useState, useContext } from 'react';
import { AuthContext } from '../utils/providers/AuthProvider';
import Dialog from '@mui/material/Dialog';
import EmailSettings from './EmailSettings';

export default function EmailSettingsFirstLoginModal() {

    const { newUser, setClosedEmailSettings, closedEmailSettings } = useContext(AuthContext);
    const [open, setOpen] = useState(true);

    if (!newUser || closedEmailSettings) return null;
    else return (
        <Dialog
            onClose={() => {
                setOpen(false);
                setClosedEmailSettings(true);
            }}
            open={open}
        >
            <div style={{ padding: '20px' }}>
                <EmailSettings is_start={true}/>
            </div>

        </Dialog>
    );
}
