import React, { useCallback, useEffect } from "react";
import { Container as MuiContainer } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReturnProductFeedbackType } from "../ReturnFeedback";
import { shipping_label_price_2 } from "../../utils/helper/shipping_label_price_2";
import { label_price_req } from "../../utils/helper/shipping_label_price_2";
import PaymentView from "./PaymentView";
import InterText from "../../styles/Inter";
import Button from "../../styles/Button";
import NoLabel from "../NoLabel";
import LoadingCircular from "../LoadingCircular";

const Container = styled(MuiContainer)`
  text-align: center;
`;

export type label_price = {
  parcel_weight: number;
  dhl_weight: number;
  price: number;
  currency: string;
  to_address: string;
};

export function PaymentDecision(props: {
  moveon: (x: boolean) => void;
  needs_payment: boolean;
  shop: string | undefined;
  line_items: ReturnProductFeedbackType[];
  shoporders_id: string | number;
  carrier_id: string | null;
  email: string | undefined;
}): React.ReactElement {
  const { moveon, needs_payment, shop, line_items, shoporders_id, carrier_id, email } = props;
  const item_count: number = line_items.map((x) => x.quantity).reduce((before, now) => before + now, 0);

  const { t } = useTranslation();

  const [label_price, setLabelPrice]: [label_price, any] = React.useState({
    price: 0,
    currency: "EUR",
    dhl_weight: 0,
    parcel_weight: 0,
    to_address: "",
  });

  const [decision, setDecision]: [string | undefined, any] = React.useState(undefined);

  useEffect(() => {
    if (!needs_payment) {
      moveon(true);
    }
  }, [moveon, needs_payment]);

  const handleLabelPrice = useCallback(() => {
    shipping_label_price_2(
      line_items.map((feedback_item: ReturnProductFeedbackType): label_price_req => {
        return {
          order_id: shoporders_id,
          shop: shop ?? "",
          variant_id: feedback_item.item.variant_id,
          quantity: feedback_item.quantity,
          carrier_id: carrier_id ?? "",
        };
      })
    ).then((response) => {
      if (response.price === 0) {
        moveon(true);
      } else {
        console.log(response);
        setLabelPrice(response);
      }
    });
  }, [line_items, moveon, shop, shoporders_id, carrier_id]);

  useEffect(() => {
    if (
      needs_payment &&
      line_items.length > 0 &&
      carrier_id !== null &&
      shop !== undefined &&
      shoporders_id !== undefined
    ) {
      handleLabelPrice();
    }
  }, [needs_payment, line_items, moveon, shop, shoporders_id, carrier_id, handleLabelPrice]);

  return (
    <Container maxWidth="sm" style={{ marginTop: "5vh" }}>
      <div></div>
      {needs_payment ? (
        decision === undefined ? (
          label_price.to_address === "" ? (
            <LoadingCircular />
          ) : (
            <>
              <InterText align="center" size="l" mb={2}>
                {t("You need to ship your order to")}
              </InterText>
              <InterText mt={0} size="m" weight={600} align="center" mb={2}>
                {label_price.to_address.split("\\n").map((x) => (
                  <span>
                    {x}
                    <br />
                  </span>
                ))}
              </InterText>
              <InterText align="center" mb={2} size="l">
                {t("Do you wish to buy a label now for just")} {label_price.price.toFixed(2)} {label_price.currency}?{" "}
                {t("You can print it directly after the purchase")}.
              </InterText>
              <Button
                variant="contained"
                color="secondary"
                mb={4}
                mt={2}
                ml={2}
                size="medium"
                onClick={() => setDecision("buy")}>
                {t("Yes, buy now")}
              </Button>
              <InterText align="center" size="l" mb={2} mt={2}>
                {t("Or, do you wish to purchase a label on your own")}
              </InterText>
              <Button
                variant="contained"
                color="primary"
                mb={2}
                mt={2}
                ml={2}
                size="medium"
                onClick={() => setDecision("donotbuy")}>
                {t("Buy my own")}
              </Button>
            </>
          )
        ) : decision === "buy" ? (
          <PaymentView
            order_id={shoporders_id.toString()}
            email={email ?? "unknown@customers.test.com"}
            shop={shop ?? "unknown"}
            label_info={label_price}
            item_number={item_count}
          />
        ) : (
          <NoLabel
            address={label_price.to_address}
            shop={shop}
            email={email}
            order_id={shoporders_id.toString()}
            productFeedback={line_items}
          />
        )
      ) : null}
    </Container>
  );
}
