import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

export async function shopify_max_keepoalas(email, customer_id, shop) {
    const req_body = JSON.stringify({shop: shop, customer_id: customer_id, email: email});

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
            'x-requested-with': 'application/json',
            'Keepoala-Cors-Header': 'enabled',
            'Signature':  'sha256=' + Base64.stringify(hmacSHA256(req_body, process.env.REACT_APP_G_CLOUD_KEY))
        },
        body: req_body
      };
    const response = await fetch((window.location.hostname === 'localhost' ? (`http://localhost:8100/europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net:443`) : ('https://'+ (process.env.REACT_APP_HOST !== undefined ? process.env.REACT_APP_HOST : 'app.keepoala.com') + '/europe-west1-' + process.env.REACT_APP_PROJECT_ID + '.cloudfunctions.net:443')) +'/shopify_count_orders_customer', requestOptions)
    return response.json().then(async (res) => {
        console.log(res)
        let max_keepoalas = res.test;
        if (res.is_user) {
            max_keepoalas = res.orders;
        } else {
            // Derive the maximum Keepoalas this user can gain
            max_keepoalas = parseFloat(res.test) * parseFloat(res.orders);
            if (max_keepoalas === 0) {
                max_keepoalas = max_keepoalas + parseFloat(res.test);
            }
        }
        return {
            max_keepoalas: max_keepoalas,
            is_user: res.is_user
        };
    }).catch((e) => {
        return {
            max_keepoalas: 300,
            is_user: false
        };
    })
}