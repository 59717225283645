import {AppBar as MuiAppBar} from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import Colors from '../utils/helper/Colors';

const AppBarPre = styled(MuiAppBar)`
    background-color: #ffffff;
    font-family: Lato;
    color: ${Colors.keepoala.black};
    text-transform: uppercase;
    font-weight: 700;
    font-size:20px;
    line-height:40px;
    letter-spacing: 2px;
`

const AppBar = styled(AppBarPre)(spacing);
export default AppBar