import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../../styles/Button";
import { shipping_label_purchase_get } from "../../utils/helper/payment/shipping_label_purchase_get";
import LoadingCircular from "../LoadingCircular";
import InterText from "../../styles/Inter";
import AlertError from "../Alert/AlertError";
import customerEmailCheck from "../../utils/helper/payment/customerEmailCheck";
import EmailChangeForm from "../Label/EmailChangeForm";
import ThankYouContent from "../ThankYouContent";
import { ThankYouContainer } from "../ReturnLabel";
import split_query from "../../utils/helper/split_query";
import { dhl_status_type, get_shop_dhl_status } from "../../utils/helper/get_pretty_shopName";
import { Coloring } from "../../types";
import KeepoalaThemeProvider from "../../styles/KeepoalaThemeProvider";
import { Container as DefaultContainer } from "../ReturnFeedback";
import { ShopLogo } from "../ReturnFeedbackCheck";

const Container = styled(DefaultContainer)`
  text-align: center;
  padding: 2em;
`;

const PaymentStatus = () => {
  window.onbeforeunload = function () {
    localStorage.clear();
  };

  const { search } = useLocation();
  const query_params: any = split_query(search);
  const { t } = useTranslation();

  const stripe = useStripe();
  const [message, setMessage]: [string | null, any] = useState(null);
  const [email, setEmail]: [string, any] = useState(query_params.email.replace("%40", "@") ?? "");
  const [newEmail, setNewEmail]: [string, any] = useState(email);
  const [label, setLabel]: [any, any] = useState(null);
  const [count, setCount]: [number, any] = useState(1);
  const [coloring, setColoring]: [Coloring | undefined, any] = useState(undefined);

  const counter = React.useRef(0);

  useEffect(() => {
    if (counter.current < 20) {
      counter.current += 1;
      console.log(counter.current);
      const timer = setTimeout(() => {
        setCount(count + 1);
        if (stripe !== null) {
          const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

          if (clientSecret !== null) {
            // Retrieve the PaymentIntent
            stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
              switch (paymentIntent?.status) {
                case "succeeded":
                  shipping_label_purchase_get(localStorage.getItem("email_label") ?? email, paymentIntent.id).then(
                    (value) => {
                      if (value.error === undefined) {
                        setLabel(value);
                        clearTimeout(timer);
                        counter.current = 20;
                        return;
                      } else {
                        console.error(value.message);
                        return;
                      }
                    }
                  );
                  break;

                case "processing":
                  setMessage("Payment processing. We'll update you when payment is received.");
                  break;

                case "requires_payment_method":
                  // Redirect your user back to your payment page to attempt collecting
                  // payment again
                  setMessage("Payment failed. Please try another payment method.");
                  break;

                default:
                  setMessage("Something went wrong.");
                  break;
              }
            });
          }
        }
      }, 1000);
      return () => clearTimeout(timer);
    } else if (counter.current >= 20 && label === null) {
      setMessage(t("We cannot find a label") + ". ");
    }
  }, [stripe, label, counter, count, email, t]);

  useEffect(() => {
    if (query_params.shop !== undefined) {
      get_shop_dhl_status(query_params.shop).then(
        (dhl_status: { res: dhl_status_type | undefined; err: string } | undefined) => {
          if (dhl_status !== undefined) {
            if (dhl_status.res !== undefined) {
              setColoring(dhl_status.res.coloring ?? undefined);
            }
          }
        }
      );
    }
  }, [query_params.shop]);

  return (
    <KeepoalaThemeProvider shop={query_params.shop} coloring={coloring}>
      <Container maxWidth="sm" style={{ marginTop: "10vh" }} className="cardbox">
        {query_params.shop !== undefined ? (
          <ShopLogo
            src={
              "https://storage.googleapis.com/" +
              process.env.REACT_APP_PROJECT_ID +
              "-public-data/shoplogos/" +
              query_params.shop +
              ".png"
            }
          />
        ) : null}
        <br />
        {message !== null && message !== "" ? (
          <div>
            <AlertError errorMessage={message ?? ""} />
          </div>
        ) : null}
        {label === null ? (
          message === null ? (
            <LoadingCircular />
          ) : null
        ) : (
          <>
            {customerEmailCheck(email) ? (
              <EmailChangeForm
                shop={label.shop}
                order_id={label.returnLabel.tracking_number}
                helptext={
                  t("We do not have any email address from you, yet") +
                  ". " +
                  t("To receive your label via email, please give it to us") +
                  ":"
                }
                setNewEmail={(x: string) => {
                  setNewEmail(x);
                  setEmail(x);
                }}
                type="real_label"
                product_feedback={[]}
              />
            ) : (
              <>
                <>
                  <InterText size="l" mb={0} mt={1} align="center">
                    {t("We sent all infos to")} <b>{email}</b>
                  </InterText>
                  <InterText size="l" mb={2} align="center">
                    {t("Please look also into your spam folder")}
                  </InterText>
                </>
              </>
            )}
            <>
              <InterText size="l" mb={0} mt={4} align="left">
                {(t("Your refund will appear within 5-10 working days after the return arrived") + ".")
                  .replace("5", localStorage.getItem("special_working_days_0") || "5")
                  .replace("10", localStorage.getItem("special_working_days_1") || "10")}
                <br />
                {(
                  t(
                    "Îf you decided for an exchange, we will send the new items within 5-10 business days after your return is on its way"
                  ) + "."
                )
                  .replace("5", localStorage.getItem("special_working_days_0") || "5")
                  .replace("10", localStorage.getItem("special_working_days_1") || "10")}
              </InterText>
              {localStorage.getItem("special_info") !== "" ? (
                <InterText size="l" mb={0} mt={1} align="left">
                  {localStorage.getItem("special_info")}
                </InterText>
              ) : null}
              {/* Remove QR Code button for carriers that are not DHL, default is DHL */}
              {label.returnLabel?.labelLink !== undefined ? (
                <div>
                  <a href={label.returnLabel?.labelLink} target="_blank" rel="noreferrer">
                    <Button mt={4} mb={4} color="secondary" style={{ minWidth: "250px" }}>
                      {t("PDF for printing")}
                    </Button>
                  </a>
                </div>
              ) : null}
            </>

            {/* Thank you container for non users */}
            <ThankYouContainer>
              <ThankYouContent
                email={newEmail !== "" ? newEmail : email}
                id={label.returnLabel.shoporders_id}
                shop={label.returnLabel.shop}
              />
            </ThankYouContainer>
          </>
        )}
        <div style={{ height: 100 }}></div>
      </Container>
    </KeepoalaThemeProvider>
  );
};

export default PaymentStatus;
