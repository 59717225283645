import React, { useEffect, useState } from 'react';
import { spacing } from '@material-ui/system';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl as MUIFormControl,
  Input,
  InputLabel,
} from '@material-ui/core';
import { Alert as MuiAlert } from '@material-ui/lab';

import Button from '../styles/Button';
import { useTranslation } from 'react-i18next';
import { shopify_verify_email_id } from '../utils/helper/shopify_verify_email_id';
import InterText from '../styles/Inter';
import Colors from '../utils/helper/Colors';
import { Progress } from './LoadingCircular';

const FormControl = styled(MUIFormControl)(spacing);
const Alert = styled(MuiAlert)(spacing);
export const ShopLogo = styled.img`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  max-height: 50px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: row;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;
const FullWidth = styled.div`
  width: 100%;
`;
const useStyles = makeStyles((theme) => ({
  btn: {
    marginTop: 20,
  },
  myAlert: {
    marginTop: 15,
    width: '90%',
  },
  hide: {
    display: 'none',
  },
  FormControl: {
    marginRight: 12,
  },
}));


/**
 * Component to check email addresses for orders
 * @param props
 *   * validatehandler - function to handle what needs to be
 *     done if the user inserts the correct email address
 *   * shop: name of a shop taking part in Keepoala and existing in Shops collection
 *   * order_id: id field of an order in shoporders collection
 *   * name: name of an order to display to the user
 *   * email: default email to be used
 * @returns
 */
export default function OrderFeedbackCheck(props: {
  validateHandler: (x: boolean, email: string) => void;
  shop: string | undefined;
  email: string | undefined;
  order_id: string | undefined;
  name: string | undefined;
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [email, setEmail]: [
    string,
    React.Dispatch<React.SetStateAction<string>>,
  ] = useState('');
  const [errorMessage, setErrorMessage]: [
    string | undefined,
    React.Dispatch<React.SetStateAction<string | undefined>>,
  ] = useState();
  const [progress, setProgress] = useState(false);

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  async function verify_email_or_firstname_id(
    shop: string,
    email: string,
    order_id_name: string,
  ) {
    const xx = await shopify_verify_email_id(
      shop,
      email,
      order_id_name,
      '',
      false,
    ).catch(() => {
      setErrorMessage(t('Problem connecting to Keepoala Server'));
    });
    if (xx !== undefined) {
      return xx
        .json()
        .then(
          async (res: {
            test: boolean;
            error?: string;
            email: string;
            name?: string;
            id?: string;
          }) => {
            if (res.test) {
              setErrorMessage('');
              setProgress(false);
              props.validateHandler(true, res.email);
            } else {
              let errorMessage =
                res.error !== undefined ? res.error : 'Error occured';
              setErrorMessage(t(errorMessage));
              setProgress(false);
            }
          },
        )
        .catch((e: any) => {
          setErrorMessage(t('Problem connecting the Keepoala Server'));
          return false;
        });
    } else {
      setErrorMessage(t('Problem connecting the Keepoala Server'));
      return false;
    }
  }

  // On submit, check the email against our database via verifyEmail
  // after that call the validateHandler or show the error
  const handleSubmit = (e: any) => {
    e.preventDefault();
    setProgress(true);
    if (
      props.shop !== undefined &&
      email !== undefined &&
      props.order_id !== undefined
    ) {
      verify_email_or_firstname_id(props.shop, email, props.order_id);
    }
  };

  useEffect(() => {
    if (props.email !== undefined) {
      setEmail(props.email);
    }
  }, [props.email]);

  return (
    <FullWidth>
      <InterText size='l' mb={4} mt={1} align='center'>
        {t('How satisfied are you with your order') + ' '}
        <b>
          {props.name !== undefined && props.name !== null
            ? props.name.replace(/%23/g, '#')
            : ''}
        </b>
        {' ' + t('from')}
      </InterText>
      {
        // derive the Shop logo from Google Cloud Storage
      }
      <ShopLogo
        src={
          'https://storage.googleapis.com/' + process.env.REACT_APP_PROJECT_ID + '-public-data/shoplogos/' +
          props.shop +
          '.png'
        }
      />

      <InterText size='l' mb={1} mt={4} align='center'>
        {t(
          'Please insert your email address to continue with the questionairy',
        ) + '.'}
      </InterText>
      {
        // Create a form that lets the user insert the email address
      }
      <Form onSubmit={handleSubmit}>
        <FormControl
          margin='normal'
          size='medium'
          required={true}
          className={classes.FormControl}
        >
          <InputLabel htmlFor='email'>{t('Email Address')}</InputLabel>
          <Input
            id='email'
            name='email'
            value={email}
            autoComplete='email'
            autoFocus
            onChange={handleEmailChange}
          />
        </FormControl>
        <InterText mr={2}>{''}</InterText>
        <Button
          variant='contained'
          color='primary'
          mb={2}
          size='medium'
          id='submit'
          type='submit'
          value='Submit'
          className={classes.btn}
        >
          {!progress ? t('Verify Email Address') : <Progress />}
        </Button>
      </Form>
      {
        //Show any trouble
      }
      <Alert
        mb={4}
        severity='error'
        className={`${classes.myAlert} ${
          errorMessage === undefined ? classes.hide : ''
        }`}
      >
        {errorMessage}
      </Alert>
    </FullWidth>
  );
}
