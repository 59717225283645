/* eslint import/no-webpack-loader-syntax: off */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@mui/material';
import HomepagePackage from '!file-loader!../assets/img/homepage_package.svg';
import InterStyled from '../styles/InterStyled';
import InterText from '../styles/InterText';
import MUIRouterLink from '../styles/MUIRouterLink';
import Button from '../styles/Button';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '15px',
  },
  welcomeSection: {
    height: 'calc(100vh - 105px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 89px)',
    },
  },
  buttonSection: {
    marginTop: '80px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '42px !important',
    },
  },
  registerButton: {
    marginLeft: '50px',
    [theme.breakpoints.down('xs')]: {
      display: 'block !important',
      marginLeft: '0px',
      marginTop: '20px',
    },
  },
}));

export default function TapToStart() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Grid
        container
        spacing={4}
        className={classes.welcomeSection}
        justifyContent='center'
        alignItems='center'
        columnSpacing={{ xs: 1, sm: 2, md: 10 }}
      >
        <Grid item xs={12} md={10} lg={7}>
          <div>
            <InterText
              color='greytext'
              size='title'
              type='semi-bold'
              className={classes.title}
            >
              {t('Save returns and the environment')}
            </InterText>
          </div>
          <div style={{ marginTop: '20px' }}>
            <InterText color='greytext' size='xl' type='regular'>
              {t('Keep your returns and get rewarded with great vouchers') +
                '.' +
                t("Sign in or Register to get started today! It's free") +
                '.'}
            </InterText>
          </div>
          <div className={classes.buttonSection}>
            <MUIRouterLink to='/login'>
              <Button style={{ width: '196px' }} color='primary'>
                {t('Sign In')}
              </Button>
            </MUIRouterLink>
            <span className={classes.registerButton}>
              <MUIRouterLink to='/signup'>
                <Button style={{ width: '196px' }} color='secondary'>
                  {t('Register')}
                </Button>
              </MUIRouterLink>
            </span>
          </div>
        </Grid>
        <Grid
          item
          xs={5}
          sx={{
            display: { xs: 'none', lg: 'block' },
          }}
        >
          <img
            style={{ width: '100%', paddingTop: '80px' }}
            src={HomepagePackage}
            alt='Package'
          />
        </Grid>
      </Grid>
    </Container>
  );
}
