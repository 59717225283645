import { ShopifyLineItemForPortal } from "../../../utils/helper/LineItemTypes";
import { shopify_get_exchange_items } from "../../../utils/helper/shopify_get_line_items copy";

export default async function shop_exchange_query(shop, variant_id, lang):Promise<{err: string | undefined, res: ShopifyLineItemForPortal | undefined}> {
  const xx = await shopify_get_exchange_items(shop, variant_id, lang)
  .catch((e: any) => {
    console.error(e);
    return undefined
  });
  if (xx !== undefined) {
    if (xx.status < 300) {
      return xx
        .json()
        .then(async (res: any) => {
          return {
            res: res,
            err: undefined,
          };
        })
        .catch((e: any) => {
          return { res: undefined, err: "Problem connecting the Keepoala Server" };
        });
    } else {
      return xx
        .json()
        .then(async (res: any) => {
          console.log(res);
          // const x = await res.test;
          //setErrorMessage('')
          return {
            res: undefined,
            err: res.test,
          };
        })
        .catch((e: any) => {
          //setErrorMessage(t('Problem connecting the Keepoala Server'))
          return { res: undefined, err: "Problem connecting the Keepoala Server" };
        });
    }
  } else {
    return {
      res: undefined,
      err: "Problem connecting the Keepoala Server"
    }
  }
}