import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import InterStyled from "../../styles/InterStyled";
import StepperButtons from "./StepperButtons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/de";

export default function DateOfPurchase(props: {
  date_of_purchase: Date | undefined;
  setDateOfPurchase: Dispatch<SetStateAction<Date | undefined>>;
  onContinuePressed: () => void;
  onBackPressed: () => void;
}) {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <InterStyled interStyle="Label 1">{t("Date of Purchase")}</InterStyled>
      <div style={{ height: 10 }} />
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={i18n.language}
      >
        {/* Set default to two weeks ago */}
        <DatePicker
          disableFuture={true}
          onChange={(new_date) => {
            if (new_date !== null) {
              props.setDateOfPurchase(new_date.toDate());
            }
          }}
        />
      </LocalizationProvider>
      <div style={{ height: 20 }} />
      <StepperButtons
        onContinuePressed={
          props.date_of_purchase !== undefined
            ? props.onContinuePressed
            : undefined
        }
        onBackPressed={props.onBackPressed}
      />
    </div>
  );
}
