import React from 'react'
import { useTranslation } from "react-i18next";
import Link from '../styles/Link'
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import {default as Lato} from '../styles/lato';

const LatoText = styled(Lato)(spacing);

export default function TermsLinks() {
    const {t} = useTranslation();
    return(<LatoText
        variant="body1"
        component="h2"
        align="center"
    >
        {t('With the registration you accept the')},{" "}
        <Link
            href="/agb"
            target="_blank"
            style={{ textDecoration: "none" }}
        >
            {t('Terms and conditions')}
        </Link>
        &nbsp;
        {t('and the')}
        &nbsp;
        <Link
            target="_blank"
            href="/privacy_policy"
            style={{ textDecoration: "none" }}
        >
            {t('Privacy Policy')}
        </Link>
    </LatoText>)
}
             