import React from 'react';
import { useTranslation } from 'react-i18next';
import { analytics } from '../firebase';
import { Container, Card as MUICard } from '@mui/material';
import styled from 'styled-components';
import InterStyled from '../styles/InterStyled';
// Password
import ProfileSettings from './ProfileSettings';
import EmailSettings from './EmailSettings';
import Stats from './Stats';
import ShopData from './ShopData';
import FAQ from './FAQ';

const Card = styled(MUICard)`
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.15) !important;
  margin: 25px 0px;
  padding: 20px;
`;
const DoubleCard = styled(Card)`
  display: flex;
  justify-content: flex-start;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

function Profile() {
  const { t } = useTranslation();

  // Send event to GA
  React.useEffect(() => {
    analytics.logEvent('screen_view', {
      firebase_screen: 'ProfileHome',
      firebase_screen_class: 'WebView',
    });
  }, []);

  return (
    <Container style={{ marginTop: '40px', paddingBottom: '60px' }}>
      {/* Background color of the page */}
      <style>{'body { background: #F2F2F2;}'}</style>

      {/* Data Overview Section */}
      <div>
        <InterStyled
          interStyle='H2'
          transform='uppercase'
          color='forestGreendefault'
          style={{ letterSpacing: '1px' }}
        >
          {t('Data Overview')}
        </InterStyled>
      </div>

      <Card>
        <ShopData />
      </Card>

      {/* Settings Section */}
      <div>
        <InterStyled
          interStyle='H2'
          transform='uppercase'
          color='forestGreendefault'
          style={{ letterSpacing: '1px' }}
        >
          {t('Settings')}
        </InterStyled>
      </div>

      <DoubleCard>
        <ProfileSettings />
        <EmailSettings is_start={false}/>
      </DoubleCard>

      {/* Statistics Section */}
      <div>
        <InterStyled
          interStyle='H2'
          transform='uppercase'
          color='forestGreendefault'
          style={{ letterSpacing: '1px' }}
        >
          {t('Statistics')}
        </InterStyled>
      </div>

      <Card>
        <Stats />
      </Card>

      {/* FAQ Section */}
      <div>
        <InterStyled
          interStyle='H2'
          transform='uppercase'
          color='forestGreendefault'
          style={{ letterSpacing: '1px' }}
        >
          {t('FAQ')}
        </InterStyled>
      </div>

      <Card>
        <FAQ dense={true} />
      </Card>
    </Container>
  );
}

export default Profile;
