import React, { useContext } from "react";
import { Route, Routes, BrowserRouter as Router, Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import Login from "../../components/Login";
import ResetPassword from "../../components/ResetPassword";
import Home from "../../components/Home";
import history from "./history";
import SignUp from "../../components/SignUp";
import Profile from "../../components/Profile";
import TapToStart from "../../components/TapToStart";
import VerifyEmail from "../../components/VerifyEmail";
import { Container } from "@material-ui/core";
import AGB from "../../components/AGB";
import PrivacyPolicy from "../../components/PrivacyPolicy";
import FAQ from "../../components/FAQ";
import VoucherStore from "../../components/VoucherStore/VoucherStore";
import WriteToSupport from "../../components/WriteToSupport";
import ManualllyAdd from "../../components/ManuallyAdd";
import OrderFeedback from "../../components/OrderFeedback";
import OrderFeedbackOverview from "../../components/OrderFeedbackOverview";
import ReturnManager from "../../components/ReturnManager";
import ReturnFeedback from "../../components/ReturnFeedback";
import NpsFeedback from "../../components/NpsFeedback";
import NavigationBar from "../../components/NavigationBar";
import JourneyRedirect from "../../components/JourneyRedirect";
import EmailSettingsWithoutLogin from "../../components/EmailSettingsWithoutLogin";
import ReturnExperienceRater from "../../components/ReturnExperienceRater";
import LoadingCircular from "../../components/LoadingCircular";
import PaymentStatus from "../../components/Payment/PaymentStatus";
import ReturnFeedbackIntro from "../../components/ReturnFeedbackIntro";
import ReturnFeedbackManual from "../../components/ReturnFeedbackManual";

const AppRoutes = () => {
  const { authenticated, loadingAuthState } = useContext(AuthContext);

  // Set Key for return questions
  localStorage.setItem("tokenKey", process.env.REACT_APP_RETURN_QUESTIONS_KEY);

  if (loadingAuthState) {
    return <LoadingCircular />;
  }

  const AddNavbarToRoute = () => {
    return (
      <div>
        <NavigationBar />
        <Outlet />
      </div>
    );
  };

  return (
    <Router history={history}>
      <Routes>
        <Route path="/returnexperiencerater" element={<ReturnExperienceRater />} />
        <Route element={<AddNavbarToRoute />}>
          {!authenticated ? (
            <>
              <Route exact path="/" element={<TapToStart />} />
              <Route exact path="/home" element={<TapToStart />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/signup" element={<SignUp />} />
              <Route exact path="/resetpassword" element={<ResetPassword />} />
              <Route
                exact
                path="/verifyemail"
                element={
                  <Container size="md">
                    <VerifyEmail />
                  </Container>
                }
              />
              <Route
                exact
                path="/agb"
                element={
                  <Container size="md">
                    <AGB />
                  </Container>
                }
              />
              <Route
                exact
                path="/privacy_policy"
                element={
                  <Container size="md">
                    <PrivacyPolicy />
                  </Container>
                }
              />
              <Route
                exact
                path="/faq"
                element={
                  <Container size="md">
                    <FAQ />
                  </Container>
                }
              />
              <Route
                exact
                path="/writetosupport"
                element={
                  <Container size="md">
                    <WriteToSupport />
                  </Container>
                }
              />
              <Route exact path="/orderfeedback" element={<OrderFeedback />} />
              <Route
                exact
                path="/paymentstatus"
                element={
                  <Container size="md">
                    <PaymentStatus />
                  </Container>
                }
              />
              <Route
                exact
                path="/returnfeedback"
                element={
                  <Container size="md">
                    <ReturnFeedback />
                  </Container>
                }
              />
              <Route
                exact
                path="/returnfeedbackintro"
                element={
                  <Container size="md">
                    <ReturnFeedbackIntro />
                  </Container>
                }
              />
              <Route
                exact
                path="/returnfeedbackmanual"
                element={
                  <Container size="md">
                    <ReturnFeedbackManual />
                  </Container>
                }
              />
              <Route
                exact
                path="/npsfeedback"
                element={
                  <Container size="md">
                    <NpsFeedback />
                  </Container>
                }
              />
              <Route exact path="/emailsettings" element={<EmailSettingsWithoutLogin />} />

              <Route path="*" element={<Navigate to="/" replace />} />
              <Route index element={<TapToStart />} />
            </>
          ) : (
            <>
              <Route exact path="/manuallyadd" element={<ManualllyAdd />} />
              <Route
                exact
                path="/overview"
                element={
                  <Container size="md">
                    <Profile />
                  </Container>
                }
              />
              <Route exact path="/emailsettings" element={<Navigate to="/overview" replace />} />
              <Route
                exact
                path="/voucher"
                element={
                  <Container size="md">
                    <VoucherStore />
                  </Container>
                }
              />
              <Route
                exact
                path="/vouchers"
                element={
                  <Container size="md">
                    <VoucherStore />
                  </Container>
                }
              />
              <Route
                exact
                path="/faq"
                element={
                  <Container size="md">
                    <FAQ />
                  </Container>
                }
              />
              <Route
                exact
                path="/writetosupport"
                element={
                  <Container size="md">
                    <WriteToSupport />
                  </Container>
                }
              />
              <Route
                exact
                path="/orderfeedback"
                element={
                  <Container size="md">
                    <OrderFeedback />
                  </Container>
                }
              />
              <Route
                exact
                path="/orderfeedbackoverview"
                element={
                  <Container size="md">
                    <OrderFeedbackOverview />
                  </Container>
                }
              />
              <Route
                exact
                path="/returnmanager"
                element={
                  <Container size="md">
                    <ReturnManager />
                  </Container>
                }
              />
              <Route
                exact
                path="/returnfeedback"
                element={
                  <Container size="md">
                    <ReturnFeedback />
                  </Container>
                }
              />
              <Route
                exact
                path="/returnfeedbackintro"
                element={
                  <Container size="md">
                    <ReturnFeedbackIntro />
                  </Container>
                }
              />
              <Route
                exact
                path="/returnfeedbackmanual"
                element={
                  <Container size="md">
                    <ReturnFeedbackManual />
                  </Container>
                }
              />
              <Route
                exact
                path="/paymentstatus"
                element={
                  <Container size="md">
                    <PaymentStatus />
                  </Container>
                }
              />
              <Route
                exact
                path="/npsfeedback"
                element={
                  <Container size="md">
                    <NpsFeedback />
                  </Container>
                }
              />
              <Route
                exact
                path="/agb"
                element={
                  <Container size="md">
                    <AGB />
                  </Container>
                }
              />
              <Route
                exact
                path="/privacy_policy"
                element={
                  <Container size="md">
                    <PrivacyPolicy />
                  </Container>
                }
              />
              <Route exact path="/journey" element={<JourneyRedirect />} />

              <Route path="*" element={<Navigate to="/" replace />} />
              <Route index element={<Home />} />
            </>
          )}
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
