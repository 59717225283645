import React, { useContext } from 'react';
import {
  FormControl,
  Input,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { AuthContext } from '../utils/providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Colors from '../utils/helper/Colors';
import Button from '../styles/Button';
import firebase from '../firebase';
import { toast } from 'react-toastify';
// import shops from '../utils/helper/shops';
import ourusers from '../utils/helper/ourusers';
import { analytics } from '../firebase';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    top: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '90%',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  wrap: {
    marginTop: '2em',
  },
  form: {
    margin: 'auto',
    maxWidth: 500,
    width: '100%',
  },
  small: {
    fontSize: '12px',
    color: Colors.keepoala.lightgrey,
    fontFamily: 'Lato',
    marginBottom: 10,
  },
  btn_wrapper: {
    marginTop: 10,
    textAlign: 'right',
    display: 'flex',
  },
  h1: {
    fontSize: 24,
    color: Colors.keepoala.dark,
    fontFamily: 'Lato',
    fontWeight: 700,
  },
  text: {
    textAlign: 'left',
    fontFamily: 'Lato',
    fontSize: 16,
    color: Colors.keepoala.black,
    display: 'block',
    width: '100%',
    maxWidth: 500,
    margin: 'auto',
  },
  error: {
    textAlign: 'left',
    fontFamily: 'Lato',
    fontSize: 16,
    color: 'red',
    display: 'block',
    width: '100%',
    maxWidth: 500,
    margin: 'auto',
  },
}));

export default function ManualllyAdd() {
  const { t } = useTranslation();
  const db = firebase.firestore();
  const { user } = useContext(AuthContext);
  const errorMessage =
    t('You can only add one order at a time') +
    '. ' +
    t('Please wait until our service processed your order') +
    '.';

  const classes = useStyles();

  const [order_name, setOrderName] = React.useState('');
  const [shop, setShop] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState('');
  const [shops, setShops] = React.useState([]);

  React.useEffect(() => {
    db.collection('Shops')
      .where('cooperation', '==', true)
      .get()
      .then(
        (querySnapshot) => {
          let temp_shops = [];

          querySnapshot.forEach((documentsnap) => {
            temp_shops.push({
              ...documentsnap.data(),
            });
          });
          console.log(temp_shops);

          setShops(temp_shops);
        },
        (err) => console.log(err),
      )
      .catch((err) => {
        console.log(err);
      });
  }, [db]);

  function handleSubmit(e) {
    e.preventDefault();
    db.collection('Orders')
      .where('user_id', '==', user.uid)
      .where('shopify.id', '==', 'unknown')
      .get()
      .then((querysnap) => {
        if (querysnap.size < 1) {
          db.collection('OrderChange')
            .add({
              type: 'new',
              dict: {
                user_id: user.uid,
                name: shop.name + ' - ' + order_name + ' - waiting for service',
                tracking_numbers: [],
                shopify: {
                  id: 'unknown',
                  customer: {
                    email: user.email,
                    id: 'unknown',
                  },
                  name: order_name,
                  shop: shop.url,
                },
              },
            })
            .then(() => {
              toast.info(t('Added order'));
              setSuccess(true);
              if (!ourusers.includes(user.uid)) {
                analytics.logEvent('Manually added order', {
                  event: 'Action',
                });
              }
              // In case from the outside there was a successHandler given, refresh
            })
            .catch((e) => {
              console.log(e);
              toast.error(t('Something went wrong'));
            });
        } else {
          toast.error(errorMessage, {
            autoClose: false,
          });
          setError(errorMessage);
        }
      })
      .catch((e) => console.log(e));
  }

  if (success) {
    return (
      <div className={classes.wrap}>
        <div className={classes.wrapper}>
          <h1 className={classes.h1}>{t('Order succesfully added')}</h1>
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.wrap}>
        <div className={classes.wrapper}>
          <h1 className={classes.h1}>{t('Manually add an order')}</h1>
          <div className={classes.text}>
            {t('Manually add an order with this form')}.{' '}
            {t(
              'In case your order was not automaticall added by our system, you can use this functionality to inform our service',
            )}
            . {t('The service will add it to your account as soon as possible')}
            .
          </div>
          <form onSubmit={handleSubmit} className={classes.form}>
            <FormControl margin='none' required fullWidth>
              <InputLabel htmlFor='email'>
                {t('Order Name')} {t('e.g.')} "#1020"
              </InputLabel>
              <Input
                id='order_name'
                name='order_name'
                value={order_name}
                autoComplete='order_name'
                autoFocus
                fullWidth
                onChange={(e) => {
                  setOrderName(e.target.value);
                }}
              />
              <div className={classes.small}>
                {t('Please look at your order confirmation')}.{' '}
                {t('You will find this number there')}.
              </div>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor='shop'>{t('Shop')}</InputLabel>
              <Select
                id='shop'
                name='shop'
                value={shop}
                label='Shop'
                onChange={(event) => setShop(event.target.value)}
                MenuProps={{ style: { maxHeight: '400px' } }}
              >
                {shops.map((shop) => {
                  return (
                    <MenuItem
                      dense={false}
                      key={shop.name}
                      id={'shop' + shop.name}
                      name={shop.name}
                      value={shop}
                    >
                      {shop.pretty !== undefined && shop.pretty !== ''
                        ? shop.pretty
                        : shop.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl className={classes.btn_wrapper}>
              <Button
                mb={3}
                color='primary'
                id='submit'
                type='submit'
                value='Submit'
                fullWidth
              >
                {t('Add this order')}
              </Button>
            </FormControl>
            {error !== '' ? (
              <div className={classes.error}>{errorMessage}</div>
            ) : null}
          </form>
        </div>
      </div>
    );
  }
}
