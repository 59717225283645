/* eslint import/no-webpack-loader-syntax: off */
import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import MUIButton from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '../../styles/Button';
import { db, analytics } from '../../firebase';
import smallKeepoalagrey from '../../assets/img/smallKeepoalagrey.svg';
import InterStyled from '../../styles/InterStyled';
import { AuthContext } from '../../utils/providers/AuthProvider';
import Colors from '../../utils/helper/Colors';
import ourusers from '../../utils/helper/ourusers';

const WhiteBox = styled(Card)`
  background-color: #ffffff !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px;
  margin-right: 25px;
  max-width: 500px;
`;

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  margin: '20px 0px',
});

export default function VoucherDetail(props) {
  const { voucher, handleBuyVoucher } = props;
  const { t, i18n } = useTranslation();
  const { user, emailverify } = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [open_verify_error, setOpenVerifyError] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseVerifyError = () => {
    setOpenVerifyError(false);
  };

  const handleBuy = () => {
    setOpen(false);
    if (emailverify) {
      db.collection('VoucherOrder')
        .add({
          type: 'voucher',
          dict: voucher,
          voucher_document_id: voucher.key,
          user_id: user.uid,
          lang: i18n.language.toString(),
          email: user.email,
          name:
            user.displayName === undefined ? 'lieber Nutzer' : user.displayName,
        })
        .then(() => {
          toast.info(t('Send voucher order'));

          // Send event to GA
          if (!ourusers.includes(user.uid)) {
            analytics.logEvent('voucher_purchase_success', {
              feature: 'challenges',
              event: 'take part',
            });
          }

          handleBuyVoucher(voucher.keepoalas);
        });
    } else {
      setOpenVerifyError(true);
    }
  };

  return voucher !== undefined ? (
    <Container>
      <WhiteBox>
        <div style={{ padding: '10px 20px' }}>
          {/* Image */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '53.5%',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <img
              src={voucher.photo}
              alt='voucher'
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: ' translate(-50%,-50%)',
                right: '0',
                bottom: '0',
                width: '100%',
              }}
            />
          </div>

          {/* Heading and Logo section */}
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            style={{ marginTop: '10px', marginBottom: '15px' }}
          >
            {/* Voucher heading */}
            <Grid item xs='auto'>
              <InterStyled interStyle='H2' color='greytext'>
                {voucher.heading1}
              </InterStyled>
            </Grid>

            {/* Voucher Partner logo */}
            <Grid item xs='auto'>
              <img
                src={voucher.partner.logo}
                style={{ maxWidth: '110px', maxHeight: '32px' }}
                alt='partner logo'
              />
            </Grid>
          </Grid>

          <div style={{ marginBottom: '10px' }}>
            <InterStyled interStyle='P3'>{voucher.terms}</InterStyled>
          </div>
        </div>

        {/* Lower section */}
        <Grid
          container
          style={{ padding: '10px 20px', backgroundColor: '#F8F8F8' }}
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid
            item
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={smallKeepoalagrey}
              style={{ marginRight: '6px', width: '15px', height: '20px' }}
              alt='keepoala grey logo'
            />
            <InterStyled interStyle='H2' color='greytext'>
              {voucher.keepoalas}
            </InterStyled>
          </Grid>

          <Grid item>
            <InterStyled interStyle='P3'>{voucher.heading2}</InterStyled>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ marginTop: '15px', marginBottom: '10px' }}
          >
            <Button
              onClick={handleClickOpen}
              color='primary'
              style={{ width: '100%' }}
            >
              {t('Redeem Voucher')}
            </Button>
          </Grid>
        </Grid>
      </WhiteBox>

      {/* Dialogs for confirmation */}
      {/* Get Voucher Confirmation */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {t('Do you really want to buy this voucher')}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MUIButton
            onClick={handleClose}
            style={{ color: Colors.keepoala.greydefault }}
          >
            {t('No')}
          </MUIButton>
          <MUIButton
            onClick={handleBuy}
            autoFocus
            style={{ color: Colors.keepoala.keepoalaGreendefault }}
          >
            {t('Yes')}
          </MUIButton>
        </DialogActions>
      </Dialog>

      {/* Verify Email Dialog */}
      <Dialog
        open={open_verify_error}
        onClose={handleCloseVerifyError}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {t('Please verify your Email')}!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MUIButton
            onClick={handleCloseVerifyError}
            autoFocus
            style={{ color: Colors.keepoala.keepoalaGreendefault }}
          >
            {t('OK')}
          </MUIButton>
        </DialogActions>
      </Dialog>
    </Container>
  ) : null;
}
