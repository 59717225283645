import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Input, InputLabel } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Alert as MuiAlert } from '@material-ui/lab';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { AuthContext } from '../utils/providers/AuthProvider';
import { default as Lato } from '../styles/lato';
import split_query from '../utils/helper/split_query';
import Button from '../styles/Button';
import ALink from '../styles/ALink';
import Link from '../styles/Link';
import { useTranslation } from 'react-i18next';
import Seperator from './Seperator';
import TermsLinks from './TermsLinks';
import SocialLogin from './SocialLogin';

const Alert = styled(MuiAlert)(spacing);
const LatoText = styled(Lato)(spacing);

const useStyles = makeStyles((theme) => ({
  link: {
    margin: 5,
    textDecoration: 'none',
  },
  linksWrapper: {
    display: 'flex',
    marginTop: 'auto',
  },
  myAlert: {
    marginTop: 15,
    width: '90%',
  },
  hide: {
    display: 'none',
  },
  btn: {
    marginTop: 20,
  },
  forgotText: {
    marginTop: 10,
  },
  hidePassBtn: {
    width: 16,
    height: 16,
    margin: -8,
  },
  wrapper: {
    top: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '90%',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  wrap: {
    marginTop: '2em',
  },
}));

function Login() {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { handleSignIn, authenticationError } = useContext(AuthContext);
  const [redirectToReturnFeedback, setRedirectToReturnFeedback] =
    useState(false);

  const location = useLocation();
  const { t } = useTranslation();

  if (location.search.length > 2) {
    const query_params = split_query(location.search);
    if (query_params.order_id !== undefined) {
      localStorage.setItem('shopify_order_id', query_params.order_id);
      localStorage.setItem('shopify_shop', query_params.shop);
      location.search = '';
    }
  }

  useEffect(() => {
    if (authenticationError !== 'empty') {
      setAlertMessage(authenticationError);
      setShowAlert(true);
    }
  }, [authenticationError]);

  // useEffect at the beginning of the component mount to check for state
  // If the state is set with doc_id and is_shopify will redirect after login
  useEffect(() => {
    if (
      location.state !== null &&
      typeof location.state == 'object' &&
      location.state.doc_id !== undefined &&
      location.state.is_shopify !== undefined
    ) {
      setRedirectToReturnFeedback(true);
    }
  }, [location.state]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignIn(email, password).then((res) => {
      if (res === 'success' && redirectToReturnFeedback) {
        window.location.href = `${window.location.origin}/returnfeedback?is_shopify=${location.state.is_shopify}&doc_id=${location.state.doc_id}`;
      }
    });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.wrapper}>
        <form onSubmit={handleSubmit}>
          <FormControl margin='normal' required fullWidth>
            <InputLabel htmlFor='email'>{t('Email Address')}</InputLabel>
            <Input
              id='email'
              name='email'
              value={email}
              autoComplete='email'
              autoFocus
              onChange={handleEmailChange}
            />
          </FormControl>
          <FormControl margin='normal' required fullWidth>
            <InputLabel htmlFor='password'>{t('Password')}</InputLabel>
            <Input
              name='password'
              type={showPassword ? 'text' : 'password'}
              value={password}
              id='password'
              autoComplete='current-password'
              onChange={handlePasswordChange}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <Visibility className={classes.hidePassBtn} />
                    ) : (
                      <VisibilityOff className={classes.hidePassBtn} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Button
            fullWidth
            variant='contained'
            color='primary'
            mb={2}
            size='medium'
            id='submit'
            type='submit'
            value='Submit'
            className={classes.btn}
          >
            {t('Sign in')}
          </Button>
          <LatoText
            variant='body1'
            component='h2'
            align='center'
            fontSize={'m'}
            className={classes.forgotText}
          >
            {t('Forgot your password')}
            {'? -  '}
            <Link href='/resetpassword' style={{ textDecoration: 'none' }}>
              <ALink>{t('Reset password')}</ALink>
            </Link>
          </LatoText>
          <br />
          <LatoText
            variant='body1'
            component='h2'
            align='center'
            className={classes.forgotText}
          >
            {t('No account, yet')}
            {' -  '}
            <Link href='/signup' style={{ textDecoration: 'none' }}>
              <ALink>
                {t('Register')}&nbsp;{t('with Email')}
              </ALink>
            </Link>
          </LatoText>
          <Alert
            mb={4}
            severity='error'
            className={`${classes.myAlert} ${!showAlert ? classes.hide : ''}`}
          >
            {alertMessage}
          </Alert>
          <Seperator mt={2} mb={2}>
            {t('or')}
          </Seperator>
          <SocialLogin />
        </form>

        <TermsLinks />
      </div>
    </div>
  );
}
export default Login;
