import { ToastContainer as WrappedToastContainer} from 'react-toastify';
import styled from 'styled-components'
import Colors from '../utils/helper/Colors';

const ToastContainer = styled(WrappedToastContainer)`
  .Toastify__toast-container {}
  .Toastify__toast--info {
    background-color: ${Colors.keepoala.light};
    color: ${Colors.keepoala.dark}
   }
   .Toastify__close-button--info{
       color:${Colors.keepoala.dark}
    }
    .Toastify__progress-bar--info{
        color:${Colors.keepoala.dark};
        background-color:${Colors.keepoala.dark}
        
   }
  .Toastify__toast--error {}
  .Toastify__toast--warning {}
  .Toastify__toast--success {}
  .Toastify__toast-body {}
  .Toastify__progress-bar {}
`;
export default ToastContainer;