import React, {useContext} from 'react';
import LatoText from '../styles/lato';
import { AuthContext } from "../utils/providers/AuthProvider";
import { useTranslation } from 'react-i18next';
import { Container as MuiContainer } from '@material-ui/core';
import styled from 'styled-components';

const Container = styled(MuiContainer)`
margin-top:2em;
`

export default function WriteToSupport() {

    const { user } = useContext(AuthContext);
    const { t } = useTranslation();
    const today = new Date();


    return(  
    
    <Container><a rel="noreferrer" target="_blank" href={"mailto:info@keepoala.com?subject=Problem%20with%20Keepoala%20App&body=Hallo%20Keepoala%20Team,%0D...%0D%0D---%0D%0DMy%20infos%0D%0Duser_id:%20"+user.uid+"%0Ddate: " + today.toDateString()}>
    <LatoText mb={3} fontSize="l" color={"main"}>{t('Send an email')}!
    </LatoText></a></Container>)
}