import styled from 'styled-components';
import {Input as InputMUI, InputLabel as InputLabelMUI} from '@material-ui/core';
import { Alert as MuiAlert } from '@material-ui/lab';
import {default as MuiRadio} from '@mui/material/Radio';
import {default as MuiRadioGroup} from '@mui/material/RadioGroup';
import Colors from '../utils/helper/Colors';
import {default as ChipMui} from "@mui/material/Chip";
import { spacing } from "@material-ui/system";

export const Input = styled(InputMUI)`
  font-family: ${(props) => {return props.theme.fontFamily ?? 'Inter'}};
  &::after {
    border-bottom: ${(props) => {
      return "2px solid " + props.theme.main ?? Colors.keepoala.main;
    }}
  };

`

export const InputLabel = styled(InputLabelMUI)`
  font-family: ${(props) => {return props.theme.fontFamily ?? 'Inter'}};
  color: ${(props) => (props.theme.greytext ?? Colors.keepoala.greytext)};
  &.MuiFormLabel-root.Mui-focused {
    color: ${(props) => {
      return props.theme.main ?? Colors.keepoala.main;
  }}
  }
`

export const Alert = styled(MuiAlert)`
  font-family: ${(props) => {return props.theme.fontFamily ?? 'Inter'}};
  background-color: ${(props) => {return props.theme.bg_alert ?? 'rgb(255, 244, 229)'}};
`

export const RadioGroup = styled(MuiRadioGroup)`

`
export const Radio = styled(MuiRadio)`
  color: ${(props) => {return props.theme.greytext ?? Colors.keepoala.black}};
  &.MuiRadio-root.Mui-checked {
    color: ${(props) => {return props.theme.main ?? Colors.keepoala.main}};
  }
  & .MuiSvgIcon-root:first-of-type {
    background-color: white;
    border-radius: 100%;
  }
`;

const ChipPre = styled(ChipMui)`
  background-color: ${props => props.theme.main ?? Colors.keepoala.main}
`

export const Chip = styled(ChipPre)(spacing);