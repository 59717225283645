import hmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";
import getEndpointUrl from "./getEndpointUrl";

export async function shipping_label_email(shop, tracking_number, email, type, productFeedback) {
  const req_body = JSON.stringify({ shop, tracking_number, email, type, productFeedback });
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-requested-with": "application/json",
      "Keepoala-Cors-Header": "enabled",
      Signature: "sha256=" + Base64.stringify(hmacSHA256(req_body, process.env.REACT_APP_ENV_DHL_API_KEY)),
    },
    body: req_body,
  };
  //   return await fetch('http://localhost:8100/0.0.0.0:5001/keepoala/europe-west1/shopify_get_shop_dhl_status', requestOptions)
  return await fetch(getEndpointUrl() + "/shipping_label_email", requestOptions);
}
