import Colors from "./Colors";
import { Coloring } from "../../types";

export const keepoala:Coloring = {
  main: '#2f8481',
  white: '#ffffff',
  light: '#dcffe6',
  bright: '#7deaa9',
  text: '#000e1c',
  dark: '#204648',
  darktext: '#1A4B49',
  lightgrey: '#A3A3A3',
  mediumgrey: '#748080',
  greengrey: '#8f9b95',
  black: '#000e1c',
  transparent: '#ffffff00',
  greytext: '#515959',
  greydefault: '#B6BBBB',
  actionGreendefault: '#94E0A3',
  actionGreenlight: '#DEFFE5',
  actionGreendark: '#7DD29A',
  keepoalaGreendark: '#1E5957',
  forestGreendefault: '#204648',
  greyveryLight: '#F8F8F8',
  keepoalaGreendefault: '#2F8481',
  greymedium: '#748080',
  greylight: '#EFF2F2',
  grey: {
    light: '#EFF2F2',
    white: '#FFFFFF',
    defaultLight: '#CFD1D1',
  },
  keepoalaGreen: {
    default: '#2F8481',
    light: '#E3FCFC',
    default60: '#2F848199',
  },
  forestGreen: {
    dark: '#1A393B',
  },
  bg: Colors.keepoala.white,
  bg_primary: Colors.keepoala.actionGreendefault,
  bg_secondary: Colors.keepoala.keepoalaGreendark,
  bg_disabled: Colors.keepoala.lightgrey,
  action_text: Colors.keepoala.greytext,
  action_text_primary: Colors.keepoala.greytext,
  action_text_hover_primary: Colors.keepoala.greytext,
  action_text_secondary: Colors.keepoala.actionGreenlight,
  action_text_disabled: Colors.keepoala.greymedium,
  action_text_fallback: Colors.keepoala.black,
  fontFamily: 'Inter',
};
const happybrush:Coloring = {
  main: 'rgb(31, 207, 203)',
  bright: 'rgb(31, 207, 203)',
  greytext: '#000000',
  bg: 'rgb(255, 255, 255)',
  bg_primary: 'rgb(255, 255, 255)',
  bg_secondary: 'rgb(31, 207, 203)',
  bg_disabled: 'rgb(230, 230, 230)',
  bg_fallback: 'rgb(255, 255, 255)',
  bg_hover: '#b8eceb',
  bg_hover_primary: '#b8eceb',
  bg_hover_secondary: 'rgb(31, 207, 203)',
  bg_hover_disabled: 'rgb(230, 230, 230)',
  bg_hover_fallback: 'rgb(255, 255, 255)',
  action_text: 'rgb(31, 207, 203)',
  action_text_primary: 'rgb(31, 207, 203)',
  action_text_secondary: '#ffffff',
  action_text_hover_primary: '#1fcfcb',
  action_text_disabled: 'rgb(250, 250, 250)',
  action_text_fallback: 'rgb(0, 0, 0)',
  text: 'rgb(0, 0, 0)',
  fontFamily: 'Quicksand',
  bg_alert: 'rgb(255, 246, 235)',
  cardBox:{
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    border: '1px solid rgb(250, 250, 250)',
    padding: '10px',
    paddingBottom: '30px'
  },
  backgroundImage: 'https://storage.googleapis.com/keepoala-public-data/typeform/happybrush_bg.webp',
}
const zuckergrey:Coloring = {
  bg_alert: 'rgb(255, 246, 235)',
  main: '#BF7A68',
  bright: '#e0795e',
  greytext: '#313638',
  text: '#313638',
  bg: 'rgb(255, 255, 255)',
  bg_primary: 'rgb(255, 255, 255)',
  bg_secondary: '#ffffff',
  bg_hover: '#BF7A68',
  bg_hover_primary: '#BF7A68',
  bg_disabled: 'rgb(230, 230, 230)',
  bg_hover_disabled: 'rgb(230, 230, 230)',
  action_text: '#BF7A68',
  action_text_disabled: 'rgb(250, 250, 250)',
  action_text_fallback: '#ffffff',
  action_text_hover_primary: '#ffffff',
  action_text_primary: '#BF7A68',
  action_text_secondary: '#BF7A68',
  fontFamily: 'Open Sauce',
  cardBox:{
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    border: '1px solid rgb(250, 250, 250)',
    padding: '10px',
    paddingBottom: '30px'
  },
  backgroundImage: 'https://storage.googleapis.com/keepoala-public-data/typeform/zuckergrey_bg.jpg',
}

export function getThemeByShop(shop:string, coloring?:Coloring):Coloring {

  if (coloring !== undefined) {
    return coloring;
  }
  switch (shop) {
    case 'happybrush-store.myshopify.com':
      return happybrush;
    case 'zuckergrey.myshopify.com':
      return zuckergrey;
    default:
      return keepoala;
  }
}