import firebase from "../../../firebase";

export default function fetchQuery(shop: string): Promise<string> {
  const db = firebase.firestore();
  return db
    .collection("Shops")
    .where("name", "==", shop)
    .get()
    .then((shop_query_snap) => {
      if (shop_query_snap.size > 0) {
        const shop_doc = shop_query_snap.docs[0].data();
        if (
          shop_doc.shopproducts_query !== undefined &&
          shop_doc.shopproducts_query.length > 0
        ) {
          return shop_doc.shopproducts_query;
        } else {
          return "";
        }
      } else {
        return "";
      }
    });
}
