/* eslint import/no-webpack-loader-syntax: off */

import React from 'react';
import { useTranslation } from 'react-i18next';
import LatoText, {LatoTextSpaced} from '../styles/lato';
import PermanentMarkerText from '../styles/PermanentMarker';
import Colors from '../utils/helper/Colors';
import Keepoala from '!file-loader!../assets/img/Keepoala.svg'
import empty from '../assets/img/empty.png'
import community from '../assets/img/community.png'
import carbon from '../assets/img/carbon.png'
import saved from '../assets/img/saved.png'
import styled from "styled-components";
import { ItemSpaced } from './Box';
import Iframe from 'react-iframe';
import firebase from "../firebase";

/* For a given date, get the ISO week number
 *
 * Based on information at:
 *
 *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
 *
 * Algorithm is to find nearest thursday, it's year
 * is the year of the week number. Then get weeks
 * between that date and the first day of that year.
 *
 * Note that dates in one year can be weeks of previous
 * or next year, overlap is up to 3 days.
 *
 * e.g. 2014/12/29 is Monday in week  1 of 2015
 *      2012/1/1   is Sunday in week 52 of 2011
 */
function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    return weekNo + 1;
}

const TipTop = styled.div`
    text-align: right;
    width: 100%;
    text-decoration: underline;
    cursor: pointer;
`
export const TopRow = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top: 1em;
    flex-wrap: wrap;
    text-align: center;
`
export const BannerLogo = styled.img`
    height: 40px;
    margin-left: 1em;
    padding-top: 6px
`

export const Batch = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    margin-left: auto;
    margin-right: auto;
`
export const BatchIMG = styled.img`
    width: 80%;
    max-width: 110px;
`

const FactImage = styled.img`
    margin: auto;
    max-width: 100%;
    width: 450px;
`
const StarText = styled(LatoTextSpaced)`
color: white;
position: relative;
top: -64px;
font-size: 24px;
height:0px;
`

export const Clickable = styled.div`
  cursor: pointer;
`

export function BannerWrapper ({children}) {
    const {t} = useTranslation();

    const [hide, setHide] = React.useState(false);
    const handleHide = () => setHide(true);

    if (hide) {
        return null;
    } else {

        return(
            <ItemSpaced>
                {children}
                <TipTop>
                    <LatoText fontSize="m" onClick={handleHide} >{t('hide')}</LatoText>
                </TipTop>
            </ItemSpaced>
        )
    }
}

export function Banner1Welcome() {
    const {t} = useTranslation();

    const handleClickable = (event) => {
        event.preventDefault();
        window.open('http://keepoala.com/community-advantages/?utm_source=Keepoala%20Welcome%20Page%20Webapp&utm_medium=Keepoala%20Welcome%20Page%20Webapp&utm_campaign=Community%20Advantages%200507')
    }

    return(
        <BannerWrapper>
            <Clickable onClick={handleClickable}>
            <TopRow>
                        
                        <PermanentMarkerText ml={2} color={Colors.keepoala.bright} size={"32px"}>{t('Welcome at')}</PermanentMarkerText>
                        <BannerLogo src={Keepoala} />
                
                    </TopRow>
                    <LatoTextSpaced mt={2} ml={3} fontSize={"xl"}>{t('It is great to have you here')}.</LatoTextSpaced>
                    <LatoTextSpaced mt={2} ml={3} fontSize={"xl"}>{t('With registering your purchase you made the first step to let the environment benefit from you not-returning')}.</LatoTextSpaced>
                    <TopRow>
                        <Batch>
                            <BatchIMG src={saved} />
                        <LatoTextSpaced fontSize={"xl"} color="dark" fontWeight={700}>{t('Vouchers')}</LatoTextSpaced>

                        </Batch>
                        <Batch>
                            <BatchIMG src={carbon} />
                        <LatoTextSpaced fontSize={"xl"} color="dark" fontWeight={700}>{t('save CO₂')}</LatoTextSpaced>

                        </Batch>
                        <Batch>
                            <BatchIMG src={community} />
                        <LatoTextSpaced fontSize={"xl"} color="dark" fontWeight={700}>{t('Community')}</LatoTextSpaced>

                        </Batch>
                    </TopRow></Clickable>
        </BannerWrapper>
    )
}

export function BannerBack1 (props) {

    const {t} = useTranslation();

    const db = firebase.firestore();

    const [year, setYear] = React.useState(2021);
    const [carbon_total, setCarbonTotal] = React.useState(0);
    const [carbon_total_keepoala, setCarbonTotalKeepoala] = React.useState(0);
    const [return_rate, setReturnRate] = React.useState(0);

    React.useEffect(() => {

        const x = new Date();
        setYear(x.getFullYear());

        db
        .collection("Aggregation")
        .doc(props.uid)
        .get()
        .then((docsnap) => {
            if (docsnap.exists) {
                const all_data = docsnap.data();
    
                if (Object.keys(all_data.carbon_history).length > 0) {
                    const array_carbon_hist = Object.keys(all_data.carbon_history).map((x) => {
                        return all_data.carbon_history[x];
                    });
    
                    const all_carbon = array_carbon_hist.reduce((x, y) => {
                        return x + y.value;
                      }, 0)
                    setCarbonTotal((Math.round(all_carbon / 100) / 10));
                }
            }
        })

        db
        .collection("Goals")
        .doc("allgoals")
        .get()
        .then((docsnap) => {
            if (docsnap.exists) {
                const x = new Date();
                const all_goal_data = docsnap.data();
                setCarbonTotalKeepoala(
                    (Math.round(all_goal_data[x.getFullYear()].current / 100) / 10)
                );
            }
        })

        db
        .collection("Orders")
        .where("user_id","==",props.uid)
        .get()
        .then((querysnap) => {
            const returned = querysnap.docs.filter((x) => x.returned).length;
            const number = querysnap.size;
            setReturnRate(Math.round((100* returned/number)));
        })
    }, [props.uid, db])

    const handleClickable = (event) => {
        event.preventDefault();
        window.open('http://keepoala.com/community-advantages/?utm_source=Keepoala%20Welcome%20Page%20Webapp&utm_medium=Keepoala%20Welcome%20Page%20Webapp&utm_campaign=Community%20Advantages%200507')
    }

    return(
        <Clickable onClick={handleClickable}>
           
                    <LatoTextSpaced mt={2} ml={3} fontSize={"xl"}>{t('It is great to have you back')}.</LatoTextSpaced>
                    <LatoTextSpaced mt={2} ml={3} fontSize={"xl"}>{t('Your acting shows an effect in the Keepoala Community')}. {t('Together we will do great things')}.</LatoTextSpaced>
                    <TopRow>
                        <Batch>
                            <BatchIMG src={empty} />
                            <StarText>{return_rate.toString()} %</StarText>
                            
                        <LatoTextSpaced fontSize={"xl"} color="dark" fontWeight={700}>{t('Your return rate')}</LatoTextSpaced>

                        </Batch>
                        <Batch>
                            <BatchIMG src={empty} />
                            <StarText>{carbon_total.toString()} kg</StarText>
                            
                        <LatoTextSpaced fontSize={"xl"} color="dark" fontWeight={700}>{t('CO₂ saved by you')}</LatoTextSpaced>

                        </Batch>
                        <Batch>
                            <BatchIMG src={empty} />
                            <StarText>{carbon_total_keepoala.toString()} kg</StarText>
                            
                        <LatoTextSpaced fontSize={"xl"} color="dark" fontWeight={700}>{t('CO₂ saved by the community in ')} {year}</LatoTextSpaced>

                        </Batch>
                    </TopRow>
        </Clickable>
            );
}

export function BannerBack2 (props) {
    const {t} = useTranslation();

    const handleClickable = (event) => {
        event.preventDefault();
        window.open('https://keepoala.com/keepoalafacts/?utm_source=Keepoala%20Welcome%20Page%20Webapp&utm_medium=Keepoala%20Welcome%20Page%20Webapp&utm_campaign=Facts%200507');
    }
    return(
        <Clickable onClick={handleClickable}>
     
                    <LatoTextSpaced align="center" mt={2} ml={3} fontSize={"xl"}>{t('Your weekly sustainability fact')}:</LatoTextSpaced>
                    <TopRow>

                        <FactImage src={process.env.PUBLIC_URL + "/facts/fact" + (getWeekNumber(new Date())).toString() + ".png"} />
                    </TopRow>
        </Clickable>
    )
}
export function BannerBack3 (props) {
    const {t} = useTranslation();

    return(
        <div>
          
                    <LatoTextSpaced align="center" mt={2} ml={3} fontSize={"xl"}>{t('Our latest blogpost on sustainability')}:</LatoTextSpaced>
                    <TopRow>

                    <Iframe url="https://keepoala.com/latestpost/"
        width="100%"
        height="400px"
        id="iframe blpg"
        scrolling="no"
        frameBorder="0"
        overflow="hidden"
        display="initial"/>
                    </TopRow>
        </div>
    )
}

export function Banner2Random(props) {

    const [item, setItem] = React.useState(null);
    const {t} = useTranslation();

    
    React.useEffect(() => {
        const random = Math.floor(Math.random() * 3);
        console.log("random", random)
        switch (random) {
            case 0:
                setItem(<BannerBack1 name={props.name} uid={props.uid}/>)
                return false;
            case 1:
                setItem(<BannerBack2 name={props.name}/>);
                return false;
            case 2:
                setItem(<BannerBack3 name={props.name}/>);
                return false;
            default:
                setItem(<BannerBack1 name={props.name} uid={props.uid}/>);
                return false;

        }

    }, [props.name, props.uid])

    return(
        <BannerWrapper>
            {
                item
            }
        </BannerWrapper>
    )

}

