/**
 * Re calculate a carbon goal and add unit
 *
 * @param value The carbon value in g
 * @returns `string` The carbon goal tranlsated + the unit as a string
 */
export function CarbonShortString(value: any) {
  if (value >= 1000000) {
    return `${StringRound(value / 1000000)} t`;
  } else if (value >= 1000) {
    return `${StringRound(value / 1000)} kg`;
  } else {
    return `${StringRound(value)} g`;
  }
}

/**
 * Round number to string
 * @param value a numeric
 * @returns the numeric rounded in a useful manner for user display
 */
export function StringRound(value: number) {
  if (value < 100) {
    return (
      value.toString().split('.')[0] +
      (value.toString().split('.')[1]
        ? '.' + value.toString().split('.')[1].substring(0, 2)
        : '')
    );
  } else {
    const roundedVal = Math.round(value);
    return roundedVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
