import React, { useState } from "react";

import Buttonn from "../styles/Button";
import { Button as ButtonForGroup } from "@material-ui/core";
import { default as ButtonGroupMUI } from "@material-ui/core/ButtonGroup";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import { default as Lato } from "../styles/lato";
import styled from "styled-components";
import { display, spacing } from "@material-ui/system";
import { useTranslation } from "react-i18next";
import Colors from "../utils/helper/Colors";
import { makeStyles } from "@material-ui/core/styles";
import { CheckCircle as CheckCircleMUI } from "@material-ui/icons";
import InterText from "../styles/Inter";
import { ShopifyLineItemForPortal, ShopifyProduct } from "../utils/helper/LineItemTypes";
import { Alert as MuiAlert } from "../styles/MUIOverwrite";
const Alert = styled(MuiAlert)(spacing);

const ButtonGroup = styled(ButtonGroupMUI)`
  .MuiButton-outlinedPrimary {
    border-color: ${(props) => {
      return props.theme.main ?? Colors.keepoala.keepoalaGreen;
    }};
    color: ${(props) => {
      return props.theme.main ?? Colors.keepoala.keepoalaGreen;
    }};
  }
  .Mui-disabled {
    border-color: ${(props) => {
      return props.theme.bg_disabled ?? Colors.keepoala.grey;
    }};
    color: ${(props) => {
      return props.theme.bg_disabled ?? Colors.keepoala.grey;
    }};
  }
  .Quantity {
    color: ${(props) => {
      return props.theme.text ?? Colors.keepoala.grey;
    }};
  }
`;
const CheckCircle = styled(CheckCircleMUI)`
  &.MuiSvgIcon-colorPrimary {
    color: ${(props) => {
      return props.theme.main ?? Colors.keepoala.keepoalaGreen;
    }};
  }
`;
const LatoText = styled(Lato)(spacing);
const useStyles = makeStyles((theme) => ({
  btn: {
    marginTop: 20,
  },
  myAlert: {
    marginTop: 15,
    width: "90%",
  },
  hide: {
    display: "none",
  },
  FormControl: {
    marginRight: 12,
  },
}));

const FlexRow = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const StyledFormGroup = styled(FormGroup)`
  align-items: center;
  padding-bottom: 15px;
  border: 1px solid #eaeaf0;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 1px 1px 0px 0px rgb(199, 199, 199);
`;

const ButtonGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 10px;
}
`;
export const PreviewImage = styled.img`
  max-width: 100px;
  width: 100px;
  box-shadow: 1px 1px 0px 0px rgb(199, 199, 199);
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  margin: auto;
  padding: 10px;
  &.disabled {
    filter: brightness(1.5) saturate(0);
  }
`;

const PreviewImageExchange = styled(PreviewImage)`
  max-width: 80px;
  width: 80px;
  margin: 0;
  margin-right: 10px;
`;

const ExchangeItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-bottom: 15px;
  align-items: center;
`;
export default function OrderLineItem(props: {
  item: ShopifyLineItemForPortal;
  index: number;
  withReturn: boolean;
  validateHandler: (quantity: number, item: ShopifyLineItemForPortal, index: number, exchange: boolean) => void;
  validationFinished: boolean;
  defaultQuantity: number;
  showPrice: boolean;
  exchangeWish?: (ShopifyProduct | undefined)[];
}) {
  const { t, i18n } = useTranslation();
  const { item, withReturn, index } = props;
  const [quantity, setQuantity]: [number, any] = useState(props.defaultQuantity);
  const classes = useStyles();
  const disabled: boolean =
    (item.policy.allowed.exchange === false && item.policy.allowed.refund === false) || item.quantity === 0;

  const language = i18n.language.toLowerCase();

  const handleFeedback = () => {
    props.validateHandler(
      quantity,
      item,
      index,
      item.exchange_products !== undefined && item.exchange_products.length > 0 && item.policy.allowed.exchange // TODO: Fix default product
    );
  };

  return (
    <StyledFormGroup row>
      <Grid container spacing={3}>
        {withReturn || disabled ? null : (
          <ButtonGrid item xs={5} md={3} alignItems="center" justify="center">
            {item.image_preview !== undefined ? (
              <PreviewImage src={item.image_preview} className={disabled ? "disabled" : "noclass"} />
            ) : null}
            {/* Create a slider to tell how many items got returned. Let it write into "checked" state */}
            <ButtonGroup color="primary" size="small" aria-label="small outlined button group">
              {!props.validationFinished ? (
                <ButtonForGroup
                  disabled={quantity === 0 || props.validationFinished}
                  onClick={() => {
                    setQuantity(quantity - 1);
                  }}>
                  -
                </ButtonForGroup>
              ) : null}
              <ButtonForGroup disabled className="Quantity">
                {quantity}
              </ButtonForGroup>
              <ButtonForGroup
                disabled={quantity >= item.quantity || props.validationFinished}
                onClick={() => {
                  setQuantity(quantity + 1);
                }}>
                {props.validationFinished ? <CheckCircle color="primary" /> : "+"}
              </ButtonForGroup>
            </ButtonGroup>
          </ButtonGrid>
        )}
        <Grid item md={props.validationFinished ? 6 : 7} xs={7}>
          <InterText color="black" size="m">
            {item.title}
          </InterText>
          <InterText size={"s"}>
            {item.variant_title !== undefined && item.variant_title !== null && item.variant_title !== "Default Title"
              ? item.variant_title + " "
              : ""}
            ({item.quantity + (item.returned ?? 0)}x {t("ordered")}
            {item.returned !== undefined && item.returned > 0
              ? " / " + t("already returned") + " " + item.returned + "x"
              : ""}
            )
          </InterText>
          {/* Show the policy infos in case there are some */}
          {item.policy !== undefined &&
          item.policy.info[language] !== undefined &&
          item.policy.info[language] !== null &&
          item.policy.info[language] !== "" &&
          !props.validationFinished &&
          !disabled ? (
            <Alert mb={2} mt={2} severity="warning">
              <InterText>{item.policy.info[language]}</InterText>
            </Alert>
          ) : null}
          {props.validationFinished ? null : quantity === 0 ? null : (
            <>
              <Grid container alignItems={"center"} spacing={2}>
                {item.policy.allowed.exchange || item.policy.allowed.refund ? (
                  <Grid item xs={12}>
                    <Buttonn
                      variant="contained"
                      color="default"
                      mb={2}
                      size="small"
                      id="submit"
                      type="submit"
                      value="Submit"
                      className={classes.btn}
                      onClick={handleFeedback}
                      fullWidth>
                      {t("Let's go")}
                    </Buttonn>
                  </Grid>
                ) : null}
              </Grid>
            </>
          )}
          {props.exchangeWish !== undefined && props.exchangeWish.length > 0 ? (
            <>
              <em>
                <InterText>
                  <br />
                  {t("exchange against")}
                  <br />
                  <ul>
                    {props.exchangeWish.map((exchangeWishSingle: ShopifyProduct | undefined) => {
                      if (exchangeWishSingle !== undefined) {
                        return (
                          <ExchangeItem>
                            <PreviewImageExchange src={exchangeWishSingle.image_preview} />
                            <InterText>
                              {exchangeWishSingle.title +
                                " " +
                                // Only render variant title in case this product really has variants
                                (exchangeWishSingle.options[0].values.length > 0 &&
                                exchangeWishSingle.options[0].values[0] !== "Default Title"
                                  ? exchangeWishSingle.variant_title
                                  : "")}
                            </InterText>
                          </ExchangeItem>
                        );
                      } else {
                        return (
                          <ExchangeItem>
                            <InterText>1x {t("Refund wanted")}</InterText>
                          </ExchangeItem>
                        );
                      }
                    })}
                  </ul>
                </InterText>
              </em>
            </>
          ) : null}

          {quantity > 0 && props.showPrice && (props.exchangeWish ?? []).filter((x) => x === undefined).length > 0 ? (
            <>
              <InterText>
                <br />
                <em>{t("refund would be")}</em>
                <br />
                <ul>
                  <InterText color="black" size="m">
                    {(
                      -(
                        // Derive the refunds inside the "exchange wish array"
                        (props.exchangeWish ?? []).filter((x) => x === undefined).length
                      ) * parseFloat(item.price.toString())
                    ).toFixed(2)}
                    &nbsp;EUR
                  </InterText>
                </ul>
              </InterText>
            </>
          ) : null}
        </Grid>
      </Grid>
    </StyledFormGroup>
  );
}
