import hmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";
import getEndpointUrl from "./getEndpointUrl";

export type label_price_req = {
  variant_id: string | number;
  quantity: number;
  order_id: string | number;
  carrier_id: string;
  shop: string;
};

export async function shipping_label_price_2(items:label_price_req[]) {
  const req_body = JSON.stringify(items);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-requested-with": "application/json",
      "Keepoala-Cors-Header": "enabled",
      Signature: "sha256=" + Base64.stringify(hmacSHA256(req_body, process.env.REACT_APP_ENV_DHL_API_KEY)),
    },
    body: req_body,
  };
  // return await fetch( 'http://localhost:8100/127.0.0.1:5001/keepoalatesting/europe-west1/shopify_get_dhl_detail', requestOptions)
  return fetch(getEndpointUrl() + "/shipping_label_price_2", requestOptions).then((value) => {
    if (value.status >=200 && value.status < 300) {
      return value.json()
    } else {
      return value.text()
    }
  })
}
