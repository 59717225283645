import React from 'react';
import {Done as MUIDoneIcon} from '@material-ui/icons';
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import InterText from '../styles/Inter';
import Colors from '../utils/helper/Colors';

const FullWidth = styled.div`width: 100%;`
const Container = styled(FullWidth)`
    @media only screen and (max-width:600px) {
        min-height : calc(100vh - 50px);
        display:flex;
        align-items:center;
        justify-content: center;
    }
    @media only screen and (min-width:600px) {
        margin-top: 16px;
    }
    background-color: ${(props) => props.theme.bright ?? Colors.keepoala.bright};
    flex-direction: column;
    padding:0;
    position:fixed;
    opacity: 0.8;
    top: 0;
    left: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

const DoneIcon = styled(MUIDoneIcon)`
    color: ${Colors.keepoala.white};
`

export default function ThankYou() {

    const {t} = useTranslation()
    return(
<Container>
    <DoneIcon fontSize='large' /><br/>
    <InterText color='white' size='xxl'>{t('Thank you')}</InterText>
</Container>
    )
}