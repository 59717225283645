import React, { useState } from "react";
import { FormControl as MUIFormControl, Input, InputLabel, CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

import { shipping_label_email } from "../../utils/helper/shipping_label_email";
import InterText from "../../styles/Inter";
import Button from "../../styles/Button";
import AlertError from "../Alert/AlertError";
import { useTranslation } from "react-i18next";
import { ReturnProductFeedbackType } from "../ReturnFeedback";

const FormControl = styled(MUIFormControl)(spacing);

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: row;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export default function EmailChangeForm(props: {
  shop: string;
  order_id: string;
  setNewEmail?: (x: string) => void;
  helptext?: string;
  type: "no_label" | "just_order" | "real_label";
  product_feedback: ReturnProductFeedbackType[];
}): React.ReactElement {
  const { t } = useTranslation();

  const [emailRequestSent, setEmailRequestSent] = useState(false);
  const [loadingEmailRequest, setLoadingEmailRequest] = useState(false);
  const [newEmailAdded, setNewEmailAdded] = useState("");
  const [emailRequestError, setEmailRequestError] = useState("");

  const handleEmailChangeRequest = (event: any) => {
    event.preventDefault();
    setLoadingEmailRequest(true);

    shipping_label_email(props.shop, props.order_id, event.target.email.value, props.type, props.product_feedback)
      .then((response) => response.json())
      .then((data) => {
        if (data.test) {
          setEmailRequestError("");
          setNewEmailAdded(event.target.email.value);
          if (props.setNewEmail !== undefined) {
            props.setNewEmail(event.target.email.value);
          }
          setEmailRequestSent(true);
        } else {
          setEmailRequestError(data.error);
        }
        setLoadingEmailRequest(false);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {/* If the request to add the email is not sent yet show the form */}
      {/* And check that the tracking number exists to be able send the email */}
      {!emailRequestSent ? (
        <>
          <InterText size="l" mb={2} align="center">
            <br />
            {props.helptext ?? t("If you want to receive all infos via email, please use this form")}
          </InterText>
          <Form onSubmit={handleEmailChangeRequest}>
            <FormControl margin="normal" size="medium" required={true}>
              <InputLabel htmlFor="email">{t("Email Address")}</InputLabel>
              <Input id="email" name="email" autoComplete="email" type="email" autoFocus />
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              mb={2}
              mt={2}
              ml={2}
              size="medium"
              id="submit"
              type="submit"
              value="Submit">
              {loadingEmailRequest ? <CircularProgress /> : t("Add Email")}
            </Button>
          </Form>
          {emailRequestError !== "" && <AlertError with_contact={true} errorMessage={emailRequestError} />}
        </>
      ) : emailRequestSent ? (
        // Else show that the email is sent
        <>
          <InterText size="l" mb={0} mt={1} align="center">
            {t("We sent all infos to")} <b>{newEmailAdded}</b>
          </InterText>
          <InterText size="l" mb={2} align="center">
            {t("Please look also into your spam folder")}
          </InterText>
        </>
      ) : null}
    </>
  );
}

EmailChangeForm.defaultProps = {
  type: "no_label",
};
