import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import firebase from '../firebase';

import { Container as MuiContainer } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { LineItemType } from '../types';
import styled from 'styled-components';
import split_query from '../utils/helper/split_query';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../utils/providers/AuthProvider';
import ReturnFeedbackCheck from './ReturnFeedbackCheck';
import InterText from '../styles/Inter';
import { Alert as MuiAlert } from '@material-ui/lab';
import ReturnQuestions, { AnswerType } from './ReturnQuestions';
import ThankYou from './ThankYou';
import ThankYouLinkKeepoala from './ThankYouLinkKeepoala';
import anyString from '../utils/helper/anyString';
import { ShopLogo as DefaultShopLogo } from './OrderFeedbackCheck';
import { get_pretty_shopName } from '../utils/helper/get_pretty_shopName';

const ShopLogo = styled(DefaultShopLogo)`
  max-height: 40px;
  margin-bottom: 16px;
`;

const Container = styled(MuiContainer)`
  @media only screen and (max-width: 600px) {
    min-height: calc(100vh - 125px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (min-width: 600px) {
    margin-top: 50px;
  }
  flex-direction: column;
`;
const Alert = styled(MuiAlert)(spacing);

export type ReturnProductFeedbackType = {
  item: LineItemType;
  quantity: number;
  answers: AnswerType[] | undefined;
};

/**
 * OrderFeedbackProcess
 *
 * A three steop process
 *   1. validate that the user is allowed to access the order
 *   2. get feedback of all items of the purchase
 *   3. get feedback about the shop itself (NPS  = net promoter score)
 *   4. call successHandler or show a thank you page
 *
 * @param query_params
 *  * email: email address belonging to the order
 *  * order_id: id field inside the database shopify
 *  * shop: name field of a Shop in our Shops collection
 *  * order_name: A name to display to the user
 *
 * @returns A three step process that guides users who
 *   bought and kept something through a process of
 *
 * @link ProductFeedBack
 * @link ReturnQuestions
 */
export default function NpsFeedback() {
  const { search, state } = useLocation();
  const db = firebase.firestore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loginValid, setLoginValid] = useState(false);
  const [isUser, setIsUser] = useState(false);

  const [npsFeedbackFinished, setNpsFeedbackFinished]: [boolean, any] =
    useState(false);
  const [npsFeedback, setNpsFeedback]: [any, any] = useState([]);
  const [finished, setFinished]: [boolean, any] = useState(false);

  const [order_id, setOrderId]: [string | number | undefined, any] = useState();
  const [shop, setShop]: [string | undefined, any] = useState();
  const [shop_pretty, setShopPretty] = useState(''); // needed for ReturnQuestions
  const [email, setEmail]: [string | undefined, any] = useState();

  const [errorMessage, setErrorMessage]: [string | undefined, any] =
    useState(undefined);

  const { user } = useContext(AuthContext);

  /**
   * What to do after a user went through the NPS
   *
   * @param answers The answers coming from a ReturnQuestions component
   */
  const npsHandler = (answers: AnswerType[]) => {
    setNpsFeedback(answers);
    setNpsFeedbackFinished(true);
  };

  /**
   * Send the questionaire answers all to FireStore
   */
  const handleFinal = () => {
    let dateX = new Date();

    return db
      .collection('typeform')
      .add({
        shoporders_id: order_id,
        shop: shop,
        answers: npsFeedback,
        category: 'nps',
        user_id: 'unknown',
        date: dateX.toUTCString(),
      })
      .then(() => setFinished(true))
      .catch((e: any) => setErrorMessage(e));
  };

  const handleNpsBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const query_params: any = split_query(search);

    // upon receiving a location query, update state
    if (query_params.id !== undefined && order_id === undefined) {
      setOrderId(query_params.id);
      setEmail(query_params.email);
      setShop(query_params.shop);
    }

    // logged in users will not see the OrderFeedbackCheck screen
    if (
      user !== null &&
      user !== undefined &&
      user.uid !== undefined &&
      !loginValid
    ) {
      setLoginValid(true);
      setIsUser(true);
    }

    // after nps and products are done, finish
    if (npsFeedbackFinished) {
      handleFinal();
    }

    // get a pretty shopname if not yet set
    if (shop_pretty === '' && shop !== undefined) {
      get_pretty_shopName(shop).then(
        (
          pretty_shopname_err:
            | { res: string | undefined; err: string }
            | undefined,
        ) => {
          if (pretty_shopname_err !== undefined) {
            if (pretty_shopname_err.res !== undefined) {
              setShopPretty(pretty_shopname_err.res);
            }
            setErrorMessage(t(pretty_shopname_err.err));
          }
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, state, search, loginValid, npsFeedbackFinished, shop]);

  return (
    <div style={{ marginBottom: '64px', marginTop: '30px' }}>
      {/* Show any Error messages that happens */}
      {errorMessage === undefined || errorMessage === '' ? null : (
        <Alert mb={4} mt={4} severity='error'>
          {errorMessage}
        </Alert>
      )}

      {/* MAIN CONTAINER */}
      <Container maxWidth='sm'>
        {/* NOT LOGGED IN USERS */}

        {!loginValid && (
          <>
            <InterText size='xxl' weight={600} align={'center'} mt={1}>
              {t('Feedback')}
            </InterText>

            {/* User Inserts email to check that order is his or not */}
            <ReturnFeedbackCheck
              validateHandler={(x: boolean, email: string) => {
                // update the email with the user entry
                setEmail(email);
                setLoginValid(x);
              }}
              shop={shop}
              email={email}
              order_id={order_id?.toString()}
              name={''}
              type='nps'
            />
          </>
        )}

        {/* LOGGED IN USERS (either really logged in or inserted correct email in the OrderFeedbackCheck) */}
        {loginValid && (
          <>
            {/* Show logo as long as not finished */}
            {!finished && (
              <ShopLogo
                src={
                  'https://storage.googleapis.com/' + process.env.REACT_APP_PROJECT_ID + '-public-data/shoplogos/' +
                  shop +
                  '.png'
                }
              />
            )}

            {/* If the shop has a pretty name and nps feedback not done */}
            {shop_pretty && !npsFeedbackFinished && (
              <ReturnQuestions
                productVariantID=''
                category='nps'
                shop={shop_pretty}
                orderID={order_id?.toString()}
                orderName={''}
                successHandler={npsHandler}
                skipStart={true} // Do not show an intro page
                startBackHandler={handleNpsBack} // what to do if the user goes back on first page
                skipEnd={true} // Do not show an answer overview
                productName='' // hides an info about the order
                userID=''
              />
            )}

            {/* Show ThankYou while sending the data to the backend */}
            {shop_pretty && npsFeedbackFinished && !finished && <ThankYou />}

            {/* Show ThankYouLinkKeepoala at the end of everything */}
            {shop_pretty && npsFeedbackFinished && finished && (
              <ThankYouLinkKeepoala
                shop={anyString(shop_pretty)}
                isUser={isUser}
              />
            )}
          </>
        )}
      </Container>
    </div>
  );
}
