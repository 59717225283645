import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { LineItemType } from '../types';
import styled from 'styled-components';
import ProductFeedback1Rate from './ProductFeedback1Rate';
import ProductFeedback2Text from './ProductFeedback2Text';
import { ProductFeedbackType } from './OrderFeedback';
const FullWidth = styled.div`
  width: 100%;
`;

type steps = 'rate' | 'positive_feedback' | 'negative_feedback';
export type TextFeedback = { index: number; rating: string };
export type RatingFeedback = { index: number; rating: number };

/**
 * Three step product rating process
 *
 * @description
 * For an array of order items, generate 3 pages
 * On the first page the users can rate each product, on the second they can
 * say what they liked about the product, on the third, they can say what
 * they did not like.
 * @param props
 *  * validateHandler - function to handle an array of ProductFeedbacks
 *    that will be returned if the user is finished
 *  * line_items - products to get rated
 *  * shop - name of the shop where the order was purchase
 *  * defaultValue - Starting value can be inserted and will be split to the
 *     three screens of this component
 * @returns A three pages view to rate products within an order
 */
export default function ProductFeedback(props: {
  validateHandler: (products: ProductFeedbackType[]) => void;
  line_items: LineItemType[];
  shop: string | undefined;
  defaultValue: ProductFeedbackType[] | undefined;
}) {
  const [loading, setLoading]: [boolean, any] = useState(false);
  const [current_step, setCurrentStep]: [steps, any] = useState('rate');
  const [goBack, setGoBack]: [boolean, any] = useState(false);

  const [ratingsFinished, setRatingsFinished]: [boolean, any] = useState(false); // needed as rating does not need a specific value
  const [ratings, setRatings]: [
    RatingFeedback[],
    React.Dispatch<React.SetStateAction<RatingFeedback[]>>,
  ] = useState(
    props.line_items.map((x, index) => ({ index: index, rating: 0 })),
  );
  const [positive_freetexts, setPositiveFreeTexts]: [
    TextFeedback[],
    React.Dispatch<React.SetStateAction<TextFeedback[]>>,
  ] = useState(
    props.line_items.map((x, index) => ({ index: index, rating: '' })),
  );
  const [negative_freetexts, setNegativeFreeTexts]: [
    TextFeedback[],
    React.Dispatch<React.SetStateAction<TextFeedback[]>>,
  ] = useState(
    props.line_items.map((x, index) => ({ index: index, rating: '' })),
  );

  /**
   * In case the product ratings are there, continue
   * to positvie text feedback
   */
  const ratingsHandler = (values: { index: number; rating: number }[]) => {
    setRatings(values);
    setRatingsFinished(true);
    setCurrentStep('positive_feedback');
  };

  /**
   * What happens if a user is finished typing a text about each product
   *
   * @param type can be 'positive' or 'negative'
   * @param values an array of index and text, please see the TextFeedback type
   *   if you need further information what is in the array
   */
  const textFeedbackHandler = (type: string, values: TextFeedback[]) => {
    if (type === 'positive') {
      // on the positive page finishing, continue to the negative page
      setPositiveFreeTexts(values);
      setCurrentStep('negative_feedback');
    } else {
      // after the negative page is finished, finish the whole process
      setNegativeFreeTexts(values);
      // combine the three ratings for each single product
      props.validateHandler(
        props.line_items.map((item: LineItemType, index: number) => ({
          index: index,
          product_name: item.title + ' (' + item.variant_title + ')',
          rating: ratings[index].rating * 2,
          positive_freetext: positive_freetexts[index].rating,
          negative_freetext: values[index].rating,
        })),
      );
    }
  };

  const backHandler = () => {
    setGoBack(true);
  };

  useEffect(() => {
    // manage what happens if the back button is pressed
    if (goBack) {
      if (['positive_feedback'].includes(current_step)) {
        setCurrentStep('rate');
      } else {
        setCurrentStep('positive_feedback');
      }
      setGoBack(false);
    }

    // split the defaultvalue onto the three pages
    if (props.defaultValue !== undefined && !ratingsFinished && !loading) {
      setLoading(true);
      setRatings(
        props.defaultValue.map((value: ProductFeedbackType, index: number) => ({
          index: index,
          rating: value.rating,
        })),
      );
      setRatingsFinished(true);
      setPositiveFreeTexts(
        props.defaultValue.map((value: ProductFeedbackType, index: number) => ({
          index: index,
          rating: value.positive_freetext,
        })),
      );
      setNegativeFreeTexts(
        props.defaultValue.map((value: ProductFeedbackType, index: number) => ({
          index: index,
          rating: value.negative_freetext,
        })),
      );

      setCurrentStep('negative_feedback');
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goBack, current_step, props.defaultValue]);

  return (
    <FullWidth>
      {/* Loading State */}
      {loading ? (
        <CircularProgress />
      ) : //   After Loading Finished and step is rate
      current_step === 'rate' ? (
        <ProductFeedback1Rate
          line_items={props.line_items}
          validateHandler={ratingsHandler}
          defaultValue={ratings.map((x: RatingFeedback) => x.rating)}
          wasFinishedBefore={ratingsFinished}
        />
      ) : //   After Loading Finished and step is positive_feedback
      current_step === 'positive_feedback' ? (
        <ProductFeedback2Text
          type='positive'
          line_items={props.line_items}
          validateHandler={textFeedbackHandler}
          defaultValue={positive_freetexts}
          backHandler={backHandler}
        />
      ) : (
        //   After Loading Finished and step is negative_feedback
        <ProductFeedback2Text
          type='negative'
          line_items={props.line_items}
          validateHandler={textFeedbackHandler}
          defaultValue={negative_freetexts}
          backHandler={backHandler}
        />
      )}
    </FullWidth>
  );
}
