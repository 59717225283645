import React, { useContext } from 'react';
import { IconButton, MenuItem } from '@material-ui/core';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { AuthContext } from '../utils/providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import ALink from '../styles/ALink';
import MUIRouterLink from '../styles/MUIRouterLink';
import Colors from '../utils/helper/Colors';
import QRCodeLogin from './QRCodeLogin';
import InterStyled from '../styles/InterStyled';
import Menu from '@mui/material/Menu';
import Button from '../styles/Button';

// Custom CSS for the components
const useStyles = makeStyles((theme) => ({
  flex: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  sectionMobile: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  click: {
    cursor: 'pointer',
  },
  menulink: {
    letterSpacing: '1px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  menulinkText: {
    paddingLeft: '8px',
    paddingRight: '24px',
  },
  langlink: {
    paddingLeft: '16px',
    paddingRight: '24px',
    marginBottom: '10px',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    height: '50px',
  },
  iconMenu: {
    paddingLeft: '10px',
  },
}));

export default function NavigationMenuMobile() {
  // Hook Initialization
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { user, handleSignOut } = useContext(AuthContext);

  // Sign out handler
  const handleLogout = () => {
    handleSignOut();
  };

  // State for opening and closing menu
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // Open and close menu functions
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';

  // Login or account buttons
  const loginIcon =
    user != null ? (
      <div className={classes.iconMenu}>
        <MUIRouterLink to='/overview'>
          <IconButton color='inherit'>
            <AccountCircleIcon style={{ color: Colors.keepoala.greytext }} />
          </IconButton>
        </MUIRouterLink>
      </div>
    ) : (
      <MUIRouterLink to='/login' style={{ marginLeft: '24px' }}>
        <Button style={{ width: '196px' }} color='primary'>{t('Login')}</Button>
      </MUIRouterLink>
    );

  // QR Code for logging on mobile
  const qrlog =
    user != null ? (
      <div className={classes.iconMenu}>
        <QRCodeLogin />
      </div>
    ) : null;

  // Log out button
  const logoutText =
    user != null ? (
      <div className={classes.iconMenu}>
        <IconButton onClick={handleLogout} color='inherit'>
          <LogoutIcon style={{ color: Colors.keepoala.greytext }} />
        </IconButton>
      </div>
    ) : null;

  /**
   * Mobile Navigation Menu for the App
   */
  return (
    <div className={classes.sectionMobile}>
      {/* Mobile Menu Show / Close button */}
      <IconButton
        aria-label='show more'
        aria-controls={mobileMenuId}
        aria-haspopup='true'
        onClick={
          !isMobileMenuOpen ? handleMobileMenuOpen : handleMobileMenuClose
        }
        color='inherit'
      >
        {!isMobileMenuOpen ? (
          <MenuIcon
            fontSize='large'
            style={{ color: Colors.keepoala.greytext }}
          />
        ) : (
          <CloseIcon
            fontSize='large'
            style={{ color: Colors.keepoala.greytext }}
          />
        )}
      </IconButton>

      {/* Mobile Menu */}
      <Menu
        anchorEl={mobileMoreAnchorEl}
        id={mobileMenuId}
        keepMounted
        MenuListProps={{
          sx: {
            paddingBottom: '20px',
            width: '250px',
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: '0px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        {/* Logged out user items */}
        {user == null
          ? [
              <MenuItem key={0}>
                <ALink
                  href='https://keepoala.com/keepoala-app-dein-digitaler-begleiter-fuers-onlineshopping/'
                  target='_blank'
                  className={classes.menulink}
                >
                  <InterStyled
                    interStyle='MenuLink'
                    className={classes.menulinkText}
                  >
                    {t('App')}
                  </InterStyled>
                </ALink>
              </MenuItem>,

              <MenuItem key={1}>
                <ALink
                  href='https://keepoala.com/werde-partner-beim-retourenexperte-und-reduziere-deine-retourenquote/'
                  target='_blank'
                  className={classes.menulink}
                >
                  <InterStyled
                    interStyle='MenuLink'
                    className={classes.menulinkText}
                  >
                    {t('Partner')}
                  </InterStyled>
                </ALink>
              </MenuItem>,

              <MenuItem key={2}>
                <ALink
                  href='https://www.keepoala.com/jobs'
                  target='_blank'
                  className={classes.menulink}
                >
                  <InterStyled
                    interStyle='MenuLink'
                    className={classes.menulinkText}
                  >
                    {t('Jobs')}
                  </InterStyled>
                </ALink>
              </MenuItem>,

              <MenuItem key={3}>
                <ALink
                  href='https://www.keepoala.com/blog'
                  target='_blank'
                  className={classes.menulink}
                >
                  <InterStyled
                    interStyle='MenuLink'
                    className={classes.menulinkText}
                  >
                    {t('Blog')}
                  </InterStyled>
                </ALink>
              </MenuItem>,
            ]
          : // Logged In Users Menu
            [
              <MenuItem key={0}>
                <MUIRouterLink to='/' className={classes.menulink}>
                  <InterStyled
                    interStyle='MenuLink'
                    className={classes.menulinkText}
                  >
                    {t('Overview')}
                  </InterStyled>
                </MUIRouterLink>{' '}
              </MenuItem>,

              <MenuItem key={1}>
                <MUIRouterLink to='/voucher' className={classes.menulink}>
                  <InterStyled
                    interStyle='MenuLink'
                    className={classes.menulinkText}
                  >
                    {t('Voucher')}
                  </InterStyled>
                </MUIRouterLink>
              </MenuItem>,
            ]}

        {/* Language change button */}
        <div className={classes.langlink}>
          <InterStyled
            interStyle={i18n.language === 'en' ? 'Button' : 'DisabledButton'}
            onClick={() =>
              i18n.changeLanguage(i18n.language === 'en' ? 'de' : 'en')
            }
          >
            {t('DE')}
          </InterStyled>

          <span
            style={{
              fontSize: '22px',
              color: Colors.keepoala.greydefault,
            }}
          >
            |
          </span>

          <InterStyled
            interStyle={i18n.language === 'de' ? 'Button' : 'DisabledButton'}
            onClick={() =>
              i18n.changeLanguage(i18n.language === 'en' ? 'de' : 'en')
            }
          >
            {t('EN')}
          </InterStyled>
        </div>

        {/* QR code for mobile login */}
        {qrlog}

        {/* Login or Profile */}
        {loginIcon}

        {/* Logout button */}
        {logoutText}
      </Menu>
    </div>
  );
}
