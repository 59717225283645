import React, { useEffect, useState } from "react";

import { spacing } from "@material-ui/system";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl as MUIFormControl } from "@material-ui/core";
import { Input, InputLabel, Alert as MuiAlert } from "../styles/MUIOverwrite";

import Button from "../styles/Button";
import { useTranslation } from "react-i18next";
import { shopify_verify_email_id } from "../utils/helper/shopify_verify_email_id";
import InterText from "../styles/Inter";
import { Progress } from "./LoadingCircular";
import { analytics } from "../firebase";

const FormControl = styled(MUIFormControl)(spacing);
const Alert = styled(MuiAlert)(spacing);
export const ShopLogo = styled.img`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  max-height: 50px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: row;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;
const FullWidth = styled.div`
  width: 100%;
`;
const useStyles = makeStyles((theme) => ({
  btn: {
    marginTop: 20,
  },
  myAlert: {
    marginTop: 15,
    width: "90%",
  },
  hide: {
    display: "none",
  },
  FormControl: {
    marginRight: 12,
  },
}));

/**
 * Component to check email addresses for orders
 * @param props
 *   * validatehandler - function to handle what needs to be
 *     done if the user inserts the correct email address
 *   * shop: name of a shop taking part in Keepoala and existing in Shops collection
 *   * order_id: id field of an order in shoporders collection
 *   * name: name of an order to display to the user
 *   * email: default email to be used
 * @returns
 */
export default function ReturnFeedbackCheck(props: {
  validateHandler: (x: boolean, email: string, id?: string, name?: string) => void;
  shop: string | undefined;
  email: string | undefined;
  order_id: string | undefined;
  name: string | undefined;
  type: string;
  useFirstName: boolean;
  support?: {
    email: string;
    link: string | undefined;
  };
  order_hint?: string;
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [email, setEmail]: [string, React.Dispatch<React.SetStateAction<string>>] = useState("");
  const [firstname, setFirstname]: [string, React.Dispatch<React.SetStateAction<string>>] = useState("");
  const [orderInfo, setOrderInfo]: [string, React.Dispatch<React.SetStateAction<string>>] = useState("");
  const [errorMessage, setErrorMessage]: [
    string | undefined,
    React.Dispatch<React.SetStateAction<string | undefined>>
  ] = useState();
  const [progress, setProgress] = useState(false);

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };
  const handleFirstnameChange = (e: any) => {
    setFirstname(e.target.value);
  };
  const handleOrderInfoChange = (e: any) => {
    setOrderInfo(e.target.value);
  };

  async function verify_email_or_firstname_id(
    shop: string,
    email: string,
    order_id_name: string,
    firstname: string,
    useFirstName: boolean
  ) {
    analytics.logEvent("returnfeedback_check_user", {
      feature: "returnfeedback",
      event: "returnfeedback_check_user",
    });
    const xx = await shopify_verify_email_id(
      shop,
      email,
      order_id_name,
      firstname,
      useFirstName,
      i18n.language.toLowerCase()
    ).catch(() => {
      setErrorMessage(t("Problem connecting to Keepoala Server"));
    });
    if (xx !== undefined) {
      return xx
        .json()
        .then(async (res: { test: boolean; error?: string; email: string; name?: string; id?: string }) => {
          if (res.test) {
            setErrorMessage("");
            setProgress(false);
            props.validateHandler(true, res.email, res.id, res.name);
          } else {
            let errorMessage = res.error !== undefined ? res.error : "Error occured";

            // If useFirstName change all email in error message to first name
            if (useFirstName) {
              errorMessage = errorMessage.replace(/email/g, "First Name");
            }
            setErrorMessage(t(errorMessage));
            setProgress(false);
          }
        })
        .catch((e: any) => {
          setErrorMessage(t("Problem connecting the Keepoala Server"));
          return false;
        });
    } else {
      setErrorMessage(t("Problem connecting the Keepoala Server"));
      return false;
    }
  }

  // On submit, check the email or first name against our database via verifyEmail
  // after that call the validateHandler or show the error
  const handleSubmit = (e: any) => {
    e.preventDefault();
    setProgress(true);
    analytics.logEvent("returnfeedback_check_user", {
      feature: "returnfeedback",
      event: "returnfeedback_check_user",
    });
    if (
      props.shop !== undefined &&
      (email !== undefined || (firstname !== undefined && props.useFirstName)) &&
      props.order_id !== undefined
    ) {
      verify_email_or_firstname_id(
        props.shop,
        email.trim(),
        props.order_id.trim(),
        firstname.trim(),
        props.useFirstName
      );
    } else {
      console.log(props.shop);
      console.log(props.email);
      console.log(props.order_id);
      setErrorMessage(t("Something was wrong with the setup"));
    }
  };

  const handleOrderInfoSubmit = (e: any) => {
    analytics.logEvent("returnfeedback_check_user", {
      feature: "returnfeedback",
      event: "returnfeedback_check_user",
    });
    e.preventDefault();
    setProgress(true);
    if (
      props.shop !== undefined &&
      (email !== undefined || (firstname !== undefined && props.useFirstName)) &&
      orderInfo !== undefined
    ) {
      verify_email_or_firstname_id(props.shop, email.trim(), orderInfo.trim(), firstname.trim(), props.useFirstName);
    } else {
      setErrorMessage(t("Please fill out all fields"));
      setProgress(false);
    }
  };

  useEffect(() => {
    if (props.email !== undefined) {
      setEmail(props.email);
    }
  }, [props.email]);

  if (props.order_id !== undefined || props.name !== undefined) {
    return (
      <FullWidth>
        <InterText size="l" mb={4} mt={1} align="center">
          {props.type === "nps" ? t("We would like your feedback on the order") : t("To return your order with the ID")}{" "}
          <b>{props.name !== undefined && props.name !== null ? props.name.replace(/%23/g, "#") : props.order_id}</b>
          {" " + t("from")}
        </InterText>
        {
          // derive the Shop logo from Google Cloud Storage
        }
        <ShopLogo
          src={
            "https://storage.googleapis.com/" +
            process.env.REACT_APP_PROJECT_ID +
            "-public-data/shoplogos/" +
            props.shop +
            ".png"
          }
        />

        <InterText size="l" mb={1} mt={4} align="center">
          {props.type === "nps"
            ? t("Please insert your email address to start")
            : props.useFirstName
            ? t("Please insert your first name to start the process and receive your return label")
            : t("Please insert your email address to start the process and receive your return label")}
          .
        </InterText>
        {
          // Create a form that lets the user insert the email address
        }
        <Form onSubmit={handleSubmit}>
          {props.useFirstName ? (
            <FormControl margin="normal" size="medium" required={true} className={classes.FormControl}>
              <InputLabel htmlFor="firstname">{t("First Name")}</InputLabel>
              <Input id="firstname" name="firstname" value={firstname} autoFocus onChange={handleFirstnameChange} />
            </FormControl>
          ) : (
            <FormControl margin="normal" size="medium" required={true} className={classes.FormControl}>
              <InputLabel htmlFor="email">{t("Email Address")}</InputLabel>
              <Input
                id="email"
                name="email"
                value={email}
                autoComplete="email"
                autoFocus
                onChange={handleEmailChange}
              />
            </FormControl>
          )}
          <InterText mr={2}>{""}</InterText>
          <Button
            variant="contained"
            color="primary"
            mb={2}
            size="medium"
            id="submit"
            type="submit"
            value="Submit"
            className={classes.btn}>
            {!progress ? props.useFirstName ? t("Verify First Name") : t("Verify Email Address") : <Progress />}
          </Button>
        </Form>
        {
          //Show any trouble
        }
        <Alert
          mb={4}
          severity="error"
          className={`${classes.myAlert} ${errorMessage === undefined ? classes.hide : ""}`}>
          {errorMessage}
          <br />
          <InterText>
            {t("You can retrieve support at") + " "}
            {
              <a
                rel="noreferrer"
                target="_blank"
                href={"mailto:" + props.support?.email ?? "retourenportal@keepoala.com"}>
                {props.support?.email ?? "retourenportal@keepoala.com"}
              </a>
            }
          </InterText>
        </Alert>
      </FullWidth>
    );
  } else {
    return (
      <FullWidth>
        <ShopLogo
          src={
            "https://storage.googleapis.com/" +
            process.env.REACT_APP_PROJECT_ID +
            "-public-data/shoplogos/" +
            props.shop +
            ".png"
          }
        />
        <InterText size="l" mb={4} mt={1} align="center">
          {props.useFirstName.toString() === "true"
            ? t("Please insert your oder id and the first name of the person you addressed the order to")
            : t("Please insert the order details to indentify you are allowed to access this order")}
        </InterText>
        <Form onSubmit={handleOrderInfoSubmit}>
          <FormControl margin="normal" size="medium" required={true} className={classes.FormControl}>
            <InputLabel htmlFor="orderinfo">
              {t("Order ID or Order Number")}
              {props.order_hint !== undefined && props.order_hint !== null && props.order_hint !== ""
                ? ` (${props.order_hint})`
                : ""}
            </InputLabel>
            <Input id="orderInfo" name="orderInfo" value={orderInfo} autoFocus onChange={handleOrderInfoChange} />
          </FormControl>
          {props.useFirstName ? (
            <FormControl margin="normal" size="medium" required={true} className={classes.FormControl}>
              <InputLabel htmlFor="firstname">{t("First Name")}</InputLabel>
              <Input id="firstname" name="firstname" value={firstname} onChange={handleFirstnameChange} />
            </FormControl>
          ) : (
            <FormControl margin="normal" size="medium" required={true} className={classes.FormControl}>
              <InputLabel htmlFor="email">{t("Email Address")}</InputLabel>
              <Input id="email" name="email" value={email} autoComplete="email" onChange={handleEmailChange} />
            </FormControl>
          )}
          <InterText mr={2}>{""}</InterText>
          <Button
            variant="contained"
            color="primary"
            mb={2}
            size="medium"
            id="submit"
            type="submit"
            value="Submit"
            className={classes.btn}>
            {!progress ? t("Verify") : <Progress />}
          </Button>
        </Form>
        {
          //Show any trouble
        }
        <Alert
          mb={4}
          severity="error"
          className={`${classes.myAlert} ${errorMessage === undefined ? classes.hide : ""}`}>
          {errorMessage}
          <br />
          <InterText>
            {t("You can retrieve support at") + " "}
            {
              <a
                rel="noreferrer"
                target="_blank"
                href={"mailto:" + props.support?.email ?? "retourenportal@keepoala.com"}>
                {props.support?.email ?? "retourenportal@keepoala.com"}
              </a>
            }
          </InterText>
        </Alert>
      </FullWidth>
    );
  }
}

ReturnFeedbackCheck.defaultProps = {
  type: "return",
  useFirstName: false,
  order_hint: "",
};
