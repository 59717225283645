import React, { useEffect, useState } from "react";
import {
  CircularProgress as CircularProgressMUI,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Container as MuiContainer,
  FormControl as MUIFormControl,
  Input,
  InputLabel,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { get_return_label_wrapper } from "../utils/helper/get_pretty_shopName";
import { shipping_label_email } from "../utils/helper/shipping_label_email";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import { LabelData } from "../types";
import InterText from "../styles/Inter";
import { useTranslation } from "react-i18next";
import Button from "../styles/Button";
import ThankYouContent from "./ThankYouContent";
import { ShopLogo } from "./ReturnFeedbackCheck";
import Colors from "../utils/helper/Colors";
import { analytics } from "../firebase";
import customerEmailCheck from "../utils/helper/payment/customerEmailCheck";

const CircularProgress = styled(CircularProgressMUI)`
  color: ${(props) => {
    return props.theme.main ?? Colors.keepoala.keepoalaGreendefault;
  }};
`;

const FormControl = styled(MUIFormControl)(spacing);
const Alert = styled(MuiAlert)(spacing);
const Container = styled(MuiContainer)`
  text-align: center;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: row;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;
export const ThankYouContainer = styled.div`
  padding: 10px;
  padding-top: 0px;
  justify-content: space-evenly;
`;
export default function ReturnLabel(props: {
  shop: string | undefined;
  id: string | undefined;
  isUser?: boolean;
  email?: string;
  special_info: string;
  special_working_days: number[];
  has_exchanges?: boolean;
}) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [returnLabel, setLabel]: [LabelData | undefined, any] = useState();
  const [dialog_open, setDialogOpen]: [boolean, any] = useState(false);

  // If the email contains `@customers.` this means it is not a correct email and that the user should be
  // Propmted to insert the correct email he wants, also he can download the file as a pdf
  const [hasCustomersEmail, setHasCustomersEmail] = useState(false);

  // Wheter the request to change email is sent or not
  const [emailRequestSent, setEmailRequestSent] = useState(false);
  const [loadingEmailRequest, setLoadingEmailRequest] = useState(false);
  const [newEmailAdded, setNewEmailAdded] = useState("");
  const [emailRequestError, setEmailRequestError] = useState("");

  function getLabel(shop: string, id: string) {
    analytics.logEvent("returnfeedback_get_label", {
      feature: "returnfeedback",
      event: "returnfeedback_get_label",
    });
    get_return_label_wrapper(shop, id).then((label_result: { res: LabelData | undefined; err: string }) => {
      if (label_result.res !== undefined) {
        setLabel(label_result.res);
        setErrorMessage(t(label_result.err));

        // Check if it is a customers email and we need to show the insert email form
        if (customerEmailCheck(label_result.res.email)) {
          setHasCustomersEmail(true);
        }
        analytics.logEvent("returnfeedback_label_success", {
          feature: "returnfeedback",
          event: "returnfeedback_label_success",
        });
        setLoading(false);
      } else {
        // try again
        setTimeout(() => {
          get_return_label_wrapper(shop, id).then((label_result: { res: LabelData | undefined; err: string }) => {
            if (label_result.err === undefined || label_result.err === "") {
              setLabel(label_result.res);
              // Check if it is a customers email and we need to show the insert email form
              if (customerEmailCheck(label_result.res?.email)) {
                setHasCustomersEmail(true);
              }
              setLoading(false);
              setErrorMessage("");
            }
          });
        }, 700);
        setTimeout(() => {
          get_return_label_wrapper(shop, id).then((label_result2: { res: LabelData | undefined; err: string }) => {
            if (label_result2.err === undefined || label_result2.err === "") {
              setLabel(label_result2.res);
              // Check if it is a customers email and we need to show the insert email form
              if (customerEmailCheck(label_result2.res?.email)) {
                setHasCustomersEmail(true);
              }
              setErrorMessage("");
              setLoading(false);
            } else {
              setErrorMessage(t(label_result2.err));
              setLoading(false);
            }
          });
        }, 2500);
      }
    });
  }

  const handleEmailChangeRequest = (event: any) => {
    event.preventDefault();
    setLoadingEmailRequest(true);

    shipping_label_email(props.shop, returnLabel?.tracking_number ?? "", event.target.email.value, "label")
      .then((response) => response.json())
      .then((data) => {
        if (data.test) {
          setEmailRequestError("");
          setNewEmailAdded(event.target.email.value);
          setEmailRequestSent(true);
        } else {
          setEmailRequestError(data.error);
        }
        setLoadingEmailRequest(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (props.shop !== undefined && props.id !== undefined && returnLabel === undefined) {
      if (props.shop !== undefined && props.id !== undefined) {
        getLabel(props.shop, props.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shop, props.id, returnLabel]);

  if (loading) {
    return (
      <Container maxWidth="sm">
        <InterText mt={5} mb={2} size="xl" align="center">
          {t("Retrieving return label")}
        </InterText>
        <CircularProgress />
      </Container>
    );
  } else {
    return (
      <Container maxWidth="sm" style={{ marginTop: "10vh" }}>
        {errorMessage === undefined || errorMessage === "" ? (
          <div>
            <ShopLogo
              src={
                returnLabel !== undefined
                  ? "https://storage.googleapis.com/" +
                    process.env.REACT_APP_PROJECT_ID +
                    "-public-data/shoplogos/" +
                    props.shop +
                    ".png"
                  : ""
              }
            />
            {/* If it is a customers email (fake email) we show the form to insert the correct email */}
            {hasCustomersEmail ? (
              <>
                {/* If the request to add the email is not sent yet show the form */}
                {/* And check that the tracking number exists to be able send the email */}
                {returnLabel !== undefined && returnLabel.tracking_number !== undefined && !emailRequestSent ? (
                  <>
                    <InterText size="l" mb={2} align="center">
                      {t("If you want to receive your return label via Email, please add your email here")}
                    </InterText>
                    <Form onSubmit={handleEmailChangeRequest}>
                      <FormControl margin="normal" size="medium" required={true}>
                        <InputLabel htmlFor="email">{t("Email Address")}</InputLabel>
                        <Input id="email" name="email" autoComplete="email" type="email" autoFocus />
                      </FormControl>

                      <Button
                        variant="contained"
                        color="primary"
                        mb={2}
                        mt={2}
                        ml={2}
                        size="medium"
                        id="submit"
                        type="submit"
                        value="Submit">
                        {loadingEmailRequest ? <CircularProgress /> : t("Add Email")}
                      </Button>
                    </Form>
                    {emailRequestError !== "" && (
                      <Alert mb={4} mt={4} severity="error">
                        {emailRequestError}
                      </Alert>
                    )}
                  </>
                ) : emailRequestSent ? (
                  // Else show that the email is sent
                  <>
                    <InterText size="l" mb={0} mt={4} align="left">
                      {t("Thank you very much") +
                        ". " +
                        t("Your return is now registered and you can send your items back to us") +
                        ". " +
                        (t("Your refund will appear within 5-10 working days after the return arrived") + ".")
                          .replace("5", props.special_working_days[0].toString())
                          .replace("10", props.special_working_days[1].toString()) +
                        (props.has_exchanges === true
                          ? t(
                              "Îf you decided for an exchange, we will send the new items within 5-10 business days after your return is on its way"
                            ) + "."
                          : ""
                        )
                          .replace("5", props.special_working_days[0].toString())
                          .replace("10", props.special_working_days[1].toString())}
                    </InterText>
                    <InterText size="l" mb={0} mt={1} align="center">
                      {t("We sent the label to")} <b>{newEmailAdded}</b>
                    </InterText>
                    {props.special_info !== "" ? (
                      <InterText size="l" mb={0} mt={1} align="center">
                        {props.special_info}
                      </InterText>
                    ) : null}
                    <InterText size="l" mb={2} align="center">
                      {t("Please look also into your spam folder or get it now as")}
                      {":"}
                    </InterText>
                  </>
                ) : null}

                <InterText size="l" mb={3} mt={2} align="center">
                  {t("Get it now as")}
                  {":"}
                </InterText>

                {/* Remove QR Code button for carriers that are not DHL, default is DHL */}
                {returnLabel?.carrier === undefined || returnLabel?.carrier === "dhl" ? (
                  <>
                    <Button mb={4} color="secondary" onClick={() => setDialogOpen(true)} style={{ minWidth: "250px" }}>
                      {t("Paperless QR Code")}
                    </Button>
                  </>
                ) : null}

                {/* PDF button */}
                <a style={{ textDecoration: "none" }} href={returnLabel?.labelLink} rel="noreferrer" target="_blank">
                  <Button mb={4} color="secondary" style={{ minWidth: "250px" }}>
                    {t("Download PDF")}
                  </Button>
                </a>
              </>
            ) : (
              // Regular case where the email is already there and sent to the correct address
              <>
                <InterText size="l" mb={0} mt={4} align="left">
                  {(t("Your refund will appear within 5-10 working days after the return arrived") + ".")
                    .replace("5", props.special_working_days[0].toString())
                    .replace("10", props.special_working_days[1].toString())}
                  <br />
                  {(props.has_exchanges === true
                    ? t(
                        "Îf you decided for an exchange, we will send the new items within 5-10 business days after your return is on its way"
                      ) + "."
                    : ""
                  )
                    .replace("5", props.special_working_days[0].toString())
                    .replace("10", props.special_working_days[1].toString())}
                </InterText>
                {props.special_info !== "" ? (
                  <InterText size="l" mb={0} mt={1} align="left">
                    {props.special_info}
                  </InterText>
                ) : null}
                <InterText size="l" mb={0} mt={1} align="center">
                  {t("We sent the label to")} <b>{returnLabel?.email}</b>
                </InterText>
                <InterText size="l" mb={2} align="center">
                  {t("Please look also into your spam folder or get it now as")}
                  {":"}
                </InterText>

                {/* Remove QR Code button for carriers that are not DHL, default is DHL */}
                {returnLabel?.carrier === undefined || returnLabel?.carrier === "dhl" ? (
                  <>
                    <Button mb={4} color="secondary" onClick={() => setDialogOpen(true)} style={{ minWidth: "250px" }}>
                      {t("Paperless QR Code")}
                    </Button>
                  </>
                ) : null}
                {returnLabel?.labelLink !== undefined ? (
                  <div>
                    <a href={returnLabel?.labelLink} target="_blank" rel="noreferrer">
                      <Button mb={4} color="secondary" style={{ minWidth: "250px" }}>
                        {t("PDF for printing")}
                      </Button>
                    </a>
                  </div>
                ) : null}
              </>
            )}

            {/* Thank you container for non users */}
            {props.isUser !== undefined && !props.isUser ? (
              <ThankYouContainer>
                <ThankYouContent
                  email={newEmailAdded !== "" ? newEmailAdded : props.email}
                  id={props.id}
                  shop={props.shop}
                />
              </ThankYouContainer>
            ) : null}

            {/* QR Code Dialog */}
            <Dialog
              open={dialog_open}
              onClose={() => setDialogOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">QR Code</DialogTitle>
              <DialogContent>
                <img src={returnLabel?.qrCodeLink} alt="Return QR code" />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialogOpen(false)}>{t("Close")}</Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          <Alert mb={4} mt={4} severity="error">
            {errorMessage}
            <br />
            {t("Please contact the Keepoala support at")}{" "}
            <a href="mailto:retourenportal@keepoala.com">retourenportal@keepoala.com</a>
          </Alert>
        )}
      </Container>
    );
  }
}
