import React, { useEffect, useState } from 'react';
import { LineItemType } from '../types';
import styled from 'styled-components';
import Button from '../styles/Button';
import { useTranslation } from 'react-i18next';
import InterText from '../styles/Inter';
import StarRating from './StarRating';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: column;
`;
const FullWidth = styled.div`
  width: 100%;
`;

/**
 * Component to get a rating from 0 - 10 of each product
 *
 * @props
 * * validateHandler - is called after finishing the rating of all items
 * * line_items - array containing items and there info that should receive a rating
 * * defaultValue - array containing numeric ratings for each item
 * * wasFinishedBefore - whether this Component is called a second time within
 *      the same screen and defaultValues are there
 *
 * @return Component that shows a star rating for each item in a cart
 */
export default function ProductFeedback1Rate(props: {
  validateHandler: (values: { index: number; rating: number }[]) => void;
  line_items: LineItemType[];
  defaultValue: number[];
  wasFinishedBefore: boolean;
}) {
  const { t } = useTranslation();

  const [ratings, setRatings]: [number[], any] = useState(props.defaultValue);
  const [definedRatings, setDefinedRatings]: [boolean[], any] = useState(
    props.line_items.map((x) => false),
  );
  const [partialRating, setPartialRating]: [
    { name: number; rating: number; changed: boolean } | undefined,
    any,
  ] = useState();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.validateHandler(
      ratings.map((value: number, index: number) => ({
        index: index,
        rating: value,
      })),
    );
  };

  useEffect(() => {
    // use a helper function partialRating to update the element that was rated
    // right now in the array of all ratings
    if (
      partialRating?.rating !== undefined &&
      partialRating.name !== undefined
    ) {
      const currently_defined = definedRatings;
      currently_defined[partialRating.name] = partialRating.changed;
      setDefinedRatings(currently_defined);
      const current_collection = ratings.map((x: number, index: number) => {
        if (index === partialRating.name) {
          return partialRating.rating;
        } else {
          return x;
        }
      });
      setRatings(current_collection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partialRating]);

  return (
    <FullWidth>
      <InterText size='l' mb={4} mt={1} align='left' weight={600}>
        {t('How would you rate each product you bought') + '?'}
      </InterText>
      <Form onSubmit={handleSubmit}>
        {props.line_items.map((x, index) => {
          return (
            <div key={'line_item_star' + index}>
              <InterText size='l'>
                {x.title +
                  (x.variant_title !== '' ? ' (' + x.variant_title + ')' : '')}
              </InterText>
              <StarRating
                index={index}
                defaultState={ratings[index]}
                validateHandler={(x: {
                  name: number;
                  rating: number;
                  changed: boolean;
                }) => {
                  setPartialRating(x);
                }}
                wasTouched={props.wasFinishedBefore}
              />
            </div>
          );
        })}
        {definedRatings.filter((x) => x).length >= props.line_items.length ||
        props.wasFinishedBefore ? (
          // finish button only shown in case all ratings are given
          <Button
            variant='contained'
            color='default'
            mt={2}
            mb={2}
            size='medium'
            id='submit'
            type='submit'
            value='Submit'
          >
            {t('Continue')}
          </Button>
        ) : (
          <InterText mb={4} mt={4}>
            {' '}
          </InterText>
        )}
      </Form>
    </FullWidth>
  );
}
