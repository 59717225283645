export default function AGB() {
    return (<div><p><strong>Teilnahmebedingungen f&uuml;r das Keepoala Programm</strong></p>
        <p>Das Keepoala Programm belohnt Nutzer f&uuml;r nachhaltiges Onlineshopping, bei dem die Nutzer Bestellungen eines Onlineshops nicht retournieren. &Uuml;ber Keepoala wird die M&ouml;glichkeit geboten, bei Bestellungen &uuml;ber Onlineshops (nachfolgend &bdquo;Keepoala-Partner&ldquo;) Punkte zu sammeln und diese gegen Pr&auml;mien und Gutscheine einzutauschen. Keepoala stellt eine Website und mobile Applikation (nachfolgend &bdquo;Plattform&ldquo;) zur Verf&uuml;gung, die den Zugriff auf Keepoala-Partner erm&ouml;glicht. F&uuml;r das Sammeln und das Einl&ouml;sen von Punkten im Rahmen des Keepoala Programms gelten folgende Nutzungsbedingungen. Auf der Plattform des Programms k&ouml;nnen weitere Hinweise und zus&auml;tzliche Bedingungen gesondert geregelt sein.</p>
        <ol>
        <li><strong> Teilnahme</strong></li>
        </ol>
        <p><strong>1.1. Teilnahmeberechtigte / Registrierung / Account </strong></p>
        <p>Teilnahmeberechtigt ist jede nat&uuml;rliche Personen, die das 18. Lebensjahr vollendet und in der Bundesrepublik Deutschland ihren Wohnsitz hat. Der tats&auml;chliche r&auml;umliche Lebensmittelpunkt (Hauptwohnsitz) des Teilnehmers gilt als Wohnsitz. Pro Person darf nur ein Account angelegt werden. Dem Nutzer ist es untersagt, mehrere Accounts mit verschiedenen pers&ouml;nlichen Daten anzulegen. Es besteht kein Rechtsanspruch zur Teilnahme an Keepoala. Der Anbieter kann ohne Angaben von Gr&uuml;nden die Teilnahme an Keepoala verweigern.</p>
        <p>Um am Programm teilzunehmen kann sich der Nutzer kostenlos auf der Keepoala Plattform unter Angabe einer E-Mail-Adresse, eines frei gew&auml;hlten Benutzernamens und Passworts registrieren und somit einen Account anlegen.Voraussetzung zum Anlegen eines Accounts ist die Zustimmung zu den AGB. Die Registrierung wird durch die Markierung des Feldes &bdquo;Ich stimme den AGB und den Datenschutzbestimmungen zu&ldquo; best&auml;tigt. Die Registrierung wird vom Nutzer durch einen Link in der Best&auml;tigungsemail, die an die vom Nutzer genannte E-Mail-Adresse versendet wird, abgeschlossen.</p>
        <p>Die Teilnahme ist kostenlos. Unabh&auml;ngig davon k&ouml;nnen Eink&auml;ufe und Bestellungen bei Partnerunternehmen Zahlungspflichten ausl&ouml;sen.Der Nutzer kann sich alternativ &uuml;ber ein Facebook Konto bereitgestellten &bdquo;Facebook Login&ldquo; oder &uuml;ber ein Google Konto &bdquo;Google Login&ldquo; registrieren und . Der Anbieter beh&auml;lt sich vor, inaktive Accounts, bei dem sich der Kunde seit &uuml;ber einem Jahr nicht hat zu deaktivieren und zu l&ouml;schen. Der Nutzer kann unbefristet den angelegten Account nutzen.</p>
        <p><strong>1.2. </strong></p>
        <p>Der Nutzer f&uuml;hrt keinen Missbrauch oder Betrug &uuml;ber seinen Account durch oder schadet dem Anbieter in anderer Weise durch Nutzung. Eine missbr&auml;uchliche Verwendung des Accounts, der Plattform und des Programms in jeder Art und Weise sowie Betrug sind nicht zul&auml;ssig.</p>
        <p>Die Nutzung des Programms und der Plattform sind dem Nutzer ausschlie&szlig;lich zum pers&ouml;nlichen Zwecken erlaubt. Eine gewerbliche Nutzung der zur Verf&uuml;gung gestellten Inhalte ist verboten.</p>
        <p>Dem Nutzer ist es verboten, die Daten inhaltlich, zu verwenden.</p>
        <p>Der Nutzer kann von dem Programm dauerhaft ausgeschlossen und die Punkte gel&ouml;scht werden, wenn ein wichtiger Grund vorliegt. Wichtige Gr&uuml;nde sind die Nichteinhaltung gesetzlicher Vorschriften durch den Nutzer oder ein schwerwiegender Versto&szlig; des Nutzers gegen diese Nutzungsbedingungen.</p>
        <p>Die im Programm bereitgestellten Inhalte d&uuml;rfen weder vervielf&auml;ltigt, verkauft, vermietet oder anderweitig genutzt werden.</p>
        <p><strong>1.3. </strong></p>
        <p>Der Nutzer kann innerhalb seines Profils die L&ouml;schung des Accounts beantragen. Innerhalb von 7 Tagen wird der Account vom Anbieter deaktiviert und per E-Mail dem Nutzer best&auml;tigt.</p>
        <p>Durch die L&ouml;schung des Accounts verfallen alle angesammelten Punkte und Pr&auml;mien. Eine Abtretung der Punkte oder Pr&auml;mien sowie eine &Uuml;bertragung auf andere Nutzer bed&uuml;rfen zu Ihrer Wirksamkeit der vorherigen Zustimmung des Anbieters.</p>
        <p>Es besteht kein Anspruch auf die Auszahlung oder Umwandlung des Punktewerts in Pr&auml;mien oder Gutscheine.</p>
        <ol start="2">
        <li><strong> Punkte</strong></li>
        </ol>
        <p><strong>2.1. Punkte erhalten</strong></p>
        <p>Der Nutzer kann nach abgeschlossener Registrierung jede Bestellung bei einem wie nachfolgend genannt im Keepoala Programm hinzuf&uuml;gen:</p>
        <p>-durch den Abschluss der Bestellung ueber Keepoala</p>
        <p>-der Upload der Bestellungbest&auml;tigung von einem Keepoala-Partner</p>
        <p>-Scan QR-Codes oder die</p>
        <p>Die Bestellbest&auml;tigung muss bis zu im Account hinterlegt werden.</p>
        <p>F&uuml;r die Gutschrift der Punkte muss der auf der Bestellbest&auml;tigung identisch mit dem Accountinhaber sein. F&uuml;r jede nicht get&auml;tigte Retoure wird dem Nutzer gutgeschrieben.</p>
        <p>Der Nutzer erh&auml;lt einmalig Punkte f&uuml;r jede nicht get&auml;tigte Retoure bei einem auf der Plattform und/oder Internetseite von Keepoala aufgelisteten Keepoala-Partner, bei welcher zum Zeitpunkt des Hinzuf&uuml;gens im Keepoala Programm das Datum der Bestellbest&auml;tigung nicht l&auml;nger als 14 Tage in der Vergangenheit liegt, der Name auf der Bestellbest&auml;tigung identisch mit dem Keepoala Accountnamen ist und die Bestellbest&auml;tigung dem vorgeschriebenen Format entspricht.</p>
        <p>Der Nutzer erh&auml;lt keine Punkte, , die Bestellbest&auml;tigung bereits hochgeladen wurde, der Name auf der Bestellbest&auml;tigung nicht mit dem Accountinhaber &uuml;bereinstimmt, die Bestellung oder Teile der Bestellung retourniert werden oder das 48-Stunden Limit von 2000 Punkten erreicht wurde.</p>
        <p>Innerhalb von Aktionen im Rahmen von Umfragen, Weiterempfehlungen oder das Erreichen eines bestimmten Status kann der Nutzer zus&auml;tzliche Punkte erhalten, deren Anzahl in den Aktionsbedingungen auf der definiert sind. Aktionen gelten nur f&uuml;r einen begrenzten Zeitraum. Es besteht au&szlig;erhalb des in den Aktionsbedingungen definierten Zeitraums kein Anspruch auf Punkte. Aktionen k&ouml;nnen auf Regionen und Nutzer begrenzt werden. Es besteht somit nicht f&uuml;r jeden Nutzer Anspruch auf die zus&auml;tzlichen Punkte einer Aktion.</p>
        <p><strong>2.2. Punkteverwaltung</strong></p>
        <p>Der Nutzer kann seinen Punktestand auf der Plattform unter seinem eingeloggten Account einsehen und verwalten.</p>
        <p>Sollte der Nutzer Einw&auml;nde zur Richtigkeit oder Vollst&auml;ndigkeit des Punktestandes haben, so muss dieser seinen Anspruch auf Richtigstellung innerhalb von 2 Wochen nach Erhalt der betreffenden Punkte per E-Mail oder Kontaktformular innerhalb der Plattform geltend machen. Das Ausbleiben einer Geltendmachung wird als Genehmigung des Punktestandes gewertet.</p>
        <p><strong>2.3. Punkte einl&ouml;sen</strong></p>
        <p>Der Nutzer hat die M&ouml;glichkeit die angesammelten Punkte bei teilnehmenden Keepoala-Partnern mit dem in der Plattform genannten Punktzahl einzul&ouml;sen, soweit der Nutzer ausreichend Punkte angesammelt hat.</p>
        <p>Die Punkte k&ouml;nnen nur f&uuml;r verf&uuml;gbare Pr&auml;mien eingel&ouml;st werden, wenn die Anzahl an Punkten ausreicht.</p>
        <p>Die Anzahl der erforderlichen Anzahl an Punkten f&uuml;r eine Pr&auml;mie wird in der Plattform angezeigt.</p>
        <p>Eine Auszahlung der Punkte ist nicht m&ouml;glich.</p>
        <p>Der Nutzer bestellt die gew&uuml;nschte Pr&auml;mie &uuml;ber die Keepoala Plattform und die Pr&auml;mie wird innerhalb von 24 Stunden dem Nutzer per E-Mail zugesendet oder &uuml;ber die Plattform bereitgestellt</p>
        <p>Die zur Verf&uuml;gung gestellten Gutscheine und Pr&auml;mien k&ouml;nnen nicht mit anderen Rabatten eingel&ouml;st oder verrechnet werden.</p>
        <p>Jede Pr&auml;mie kann bis zum Verfallsdatum einmal eingel&ouml;st werden. Nach dem Verfallsdatum ist die Pr&auml;mie abgelaufen und kann nicht mehr genutzt werden.</p>
        <p>F&uuml;r das Einl&ouml;sen einer Pr&auml;mie muss die ben&ouml;tigte Anzahl an Punkten dem Nutzer zur Verf&uuml;gung stehen, d.h. nicht mehr im .</p>
        <p>Die Pr&auml;mienverf&uuml;gbarkeit kann sich zwischen Regionen, Nutzern und Datum unterscheiden und teilweise k&ouml;nnen Pr&auml;mien nicht verf&uuml;gbar sein.</p>
        <p>Keepoala hat auf die Verf&uuml;gbarkeit von Pr&auml;mien und Leistungen bei den Keepoala-Partnern keine Einfluss. Es besteht kein Recht auf die Verf&uuml;gbarkeit von einzelnen Pr&auml;mien bei den Keepoala-Partnern. Keepoala ist nicht verantwortlich f&uuml;r die Leistungen und Vertragserf&uuml;llung eines Keepoala-Partners. Den Leistungsvertrag schlie&szlig;t der Nutzer direkt mit dem Keepoala-Partner und somit finden die Allgemeinen Gesch&auml;ftsbedingungen des jeweiligen Keepoala-Partners Anwendung.</p>
        <p><strong>2.4. Verfall von Punkten</strong></p>
        <p>Punkte verfallen 36 Monate nach der Gutschrift, wenn diese nicht gegen Pr&auml;mien eingel&ouml;st wurden.</p>
        <ol start="3">
        <li><strong> Ausschluss von der Teilnahme</strong></li>
        </ol>
        <p>Keepoala beh&auml;lt sich das Recht vor Nutzer von der Teilnahme auszuschlie&szlig;en wenn diese gegen die Nutzungsbedingungen von Keepoala oder Keepoala-Partnern schuldhaft versto&szlig;en. Ein Recht auf die Auszahlung oder Einl&ouml;sung der Punkte in Pr&auml;mien besteht nicht.</p>
        <ol start="4">
        <li><strong> Programmbeendigung</strong></li>
        </ol>
        <p>Keepola beh&auml;lt sich das Recht vor, das Programm jederzeit zu beenden oder durch ein anderes Programm zu ersetzen. Durch das Beenden des Programms verfallen alle gesammelten Punkte automatisch und ein Recht auf die Auszahlung oder Einl&ouml;sung der Punkte in Pr&auml;mien besteht nicht.</p>
        <ol start="5">
        <li><strong> &Auml;nderung der Teilnahmebedingungen</strong></li>
        </ol>
        <p>Keepoala beh&auml;lt sich das Recht vor, die Nutzungsbedingungen jederzeit zu &auml;ndern oder zu erweitern, , soweit dies durch entsprechende Gesetzes&auml;nderungen oder zur Abdeckung von neu integrierten Funktionen erforderlich wird. Keepoala beh&auml;lt sich das Recht vor, diese Nutzungsbedingungen f&uuml;r die Zukunft zu &auml;ndern oder zu erg&auml;nzen. Schadensersatzanspr&uuml;che von Nutzern gegen Keepoala wegen &Auml;nderungen sind ausgeschlossen. Die &Auml;nderungen oder Erg&auml;nzungen der Nutzerbedingungen gelten als genehmigt, wenn ein Teilnehmer nicht innerhalb von einem Monat nach Bekanntgabe in Textform Widerspruch einlegt.</p>
        <ol start="6">
        <li><strong> Eingeschr&auml;nkte Haftung f&uuml;r rechtswidrige Inhalte Dritter und Hyperlinks</strong></li>
        </ol>
        <p>Die Plattform kann Hyperlinks auf Webseiten Dritter enthalten. Keepoala &uuml;bernimmt f&uuml;r die Inhalte dieser Webseiten weder eine Verantwortung, noch macht Keepoala sich diese zu eigen. Es besteht keine M&ouml;glichkeit, Einfluss auf die Inhalte von verlinkten Seiten anderer Unternehmen zu nehmen. Auch ist es unm&ouml;glich, eine andauernde inhaltliche Kontrolle von verlinkten fremden Seiten sicher zu stellen. Soweit Keepoala rechtswidrige Inhalte auf verlinkten fremden Seiten bekannt werden, werden die entsprechenden Links und/oder die entsprechenden Fremdinhalte umgehend gesperrt, soweit Keepoala dies mit zumutbarem Aufwand technisch m&ouml;glich ist. Keepoala kann Nutzer auch mittels Links auf fremde Inhalte und Anwendungen Dritter hinweisen. Wenn und soweit im Zusammenhang mit diesen Drittinhalten der Abschluss eines Vertrages angeboten wird, kommt dieser bei Abschluss desselben ausschlie&szlig;lich mit dem jeweils als Anbieter ausgewiesenen Verantwortlichen und dem Nutzer zustande. Keepoala wird nicht Vertragspartner.</p>
        <ol start="7">
        <li><strong> Rechts&uuml;bertragung</strong></li>
        </ol>
        <p>Der Nutzer gestattet dem Anbieter die vom ihm im Rahmen der Plattform eingestellten Inhalte f&uuml;r die Erbringung seiner mit dem Betrieb der Plattform verbundenen Dienstleistungen zu nutzen und r&auml;umt dem Anbieter hierf&uuml;r die erforderlichen Rechte an den Inhalten unentgeltlich ein. Dieses Nutzungsrecht beinhaltet insbesondere das r&auml;umlich und zeitlich unbeschr&auml;nkte, nicht-exklusive Recht, die Inhalte &uuml;ber die Plattform oder gegebenenfalls anderen Medien &ouml;ffentlich zug&auml;nglich zu machen, sie zu vervielf&auml;ltigen, zu verbreiten und an Dritte zu &uuml;bertragen, soweit sich nicht etwas anderes aus den zwischen dem Anbieter und dem Nutzer getroffenen Vereinbarungen ergibt.</p>
        <p>Der Nutzer gestattet dem Anbieter, die eingestellten Inhalte technisch zu bearbeiten, sie insbesondere an die zur Nutzung erforderlichen Formatvorgaben der Plattform anzupassen oder die Darstellungsqualit&auml;t zu verbessern.</p>
        <ol start="7">
        <li><strong> Haftung </strong></li>
        </ol>
        <p>Keepoala haftet bei vertraglichen und au&szlig;ervertraglichen Anspr&uuml;chen wegen Sch&auml;den von Nutzern grunds&auml;tzlich nur bei Vorsatz und grober Fahrl&auml;ssigkeit. F&uuml;r Sch&auml;den, die Teilnehmern im Zusammenhang mit ihrer Teilnahme durch einen Betreiber, einen Mitherausgeber oder die jeweiligen Erf&uuml;llungsgehilfen entstehen, gilt Folgendes: Bei Vorsatz oder grober Fahrl&auml;ssigkeit sowie bei Vorliegen einer Garantie ist die Haftung unbeschr&auml;nkt.</p>
        <p><br /> Bei einer leicht fahrl&auml;ssigen Verletzung wesentlicher Vertragspflichten ist die Haftung beschr&auml;nkt auf darauf zur&uuml;ckzuf&uuml;hrende Sach- und Verm&ouml;genssch&auml;den in H&ouml;he des vorhersehbaren, typischerweise eintretenden Schadens. Eine wesentliche Vertragspflicht ist die Pflicht, deren Erf&uuml;llung die ordnungsgem&auml;&szlig;e Durchf&uuml;hrung des Vertrages &uuml;berhaupt erst erm&ouml;glicht und auf deren Einhaltung der Teilnehmer regelm&auml;&szlig;ig vertrauen darf. Jede weitergehende Haftung auf Schadensersatz ist &ndash; au&szlig;er f&uuml;r Anspr&uuml;che nach dem Produkthaftungsgesetz &ndash; ausgeschlossen.</p>
        <p>sowie im Falle zwingender gesetzlicher Regelungen und wesentlicher Vertragspflichten.</p>
        <ol start="8">
        <li><strong> Recht, Gerichtsstand</strong></li>
        </ol>
        <p>Diese Nutzungsbedingungen unterliegen dem Recht der Bundesrepublik Deutschland. Gerichtsstand f&uuml;r alle Streitigkeiten nach diesen Nutzungsbedingungen oder im Zusammenhang mit dem Programm Keepoala ist M&uuml;nchen. Zu einer Teilnahme an einem Schlichtungsverfahren sind die Betreiber nicht verpflichtet. Sollten eine oder mehrere Bestimmungen dieser Vereinbarung unwirksam sein oder werden, so wird die Wirksamkeit der Vereinbarung im &Uuml;brigen davon nicht ber&uuml;hrt. F&uuml;r den Abschluss und die Abwicklung s&auml;mtlicher Vertr&auml;ge gilt das Recht der Bundesrepublik Deutschland. Die Geltung des UN-Kaufrechts wird ausgeschlossen.</p>
        <p><strong>Stand: September 2020</strong></p>
        </div>)
}