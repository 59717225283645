// Helper function
// Splits the orders into smaller arrays that have the same month
export default function splitOrdersByMonth(orderlist) {
  // If orderlist is empty return empty array
  if (orderlist.length === 0) return [];

  // Copy the contents into new array and initialize new array
  orderlist = [...orderlist];
  let splitOrders = [];

  // Keeps track of the last month added to group
  let lastMonth = null;
  // Group array that would have each month orders together
  let monthOrderGroup = [];

  // Main loop of splitting
  orderlist.forEach((order, index) => {
    // First Order in the array
    if (lastMonth === null) {
      monthOrderGroup.push(order);
      lastMonth = order.date;
    }
    // All orders other than the first
    else {
      // Check if the month of the order is the same as last month
      // Check that month and year are the same
      if (
        parseInt(lastMonth.split('-')[0]) ===
          parseInt(order.date.split('-')[0]) &&
        parseInt(lastMonth.split('-')[1]) === parseInt(order.date.split('-')[1])
      ) {
        // Add the order to month group
        monthOrderGroup.push(order);
      } else {
        // Push the last month Order Group to split orders and empty the array for the new month
        splitOrders.push(monthOrderGroup);
        monthOrderGroup = [];

        // Push new order and set last month
        monthOrderGroup.push(order);
        lastMonth = order.date;
      }
    }
    // If last order we need to push the monthOrderGroup to SplitOrders
    if (index === orderlist.length - 1) {
      splitOrders.push(monthOrderGroup);
    }
  });

  console.log(splitOrders);
  return splitOrders;
}
