import styled from 'styled-components';
import { Button as MuiButton } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import Colors from '../utils/helper/Colors';

const ButtonPre = styled(MuiButton)`
  color: ${(props) => {
    if (props.disabled !== true) {

      switch (props.color) {
        case 'default':
          return props.theme.action_text ?? Colors.keepoala.greytext;
        case 'primary':
          return props.theme.action_text_primary ?? Colors.keepoala.greytext;
        case 'secondary':
          return props.theme.action_text_secondary ?? Colors.keepoala.actionGreenlight;
          default:
            return  props.theme.action_text_fallback ?? Colors.keepoala.black;
      }
    } else {
      return props.theme.action_text_disabled ?? Colors.keepoala.greymedium;
    }
  }};
  .MuiButton-label{
    color: ${(props) => {
      if (props.disabled !== true) {
  
        switch (props.color) {
          case 'default':
            return props.theme.action_text ?? Colors.keepoala.greytext;
          case 'primary':
            return props.theme.action_text_primary ?? Colors.keepoala.greytext;
          case 'secondary':
            return props.theme.action_text_secondary ?? Colors.keepoala.actionGreenlight;
            default:
              return  props.theme.action_text_fallback ?? Colors.keepoala.black;
        }
      } else {
        return props.theme.action_text_disabled ?? Colors.keepoala.greymedium;
      }
    }};
    font-family: ${(props) => props.theme.fontFamily ?? 'Inter'};
  }
  background-color: ${(props) => {
    if (props.disabled !== true) {
      switch (props.color) {
        case 'default':
          return props.theme.bg ?? Colors.keepoala.white;
        case 'primary':
          return props.theme.bg_primary ?? Colors.keepoala.actionGreendefault;
        case 'secondary':
          return props.theme.bg_secondary ?? Colors.keepoala.keepoalaGreendark;
        case 'disabled':
          return props.theme.bg_disabled ?? Colors.keepoala.white;
        default:
          return props.theme.bg_fallback ?? Colors.keepoala.white;
      }
    } else {
      return props.theme.bg_disabled ?? Colors.keepoala.lightgrey;
    }
  }} !important;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  min-height: 46px;
  @media (max-width: 768px) {
    font-size: 12px;
    height: 34px;
  }
  &:hover {
    background-color: ${(props) => {
      switch (props.color) {
        case 'default':
          return props.theme.bg_hover ?? Colors.keepoala.dark;
        case 'primary':
          return props.theme.bg_hover_primary ?? Colors.keepoala.actionGreendark;
        case 'secondary':
          return props.theme.bg_hover_secondary ?? Colors.keepoala.forestGreendefault;
        case 'disabled':
          return props.theme.bg_hover_disabled ?? Colors.keepoala.grey;
        default:
          return props.theme.bg_hover_fallback ?? Colors.keepoala.grey;
      }
    }};
    color: ${(props) => {
      switch (props.color) {
        case 'default':
          return props.theme.action_text_hover ?? Colors.keepoala.white;
        case 'primary':
          return props.theme.action_text_hover_primary ?? Colors.keepoala.greytext;
        case 'secondary':
          return props.theme.action_text_hover_secondary ?? Colors.keepoala.actionGreenlight;
        case 'disabled':
          return props.theme.action_text_hover_disabled ?? Colors.keepoala.greymedium;
        default:
          return props.theme.action_text_hover_fallback ?? Colors.keepoala.darktext;
      }
    }};
    .MuiButton-label{
      color: ${(props) => {
        if (props.disabled !== true) {
    
          switch (props.color) {
            case 'default':
              return props.theme.action_text_hover ?? Colors.keepoala.greytext;
            case 'primary':
              return props.theme.action_text_hover_primary ?? Colors.keepoala.greytext;
            case 'secondary':
              return props.theme.action_text_hover_secondary ?? Colors.keepoala.actionGreenlight;
              default:
                return  props.theme.action_text_hover_fallback ?? Colors.keepoala.black;
          }
        } else {
          return props.theme.action_text_disabled ?? Colors.keepoala.greymedium;
        }
      }};
    }
  }
  &.MuiButtonBase-root.Mui-disabled{
    cursor: 'not-allowed' !important;
  };
  cursor: 'pointer';
`;
const Button = styled(ButtonPre)(spacing);
export default Button;

export const FacebookButton = styled(Button)`
  background-color: #4460a0;
  color: #ffffff;
  &:hover {
    background-color: ${Colors.keepoala.grey};
    color: #4460a0;
  }
`;

export const AppleButton = styled(Button)`
  background-color: ${Colors.keepoala.black};
  color: #ffffff;
  &:hover {
    background-color: ${Colors.keepoala.grey};
    color: ${Colors.keepoala.black};
  }
`;
