import React, { useContext } from 'react';
import { AuthContext } from '../utils/providers/AuthProvider';
import { AppBar, Container, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import { default as Lato } from '../styles/lato';
import { useTranslation } from 'react-i18next';
import Link from '../styles/Link';
import Colors from '../utils/helper/Colors';

const LatoText = styled(Lato)(spacing);

const useStyles = makeStyles({
  footer: {
    bottom: 0,
    top: 'auto',
    backgroundColor: '#ffffff',
    lineHeight: '25px',
    color: Colors.keepoala.black,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
});
export default function Footer() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { authenticated } = useContext(AuthContext);

  return (
    <AppBar position='fixed' className={classes.footer}>
      <Container maxWidth='lg'>
        <Box flexDirection='row' className={classes.flex}>
          <LatoText fontSize='m'>
            <Link href='/agb'>{t('Terms and conditions')}</Link>
          </LatoText>
          &nbsp;&nbsp;&nbsp;
          <LatoText fontSize='m'>
            <Link href='/privacy_policy'>{t('Privacy Policy')}</Link>
          </LatoText>
          &nbsp;&nbsp;&nbsp;
          <LatoText fontSize='m'>
            <Link href='/faq'>{t('FAQ')}</Link>
          </LatoText>
          &nbsp;&nbsp;&nbsp;
          {authenticated ? (
            <LatoText fontSize='m'>
              <Link href='/writetosupport'>{t('Write to support')}</Link>
            </LatoText>
          ) : null}
        </Box>
      </Container>
    </AppBar>
  );
}
