import { useState, useCallback, useEffect } from "react";

export const useRateLimiter = (callback, maxCallsPerMinute) => {
  const [callCount, setCallCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCallCount(0); // Reset the count every minute
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const rateLimitedCallback = useCallback(() => {
    if (callCount < maxCallsPerMinute) {
      setCallCount((prev) => prev + 1);
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, maxCallsPerMinute]);

  return rateLimitedCallback;
};
