import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import AppShortcut from '@material-ui/icons/PhoneIphone';
import AppShortcut2 from '@material-ui/icons/ChevronRight';
import Close from '@material-ui/icons/Close';
import { default as Lato } from '../styles/lato';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../utils/providers/AuthProvider';
import { db } from '../firebase';
import QRCode from 'react-qr-code';
import { AES } from 'crypto-js';
import {
  Dialog as DialogMUI,
  DialogTitle as DialogTitleMUI,
  IconButton,
} from '@material-ui/core';
import Colors from '../utils/helper/Colors';
import { Clickable } from './Banner1_Welcome';

const LatoTextSpace = styled(Lato)(spacing);
const LatoText = styled(LatoTextSpace)`
  @media only screen and (max-width: 800px) {
    font-size: 16px;
    max-width: 150px;
    margin-right: 10px;
  }
`;

const Dialog = styled(DialogMUI)`
  & .MuiDialog-paper {
    padding: 2em;
    text.align: center;
  }
  & MuiBackdrop-root {
    background-color: rgba(255, 255, 255, 0.8);
  }
`;
const DialogTitle = styled(DialogTitleMUI)``;

const FlexRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Divspacing = styled(styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`)(spacing);
/**
 * Icon that shows a QR Code which can be used for App Login
 *
 * Upon clicking the Icon a Modal shows that contains
 * a QR Code which contains some basic user information
 * and the current date in UTC
 *
 * This information can be used by the Phone to authenticate
 * the user again with Firebase Cloud functions
 *
 * We encrypt the information such that only Firebase Cloud functions
 * can decrypt it using exactly the same key/password
 *
 * @param {*} props can contain `color:string` , `with_text:boolen`, `dialog_only:boolean`, `open:boolean` , `setOpen:function`
 * @returns
 */
export default function QRCodeLogin(props) {
  const { t } = useTranslation();
  // Extract config to see if this action gets extra points
  const { user, config } = useContext(AuthContext);
  const [info_string_loading, setInfo_String_Loading] = useState(true);
  const [info_string, setInfo_String] = useState('');
  const [open, setOpen] = useState(false);

  let { color, with_text } = props;

  if (color === undefined) {
    color = Colors.keepoala.greytext;
  }

  if (with_text === undefined) {
    with_text = false;
  }

  useEffect(() => {
    // reset the QR Code anytime this gets clicked
    if (open || (props.dialog_only && props.open)) {
      setInformationString();
    }
  }, [open, props.open]);

  //setting the information string here
  function setInformationString() {
    setInfo_String_Loading(true);
    var date = new Date();
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    );

    const cur_utc = new Date(now_utc);
    return db
      .collection('User')
      .doc(user.uid)
      .get()
      .then((snapshot) => {
        const key = process.env.REACT_APP_ENV_QR_KEY;
        const inf_str =
          user.uid +
          ',' +
          snapshot.data().registration_date +
          ',' +
          cur_utc.getTime();
        //   Use AES encryption to send the info_string to the App via the QRCode
        const enc_str = AES.encrypt(inf_str, key).toString();
        setInfo_String(enc_str);
        setInfo_String_Loading(false);
      });
  }

  return (
    <div>
      {/* Show a simple button to visualize this login */}
      {/* If dialog only is set then do not show anything */}
      {!props.dialog_only && (
        <IconButton onClick={() => setOpen(!open)}>
          <Divspacing>
            {/* In case text is wanted, also show some explanatory text before the button */}
            {with_text ? (
              <div>
                <LatoText fontSize={'xxl'} color={color}>
                  {t('Already have the app') + '? '}
                </LatoText>
                <br />
                <LatoText fontSize={'xxl'} color={color}>
                  {t('Click to login')}{' '}
                  {config?.qr_code_login > 0
                    ? t('and receive') +
                      ' ' +
                      config.qr_code_login +
                      ' ' +
                      t('extra Keepoalas')
                    : null}
                </LatoText>
              </div>
            ) : null}
            <AppShortcut
              fontSize={with_text ? 'large' : 'medium'}
              htmlColor={color}
            />
            <AppShortcut2
              fontSize={with_text ? 'large' : 'medium'}
              htmlColor={color}
            />
          </Divspacing>
        </IconButton>
      )}
      {/* Dialog to show a QR Code */}
      <Dialog
        open={props.dialog_only ? props.open : open}
        onClose={() =>
          props.dialog_only ? props.setOpen(false) : setOpen(false)
        }
      >
        <FlexCol>
          <FlexRow>
            <DialogTitle>
              <LatoText fontSize='xl'>{t('Already have the app')}?</LatoText>
              <LatoText fontSize='xl'>
                {t('Scan this QR Code to login with inside the app')}
              </LatoText>
              {/* In case we defined it, the users can gain extra points by using this type of login */}
              <LatoText fontSize='xl'>
                {config?.qr_code_login > 0
                  ? t('and receive') +
                    ' ' +
                    config?.qr_code_login +
                    ' ' +
                    t('extra Keepoalas')
                  : null}
              </LatoText>
            </DialogTitle>
            <Clickable
              onClick={() =>
                props.dialog_only ? props.setOpen(false) : setOpen(false)
              }
            >
              <Close fontSize={'large'}></Close>
            </Clickable>
          </FlexRow>
          {info_string_loading ? (
            <LatoText>{t('Loading')}</LatoText>
          ) : (
            <QRCode
              value={info_string}
              title='Keepoala App Login Code'
              level='M'
            />
          )}
        </FlexCol>
      </Dialog>
    </div>
  );
}
