import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import firebase from '../firebase';

import { CircularProgress, Container as MuiContainer } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { LineItemType } from '../types';
import styled from 'styled-components';
import split_query from '../utils/helper/split_query';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../utils/providers/AuthProvider';
import OrderFeedbackCheck from './OrderFeedbackCheck';
import InterText from '../styles/Inter';
import { Alert as MuiAlert } from '@material-ui/lab';
import ProductFeedback from './ProductFeedback';
import ReturnQuestions, { AnswerType } from './ReturnQuestions';
import ThankYou from './ThankYou';
import ThankYouLinkKeepoala from './ThankYouLinkKeepoala';
import anyString from '../utils/helper/anyString';
import { ShopLogo as DefaultShopLogo } from './OrderFeedbackCheck';
import { get_line_items } from '../utils/helper/get_line_item';
import { get_pretty_shopName } from '../utils/helper/get_pretty_shopName';
import { firestore_get_nps } from '../utils/helper/firestore_get_nps';
import InterStyled from '../styles/InterStyled';
import Colors from '../utils/helper/Colors';
// eslint-disable-next-line import/no-webpack-loader-syntax
import HomepagePackage from '!file-loader!../assets/img/homepage_package.svg';

const ShopLogo = styled(DefaultShopLogo)`
  max-height: 40px;
  margin-bottom: 16px;
`;

const Container = styled(MuiContainer)`
  @media only screen and (max-width: 600px) {
    min-height: calc(100vh - 125px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (min-width: 600px) {
    margin-top: 50px;
  }
  flex-direction: column;
`;
const Alert = styled(MuiAlert)(spacing);

export type ProductFeedbackType = {
  index: number;
  rating: number;
  positive_freetext: string;
  negative_freetext: string;
  product_name: string;
};

// Component Steps in Order
const enum STEPS {
  PRODUCTQUESTIONS = 'Product Questions',
  NPSQUESTIONS = 'NPS Questions',
  SENDINGANSWERS = 'Sending Answers',
  FINISHED = 'Finished',
}

/**
 * OrderFeedbackProcess
 *
 * A three steop process
 *   1. validate that the user is allowed to access the order
 *   2. get feedback of all items of the purchase
 *   3. get feedback about the shop itself (NPS  = net promoter score)
 *   4. call successHandler or show a thank you page
 *
 * @param query_params
 *  * email: email address belonging to the order
 *  * order_id: id field inside the database shopify
 *  * shop: name field of a Shop in our Shops collection
 *  * order_name: A name to display to the user
 * @returns A three step process that guides users who
 *   bought and kept something through a process of
 *
 * @link ProductFeedBack
 * @link ReturnQuestions
 */
export default function OrderFeedback() {
  const location = useLocation();
  const query_params: any = split_query(location.search);
  const db = firebase.firestore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loginValid, setLoginValid] = useState(false);
  const [isUser, setIsUser] = useState(false);

  const [currentStep, setCurrentStep]: [string, any] = useState(
    STEPS.PRODUCTQUESTIONS,
  );

  const [productFeedback, setProductFeedback]: [any, any] = useState([]);
  useState(false);
  const [npsFeedback, setNpsFeedback]: [any, any] = useState([]);

  const [order_id, setOrderId]: [string | number | undefined, any] = useState(query_params.id);
  const [order_name, setOrderName]: [string | undefined, any] = useState(query_params?.name.replace(/%23/g, '#'));
  const [shop, setShop]: [string | undefined, any] = useState(query_params.shop);
  const [shop_pretty, setShopPretty] = useState(''); // needed for ReturnQuestions
  const [email, setEmail]: [string | undefined, any] = useState(query_params.email);

  const [order_data, setOrderData]: [LineItemType[] | undefined, any] =
    useState();

  const [errorMessage, setErrorMessage]: [string | undefined, any] =
    useState(undefined);

  // Loading Until NPS and Tree category request is finsihed
  const [loadingNPSandTreeCategory, setLoadingNPSandTreeCategory] =
    useState(false);
  const [NPSandTreeCategory, setNPSandTreeCategory]: [
    { nps: boolean; tree: any } | undefined,
    (x: any) => void,
  ] = useState();

  // Questions Already Answered
  const [alreadyAnswered, setAlreadyAnswered] = useState(false);
  const [answerMessage, setAnswerMessage] = useState('');

  const { user } = useContext(AuthContext);

  /**
   * What to do, after the user gave ratings and text answers for products
   *
   * @param products a list of ratings for products consisting of
   *   rating, positive_freetext, negative_freetext
   */
  const productFeedbackHandler = (products: ProductFeedbackType[]) => {
    setProductFeedback(products);

    // Check if nps is false then send answers and skip nps, else go to nps questions
    if (NPSandTreeCategory?.nps === false) {
      setCurrentStep(STEPS.SENDINGANSWERS);
    } else {
      setCurrentStep(STEPS.NPSQUESTIONS);
    }
  };

  /**
   * What to do after a user went through the NPS
   *
   * @param answers The answers coming from a ReturnQuestions component
   */
  const npsHandler = (answers: AnswerType[]) => {
    setNpsFeedback(answers);
    setCurrentStep(STEPS.SENDINGANSWERS);
  };

  // Try to handle, what happens if users leave the NPS
  const handleNpsBack = () => {
    setCurrentStep(STEPS.PRODUCTQUESTIONS);
  };

  /**
   * Send the questionaire answers all to FireStore
   */
  const handleFinal = async () => {
    let dateX = new Date();

    if (npsFeedback.length !== 0) {
      await db
        .collection('typeform')
        .add({
          shoporders_id: order_id,
          shop: shop,
          name: order_name,
          answers: npsFeedback,
          category: 'nps',
          user_id:
            user !== null && user.uid !== undefined ? user.uid : 'unknown',
          date: dateX.toUTCString(),
        })
        .catch((e) => {
          setErrorMessage(e);
        });
    }

    if (order_data !== undefined) {
      console.log(productFeedback);
      Promise.all(
        order_data.map((value: LineItemType, index: number) => {
          return db
            .collection('typeform')
            .add({
              user_id:
                user !== null && user.uid !== undefined ? user.uid : 'unknown',
              shoporders_id: order_id,
              shop: shop,
              name: order_name,
              is_return: false,
              category: 'product_questions',
              productID: value.id,
              productName:
                value.title +
                (value.variant_title !== undefined && value.variant_title !== ''
                  ? ' (' + value.variant_title + ')'
                  : ''),
              productVariantID:
                value.variant_id !== undefined ? value.variant_id : '',
              date: dateX.toUTCString(),
              answers: productFeedback[index],
            })
            .then(() => {
              return true;
            })
            .catch((e: any) => {
              setErrorMessage(e.message);
            });
        }),
      )
        .then(() => {
          // Set finished to true to show a Thank you page
          setCurrentStep(STEPS.FINISHED);
        })
        .catch((e: any) => {
          console.log('ERROR IN PROMISES');
          console.log(e);
          setErrorMessage(e.message);
        });
    } else {
      setErrorMessage(t('This did not contain any items'));
    }
  };

  // Redirect to OrderFeedback overview if there is no query params
  const checkRedirectToOverview = () => {
    if (
      query_params.id === undefined &&
      query_params.email === undefined &&
      query_params.shop === undefined &&
      query_params.name === undefined
    ) {
      navigate(`/orderfeedbackoverview`);
    }
  };

  useEffect(() => {
    // If there is no query params navigate to Order Feedback Overview
    checkRedirectToOverview();

    // Derive NPS tree after loginvalid
    if (shop !== undefined && loginValid) {

      // Set loading to show loading bar
      setLoadingNPSandTreeCategory(true);
      firestore_get_nps(query_params.shop)
        .then((response) => response.json())
        .then((data) => {
          setNPSandTreeCategory(data);
          setLoadingNPSandTreeCategory(false);
        })
        .catch((error) => console.log(error));
    }

    // set OrderName to a useful value
    if (query_params.name === undefined && order_name === undefined) {
      setOrderName(query_params.id);
    }

    // logged in users will not see the OrderFeedbackCheck screen
    if (
      user !== null &&
      user !== undefined &&
      user.uid !== undefined &&
      !loginValid
    ) {
      setLoginValid(true);
      setIsUser(true);
    }

    // after login or validation, get the line_items
    // If the shop, email and order_id are set
    if (
      loginValid &&
      shop !== undefined &&
      email !== undefined &&
      order_id !== undefined &&
      order_data === undefined
    ) {
      get_line_items(shop, email, order_id.toString())
        .then((returns: { res: any; err: any } | undefined) => {
          if (
            returns !== undefined &&
            returns.res !== undefined &&
            returns.res.test !== undefined
          ) {
            // Check if status not undefined then already answered
            if (
              returns.res.status !== undefined &&
              returns.res.message !== undefined
            ) {
              setAlreadyAnswered(true);
              setAnswerMessage(returns.res.message);
            }
            if (
              returns.res.test.length > 0 &&
              typeof returns.res.test !== 'string'
            ) {
              // do not include vouchers in rating
              setOrderData(
                returns.res.test.filter(
                  (x: { price: string | number | undefined }) => {
                    if (x.price === undefined) {
                      return true;
                    } else {
                      if (typeof x.price === 'number') {
                        return x.price >= 0;
                      } else {
                        return parseFloat(x.price) >= 0;
                      }
                    }
                  },
                ),
              );
            } else {
              console.log('SETTING PERFECT ERROR');
              setErrorMessage(returns.res.test);
            }
          } else {
            setErrorMessage(returns?.err);
          }
        })
        .catch((e) => {
          console.log(e);
          setErrorMessage(e);
        });
    }

    // after nps and products are done, finish
    if (currentStep === STEPS.SENDINGANSWERS) {
      handleFinal();
    }

    // get a pretty shopname if not yet set
    if (shop_pretty === '' && shop !== undefined && loginValid) {
      get_pretty_shopName(shop).then(
        (pretty_shopname: { res: string | undefined; err: string }) => {
          if (pretty_shopname.res !== undefined) {
            setShopPretty(pretty_shopname.res);
          }
          setErrorMessage(pretty_shopname.err);
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, location.search, loginValid, currentStep, shop]);

  return (
    <div style={{ marginBottom: '64px', marginTop: '30px' }}>
      {/* Show any Error messages that happens */}
      {errorMessage === undefined || errorMessage === '' ? null : (
        <Alert mb={4} mt={4} severity='error'>
          {errorMessage}
        </Alert>
      )}
      {/* MAIN CONTAINER */}

      {loadingNPSandTreeCategory ? (
        <div style={{ margin: '20vh auto', textAlign: 'center' }}>
          <CircularProgress size={150} thickness={1} />
        </div>
      ) : (
        <Container maxWidth='sm'>
          {/* NOT LOGGED IN USERS */}
          {!loginValid && (
            <>
              <InterText size='xxl' weight={600} align={'center'} mt={1}>
                {t('Questionary')}
              </InterText>

              {/* User Inserts email to check that order is his or not */}
              <OrderFeedbackCheck
                validateHandler={(x: boolean, email: string) => {
                  // update the email with the user entry
                  setEmail(email);
                  setLoginValid(x);
                }}
                shop={shop}
                email={email}
                order_id={order_id?.toString()}
                name={order_name}
              ></OrderFeedbackCheck>
            </>
          )}

          {/* LOGGED IN USERS (either really logged in or inserted correct email in the OrderFeedbackCheck) */}
          {loginValid &&
            (alreadyAnswered ? (
              <>
                <div
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <InterStyled
                    interStyle='H2'
                    style={{ color: Colors.keepoala.main }}
                  >
                    {t(answerMessage) + '!'}
                  </InterStyled>
                  <img
                    src={HomepagePackage}
                    alt='Keepoala'
                    style={{ width: '100 px', height: '200 px' }}
                  />
                </div>
              </>
            ) : (
              <>
                {/* Show logo as long as not finished */}
                {currentStep !== STEPS.FINISHED && (
                  <ShopLogo
                    src={
                      'https://storage.googleapis.com/' + process.env.REACT_APP_PROJECT_ID + '-public-data/shoplogos/' +
                      shop +
                      '.png'
                    }
                  />
                )}

                {/* Show loading until data is fetched from the server */}
                {order_data === undefined && (
                  <div style={{ margin: '50px auto', textAlign: 'center' }}>
                    <CircularProgress size={150} thickness={1} />
                  </div>
                )}

                {/* When data is finsihed loading */}
                {/* The process goes as follows */}
                {/* 1- Show Product feedback */}
                {/* 2- When user finishes product feedback we show NPS feedback(shop feedback) */}
                {/* 3- When user NPS feedback(shop feedback) we show them ThankYou screen until we finish sending the data to the backend */}
                {/* 4- When the data is sent we show another thank you page with a link ThankYouLinkKeeopala */}
                {order_data !== undefined && (
                  <>
                    {/* Show ProductFeedback first */}
                    {currentStep === STEPS.PRODUCTQUESTIONS && (
                      <ProductFeedback
                        validateHandler={productFeedbackHandler}
                        line_items={order_data}
                        shop={shop}
                        defaultValue={
                          productFeedback !== undefined &&
                          productFeedback.length > 0
                            ? productFeedback
                            : undefined
                        }
                      />
                    )}

                    {/* Show NPSFeedback when finished with product feedback */}
                    {currentStep === STEPS.NPSQUESTIONS && (
                      <ReturnQuestions
                        productVariantID=''
                        category='nps'
                        shop={shop_pretty}
                        orderID={order_id?.toString()}
                        orderName={order_name}
                        successHandler={npsHandler}
                        skipStart={true} // Do not show an intro page
                        startBackHandler={handleNpsBack} // what to do if the user goes back on first page
                        skipEnd={true} // Do not show an answer overview
                        productName='' // hides an info about the order
                        userID=''
                      />
                    )}

                    {/* Show ThankYou while sending the data to the backend */}
                    {currentStep === STEPS.SENDINGANSWERS && <ThankYou />}

                    {/* Show ThankYouLinkKeepoala at the end of everything */}
                    {currentStep === STEPS.FINISHED && (
                      <ThankYouLinkKeepoala
                        extraLinkAddress='orderfeedbackoverview'
                        shop={anyString(shop_pretty)}
                        isUser={isUser}
                      />
                    )}
                  </>
                )}
              </>
            ))}
        </Container>
      )}
    </div>
  );
}
