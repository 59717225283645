import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { db } from '../firebase';
import { default as Lato } from '../styles/lato';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import { AuthContext } from '../utils/providers/AuthProvider';

import {
  Accordion as MuiAccordeon,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Container,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Colors from '../utils/helper/Colors';

const Accordion = styled(MuiAccordeon)`
  border-radius: 5px;
  border: 2px solid ${Colors.keepoala.grey};
  margin-top: 1em;
  box-shadow: none;
  &.Mui-expanded:first-child {
    margin-top: 1em;
  }
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  &.Mui-focused {
    background-color: ${Colors.keepoala.bright};
  }
`;
const AccordionDetails = styled(MuiAccordionDetails)`
  flex-direction: column;
`;

const LatoText = styled(Lato)(spacing);

export default function FAQ(props) {
  const { t, i18n } = useTranslation();
  const { user } = useContext(AuthContext);

  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    db.collection('FAQ')
      // without the where clause this will not get any data
      .where('lang', '==', i18n.language)
      .orderBy('order')
      .get()
      .then(
        (querySnapshot) => {
          const orderlist = [];
          // push the data to the dictionary
          querySnapshot.forEach((documentSnapshot) => {
            orderlist.push({
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            });
          });

          // Check if this is mounted before setting anything
          if (mounted) {
            setFaqs(orderlist);
            setLoading(false);
          }
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        },
      );
  }, [i18n.language]);

  const faq_id = user !== undefined && user != null ? user.uid : 'any';

  if (loading) {
    return null;
  }

  const today = new Date();
  return (
    <Container
      style={{
        padding: !props.dense ? '0px 24px 50px' : '0px',
      }}
    >
      {!props.dense && (
        <LatoText mt={5} fontSize='xl'>
          FAQ
        </LatoText>
      )}

      {faqs.map((faq,index) => {
        return (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <LatoText fontSize='l'>{faq.question}</LatoText>
            </AccordionSummary>
            <AccordionDetails>
              {faq.answer.split('\\n').map((part_answer, number) => {
                return <LatoText key={number} mt={0.5}>{part_answer}</LatoText>;
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
      <LatoText mb={5} fontSize='xl'>
        &nbsp;
      </LatoText>
      <LatoText mb={5} fontSize='xl'>
        {t('Your Question was not answered')}?
      </LatoText>
      <a
        rel='noreferrer'
        target='_blank'
        href={
          'mailto:info@keepoala.com?subject=Problem%20with%20Keepoala%20App&body=Hallo%20Keepoala%20Team,%0D...%0D%0D---%0D%0DMy%20infos%0D%0Duser_id:%20' +
          faq_id +
          '%0Ddate: ' +
          today.toDateString()
        }
      >
        <LatoText mb={3} fontSize='l' color={'main'}>
          {t('Send an email')}!
        </LatoText>
      </a>
      <LatoText mb={5} fontSize='xl'>
        &nbsp;
      </LatoText>
      <LatoText mb={5} fontSize='xl'>
        {t('Any trouble')}?
      </LatoText>
      <a rel='noreferrer' target='_blank' href={'https://forum.keepoala.com'}>
        <LatoText mb={3} fontSize='l' color={'main'}>
          {t('Write in the forum')}!
        </LatoText>
      </a>
    </Container>
  );
}
