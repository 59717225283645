export default function split_query(x) {
    const query_params = {};
    const query_part = x.split("?")
    if (query_part.length > 1) {
        const query_parms_array =query_part[1].split("&").map((item) => item.split("="));
        for (let i = 0; i < query_parms_array.length; i++) {
            query_params[query_parms_array[i][0]] = query_parms_array[i][1].replace(/%20/g, " ");
            if (query_params[query_parms_array[i][0]] === "undefined") {
                query_params[query_parms_array[i][0]] = "";
            }
        }
    }
    return query_params;
}