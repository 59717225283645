import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import firebase, { analytics } from "../firebase";
import { Container as MuiContainer } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Coloring, LineItemType } from "../types";
import styled from "styled-components";
import split_query from "../utils/helper/split_query";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../utils/providers/AuthProvider";
import ReturnFeedbackCheck from "./ReturnFeedbackCheck";
import ReturnFeedbackItemCheck from "./ReturnFeedbackItemCheck";
import InterText from "../styles/Inter";
import { Alert as MuiAlert } from "@material-ui/lab";
import ReturnQuestions, { AnswerType } from "./ReturnQuestions";
import ThankYou from "./ThankYou";
import ThankYouLinkKeepoala from "./ThankYouLinkKeepoala";
import anyString from "../utils/helper/anyString";
import { ShopLogo as DefaultShopLogo } from "./OrderFeedbackCheck";
import ReturnProductFeedback from "./ReturnProductFeedback";
import { get_line_items } from "../utils/helper/get_line_item";
import { firestore_get_nps } from "../utils/helper/firestore_get_nps";
import {
  dhl_status_type,
  get_pretty_shopName,
  get_shop_dhl_status,
  get_label_price,
} from "../utils/helper/get_pretty_shopName";
import ReturnLabel from "./ReturnLabel";
import LoadingCircular from "./LoadingCircular";
// eslint-disable-next-line import/no-webpack-loader-syntax
import ProgressStepper from "./ProgressStepper";
import { getThemeByShop } from "../utils/helper/Themes";
import { ShopifyLineItemForPortal, ReturnLabelType, ShopifyProduct } from "../utils/helper/LineItemTypes";
import NoLabel from "./NoLabel";
import { PaymentDecision } from "./Payment/PaymentDecision";
import KeepoalaThemeProvider from "../styles/KeepoalaThemeProvider";

export const ShopLogo = styled(DefaultShopLogo)`
  max-height: 40px;
  margin-bottom: 16px;
`;

export const Container = styled(MuiContainer)`
  @media only screen and (max-width: 600px) {
    min-height: calc(100vh - 160px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (min-width: 600px) {
    margin-top: 50px;
  }
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
  padding: ${(props) => {
    return props.theme.cardBox?.padding ?? "0";
  }};
  background-color: ${(props) => {
    return props.theme.cardBox?.backgroundColor ?? "white";
  }};
  border-radius: ${(props) => {
    return props.theme.cardBox?.borderRadius ?? "";
  }};
  padding-bottom: ${(props) => {
    return props.theme.cardBox?.paddingBottom ?? "0";
  }};
  border: ${(props) => {
    return props.theme.cardBox?.border ?? "";
  }};
`;
const Alert = styled(MuiAlert)(spacing);

const LineHorizontalPre = styled.hr`
  border: 1px solid #eaeaf0;
`;
export const LineHorizontal = styled(LineHorizontalPre)(spacing);

export type ReturnProductFeedbackType = {
  item: ShopifyLineItemForPortal;
  quantity: number;
  answers: AnswerType[] | undefined;
  exchange: ShopifyProduct[] | undefined;
};

// Component Steps in Order
const enum STEPS {
  PRODUCTQUESTIONS = "Product Questions",
  NPSQUESTIONS = "NPS Questions",
  SENDINGANSWERS = "Sending Answers",
  LABELDECISION = "Checking for label",
  FINISHED = "Finished",
}

/**
 * ReturnFeedbackProcess
 *
 * A three step process
 *   1. validate that the user is allowed to access the order
 *   2. get feedback of all items of the purchase
 *   3. get feedback about the shop itself (NPS  = net promoter score)
 *   4. Show a thank you page
 *
 * @link ProductFeedBack
 * @link ReturnQuestions
 */
export default function ReturnFeedback() {
  const { search } = useLocation();
  const query_params: any = split_query(search);
  const db = firebase.firestore();
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [default_lang_set, setDefaultLangSet] = useState(false);
  const [loginValid, setLoginValid] = useState(false);
  const [isUser, setIsUser] = useState(false);

  const [currentStep, setCurrentStep]: [string, any] = useState(STEPS.PRODUCTQUESTIONS);

  const [productFeedback, setProductFeedback]: [ReturnProductFeedbackType[], any] = useState([]);
  const [npsFeedback, setNpsFeedback]: [any, any] = useState([]);
  const [has_exchanges, setHasExchanges]: [boolean, any] = useState(false);

  const [order_id, setOrderId]: [string | number | undefined, any] = useState(query_params.id);
  const [order_id_doc, setOrderIdDoc]: [string | number | undefined, any] = useState();
  const [order_name, setOrderName]: [string | undefined, any] = useState(query_params.name?.replace(/%23/g, "#"));
  const [shop, setShop]: [string | undefined, any] = useState(query_params.shop);
  const [shop_pretty, setShopPretty] = useState(""); // needed for ReturnQuestions
  const [email, setEmail]: [string | undefined, any] = useState(query_params.email);
  const [platform, setPlatform]: [string | undefined, any] = useState(undefined);
  const [show_label, setShowLabel]: [boolean | undefined, any] = useState(undefined);

  const [is_dhl, setIsDHL]: [boolean | undefined, any] = useState();
  const [dhl_price, setDHLPrice] = useState(0);
  const default_dhl_price_info = {
    en: "The price of the label will be reducted from the amount of money you will receive after a successful return. The current price is {price} {currency}.",
    de: "Du zahlst das Retourenlabel selbst. Es wird mit deiner Rückzahlung verrechnet. Das Label kostet {price} {currency}.",
  };
  const [dhl_price_info, setDHLPriceInfo]: [
    {
      [key: string]: string;
    } | null,
    any
  ] = useState(null);
  const [dhl_label_price_text, setDHLLabelPriceText]: [string, (string) => void] = useState("");
  const [dhl_carrier, setDHLCarrier] = useState("dhl");
  const [keep_days, setKeepDays] = useState(14);
  const [label_payment_needed, setLabelPaymentNeeded]: [boolean, any] = useState(false);
  const [carrier_id, setCarrierId]: [string | null, any] = useState(null);

  const [support, setSupport]: [{ email: string | undefined; link: string | undefined } | undefined, any] =
    useState(undefined);
  const [show_prices, setShowPrices]: [boolean, any] = useState(true);

  const [coloring, setColoring]: [Coloring | undefined, any] = useState(undefined);

  const [is_shopify, setIsShopify]: [boolean | undefined, any] = useState(
    query_params.is_shopify === undefined ? true : query_params.is_shopify === "true"
  );
  // Listener only for handle Order Select
  const [single_order, setIsSingleOrder] = useState(false);

  const [loadbar_message, setLoadBarMessage] = useState(null);

  const [orderDataLoading, setorderDataLoading] = useState(true);
  const [order_data, setOrderData]: [ShopifyLineItemForPortal[] | undefined, any] = useState();

  const [errorMessage, setErrorMessage]: [string | undefined, any] = useState(undefined);

  const [label_data, setLabeldata]: [
    {
      datenum: number;
      pdf: string;
      pdfLink: string;
      qrCode: string;
      qrCodeLink: string;
      tracking_numbers: string[];
      tracking_number: string;
      carrier: string | undefined;
    } | null,
    (any) => void
  ] = useState(null);

  // no label functionality
  const [no_label, setNoLabel]: [boolean, any] = useState(false);
  const [no_label_address, setNoLabelAddress]: [string, any] = useState("");

  // order hint for easier finding
  const [order_hint, setOrderHint]: [string, any] = useState("");

  const [final_hint, setFinalHint]: [{ [key: string]: string } | null, any] = useState(null);
  // An info message shown to the user after a returnlabel is received
  const [final_string, setFinalString]: [string, any] = useState("");
  // Shops can specify how many working days they need to handle a return
  const [final_working_days, setFinalWorkingDays]: [number[], any] = useState([5, 10]);

  const { user } = useContext(AuthContext);

  // To use the first name instead of the email in ReturnFeedbackCheck or not
  const [useFirstName, setUseFirstName] = useState(query_params.use_firstname === "true");

  // Loading Until NPS and Tree category request is finsihed
  const [loadingNPSandTreeCategory, setLoadingNPSandTreeCategory] = useState(false);
  const [NPSandTreeCategory, setNPSandTreeCategory]: [
    { nps: boolean; tree: any; nps_tree: any } | undefined,
    (x: any) => void
  ] = useState();
  const [withNPS, setWithNPS]: [boolean | undefined, (x: any) => void] = useState();

  /**
   * What to do, after the user gave ratings and text answers for products
   *
   * @param products a list of ratings for products consisting of
   *   rating, positive_freetext, negative_freetext
   */
  const productFeedbackHandler = (
    products: {
      item: ShopifyLineItemForPortal;
      quantity: number;
      answers: AnswerType[] | undefined;
      exchange: (ShopifyProduct | undefined)[];
    }[]
  ) => {
    analytics.logEvent("productfeedback_given", {
      feature: "returnfeedback",
      event: "productfeedback_given",
    });
    setProductFeedback(products);

    // Check if withNPS is not undefined
    if (withNPS !== undefined) {
      if (withNPS === true) {
        setCurrentStep(STEPS.NPSQUESTIONS);
      } else {
        setCurrentStep(STEPS.SENDINGANSWERS);
      }
    }
    // Else check if nps from the shop is false then skip
    else if (NPSandTreeCategory?.nps === false) {
      setCurrentStep(STEPS.SENDINGANSWERS);
    }
    // Else go to nps
    else {
      setCurrentStep(STEPS.NPSQUESTIONS);
    }
  };

  const productFeedbackHandlerNoShopify = (answers: AnswerType[]) => {
    analytics.logEvent("productfeedback_given", {
      feature: "returnfeedback",
      event: "productfeedback_given",
    });
    setProductFeedback(answers);

    // Check if withNPS is not undefined
    if (withNPS !== undefined) {
      if (withNPS === true) {
        setCurrentStep(STEPS.NPSQUESTIONS);
      } else {
        setCurrentStep(STEPS.SENDINGANSWERS);
      }
    }
    // Else check if nps from the shop is false then skip
    else if (NPSandTreeCategory?.nps === false) {
      setCurrentStep(STEPS.SENDINGANSWERS);
    }
    // Else go to nps
    else {
      setCurrentStep(STEPS.NPSQUESTIONS);
    }
  };

  /**
   * What to do after a user went through the NPS
   *
   * @param answers The answers coming from a ReturnQuestions component
   */
  const npsHandler = (answers: AnswerType[]) => {
    setNpsFeedback(answers);
    setCurrentStep(STEPS.SENDINGANSWERS);
  };

  // Try to handle, what happens if users leave the NPS
  const handleNpsBack = () => {
    setCurrentStep(STEPS.PRODUCTQUESTIONS);
  };

  const successHandler = () => {
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  };

  const handleFinalProduct = () => {
    let dateX = new Date();

    analytics.logEvent("productfeedback_sending_questions", {
      feature: "returnfeedback",
      event: "productfeedback_sending_questions",
    });
    if (order_data !== undefined) {
      Promise.all(
        order_data.map((value: ShopifyLineItemForPortal, index: number) => {
          if (productFeedback[index].answers !== undefined) {
            // Check to look for exchanges
            if (
              productFeedback[index].exchange !== undefined &&
              productFeedback[index].exchange?.some((x) => x !== null)
            ) {
              setHasExchanges(true);
            }

            return db
              .collection("typeform")
              .add({
                user_id: isUser ? user.uid : "unknown",
                email: email,
                shoporders_id: order_id,
                shop: shop,
                name: order_name,
                category: "product_questions",
                is_return: true,
                productID: value.id,
                productName: value.title + (value.variant_title !== "" ? " (" + value.variant_title + ")" : ""),
                productVariantID: value.variant_id ?? null,
                productIndex: index,
                quantity: productFeedback[index].quantity,
                date: dateX.toUTCString(),
                answers: productFeedback[index].answers,
                exchange_wishes: productFeedback[index].exchange?.map((x) => x ?? null) ?? null,
              })
              .then(() => {
                return true;
              })
              .catch((e: any) => {
                setErrorMessage(e);
                console.log(e);
              });
          } else {
            return true;
          }
        })
      )
        .then(() => {
          // for logged in users we can use the successHandler
          // Else we simply show a Thank you page

          setCurrentStep(STEPS.LABELDECISION);

          if (isUser && !is_dhl) {
            successHandler();
          }
        })
        .catch((e: any) => {
          console.log("ERROR IN PROMISES");
          console.log(e);
          setErrorMessage(e);
        });
    } else {
      return db
        .collection("typeform")
        .add({
          user_id: isUser ? user.uid : "unknown",
          orderID: order_id,
          orderIDDoc: order_id_doc,
          shop: shop,
          name: order_name,
          category: "return_questions",
          date: dateX.toUTCString(),
          answers: productFeedback,
          email: email,
          is_return: true,
        })
        .then(() => {
          setCurrentStep(STEPS.FINISHED);
          if (isUser && !is_dhl) {
            successHandler();
          }
        })
        .catch((e: any) => {
          setErrorMessage(e);
          console.log(e);
        });
    }
  };

  /**
   * Send the questionaire answers all to FireStore
   */
  const handleFinal = () => {
    let dateX = new Date();

    if (npsFeedback.length > 0) {
      analytics.logEvent("nps_feedback_given", {
        feature: "returnfeedback",
        event: "nps_feedback_given",
      });
      return db
        .collection("typeform")
        .add({
          shoporders_id: is_shopify ? order_id : "",
          orderID: is_shopify ? "" : order_id,
          shop: shop,
          name: order_name,
          answers: npsFeedback,
          category: "nps",
          user_id: isUser ? user.uid : "unknown",
          date: dateX.toUTCString(),
        })
        .then(() => {
          handleFinalProduct();
        });
    } else {
      return handleFinalProduct();
    }
  };

  // Handler in case sb opens the return portal and is logged in at Keepoala
  const handleOrderSelect = (order_id: string, shop: string, name: string, email: string) => {
    setOrderId(order_id);
    setShop(shop);
    setOrderName(name);
    setEmail(email);
    setIsSingleOrder(true);
  };

  const handleDecisionMoveOn = (yes: boolean) => {
    if (yes) {
      setCurrentStep(STEPS.FINISHED);
    }
  };

  const checkRedirectToOverview = () => {
    if (
      query_params.id === undefined &&
      query_params.email === undefined &&
      query_params.shop === undefined &&
      query_params.name === undefined &&
      query_params.doc_id === undefined &&
      order_id === undefined &&
      order_id_doc === undefined &&
      shop === undefined &&
      email === undefined
    ) {
      navigate(`/returnmanager`);
    }
  };

  useEffect(() => {
    if (dhl_price > 0) {
      const used_info_map = dhl_price_info ?? default_dhl_price_info;
      const used_info_string: string = used_info_map[i18n.language] ?? default_dhl_price_info.en;
      setDHLLabelPriceText(used_info_string.replace("{price}", dhl_price.toFixed(2)).replace("{currency}", "EUR"));
    }
  }, [default_dhl_price_info, dhl_price, dhl_price_info, i18n]);

  useEffect(() => {
    if (final_hint !== null) {
      const hint_string: string = final_hint[i18n.language] ?? "";
      setFinalString(hint_string.replace("{price}", dhl_price.toFixed(2)).replace("{currency}", "EUR"));
      localStorage.setItem(
        "special_info",
        hint_string.replace("{price}", dhl_price.toFixed(2)).replace("{currency}", "EUR")
      );
    }
  }, [i18n, dhl_price, final_hint]);

  useEffect(() => {
    checkRedirectToOverview();

    if (i18n.language !== query_params.lang && default_lang_set === false && query_params.lang !== undefined) {
      setDefaultLangSet(true);
      i18n.changeLanguage(query_params.lang ?? "de");
    }

    const user_check = user !== null && user !== undefined && user.uid !== undefined && !loginValid;

    // Onload - derive all infos about the shop
    if (is_dhl === undefined) {
      get_shop_dhl_status(query_params.shop).then(
        (dhl_status: { res: dhl_status_type | undefined; err: string } | undefined) => {
          if (dhl_status !== undefined) {
            if (dhl_status.res !== undefined) {
              setIsDHL(dhl_status.res.test);
              setSupport(dhl_status.res.support ?? undefined);
              console.log("SETTING Coloring");
              setColoring(dhl_status.res.coloring ?? undefined);
              // setNoLabel(true);
              setNoLabel(dhl_status.res.no_label ?? false);
              setNoLabelAddress(dhl_status.res.address ?? "");
              // setNoLabelAddress('Vresh GmbH\n Peter-Behrens-Platz 6/4\n 4020 Linz\n AUSTRIA\n')
              setOrderHint(dhl_status.res.order_hint ?? "");
            }
            if (dhl_status.err === "") {
              setErrorMessage(undefined);
            } else {
              setErrorMessage(t(dhl_status.err));
              console.log(dhl_status.err);
            }
          }
        }
      );
    }

    // Get the nps and tree categories for the shop if the shop is undefined
    if (NPSandTreeCategory === undefined && loginValid) {
      // Set loading to show loading bar
      setLoadingNPSandTreeCategory(true);
      firestore_get_nps(query_params.shop)
        .then((response) => response.json())
        .then((data) => {
          setNPSandTreeCategory(data);
          setLoadingNPSandTreeCategory(false);
          setWithNPS(data.nps);
        })
        .catch((error) => console.log(error));
    }
    // Set the state from the query_params only once at the start
    // Two ways to get the page running
    // 1- Shop is set in query_params and the email and others can be in query_params or user asked to add them
    // 2- Document ID and is_shopify has to be false
    if (
      (query_params.shop !== undefined && shop === undefined) ||
      (query_params.doc_id !== undefined && order_id_doc === undefined)
    ) {
      // if logged in get the email from the backend
      if (user !== null && user !== undefined && user.uid !== undefined) {
        db.collection("Orders")
          .where("shop", "==", query_params.shop)
          .where("user_id", "==", user.uid)
          .get()
          .then((all_orders) => {
            if (all_orders.size >= 1) {
              const order_data_query = all_orders.docs
                .map((order_doc_query) => order_doc_query.data())
                .filter(
                  (order_doc_data) =>
                    order_doc_data.shopify.id === query_params.id ||
                    order_doc_data.shopify.id === parseFloat(query_params.id)
                );
              if (order_data_query.length > 0) {
                setEmail(order_data_query[0].shopify.email);
              } else {
                setErrorMessage(t("You do not own this order"));
                setEmail(user.email);
              }
            } else {
              setErrorMessage(t("You do not own this order"));
              setEmail(user.email);
            }
          })
          .catch((e) => {
            setErrorMessage(t("You do not own this order"));
            setEmail(user.email);
          });
        // db.collection("shoporders")
        //   .where("shop", "==", query_params.shop)
        //   .where("user_id", "==", user.uid)
        //   .where("id", "==", query_params.id)
        //   .get()
        //   .then((all_orders) => {
        //     if (all_orders.size >= 1) {
        //       const order = all_orders.docs[0].data();
        //       setPlatform(order.Seller?.Platform ?? "Shopify");
        //       if (order.Seller?.Platform === "AvocadoStore") {
        //         setShowLabel(false);
        //       }
        //     }
        //   })
        //   .catch((e) => {
        //     console.error(e);
        //   });
      }
      // Else set Email if in query_params
      else if (query_params.email !== undefined) {
        setEmail(query_params.email);
      }

      setOrderId(query_params.id);
      setOrderIdDoc(query_params.doc_id);
      setShop(query_params.shop);
      if (query_params.is_dhl !== undefined) {
        setIsDHL(query_params.is_dhl === "true");
      }
      if (query_params.is_shopify !== undefined) {
        setIsShopify(query_params.is_shopify === "true");
      } else {
        setIsShopify(true);
      }
      if (query_params.with_nps !== undefined) {
        setWithNPS(query_params.with_nps === "true");
      }
      if (query_params.name !== undefined) {
        setOrderName(query_params.name.replace(/%23/g, "#"));
      } else {
        setOrderName(query_params.id);
      }
      if (query_params.use_firstname !== undefined) {
        setUseFirstName(query_params.use_firstname === "true");
      }

      // retrieve whether the shop offers DHL return labels
    }

    // logged in users will not see the Return FeedbackCheck screen
    if (user_check) {
      setLoginValid(true);
      setLoadBarMessage(null);
      setIsUser(true);
    }

    // Return label for scans for internal users
    if (
      loginValid &&
      !is_shopify &&
      (query_params.doc_id !== undefined || order_id_doc !== undefined) &&
      currentStep === STEPS.PRODUCTQUESTIONS
    ) {
      console.log("Return Label for scan");
      const doc_id = query_params.doc_id !== undefined ? query_params.doc_id : order_id_doc;

      // Get the order with the document id
      db.collection("Orders")
        .doc(doc_id)
        .get()
        .then((order) => {
          const orderDocumentData = order.data();

          if (orderDocumentData !== undefined) {
            // Set the variables from the document
            setOrderId(orderDocumentData?.id);
            setOrderIdDoc(doc_id);
            setEmail(user.email);
            setShop(orderDocumentData?.shop);
            setOrderName(orderDocumentData?.name);
            setIsDHL(false);
            setOrderData(undefined);
            setShopPretty(orderDocumentData?.shop);

            // Get the nps and tree categories for the shop if the shop is undefined
            if (orderDocumentData.shop !== undefined) {
              // Set loading to show loading bar
              setLoadingNPSandTreeCategory(true);
              firestore_get_nps(query_params.shop)
                .then((response) => response.json())
                .then((data) => {
                  setNPSandTreeCategory(data);
                  setLoadingNPSandTreeCategory(false);
                })
                .catch((error) => console.log(error));
            }

            setorderDataLoading(false);
          } else {
            console.log("Log out");
            setOrderIdDoc(undefined);
            setLoadBarMessage(t("Order not found"));
            setTimeout(() => {
              navigate("/login", {
                state: {
                  doc_id: doc_id,
                  is_shopify: query_params.is_shopify,
                },
              });
            }, 2000);
          }
        })
        .catch((err) => {
          console.log(`Encountered error: ${err}`);
          setLoadBarMessage(t("Order not found"));
          setTimeout(() => {
            navigate("/", {
              state: {
                doc_id: query_params.doc_id,
              },
            });
          }, 2000);
        });
    }

    // after login or validation, get the line_items
    if (loginValid) {
      if (
        shop !== undefined &&
        email !== undefined &&
        order_id !== undefined &&
        is_shopify !== undefined &&
        is_shopify === true &&
        order_data === undefined
      ) {
        analytics.logEvent("returnfeedback_started", {
          feature: "returnfeedback",
          event: "returnfeedback_started",
        });
        get_label_price(shop, order_id.toString()).then(
          (value: {
            res:
              | {
                  price: number;
                  textual_info: {
                    [key: string]: string;
                  };
                }
              | undefined;
            err?: string;
          }) => {
            if (value.err === undefined || value.err === "") {
              setDHLPrice(parseFloat((value.res?.price ?? 0).toString()));
              setDHLPriceInfo(value.res?.textual_info ?? null);
            }
          }
        );
        get_line_items(shop, email, order_id.toString()).then(
          (
            line_items_err:
              | {
                  res:
                    | {
                        return_info: ReturnLabelType[] | [undefined];
                        line_items: ShopifyLineItemForPortal[];
                        show_prices: boolean;
                        carrier: string;
                        keep_days: number;
                        end_screen_info?: {
                          working_days: number[];
                          text: {
                            en: string;
                            de: string;
                          };
                        } | null;
                        label_payment_needed: boolean;
                        carrier_id: string | null;
                      }
                    | undefined;
                  err: string;
                }
              | undefined
          ) => {
            if (line_items_err !== undefined) {
              if (line_items_err.res !== undefined) {
                if (line_items_err.res.return_info !== undefined && line_items_err.res.return_info[0] !== null) {
                  setLabeldata(line_items_err.res.return_info);
                }

                if (line_items_err.res.carrier !== "no_label") {
                  setNoLabel(false);
                } else if (line_items_err.res.carrier === "no_label") {
                  setNoLabel(true);
                }
                if (line_items_err.res.carrier === "postat") {
                  setDHLCarrier("Post");
                }

                if (line_items_err.res.keep_days !== undefined) {
                  setKeepDays(line_items_err.res.keep_days);
                }

                if (line_items_err.res.line_items !== undefined) {
                  setOrderData(line_items_err.res.line_items);
                }
                if (line_items_err.err === "") {
                  setErrorMessage(undefined);
                } else {
                  setErrorMessage(t(line_items_err.err));
                }

                if (line_items_err.res.end_screen_info !== undefined && line_items_err.res.end_screen_info !== null) {
                  setFinalHint(line_items_err.res.end_screen_info.text ?? null);
                  if (line_items_err.res.end_screen_info?.working_days.length === 2) {
                    setFinalWorkingDays(line_items_err.res.end_screen_info?.working_days);
                    localStorage.setItem(
                      "special_working_days_0",
                      line_items_err.res.end_screen_info?.working_days[0].toString()
                    );
                    localStorage.setItem(
                      "special_working_days_1",
                      line_items_err.res.end_screen_info?.working_days[1].toString()
                    );
                  }
                }

                if (line_items_err.res.label_payment_needed !== undefined) {
                  setLabelPaymentNeeded(line_items_err.res.label_payment_needed);
                }

                if (line_items_err.res.carrier_id !== null && line_items_err.res.carrier_id !== undefined) {
                  setCarrierId(line_items_err.res.carrier_id);
                }

                setShowPrices(line_items_err.res.show_prices ?? true);
              } else {
                setErrorMessage(t(line_items_err.err));
              }
            }

            setorderDataLoading(false);
          }
        );
      }
    } else {
      // Return labels for scans for a non logged in user
      // Navigate them to login and then they will be redirected back
      if (!user_check && !is_shopify) {
        if (
          query_params.is_shopify !== undefined &&
          query_params.is_shopify === "false" &&
          (query_params.doc_id !== undefined || order_id_doc !== undefined)
        ) {
          setLoadBarMessage(t("You will be redirected to login"));
          // Redirect user to login after 3 seconds
          setTimeout(() => {
            const doc_id = query_params.doc_id !== undefined ? query_params.doc_id : order_id_doc;
            navigate("/login", {
              state: {
                doc_id: doc_id,
                is_shopify: query_params.is_shopify,
              },
            });
          }, 1000);
        }
      }
    }

    // after nps and products are done, finish
    if (currentStep === STEPS.SENDINGANSWERS) {
      handleFinal();
    }

    // get a pretty shopname if not yet set
    if (shop_pretty === "" && shop !== undefined && is_shopify && loginValid) {
      console.log("Rceiving pretty shopname", shop_pretty, shop);
      get_pretty_shopName(shop).then((pretty_shopname_err: { res: string | undefined; err: string } | undefined) => {
        if (pretty_shopname_err !== undefined) {
          if (pretty_shopname_err.res !== undefined) {
            setShopPretty(pretty_shopname_err.res);
          }
          if (pretty_shopname_err.err === "") {
            setErrorMessage(undefined);
          } else {
            console.log("PRETTYERR");
            setErrorMessage(t(pretty_shopname_err.err));
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, search, loginValid, currentStep, shop, single_order, is_shopify, email, default_lang_set, coloring]);

  return shop === undefined ? (
    <LoadingCircular />
  ) : (
    <div>
      {/* TWO WAYS TO SHOW LOADING BAR */}
      {/* FIRST */}
      {/* Loading Until the document request for the order finishes */}
      {/* The query param doc_id means that this is a scan and we should send a request to fetch the document */}
      {/* the order_id_doc when set to anything other than undefined means the request is finished */}
      {/* SECOND */}
      {/* Waiting for the request to get the nps and tree category to be finsihed  */}
      {((split_query(search) as any)?.doc_id && order_id_doc === undefined) || loadingNPSandTreeCategory ? (
        <KeepoalaThemeProvider coloring={coloring} shop={shop}>
          <LoadingCircular
            // If the user is not logged in show them that they will be redirected
            textElement={
              <InterText size="xxl" weight={300} align={"center"} mb={3}>
                {loadbar_message}
              </InterText>
            }
          />
        </KeepoalaThemeProvider>
      ) : (
        <KeepoalaThemeProvider coloring={coloring} shop={shop}>
          <div style={{ marginBottom: "64px", marginTop: "30px" }}>
            {/* //Error Message Window */}
            {errorMessage === undefined || errorMessage === "" ? null : (
              <Alert mb={4} mt={4} severity="error">
                {errorMessage}
              </Alert>
            )}

            {/* MAIN CONTAINER */}
            <Container maxWidth="sm" className="cardbox">
              {/* NOT LOGGED IN USERS */}
              {!loginValid && (
                <>
                  {/* // Return Portal Textfield on top of start page */}
                  <InterText size="xxl" weight={600} align={"center"} mt={3} mb={3}>
                    {t("Return Portal")}
                  </InterText>

                  {/* User Inserts email or first name to check that order is his or not */}
                  <ReturnFeedbackCheck
                    validateHandler={(x: boolean, email: string, id?: string, name?: string) => {
                      // update the email with the user entry
                      setEmail(email);
                      if (name !== undefined) {
                        setOrderName(name);
                      }
                      if (id !== undefined) {
                        setOrderId(id);
                      }
                      setLoginValid(x);
                    }}
                    shop={shop}
                    email={email}
                    order_id={order_id?.toString()}
                    name={order_name}
                    useFirstName={useFirstName}
                    support={support}
                    order_hint={order_hint}></ReturnFeedbackCheck>
                </>
              )}

              {/* LOGGED IN USERS (either really logged in or inserted correct email in the ReturnFeedbackCheck) */}
              {loginValid && (
                <>
                  {/* Show loading until data is fetched from the server */}
                  {/* Add order_id because if it does not exist we are not fetching anything from server */}
                  {order_id && orderDataLoading && <LoadingCircular />}

                  {/* Finished loading show Questions process */}
                  {/* Add order_id because if it does not exist we are not fetching anything from server */}
                  {(order_id === undefined || !orderDataLoading) && (
                    <>
                      {/* Shop logo and progress bar */}
                      {currentStep !== STEPS.FINISHED && !orderDataLoading && (
                        <>
                          {/* Step Progress Bar   */}
                          <ProgressStepper
                            steps={(withNPS === true || (withNPS === undefined && NPSandTreeCategory?.nps === true)
                              ? ["Product Questions", "Shop Feedback", "Submitting your answers"]
                              : ["Product Questions", "Submitting your answers"]
                            ).concat(label_payment_needed ? ["Parcel labeling"] : [])}
                            activeStep={
                              currentStep === STEPS.NPSQUESTIONS && withNPS
                                ? 1
                                : currentStep === STEPS.PRODUCTQUESTIONS
                                ? 0
                                : currentStep === STEPS.LABELDECISION && withNPS
                                ? 3
                                : 2
                            }
                          />

                          {/* Shop logo in case this is not finished */}
                          {is_shopify && (
                            <ShopLogo
                              src={
                                "https://storage.googleapis.com/" +
                                process.env.REACT_APP_PROJECT_ID +
                                "-public-data/shoplogos/" +
                                shop +
                                ".png"
                              }
                            />
                          )}
                        </>
                      )}

                      {/* Main Questions Logic if either */}
                      {/* 1- the line_items are loaded which make order_data not undefined */}
                      {/* 2- is_shopify is false because it is a scan */}
                      {order_data !== undefined || !is_shopify ? (
                        <>
                          {/* Main Steps Components */}
                          {currentStep === STEPS.PRODUCTQUESTIONS ? (
                            is_shopify && order_data !== undefined ? (
                              <ReturnProductFeedback
                                validateHandler={productFeedbackHandler}
                                line_items={order_data}
                                shop={shop}
                                defaultValue={
                                  productFeedback !== undefined && productFeedback.length > 0
                                    ? productFeedback
                                    : undefined
                                }
                                order_id={order_id}
                                shop_pretty={shop_pretty}
                                order_name={order_name}
                                withLabel={is_dhl && !no_label}
                                specialCarrierInfo={
                                  no_label
                                    ? t("You need to pay the label yourself and ship it to") + "\\n" + no_label_address
                                    : ""
                                }
                                labelPrice={dhl_price}
                                labelPriceText={dhl_label_price_text}
                                labelCarrier={dhl_carrier}
                                NPSandTreeCategory={NPSandTreeCategory}
                                support={support}
                                show_prices={show_prices}
                                label_data={label_data}
                              />
                            ) : (
                              <ReturnQuestions
                                userID=""
                                shop={shop}
                                orderName={order_name}
                                orderID={order_id}
                                productVariantID={""}
                                skipStart={false}
                                skipEnd={true}
                                successHandler={productFeedbackHandlerNoShopify}
                                productName=""
                                // TODO: Will be changed later to add different categories for products
                                category={NPSandTreeCategory?.tree?.default}
                              />
                            )
                          ) : currentStep === STEPS.NPSQUESTIONS ? (
                            <>
                              {/* // Short info in case NPS is shonw */}
                              <InterText size="l" align="center" mb={3}>
                                {t("Just 3 short questions to improve")} {shop_pretty}
                              </InterText>

                              <ReturnQuestions
                                productVariantID=""
                                category={NPSandTreeCategory?.nps_tree.default ?? "nps"}
                                shop={shop_pretty}
                                orderID={order_id?.toString()}
                                orderName={order_name}
                                successHandler={npsHandler}
                                skipStart={true} // Do not show an intro page
                                startBackHandler={handleNpsBack} // what to do if the user goes back on first page
                                skipEnd={true} // Do not show an answer overview
                                productName="" // hides an info about the order
                                userID=""
                              />
                            </>
                          ) : currentStep === STEPS.SENDINGANSWERS ? (
                            <ThankYou />
                          ) : !is_dhl ? (
                            isUser ? (
                              <ThankYou />
                            ) : (
                              <ThankYouLinkKeepoala
                                extraLinkAddress="returnmanager"
                                shop={anyString(shop_pretty)}
                                noarrow={false}
                              />
                            )
                          ) : currentStep === STEPS.LABELDECISION ? (
                            <PaymentDecision
                              moveon={handleDecisionMoveOn}
                              needs_payment={label_payment_needed}
                              shop={shop}
                              line_items={productFeedback}
                              carrier_id={carrier_id}
                              shoporders_id={order_id?.toString() ?? "undefinedSTRING"}
                              email={email}
                            />
                          ) : !no_label ? (
                            <ReturnLabel
                              shop={shop}
                              id={order_id?.toString()}
                              email={email}
                              isUser={isUser}
                              special_info={final_string}
                              special_working_days={final_working_days}
                              has_exchanges={has_exchanges}
                            />
                          ) : (
                            <NoLabel
                              shop={shop}
                              name={order_name?.toString()}
                              isUser={isUser}
                              address={no_label_address}
                              email={email}
                              order_id={order_id?.toString()}
                              productFeedback={productFeedback}
                            />
                          )}
                        </>
                      ) : // If we do not go to the questions we show the user his orders from this shop
                      shop !== undefined ? (
                        // In case a user is logged in and goes to the return portal, we only show them
                        // their own orders
                        <ReturnFeedbackItemCheck shop={shop} callback={handleOrderSelect} />
                      ) : (
                        // Else we show an error if there is no shop specified
                        <Alert mb={4} mt={4} severity="error">
                          {t("You do not have any orders from ") + shop}
                        </Alert>
                      )}
                    </>
                  )}
                </>
              )}
            </Container>
          </div>
        </KeepoalaThemeProvider>
      )}
    </div>
  );
}
