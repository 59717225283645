/* eslint import/no-webpack-loader-syntax: off */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import Button from '../styles/Button';
import InterStyled from '../styles/InterStyled';
import styled from 'styled-components';
import iPhone_DE from '../assets/img/iPhone14_DE.png';
import iPhone_EN from '../assets/img/iPhone14_EN.png';
import DownloadOnAppstore from '!file-loader!../assets/img/download_apple/de.svg';
import DownloadOnAppstoreEN from '!file-loader!../assets/img/download_apple/en.svg';
import DownloadOnGPlay from '!file-loader!../assets/img/google-play-badge.svg';
import DownloadOnGPlayEN from '!file-loader!../assets/img/google-play-badge-en.svg';
import QRCodeIMG from '../assets/img/qr-code_ohneutm2.png';
import QRCodeLogin from './QRCodeLogin';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';

const FluidContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin: 40px 0px;
  padding: 20px 0px;
  background: linear-gradient(
      205.01deg,
      #2f8481 -36.66%,
      rgba(47, 132, 129, 0) 53.32%
    ),
    #204648;
  @media only screen and (max-width: 800px) {
    margin-top: 100px;
    margin-bottom: 80px;
  }
`;

const BottomGreyColor = styled.div`
  position: absolute;
  background-color: #f2f2f2;
  bottom: -5px;
  height: 145px;
  width: 100%;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

const BannerContainer = styled.div`
  max-width: 960px;
  z-index: 10;
`;

const AppStore = styled.img`
  width: 150px;
  height: 40px;
`;
const PlayStore = styled.img`
  width: 150px;
  height: 40px;
`;

const FixedWidthButton = styled(Button)`
  width: 320px;
`;

const IPhone = styled.img`
  width: 200px;
  height: 405px;
  @media only screen and (max-width: 800px) {
    position: absolute;
    top: -90px;
    right: 0;
    width: 200px;
    height: 250px;
  }
  @media only screen and (max-width: 600px) {
    position: absolute;
    top: -90px;
    right: 0;
    width: 110px;
    height: 184px;
  }
`;

const LeftSideGrid = styled(Grid)`
  padding-right: 150px;
  @media only screen and (max-width: 800px) {
    padding-right: 0px;
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background: none;
  font-size: 12px;
  @media only screen and (max-width: 800px) {
    bottom: -46px;
    top: inherit;
    left: 50%;
    transform: translate(-50%, 0);
    color: #515959;
  }
`;

const QRCodeSectionsContainer = styled.div`
  margin-bottom: 38px;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

const QRCodeLoginContainer = styled.div`
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

const StoresSectionContainer = styled.div`
  margin-bottom: 38px;
  display: flex;
  justify-content: space-between;
  margin-right: -5px;
  @media only screen and (max-width: 800px) {
    margin-bottom: 0px;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 38px;
  @media only screen and (max-width: 500px) {
    width: 200px;
    margin-bottom: 20px;
  }
`;

const QRCode = styled.img`
  width: 200px;
  height: 200px;
  margin: 20px;
`;

export default function BannerApp() {
  const { t, i18n } = useTranslation();

  const [bannerVisible, setBannerVisible] = useState(true);
  const [qrDialogOpen, setQrDialogOpen] = useState(false);
  const [loginQRDialogOpen, setLoginQRDialogOpen] = useState(false);

  // Check if banner not closed return banner
  // Else return null
  if (bannerVisible)
    return (
      <FluidContainer>
        {/* Desktop view */}
        <BannerContainer>
          <Grid container>
            {/* Left section of the banner */}
            <LeftSideGrid item xs='auto'>
              {/* Upper section of the banner */}
              <TitleContainer>
                <InterStyled
                  interStyle='H2'
                  color='white'
                  transform='uppercase'
                  style={{ letterSpacing: '1px' }}
                >
                  {t('The Keepoala App')}
                </InterStyled>
              </TitleContainer>

              <div style={{ marginBottom: '38px' }}>
                <div>
                  <InterStyled interStyle='H3' color='white'>
                    {t('is now available for iOS and Android')}.
                  </InterStyled>
                </div>

                <div>
                  <InterStyled interStyle='H3' color='white'>
                    {t('Get it now!')}
                  </InterStyled>
                </div>
              </div>

              {/* Playstore and Appstore section */}
              <StoresSectionContainer>
                <a
                  href='https://apps.apple.com/de/app/keepoala-f%C3%BCrs-klima-shoppen/id1539283171?itsct=apps_box_badge&amp;itscg=30200'
                  target='_new'
                  style={{ marginRigth: '20px' }}
                >
                  <AppStore
                    src={
                      i18n.language === 'de'
                        ? DownloadOnAppstore
                        : DownloadOnAppstoreEN
                    }
                  />
                </a>
                <a
                  target='_new'
                  href='https://play.google.com/store/apps/details?id=com.keepoala.keepoala&utm_source=WebAppBanner&utm_campaign=German&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                >
                  <PlayStore
                    src={
                      i18n.language === 'de'
                        ? DownloadOnGPlay
                        : DownloadOnGPlayEN
                    }
                  />
                </a>
              </StoresSectionContainer>

              {/* Download QR code */}
              <QRCodeSectionsContainer>
                <FixedWidthButton
                  color='secondary'
                  onClick={() => setQrDialogOpen(true)}
                >
                  {t('SHOW DOWNLOAD QR CODE')}
                </FixedWidthButton>
              </QRCodeSectionsContainer>

              {/* Login using QR Code section */}
              <QRCodeLoginContainer>
                <div style={{ marginBottom: '15px' }}>
                  <InterStyled interStyle='H3'>
                    {t('Downloaded the app? Then')} ...
                  </InterStyled>
                </div>
                <div>
                  <FixedWidthButton
                    color='primary'
                    onClick={() => {
                      setLoginQRDialogOpen(true);
                    }}
                  >
                    {t('USE THE LOGIN WITH QR CODE')}
                  </FixedWidthButton>
                </div>
              </QRCodeLoginContainer>
            </LeftSideGrid>

            {/* Phone App image */}
            <Grid item xs='auto'>
              <IPhone src={i18n.language === 'de' ? iPhone_DE : iPhone_EN} />
            </Grid>
          </Grid>
        </BannerContainer>

        {/* Bottom Grey Color for Desktop */}
        <BottomGreyColor />

        {/* Close Button */}
        <CloseButton
          onClick={() => {
            setBannerVisible(false);
          }}
        >
          {t('Close')} <CloseIcon style={{ marginLeft: '3px' }} />
        </CloseButton>

        {/* Download App QR code dialog*/}
        <Dialog
          onClose={() => {
            setQrDialogOpen(false);
          }}
          open={qrDialogOpen}
        >
          <QRCode src={QRCodeIMG} />
        </Dialog>

        {/* Login using QR Code Dialog */}
        <QRCodeLogin
          dialog_only={true}
          setOpen={setLoginQRDialogOpen}
          open={loginQRDialogOpen}
        />
      </FluidContainer>
    );
  else return null;
}
