import styled from "styled-components";
import Colors from '../utils/helper/Colors';
import { spacing } from "@material-ui/system";

export const ItemSpaced = styled.div`
padding:0.5rem 0.5rem;
margin:0.5rem 1rem;
`;

const Box = styled(ItemSpaced)`
border-radius:10px;
background:#ffffff;
border: 2px solid ${Colors.keepoala.grey};`
export default Box

export const BoxSpaced = styled(Box)(spacing);