import React, {useState} from 'react';
import styled from 'styled-components';
import Colors from '../utils/helper/Colors';

const RatingContainer = styled.div`
    display: grid;
    grid-template-areas:
        "a b c d e f ." 
        ". g h i j k l";
    margin: auto
    
`
const StyledNumberWrapper = styled.span`
    font-size: 16px;
    &.active {
        font-size:20px;
        margin: 2px;
        padding: 2px;
        font-weight: 700;
        color: ${(props) => {return props.theme.main ?? Colors.keepoala.keepoalaGreen}};
    }
    width: calc(100vw / 16);
    height: calc(100vw / 16);
    @media only screen and (max-width: 400px) {
        width: calc(100vw / 10);
        height: calc(100vw / 10);
    }
    max-width: 50px;
    max-height: 50px;
    border-radius: 5px;
    border: 1px solid ${Colors.keepoala.lightgrey};
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    margin: 4px;
    &.grid0 {
        grid-area: a
    }
    &.grid1 {
        grid-area: b
    }
    &.grid2 {
        grid-area: c
    }
    &.grid3 {
        grid-area: d
    }
    &.grid4 {
        grid-area: e
    }
    &.grid5 {
        grid-area: f
    }
    &.grid6 {
        grid-area: g;
    }
    &.grid7 {
        grid-area: h;
    }
    &.grid8 {
        grid-area: i;
    }
    &.grid9 {
        grid-area: j;
    }
    &.grid10 {
        grid-area: k;
    }
    &.griddown {
        margin-left: -25px;
        margin-top: 10px;
    }
`

const StyledNumber = styled.div`
`


export function IconContainer(props:{value:number}) {
    const { value, ...other }:{value:number, [x:string]:any} = props;
    const customIcons:{[index:number]:{icon:any, label:string, value?:number}} = {
        1: {
            icon: <StyledNumber >0</StyledNumber>,
            label: '0',
            value: 0,
        },
        2: {
            icon: <StyledNumber >1</StyledNumber>,
            label: '1',
        },
        3: {
            icon: <StyledNumber >2</StyledNumber>,
            label: '2',
        },
        4: {
            icon: <StyledNumber >3</StyledNumber>,
            label: '3',
        },
        5: {
            icon: <StyledNumber>4</StyledNumber>,
            label: '4',
        },
        6: {
            icon: <StyledNumber >5</StyledNumber>,
            label: '5',
        },
        7: {
            icon: <StyledNumber >6</StyledNumber>,
            label: '6',
        },
        8: {
            icon: <StyledNumber >7</StyledNumber>,
            label: '7',
        },
        9: {
            icon: <StyledNumber >8</StyledNumber>,
            label: '8',
        },
        10: {
            icon: <StyledNumber >9</StyledNumber>,
            label: '9',
        },
        11: {
            icon: <StyledNumber >10</StyledNumber>,
            label: '10',
        },
    };
    return <span key={'icon' + value}  {...other}>{customIcons[value].icon}</span>;
}

export default function StyledRating(props:{
    name: string,
    max: number,
    onChange: (value: number) => void,
    defaultValue?: number | undefined,
}){

    const [innerValue, setInnerValue]:[number|undefined, any] = useState(props.defaultValue)

    const handleMouseHover = (e, index) => {
        if (e.type === 'mouseenter') {
            setInnerValue(index)
            return
        }
    }

    return <RatingContainer>
    {
        Array.from({ length: props.max }).map((elem, index) => {

                return (
                    <StyledNumberWrapper
                        key={props.name + index}
                        onClick={() => props.onChange(index + 1)}
                        onMouseEnter={(e) => handleMouseHover(e, index)}
                        onMouseLeave={(e) => handleMouseHover(e, index)}
                        className={(innerValue !== undefined && index <= (innerValue ?? 0) ? 'active' : '') + (' grid' + index.toString()) + (index >=6 ? ' griddown' : '')}
                    >
                        <IconContainer value={index + 1} />
                    </StyledNumberWrapper>
            )
        })
    }
    </RatingContainer>;
};