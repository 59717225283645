import React, { useState } from 'react';
import styled from 'styled-components';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { TextField } from '@mui/material';

export default function PasswordField(props) {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (e) => {
    props.setPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    if (props.password !== 'xxxxxxxx') {
      setShowPassword(!showPassword);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const Input = styled(TextField)`
    & input {
      padding-top: 10px;
      padding-left: 10px;
    }
    & label.Mui-focused {
      color: #1e5957;
    }
    & label {
      color: #1e5957;
      padding-left: 10px;
    }
    & label.MuiInputLabel-shrink {
      color: #1e5957;
      padding-left: 10px;
      padding-top: 10px;
    }
    & .MuiInputBase-colorPrimary::after {
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    }
    & {
      background-color: ${props.greenInput ? '#deffe5' : 'initial'};
    }
  `;

  return (
    <Input
      type={showPassword ? 'text' : 'password'}
      value={props.password}
      variant='standard'
      fullWidth
      size='small'
      onFocus={() => {
        if (props.password === 'xxxxxxxx') {
          props.setPassword('');
        }
      }}
      label={props.label}
      autoComplete='current-password'
      onChange={handlePasswordChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
