import { spacing } from "@material-ui/system";
import styled from "styled-components";

const PermanentMarker = styled.div`
font-family: 'Permanent Marker';
color: ${props => {
    return props.color
}};
font-size: ${
    props => (props.size)
};
`

const PermanentMarkerText = styled(PermanentMarker)(spacing);
export default PermanentMarkerText;