import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import InterStyled from "../../styles/InterStyled";
import StepperButtons from "./StepperButtons";
import AddressForm, { AddressFormData } from "./AddressForm";

export default function AddressDetails(props: {
  address: AddressFormData;
  setAddress: Dispatch<SetStateAction<AddressFormData>>;
  onContinuePressed: () => void;
  onBackPressed: () => void;
}) {
  const { t } = useTranslation();

  const [is_valid, setIsValid] = useState<boolean>(false);

  return (
    <div>
      <InterStyled interStyle="Label 1">{t("Address details")}</InterStyled>
      <div style={{ height: 10 }} />
      <InterStyled interStyle="P1">{t("Please give us the address we can send exchanges to") + "."}</InterStyled>

      <div style={{ height: 30 }} />
      <AddressForm
        value={props.address}
        onSubmit={(data) => {
          props.setAddress(data);
        }}
        setIsValid={setIsValid}
      />
      <div style={{ height: 20 }} />
      <StepperButtons
        onContinuePressed={is_valid ? props.onContinuePressed : undefined}
        onBackPressed={props.onBackPressed}
      />
    </div>
  );
}
