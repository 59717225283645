import firebase from '../../firebase';

export default function xml (lang) {
    const db = firebase.firestore();


    var XMLParser = require('react-xml-parser');
    return db.collection("typeformQuestions")
        .limit(873)
        .get()
        .then((firestoreDocuments) => {
            const real_doc = firestoreDocuments.docs.filter((firestoreDocument) => {
                console.log(firestoreDocument.id)
                return firestoreDocument.id === ('Tree-' + lang.toUpperCase())
            });
            const document_data = real_doc[0].data();
            return new XMLParser().parseFromString(document_data.xml);
    })
}