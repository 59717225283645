import React, {useState, useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Chart,
  ArgumentAxis,
  BarSeries,
  Title,
  ValueAxis,
  Legend,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { withStyles } from '@material-ui/core/styles';
import { Stack, Animation, EventTracker } from '@devexpress/dx-react-chart';
import Colors from '../utils/helper/Colors';
import { useTranslation, withTranslation } from 'react-i18next';

const legendStyles = () => ({
    root: {
      display: 'flex',
      margin: 'auto',
      flexDirection: 'row',
    },
  });
  const legendRootBase = ({ classes, ...restProps }) => (
    <Legend.Root {...restProps} className={classes.root} />
  );
  const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
  const legendLabelStyles = () => ({
    label: {
      whiteSpace: 'nowrap',
    },
  });
  const legendLabelBase = ({ classes, text, t, ...restProps }) => {
    return <Legend.Label className={classes.label} text={t(text)} {...restProps} />
  };
  const Label = withTranslation()(withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase));
  

export default function CarbonSavings(props) {

    const [carbonData, setCarbonData] = useState([])
    const {t} = useTranslation();

    function convertDataforChart(data){
        let carbonHistory = [];
        data.map((e) => {
            carbonHistory.push({
                month: e.month,
                saved: e.value,
                possible: e.possible_value
            })
            return null
        })
        //console.log('history' + data[3].value)
        return carbonHistory;
    }

    useEffect(() => {
      let mounted = true;
      if (mounted) {
        setCarbonData(convertDataforChart(props.data))
      }
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])
    
    
    return (
        <Paper>
        <Chart
          data={carbonData}
        >
          <ArgumentAxis />
          {<ValueAxis
            max={0}
            showGrid={false}
            showLabels={false}

          /> }

          <BarSeries
            name='Saved'
            valueField="saved"
            argumentField="month"
            color={Colors.keepoala.main}
          />
          <BarSeries
            name="Possible"
            valueField="possible"
            argumentField="month"
            color={Colors.keepoala.grey}
          />
          <Animation />
          <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
          <Title text={t("Your Carbon Savings - in g CO2")} />
          <Stack
            stacks={[
              { series: ['Saved', 'Possible'] },
            ]}
          />
          <EventTracker />
          <Tooltip />
        </Chart>
      </Paper>
    );
}