import React, { useEffect, useState } from 'react';
import StarIcon from '@material-ui/icons/Star';
import Rating from '@material-ui/lab/Rating';

/**
 * component to render 5 stars that rate 0 - 10
 * 
 * @param props 
 * * defaultState - starting value
 * * wasTouched - whether this was touched before rendering
 * * validateHandler - what to do upon finishing, meaning the user clicks a value
 * * index - index to get returned with the value for better handling in ProductFeedback1Rate
 * @returns 
 */
export default function StarRating(props:{
    defaultState:number,
    wasTouched:boolean,
    validateHandler:(x:{name:number, rating:number, changed:boolean}) => void,
    index: number
}) {
    const [value, setValue]:[number, any] = useState(props.defaultState);
    const [touched, setTouched]:[boolean, any] = useState(props.wasTouched);

   useEffect(() => {
       if (touched) {
           props.validateHandler({
               name: props.index,
               rating: value,
               changed: true,
           })
       }
   }, [value, touched])

  return ( <Rating
        name={"hover-feedback" + props.index}
        value={value}
        precision={0.5}
        onChange={(event, newValue:number|null) => {
            setTouched(true);
            setValue(newValue)
        }}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />
  );
}