export default function customerEmailCheck(email) {
  const internal_email = email ?? "test0@customers.test.com";
  if (internal_email.includes("@customers.")) {
    return true;
  }
  if (internal_email.includes("avocadostore@hello-pine.com")) {
    return true;
  }
  return false;
}
