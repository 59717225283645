import hmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";
import getEndpointUrl from "../getEndpointUrl";

export async function create_payment(body) {
  const req_body = JSON.stringify(body);

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-requested-with": "application/json",
      "Keepoala-Cors-Header": "enabled",
      Signature: "sha256=" + Base64.stringify(hmacSHA256(req_body, process.env.REACT_APP_G_CLOUD_KEY)),
    },
    body: req_body,
  };
  return await fetch(getEndpointUrl() + "/stripe_integration", requestOptions);
}
