import React, { Dispatch, SetStateAction, useState } from "react";
import InterStyled from "../../styles/InterStyled";
import { useTranslation } from "react-i18next";
import StepperButtons from "./StepperButtons";
import { TextField as MuiInput } from "@material-ui/core";
import Colors from "../../utils/helper/Colors";
import { withStyles } from "@material-ui/core/styles";
const Input = withStyles({
  root: {
    "& label.Mui-focused": {
      color: Colors.keepoala.dark,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: Colors.keepoala.dark,
    },
  },
})(MuiInput);

export default function PurchaseEmail(props: {
  email: string | undefined;
  setEmail: Dispatch<SetStateAction<string | undefined>>;
  onContinuePressed: () => void;
}) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  return (
    <div>
      <InterStyled interStyle="Label 1">{`${t("What is your email address")}?`}</InterStyled>
      <div style={{ height: 10 }} />
      <Input
        label="E-Mail"
        fullWidth
        required
        size="small"
        value={email}
        onChange={(event) => {
          const email_intern = event.target.value;
          if (validateEmail(email_intern)) {
            setError("");
            props.setEmail(email_intern);
            // Handle the email submission, e.g., send it to a server
          } else {
            setError(t("Please enter a valid email address"));
          }
          setEmail(email_intern);
        }}
        error={Boolean(error)}
        helperText={error}
      />
      <div style={{ height: 20 }} />
      <StepperButtons
        onBackPressed={undefined}
        onContinuePressed={
          error === "" && props.email !== undefined
            ? props.onContinuePressed
            : undefined
        }
      />
    </div>
  );
}
