import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { ReturnLabelType } from "../utils/helper/LineItemTypes";
import Button from "../styles/Button";
import InterText from '../styles/InterText';
import { useTranslation } from 'react-i18next';

const ReturnLabelContainer = styled.div`
border: 1px solid #eaeaf0;
  border-radius: 5px;
  padding: 8px;
  box-shadow: 1px 1px 0px 0px rgb(199,199,199);
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top:12px;
`



export default function ReturnLabelTiny(props:{returnLabel: ReturnLabelType}) {
  const {returnLabel} = props;
  const {t} = useTranslation();
  const pretty_date:string = ((new Date(returnLabel.datenum * 1000)).toLocaleString('de-DE').split(',')[0]);

  const [dialogOpen, setDialogOpen] = useState(false);

  return <ReturnLabelContainer key={returnLabel.tracking_numbers[0]}>
     <Dialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>QR Code</DialogTitle>
              <DialogContent>
                <img src={returnLabel?.qrCodeLink} alt='Return QR code' />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialogOpen(false)}>
                  {t('Close')}
                </Button>
              </DialogActions>
            </Dialog>
    <InterText style={{width:'100%'}} align='center'>{pretty_date}</InterText>
    {
                  returnLabel?.carrier === undefined || returnLabel?.carrier === 'dhl' ? 
                  <>
                    <Button
                      mb={1}
                      color='secondary'
                      onClick={() => setDialogOpen(true)}
                      style={{ minWidth: '250px' }}
                    >
                      {t('Paperless QR Code')}
                    </Button>
                  </> : null
                }
  {
    returnLabel?.pdfLink !== undefined ?
    <div>
      <a href={ returnLabel?.pdfLink } target = "_blank" rel="noreferrer">
        <Button
        mb={1}
        color='secondary'
        style={{ minWidth: '250px' }}
        >
        {t('PDF for printing')}
        </Button>
      </a>
    </div> : null
  }
  </ReturnLabelContainer>
  }