import React, { useState, useEffect } from 'react';
import firebase, { analytics } from '../../firebase';
import { shopify_move_orders } from '../helper/shopify_move_orders';
import { useTranslation } from 'react-i18next';
import ourusers from '../helper/ourusers';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [newUser, setNewUser] = useState(false);
  const [closedEmailSettings, setClosedEmailSettings] = useState(false);
  const [userDetail, setUserDetail] = useState();
  const [config, setConfig] = useState();
  const [emailverify, setEmailVerify] = useState(false);
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  const [authenticationError, setAuthenticationError] = useState('empty');
  const db = firebase.firestore();
  const { t, i18n } = useTranslation();

  // Check if running in iframe or not
  function inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(async () => {
    function afterLogin(user) {
      if (!ourusers.includes(user.uid) && !inIframe()) {
        console.log('Send GA event');
        analytics.logEvent('SignIn', {
          event: 'SignInOut',
        });
      }
      console.log('Auth changed', user.uid);
      setEmailVerify(user.emailVerified);
      db.collection('User')
        .doc(user.uid)
        .get()
        .then((documentSnapshot) => {
          setUserDetail(documentSnapshot.data());
        });

      db.collection('KeepoalaConfig')
        .doc('config')
        .get()
        .then((documentSnapshot) => {
          documentSnapshot.exists
            ? setConfig(documentSnapshot.data())
            : setConfig({ keep: 0 });
        });
      // move all recent orders
      if (
        localStorage.getItem('shopify_shop') === null ||
        localStorage.getItem('shopify_shop') === ''
      ) {
        db.collection('Orders')
          .where('user_id', '==', user.uid)
          .get()
          .then((queryall_orders) => {
            if (queryall_orders.size > 0) {
              const doc_data = queryall_orders.docs[0].data();
              shopify_move_orders(
                doc_data.shopify.shop,
                doc_data.shopify.id,
                user.uid,
              ).then(() => {
                console.log('ORDER MOVE API CALLED BY AUTH');
                try {
                  localStorage.removeItem('shopify_shop');
                  localStorage.removeItem('shopify_order_id');
                } catch (e) {
                  console.log(e);
                }
              });
            }
          });
      }
    }

    let mounted = true;

    firebase.auth().onAuthStateChanged(async (user) => {
      var user_intermediate = user;
      if (user_intermediate != null) {
        console.log('EMAIL VER', user.emailVerified);
        // if (!user.emailVerified) {
        //     // user_intermediate = null;
        //     setAuthenticationError(t('Your Email is not verified, yet'))
        //     user.sendEmailVerification();
        //     user_intermediate = null;
        //     if (window.location.pathname !== "/verifyemail") {
        //         window.location = window.location.origin + "/verifyemail";
        //     }
        // } else {
        setEmailVerify(user.emailVerified);
        afterLogin(user);
        // }

        //Checking if the user is a keepoalaUser to log
        if (user.displayName === 'KeepoalaUser' && !inIframe()) {
          analytics.logEvent('keepoalaUser_name', {
            feature: 'default name',
            event: 'User set to default',
          });
        }
      }
      setUser(user_intermediate);

      if (mounted) {
        setLoadingAuthState(true);
        if (user !== null && userDetail !== undefined) {
          setLoadingAuthState(false);
        } else {
          setLoadingAuthState(false);
        }
      }
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [db]);

  // Helper Function
  const sendPlatformAndLanguageUserChange = () => {
    // Send platform and language using userchange event after 1 second
    setTimeout(() => {
      // Send platform web
      db.collection('UserChange')
        .doc(firebase.auth().currentUser?.uid + 'plat')
        .set({
          uid: firebase.auth().currentUser?.uid,
          first_platform: 'web',
        })
        .then(() => console.log('Sent Platform Change'))
        .catch(() => {
          console.log('Failed to Send Platfrom Change');
        });

      // Send Language
      const lang = i18n.language;
      db.collection('UserChange')
        .doc(firebase.auth().currentUser?.uid)
        .set({
          lang: lang,
          uid: firebase.auth().currentUser?.uid,
          message:
            t('We set your language to') +
            ': ' +
            (lang.toLowerCase() === 'de' ? 'Deutsch' : 'English'),
        })
        .then(() => console.log('Sent Language Change'))
        .catch(() => {
          console.log('Failed to Send Language Change');
        });
    }, 1000);
  };

  const handleSignIn = (username, password) => {
    return (
      firebase
        .auth()
        .signInWithEmailAndPassword(username, password)
        .catch((error) => {
          setAuthenticationError(error.message);
          if (error.message.match('verifi') !== null) {
            firebase.auth().currentUser.sendEmailVerification();
          }
        })
        // Please check: https://firebase.google.com/docs/reference/js/firebase.auth#usercredential
        .then((UserCredential) => {
          if (UserCredential !== undefined) {
            if (UserCredential.additionalUserInfo !== null) {
              if (UserCredential.additionalUserInfo.isNewUser) {
                firebase.auth().currentUser.sendEmailVerification();
                setNewUser(true);
              } else {
                console.log('Simple Login');
              }
              return Promise.resolve('success');
            } else {
              if (!UserCredential.user.emailVerified) {
                firebase.auth().currentUser.sendEmailVerification();
              }
            }
          }
        })
    );
  };

  const handleGoogleSignIn = (lang) => {
    firebase.auth().languageCode = lang;
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithRedirect(provider)
      .then((user) => {
        if (user.additionalUserInfo.isNewUser) {
          analytics.logEvent('Register', {
            event: 'SignInOut',
          });
          sendPlatformAndLanguageUserChange();
          setNewUser(true);
        }
        console.log(user);
      })
      .catch((error) => {
        setAuthenticationError(error.message);
      });
  };

  const handleFacebookSignIn = (lang) => {
    firebase.auth().languageCode = lang;
    const fbProvider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(fbProvider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */

        console.log(result.user);

        if (result.additionalUserInfo.isNewUser) {
          analytics.logEvent('Register', {
            event: 'SignInOut',
          });
          sendPlatformAndLanguageUserChange();
          setNewUser(true);
        }
      })
      .catch((error) => {
        setAuthenticationError(error.message);
      });
  };

  const handleAppleSignIn = (lang) => {
    firebase.auth().languageCode = lang;
    const appleProvider = new firebase.auth.OAuthProvider('apple.com');
    firebase
      .auth()
      .signInWithRedirect(appleProvider)
      .then((user) => {
        console.log('Logged in with Apple', user.accesToken);
        if (user.additionalUserInfo.isNewUser) {
          analytics.logEvent('Register', {
            event: 'SignInOut',
          });
          sendPlatformAndLanguageUserChange();
          setNewUser(true);
        }
      })
      .catch((error) => {
        setAuthenticationError(error.message);
      });
  };

  const handleSignOut = () => {
    setLoadingAuthState(true);
    if (!ourusers.includes(user.uid)) {
      analytics.logEvent('SignOut', {
        event: 'SignInOut',
      });
    }
    localStorage.removeItem('HOMEEXEC');
    localStorage.removeItem('shopify_shop');
    localStorage.removeItem('shopify_order_id');
    firebase
      .auth()
      .signOut()
      .then((res) => {
        console.log('Im signed out,', res);
        localStorage.removeItem('intermediate_pwd');
        setLoadingAuthState(false);
      })
      .catch((err) => {
        console.error(err.message);
        setLoadingAuthState(false);
      });
  };

  const signUpWithEmail = (email, password, username, lang) => {
    if (
      email !== undefined &&
      email.toLowerCase().match('mydealz.ml') !== null
    ) {
      setAuthenticationError('We locked mydealz.ml');
      setLoadingAuthState(false);
    } else {
      firebase.auth().languageCode = lang;
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(() => {
          analytics.logEvent('Register', {
            event: 'SignInOut',
          });
          firebase.auth().currentUser.updateProfile({ displayName: username });
          firebase
            .auth()
            .currentUser.sendEmailVerification()
            .then(function () {
              // Email sent.
              console.log('EMAIL got send');
            })
            .catch(function (error) {
              // An error happened.
              setAuthenticationError(error.message);
            });
          if (username === 'KeepoalaUser') {
            localStorage.setItem('intermediate_pwd', password);
          }
          sendPlatformAndLanguageUserChange();
          setNewUser(true);
        })
        .catch((err) => {
          setAuthenticationError(err.message);
          setLoadingAuthState(false);
        });
    }
  };

  const checkEmail = (email) => {
    return firebase
      .auth()
      .fetchSignInMethodsForEmail(email)
      .then((x) => {
        return x.length;
      });
  };

  function changePassword(password, newPassword) {
    var user = firebase.auth().currentUser;

    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      password,
    );

    return user
      .reauthenticateWithCredential(credential)
      .then(function () {
        return user
          .updatePassword(newPassword)
          .then(function () {
            setAuthenticationError('empty');
            localStorage.removeItem('intermediate_pwd');
            return true;
          })
          .catch(function (error) {
            setAuthenticationError(error.message);
            return false;
          });
      })
      .catch(function (error) {
        setAuthenticationError(error.message);
        return false;
      });
  }

  function changeEmail(email, password) {
    var user = firebase.auth().currentUser;

    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      password,
    );

    return user
      .reauthenticateWithCredential(credential)
      .then(function () {
        return user
          .updateEmail(email)
          .then(() => {
            setAuthenticationError('empty');
            return true;
          })
          .catch((e) => {
            setAuthenticationError(e.message);
            return false;
          });
      })
      .catch((e) => setAuthenticationError(e.message));
  }

  function changeDisplayName(name) {
    var user = firebase.auth().currentUser;

    return user
      .updateProfile({
        displayName: name,
      })
      .then(function () {
        setAuthenticationError('empty');
        return true;
      })
      .catch(function (error) {
        setAuthenticationError(error.message);
        return false;
      });
  }

  function resetPassword(email) {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  function setAuthenticationErrorEmpty() {
    setAuthenticationError('empty');
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        userDetail,
        authenticated: user !== null,
        setUser,
        handleSignIn,
        handleGoogleSignIn,
        handleFacebookSignIn,
        handleAppleSignIn,
        handleSignOut,
        signUpWithEmail,
        loadingAuthState,
        authenticationError,
        setAuthenticationErrorEmpty,
        changePassword,
        changeEmail,
        changeDisplayName,
        config,
        newUser,
        checkEmail,
        emailverify,
        resetPassword,
        setClosedEmailSettings,
        closedEmailSettings,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
