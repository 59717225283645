import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../styles/Button";
import InterText from "../styles/Inter";
import parcelGIF from "../assets/img/parcel.gif";

import styled from "styled-components";
const Content = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  min-height: 200px;
  padding: 0px 0px 60px;
  cursor: pointer;
  align-items: center;
`;
export default function ThankYouContent(props: { id?: string; shop?: string; email?: string }) {
  const { t, i18n } = useTranslation();
  const link =
    "https://app.keepoala.com" +
    (props.id !== undefined && props.shop !== undefined && props.email !== undefined
      ? `?order_id=${props.id}&email=${props.email}&shop=${props.shop}&utm_source=shop=${props.shop} ReturnPortal&utm_medium=ReturnPortal&utm_campaign=pointsandvouchers`
      : "");
  return (
    <Content
      onClick={(e) => {
        e.preventDefault();
        window.location.href = link;
      }}>
      <img src={parcelGIF} alt="parcel gif" width={200} height={140} />
      <InterText align="center" size="l" mb={3}>
        {t("Do you already use Keepoala")}?
      </InterText>
      <InterText align="center" size="l">
        {t("Click here and get")} <b>40</b> {t("Keepoalas")}
        {t(", great benefits and fascinating rewards")}.
      </InterText>
      <Button color="primary" mt={3} mb={2} style={{ width: "250px" }}>
        {t("POINTS & REWARDS")}
      </Button>
      <br />
      <InterText align="center" size="l" mb={2}>
        {t("How was your return experience")}?
      </InterText>
      <iframe
        title="rater"
        src={`https://app.keepoala.com/returnexperiencerater?shop=${props.shop}&lang=${i18n.language}`}
        style={{ width: "100%", height: "180px", border: "medium none", opacity: 1, visibility: "visible" }}></iframe>
    </Content>
  );
}
