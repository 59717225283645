import React from "react";
import { useTranslation } from "react-i18next";
import Colors from "../../utils/helper/Colors";
import Button from "../../styles/Button";

export default function StepperButtons(props: {
  onBackPressed: undefined | (() => void);
  onContinuePressed: undefined | (() => void);
  labels?: string[];
}) {
  const { t } = useTranslation();
  const default_labels = ["Go Back", "Next"];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        flexGrow: "1",
        maxHeight: "100px",
      }}>
      {props.onBackPressed !== undefined ? (
        <Button color="secondary" onClick={props.onBackPressed}>
          {t((props.labels ?? default_labels)[0])}
        </Button>
      ) : null}
      <Button
        color="primary"
        style={{
          backgroundColor: Colors.keepoala.bright,
          alignSelf: "flex-end",
          marginLeft: "auto",
        }}
        disabled={props.onContinuePressed === undefined}
        onClick={props.onContinuePressed}>
        {t((props.labels ?? default_labels)[1])}
      </Button>
    </div>
  );
}
