import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../utils/providers/AuthProvider';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { shopify_move_orders } from '../utils/helper/shopify_move_orders';
import split_query from '../utils/helper/split_query';
import { db } from '../firebase';
import Order from './Order';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import { default as Lato } from '../styles/lato';
import PermanentMarkerText from '../styles/PermanentMarker';
import Loading from './Loading';
import ALink from '../styles/ALink';
import { BoxSpaced } from './Box';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Colors from '../utils/helper/Colors';
import { Banner1Welcome, TopRow } from './Banner1_Welcome';
import BannerApp from './BannerApp';
import { Container } from '@material-ui/core';
import i18n from '../i18n/i18napp';
import InterStyled from '../styles/InterStyled';
import Grid from '@mui/material/Grid';
import ourusers from '../utils/helper/ourusers';
import { analytics } from '../firebase';
import Dialog from '@mui/material/Dialog';
import Close from '@material-ui/icons/Close';
import IconButton from '@mui/material/IconButton';
import splitOrdersByMonth from '../utils/helper/splitOrdersByMonth';
import EmailSettingsFirstLoginModal from './EmailSettingsFirstLoginModal';

const LatoTextSpace = styled(Lato)(spacing);
const LatoText = styled(LatoTextSpace)`
  display: flex;
  align-items: center;
`;
const Styleddiv = styled.div`
  background-color: transparent;
`;
const Divspacing = styled(Styleddiv)(spacing);

function Home() {
  console.log("IS HOME")
  const { t } = useTranslation();

  const { user, newUser, emailverify } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [apicrawled, setAPICrawled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [lastOrder, setLastOrder] = useState(0);
  const [lastOrderPossible, setLastOrderPossible] = useState(0);
  const [ordersFired, setOrdersFired] = useState(false);
  const [movedOrders, setMovedOrders] = useState(false);
  const [anyDraft, setAnyDraft] = useState(true);
  const [openOrderDialog, setOpenOrderDialog] = useState(false);
  const [selectedDialogOrder, setSelectedDialogOrder] = useState();

  console.log(lastOrder); //TODO: Add ShareButton using lastorder
  console.log(lastOrderPossible); //TODO: Add ShareButton using lastorderpossible

  const location = useLocation();
  if (location.search.length > 2) {
    const query_params = split_query(location.search);
    if (query_params.order_id !== undefined) {
      localStorage.setItem('shopify_order_id', query_params.order_id);
      localStorage.setItem('shopify_shop', query_params.shop);
    }
  }

  // Helper function
  // Check if a single order is specified in the query params
  // Open it in a dialog if there exists one
  function openSingleOrderFromURL(orderlist) {
    // If orderlist is empty return
    if (orderlist.length === 0) return;

    const query_params = split_query(location.search);
    if (query_params.id === undefined) return;

    // Loop to find the correct order
    orderlist.forEach((order) => {
      if (order.id === query_params.id) {
        // Set the order and Show the dialog
        setOpenOrderDialog(true);
        setSelectedDialogOrder(order);
        return;
      }
    });
  }

  function setsubscriber(mounted) {
    return db
      .collection('ShopsWithDHL')
      .get()
      .then((shops) => {
        let all_dhl_shops = [];
        shops.forEach((shop) => {
          const shop_data = shop.data();
          console.log(shop_data);
          all_dhl_shops.push(shop_data.name);
        });
        return (
          db
            .collection('Orders')
            .orderBy('datenum', 'desc')
            // without the where clause this will not get any data
            .where('user_id', '==', user.uid)
            .onSnapshot(
              (querySnapshot) => {
                console.log('Getting orders');
                const orderlist = [];
                // push the data to the dictionary
                querySnapshot.forEach((documentSnapshot) => {
                  orderlist.push({
                    ...documentSnapshot.data(),
                    key: documentSnapshot.id,
                  });
                });
                setAPICrawled(true);

                // if there is a draft, do not show manual order add
                if (
                  orderlist.filter(
                    (x) =>
                      x.shopify !== undefined &&
                      x.shopify.id !== undefined &&
                      x.shopify.id === 'unknown',
                  ).length > 0
                ) {
                  setAnyDraft(true);
                } else {
                  setAnyDraft(false);
                }

                orderlist.forEach(function (part, index) {
                  this[index].is_dhl = all_dhl_shops.includes(this[index].shop);
                }, orderlist); // use arr as this

                // Check if this is mounted before setting anything
                if (mounted) {
                  console.log(orderlist.length)
                  // Split the orders according to their date
                  const monthSplitedOrders = splitOrdersByMonth(orderlist);

                  // Check if a single order is specified in the query params
                  // Open it in a dialog if there exists one
                  openSingleOrderFromURL(orderlist);

                  setOrders(monthSplitedOrders);
                  setLoading(false);
                  if (orderlist.length > 0) {
                    // toast.info(t('Orders updated'))
                    setLastOrder(orderlist[0].keepoalas);
                    setLastOrderPossible(orderlist[0].keepoalas_possible);
                    orderlist.splice(0, orderlist.length);
                  }
                }
              },
              (err) => {
                console.log(`Encountered error: ${err}`);
              },
            )
        );
      });
  }

  useEffect(() => {
    var mounted = true;
    // Use a FireStore subscription
    // to derive the data from FireBase/FireStore
    var subscriber = setsubscriber(mounted);

    async function asignOrders() {
      console.log('Moving Shopify Orders');
      setAPICrawled(true);
      const xx = await shopify_move_orders(
        localStorage.getItem('shopify_shop'),
        localStorage.getItem('shopify_order_id'),
        user.uid,
      ).catch((e) => {
        localStorage.removeItem('shopify_shop');
        localStorage.removeItem('shopify_order_id');
        toast.error(
          <div>
            <h2>{t('Something went wrong')}</h2>
            <p>
              {t('We tried to import your shop orders from')}{' '}
              {localStorage.getItem('shopify_shop')}.{' '}
              {t('Please contact us in case your orders do not appear')}.
            </p>
          </div>,
          { autoClose: 10000 },
        );
        // toast.error("test")
        console.log(e);
      });
      if (xx !== undefined) {
        await xx.json().then((res) => {
          localStorage.removeItem('shopify_shop');
          localStorage.removeItem('shopify_order_id');
          window.history.replaceState({}, document.title, '/');
          if (!ordersFired) {
            toast.success('new orders successfully added', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
            });
            setOrdersFired(true);
          }

          setMovedOrders(true);
          // refresh
        });
      }
      console.log('finished move');
      localStorage.removeItem('HOMEEXEC');
    }
    if (
      localStorage.getItem('shopify_shop') !== undefined &&
      localStorage.getItem('shopify_order_id') !== undefined &&
      localStorage.getItem('shopify_order_id') !== null &&
      localStorage.getItem('shopify_shop') !== null &&
      localStorage.getItem('HOMEEXEC') == null
    ) {
      if (mounted) {
        if (!ordersFired && !movedOrders && loading && !apicrawled) {
          localStorage.setItem('HOMEEXEC', 'true'); // PREVENT The component from having the possibility to run twice upon being rendered twice (Firebase Login renders the component twice, god knows why )
          asignOrders();
        }
      }
    }

    if (!loading) {
      // Unsubscribe from events when no longer in use
      // if (!loading) {
      return () => {
        try {
          subscriber();
        } catch (error) {
          console.log(error);
        }
      };
    }

    console.log('MOVEDORDERS:', movedOrders);
    console.log('ordersFired:', ordersFired);
    console.log('loading:', loading);
    console.log('apicrawled:', apicrawled);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [loading, movedOrders, ordersFired, apicrawled, i18n.language, user.uid]);

  useEffect(() => {
    // Send GA pageview
    if (!ourusers.includes(user.uid)) {
      analytics.logEvent('screen_view', {
        firebase_screen: 'Journey',
        firebase_screen_class: 'WebView',
      });
    }
  }, [user.uid]);

  return (
    <div>
      <style>
        {'body { background: #F2F2F2; background-repeat: no-repeat; margin:0}'}
      </style>

      <div>
        <Container size='md'>
          <TopRow>
            <PermanentMarkerText
              ml={2}
              color={Colors.keepoala.forestGreendefault}
              size={'32px'}
            >
              {t('Welcome back')} {user.displayName}
            </PermanentMarkerText>
          </TopRow>
        </Container>
        {<BannerApp />}
        <Container size='md'>
          {newUser ? <Banner1Welcome /> : null}
          {!emailverify ? (
            <BoxSpaced mt={3}>
              <LatoText ml={3} mt={3} mb={3} fontSize='l'>
                <b>{t('Action needed')}</b>
              </LatoText>
              <LatoText
                ml={3}
                mt={3}
                mb={3}
                fontSize='l'
                uppercase={false}
                color='black'
              >
                {t(
                  'Please look into your Email Mailbox to verify your email address',
                )}
                .{' '}
                {t(
                  'You need to verify your email address to get all benefits of Keepoala',
                )}
              </LatoText>
            </BoxSpaced>
          ) : null}
          {localStorage.getItem('intermediate_pwd') !== undefined &&
            localStorage.getItem('intermediate_pwd') !== null ? (
            <BoxSpaced mt={3}>
              <LatoText
                ml={3}
                mt={3}
                mb={3}
                fontSize='l'
                uppercase={false}
                color='black'
              >
                <ALink href='/overview?tab=1'>
                  {t('We created an intermediate Password for you')}.{' '}
                  {t('Please change it as soon as possible')}.{' '}
                  {t('The password is')}:
                </ALink>
                <LatoText
                  ml={1}
                  mt={3}
                  mb={3}
                  fontSize='l'
                  uppercase={false}
                  color='main'
                >
                  {localStorage.getItem('intermediate_pwd')}
                </LatoText>
              </LatoText>
            </BoxSpaced>
          ) : null}
          {!anyDraft ? (
            <BoxSpaced mt={3}>
              <LatoText
                ml={3}
                mt={3}
                mb={3}
                fontSize='xl'
                uppercase={false}
                color='main'
              >
                <ALink href='/manuallyadd'>{t('Manually add order')}</ALink>
                <a href='/manuallyadd'>
                  <KeyboardArrowRightIcon
                    fontSize='large'
                    htmlColor={Colors.keepoala.main}
                    viewBox='0 0 20 20'
                  ></KeyboardArrowRightIcon>
                </a>
              </LatoText>
            </BoxSpaced>
          ) : null}

          {/* Orders is an array of arrays each array has a group of orders grouped by their month */}
          {orders.map((group, outerIndex) => (
            <React.Fragment key={outerIndex}>
              <div style={{ marginLeft: '30px', marginTop: '40px' }}>
                <InterStyled InterStyle='Label 1'>
                  {/* Output date according to currect locale either english or german */}
                  {new Date(group[0].date).toLocaleDateString(
                    i18n.language === 'en' ? 'en-EN' : 'de-DE',
                    {
                      month: 'long',
                      year: 'numeric',
                    },
                  )}
                </InterStyled>
              </div>

              <Grid
                container
                columnSpacing={{ xs: 1, md: 4 }}
                ml={1}
                mt={4}
                mb={8}
                sx={{ width: '100% !important', marginLeft: '0px !important' }}
              >
                {group.map((order, index) => (
                  <Grid item xs={12} md={6} key={index} mb={4}>
                    <Order key={order.id} order_item={order} t={t} />
                  </Grid>
                ))}
              </Grid>
            </React.Fragment>
          ))}

          {orders.length === 0 && apicrawled ? (
            <div>
              <LatoText fontSize='xl' mt={5}>
                {t('You do not have any orders, yet')}
              </LatoText>
              <a rel='noreferrer' href='https://keepoala.com/unsere-partner-keepoala/' target='_blank'>
                <LatoText fontSize='xl' mt={2} color='main'>
                  {t('Shop at PHYNE to register your first order')}
                </LatoText>
              </a>
            </div>
          ) : orders.length === 0 ? (
            <Loading />
          ) : null}
        </Container>
      </div>
      <Divspacing mt={8}></Divspacing>

      {/* Selected Order Dialog*/}
      {selectedDialogOrder && (
        <Dialog
          onClose={() => {
            setOpenOrderDialog(false);
          }}
          open={openOrderDialog}
        >
          <div
            style={{
              backgroundColor: '#F8F8F8',
              textAlign: 'right',
            }}
          >
            <IconButton
              onClick={() => {
                setOpenOrderDialog(false);
              }}
              aria-label='close'
            >
              <Close />
            </IconButton>
          </div>
          <Order order_item={selectedDialogOrder} t={t} />
        </Dialog>
      )}

      {/* Email Settings Dialog*/}
      <EmailSettingsFirstLoginModal />
    </div>
  );
}

export default Home;
