import { spacing } from "@material-ui/system";
import styled from "styled-components";
import Colors from '../utils/helper/Colors'

const LatoTextPre = styled.div`
    font-family: ${props => props.theme.fontFamily ?? 'Inter'};
    font-size: ${props => {switch(props.fontSize){
            case "m":
                return "14px";
            case "l":
                return "16px";
            case "xl":
                return "20px";
            case "xxl":
                return "36px"
            case "xxxl":
                return "48px"
            default:
                return "12px";
        }}
    };
    text-align: ${props => props.align};
    color: ${props => {
        switch (props.color){
            case "main":
                return Colors.keepoala.main;
            case "lightgrey":
                return Colors.keepoala.lightgrey;
            case "dark":
                return Colors.keepoala.dark;
            case "white":
                return "#ffffff";
            case "greengrey":
                return "#8f9b95";
            default:
                return Colors.keepoala.black;
        }
    }};
    text-transform: ${props => props.uppercase ? "uppercase" : "none"};
    &:hover {
        color: ${props => (props.link) ? Colors.keepoala.main : ""};
    }
    &:link {
        color: ${props => {
            switch (props.color){
                case "main":
                    return Colors.keepoala.main;
                case "lightgrey":
                    return Colors.keepoala.lightgrey;
                case "white":
                    return "#ffffff";
                default:
                    return Colors.keepoala.black;
            }
        }};
    }
    font-weight: ${
        props => (props.fontWeight)
    }
    `

export const LatoText = styled(LatoTextPre)(spacing);

export const LatoTextSpaced = LatoText;

export default LatoText

