import React, { useState, useContext } from 'react';
import Rating from '@mui/material/Rating';
import styling from 'styled-components';
import Colors from '../utils/helper/Colors';
import { styled } from '@mui/material/styles';
import Button from '../styles/Button';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import { AuthContext } from '../utils/providers/AuthProvider';
import split_query from '../utils/helper/split_query';
import { shop_return_experience_rater } from '../utils/helper/shop_return_experience_rater';
import { useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: Colors.keepoala.keepoalaGreendefault,
  },
  '& .MuiRating-iconHover': {
    color: Colors.keepoala.keepoalaGreendefault,
  },
});

const Container = styling.div`
    width: 100vw;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const ReturnExperienceRaterChild = () => {
  const location = useLocation();
  const query_params = split_query(location.search);
  const lang = query_params.lang === 'de' ? 'de' : 'en';
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [requestSent, setRequestSent] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({
    message: '',
    status: 'success',
  });
  const { authenticated, user } = useContext(AuthContext);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;

    // Get the data
    const token = await executeRecaptcha('RateShop');
    const rating = form.rating.value * 2;
    const user_id = authenticated ? user.uid : null;
    const shop = query_params.shop;

    const payload = {
      token,
      user_id,
      shop,
      rating,
    };

    // Send the request
    setRequestSent(true);
    setShowAlert(false);

    try {
      const response = await shop_return_experience_rater(payload);

      // const data = await response.json();

      if (response.status === 200) {
        setShowAlert(true);
        setAlert({
          message:
            lang === 'en'
              ? 'Thank you for your feedback!'
              : 'Danke für Ihre Rückmeldung!',
          status: 'success',
        });
      } else {
        setShowAlert(true);
        setAlert({
          message:
            lang === 'en'
              ? 'Failed to get Feedback, Please try Again'
              : 'Rückmeldung fehlgeschlagen, bitte versuchen Sie es erneut',
          status: 'error',
        });
        setRequestSent(false);
      }
    } catch (e) {
      console.error(e);
      setShowAlert(true);
      setAlert({
        message:
          lang === 'en'
            ? 'Failed to get Feedback, Please try Again'
            : 'Rückmeldung fehlgeschlagen, bitte versuchen Sie es erneut',
        status: 'error',
      });
      setRequestSent(false);
    }
  };

  return (
    <Container>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        onSubmit={handleOnSubmit}
      >
        <StyledRating
          precision={0.5}
          name='rating'
          defaultValue={0}
          size='large'
          readOnly={requestSent}
        />
        <Button
          disabled={requestSent}
          color='primary'
          type='submit'
          style={{ marginTop: '10px' }}
        >
          {lang === 'en' ? 'Rate' : 'Bewertung abgeben'}
        </Button>
      </form>
      {showAlert && (
        <Alert severity={alert.status} style={{ marginTop: '20px' }}>
          <AlertTitle>{alert.message}</AlertTitle>
        </Alert>
      )}
    </Container>
  );
};

const ReturnExperienceRater = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <ReturnExperienceRaterChild />
    </GoogleReCaptchaProvider>
  );
};
export default ReturnExperienceRater;
