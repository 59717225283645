import React, { useContext } from "react";
/* eslint import/no-webpack-loader-syntax: off */
import AppleIcon from '@material-ui/icons/Apple';
import FacebookIcon from '@material-ui/icons/Facebook';
import GoogleIcon from '!file-loader!../assets/img/Google.svg'
import Button, {FacebookButton, AppleButton} from '../styles/Button';
import { useTranslation } from "react-i18next";
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import { AuthContext } from "../utils/providers/AuthProvider";
import {default as Lato} from '../styles/lato';


const LatoText = styled(Lato)(spacing);

export default function SocialLogin() {
    const {t, i18n} = useTranslation();
    const { handleGoogleSignIn, handleFacebookSignIn, handleAppleSignIn } = useContext(AuthContext);

    const  handleFacebookSign = () => {
        handleFacebookSignIn(i18n.language);
    }

    const handleGoogleSignhere = () => {
        handleGoogleSignIn(i18n.language);
    }

    const handleAppleSign = () => {
        handleAppleSignIn(i18n.language);
    }

    return(
    <div>
        <LatoText align="center" m={1}>{t('Use social logins')}</LatoText>
        <FacebookButton mt={2} onClick={handleFacebookSign} size="medium" fullWidth={true}>
            <FacebookIcon/>{t('SignIn with Facebook')}
        </FacebookButton><br/><br/>
        <Button mt={2} variant="contained" color="default" onClick={handleGoogleSignhere} size="medium" fullWidth={true}> 
            <img src={GoogleIcon} style={{width:20,marginRight:4}} alt="Google Logo" />
            {t('SignIn with Google')}</Button><br/><br/>
        <AppleButton mt={2}  onClick={handleAppleSign} size="medium" fullWidth={true}><AppleIcon />{t('SignIn with Apple ID')}</AppleButton><br/><br/>
    </div>
    );
}