import React, { useState } from "react";
import StepperButtons from "./StepperButtons";
import { ShopifyLineItemForPortal, ShopifyProduct } from "../../utils/helper/LineItemTypes";
import ReturnExchange from "../ReturnExchange";

export default function ReturnExchangeWrapper(props: {
  onContinuePressed: () => void;
  onBackPressed: () => void;
  exchange_items: ShopifyLineItemForPortal | undefined;
  setValue: (x: (ShopifyProduct | undefined)[]) => void;
  value: (ShopifyProduct | undefined)[] | undefined;
}) {
  const [fin, setFin] = useState<(ShopifyProduct | undefined)[] | undefined>(props.value);

  const handleExChange = (x: (ShopifyProduct | undefined)[]) => {
    setFin(x);
    console.log("SUCCESS");
    props.setValue(x);
    props.onContinuePressed();
  };

  return (
    <div>
      <div style={{ height: 20 }} />
      <ReturnExchange quantity={1} line_item={props.exchange_items} successHandlerEx={handleExChange} />
      <StepperButtons
        onContinuePressed={fin !== undefined ? props.onContinuePressed : undefined}
        onBackPressed={props.onBackPressed}
      />
    </div>
  );
}
