import hmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";

export async function search_shopproducts(shop, search_query) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-requested-with": "application/json",
      "Keepoala-Cors-Header": "enabled",
      Signature: "sha256=" + Base64.stringify(hmacSHA256(`${shop}___shop_products`, process.env.REACT_APP_G_CLOUD_KEY)),
      shop: shop,
    },
  };

  const search_query_split = search_query.split(",");
  return Promise.all(
    search_query_split.map(async (search_query) => {
      const response = await fetch(
        (window.location.hostname === "localhost"
          ? `http://localhost:8100/europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net:443`
          : "https://" +
            (process.env.REACT_APP_HOST !== undefined ? process.env.REACT_APP_HOST : "app.keepoala.com") +
            "/europe-west1-" +
            process.env.REACT_APP_PROJECT_ID +
            ".cloudfunctions.net:443") +
          "/shopproducts_search?" +
          new URLSearchParams({
            shop: shop,
            search: search_query,
          }),
        requestOptions
      );
      return response
        .json()
        .then(async (results) => {
          return { message: "Success", data: results.data };
        })
        .catch((e) => {
          return { message: `Failed ${e}`, data: undefined };
        });
    })
  ).then((value) => {
    console.log(value);
    const data = value.reduce((prev, cur) => {
      prev = prev.concat(cur.data);
      return prev;
    }, []);
    const error = value.reduce((prev, cur) => {
      prev.push(cur.message);
      return prev;
    }, []);
    return {
      message: error.filter((x) => x === "Success").length < 1 ? "Failure" : "Success",
      data: data,
    };
  });
}
