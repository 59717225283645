import { shopify_get_line_items } from "./shopify_get_line_items";

// Derive Line items from REST API
export async function get_line_items(shop: string, email: string, order_id: string) {
  let err;
  const xx = await shopify_get_line_items(shop, order_id, email).catch((e: any) => {
    console.log(e);
    err = "Problem connecting the Keepoala Server";
  });
  if (xx !== undefined) {
    if (xx.status < 300) {
      return xx
        .json()
        .then(async (res: any) => {
          console.log(res);
          return {
            res: res,
            err: "",
          };
        })
        .catch((e: any) => {
          //setErrorMessage(t('Problem connecting the Keepoala Server'))
          err = "Problem connecting the Keepoala Server";
          return { res: undefined, err: err };
        });
    } else {
      return xx
        .json()
        .then(async (res: any) => {
          console.log(res);
          // const x = await res.test;
          //setErrorMessage('')
          return {
            res: undefined,
            err: res.test,
          };
        })
        .catch((e: any) => {
          //setErrorMessage(t('Problem connecting the Keepoala Server'))
          err = "Problem connecting the Keepoala Server";
          return { res: undefined, err: err };
        });
    }
  }
}
