import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { LineItemType } from '../types';
import styled from 'styled-components';
import {ArrowBackIconBack} from './ReturnExchange';
import Button from '../styles/Button';
import { useTranslation } from 'react-i18next';
import InterText from '../styles/Inter';
import { TextFeedback } from './ProductFeedback';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: column;
`;
const FullWidth = styled.div`
  width: 100%;
`;

/**
 * List of TextFields to rate items
 * @param props
 * * validateHandler - What happens in case all text fields are
 *    filled out or the user is simply finished giving info
 * * line_items - array of items to get rated
 * * type - What the user rates here, changes the description
 * * defaultValue - A starting value for all text fields
 * * backHandler - An external function that manages what happens if the
 *    user clicks the back button inside this thing
 * @returns
 */
export default function ProductFeedback2Text(props: {
  validateHandler: (
    type: string,
    values: { index: number; rating: string }[],
  ) => void;
  line_items: LineItemType[];
  type: 'positive' | 'negative';
  defaultValue: TextFeedback[];
  backHandler: () => void;
}) {
  const { t } = useTranslation();

  // also from stackoverflow, how to store multiple strings in a single state
  const [textFields, setTextFields]: [{ [key: number]: string }, any] =
    useState({ 0: '' });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.validateHandler(
      props.type,
      Object.keys(textFields).map((value: string) => ({
        index: parseFloat(value),
        rating: textFields[parseFloat(value)],
      })),
    );
  };

  // function to update an object with strings
  // simply from stackoverflow
  const textFieldHandler = (index: number, value: string) => {
    setTextFields({
      ...textFields,
      [index]: value,
    });
  };

  useEffect(() => {
    // constructor for defaultValue objects
    const x: { [key: number]: string } = {};
    for (let i = 0; i < props.defaultValue.length; i++) {
      x[i] = props.defaultValue[i].rating;
    }
    setTextFields(x);
  }, [props.defaultValue]);

  return (
    <FullWidth>
      <InterText size='l' mb={4} mt={1} align='left' weight={600}>
        {props.type === 'positive' ? (
          t('What did you like about each product, please tell us more')
        ) : (
          <span>
            {t('What')} <em>{t("didn't")}</em> {t('you like about the product')}
          </span>
        )}
        ?
      </InterText>
      <Form onSubmit={handleSubmit}>
        {
          // for each item, render a text field
          props.line_items.map((x, index) => {
            return (
              <div key={'line_item_star' + index}>
                <InterText size='l'>
                  {x.title +
                    (x.variant_title !== ''
                      ? ' (' + x.variant_title + ')'
                      : '')}
                </InterText>
                <TextField
                  id={index + props.type + 'textfield'}
                  label=''
                  multiline
                  rows={4}
                  placeholder={t('Type your answer here')}
                  value={textFields[index]}
                  onChange={(e: any) => textFieldHandler(index, e.target.value)}
                  fullWidth
                />
              </div>
            );
          })
        }

        <Button
          variant='contained'
          color='default'
          mt={2}
          mb={2}
          size='medium'
          id='submit'
          type='submit'
          value='Submit'
        >
          {t('Continue')}
        </Button>
      </Form>
      <Button onClick={props.backHandler}>
        <ArrowBackIconBack />
      </Button>
    </FullWidth>
  );
}
