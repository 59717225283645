import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthProvider } from "./utils/providers/AuthProvider";
import "./i18n/i18napp";
import LoadingCircular from "./components/LoadingCircular";
import { StylesProvider } from "@material-ui/core/styles";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

ReactDOM.render(
  <StylesProvider injectFirst>
    <Suspense fallback={<LoadingCircular />}>
      <AuthProvider>
        <React.StrictMode>
          <Elements stripe={stripePromise}>
            <App />
          </Elements>
        </React.StrictMode>
      </AuthProvider>
    </Suspense>
  </StylesProvider>,
  document.getElementById("root")
);
