import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import { AuthContext } from '../utils/providers/AuthProvider';
import { default as Lato } from '../styles/lato';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import InterStyled from '../styles/InterStyled';

// Components
import { FormControl } from '@material-ui/core';
import { Alert as MuiAlert } from '@material-ui/lab';
import Button from '../styles/Button';
import PasswordField from './PasswordField';
import { TextField } from '@mui/material';

const LatoText = styled(Lato)(spacing);
const Alert = styled(MuiAlert)(spacing);

const ProfileSettingsContainer = styled.div`
  width: 400px;
  margin-right: 40px;
  @media only screen and (max-width: 800px) {
    margin-right: 10px;
    width: 300px;
  }
  @media only screen and (max-width: 400px) {
    margin-right: 0px;
    width: 100%;
  }
`;

const GreenTextField = styled(TextField)`
  & input {
    background-color: #deffe5;
  }
  & label.Mui-focused {
    color: #1e5957;
  }
  & .MuiInputBase-colorPrimary::after {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }
`;

const useStyles = makeStyles({
  icon: {
    width: '1em',
    float: 'left',
  },
  flex_hcenter: {
    display: 'flex',
    alignItems: 'center',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  order_name: {
    textTransform: 'capitalize',
  },
  myAlert: {
    marginTop: 15,
    width: '90%',
  },
  hide: {
    display: 'none',
  },
  clickableInput: {
    '&:input': {
      cursor: 'pointer',
    },
  },
});

export default function ProfileSettings() {
  const { t } = useTranslation();

  const defaultpassword = 'xxxxxxxx';
  const {
    user,
    changePassword,
    changeEmail,
    changeDisplayName,
    authenticationError,
    setAuthenticationErrorEmpty,
  } = useContext(AuthContext);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);

  // Show forms or hide them
  const [showUserNameForm, setshowUserNameForm] = useState(false);
  const [showEmailForm, setshowEmailForm] = useState(false);
  const [showPasswordForm, setshowPasswordForm] = useState(false);

  //name form
  const [displayName, setDisplayName] = useState(user.displayName);

  // emailForm
  const [email, setEmail] = useState(user.email);
  const [passwordConfirm, setPasswordConfirm] = useState(defaultpassword);

  //pwd form
  const [password, setPassword] = useState(
    localStorage.getItem('intermediate_pwd') !== undefined &&
      localStorage.getItem('intermediate_pwd') !== null
      ? localStorage.getItem('intermediate_pwd')
      : defaultpassword,
  );
  const [passwordNEW, setPasswordNEW] = useState(defaultpassword);
  const [passwordNEW2, setPasswordNEW2] = useState(defaultpassword);

  const classes = useStyles();

  function handlePasswordChange(e) {
    e.preventDefault();
    toast.info(t('Updating password'));

    async function updatePWD(password, passwordNEW) {
      const passwordchange = await changePassword(password, passwordNEW);
      return passwordchange;
    }
    updatePWD(password, passwordNEW).then((res) => {
      if (res) {
        toast.info(t('Password changed'));
        setPassword(defaultpassword);
        setPasswordNEW(defaultpassword);
        setPasswordNEW2(defaultpassword);
      }
    });
  }
  function handleEmailChange(e) {
    e.preventDefault();
    toast.info(t('Updating email'));
    async function updateEmail(email, pwd) {
      console.log('Here');
      const emailchange = await changeEmail(email, pwd);
      return emailchange;
    }
    updateEmail(email, passwordConfirm).then((res) => {
      if (res) {
        toast.info(t('Email successfully updated'));
        setPasswordConfirm(defaultpassword);
      }
    });
  }

  function handleEmailField(e) {
    setEmail(e.target.value);
  }

  function handleNameChange(e) {
    e.preventDefault();
    toast.info(t('Updating name'));
    setshowUserNameForm(false);
    async function updateName(name) {
      const emailchange = await changeDisplayName(name);
      return emailchange;
    }
    updateName(displayName).then((res) => {
      if (res) {
        toast.info(t('Name successfully updated'));
      }
    });
  }

  useEffect(() => {
    if (passwordNEW.length < 8) {
      setAlertMessage(t('Passwords needs mininum 8 characters'));
      setShowAlert(true);
    } else {
      if (passwordNEW !== passwordNEW2) {
        setShowAlert(true);
        setAlertMessage(t('Passwords are not equal'));
      } else {
        setShowAlert(false);
      }
    }

    if (authenticationError !== 'empty') {
      toast.error(authenticationError);
      setAuthenticationErrorEmpty();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [passwordNEW, passwordNEW2, authenticationError]);

  return (
    <ProfileSettingsContainer>
      {/* UserName Section */}
      <section style={{ marginBottom: '20px' }}>
        <div>
          <InterStyled interStyle='Label1'>{t('Username')}</InterStyled>
        </div>

        {user.providerData[0].providerId === 'password' ? (
          <div>
            {/* TextField that shows the read only value */}
            {!showUserNameForm ? (
              <div style={{ marginTop: '15px' }}>
                <TextField
                  variant='filled'
                  hiddenLabel
                  readOnly
                  fullWidth
                  size='small'
                  value={displayName}
                  onClick={() => setshowUserNameForm(true)}
                  InputProps={{
                    className: classes.clickableInput,
                  }}
                ></TextField>
              </div>
            ) : (
              <form onSubmit={handleNameChange}>
                {/* Form to change the input */}
                <FormControl margin='normal' required fullWidth>
                  <GreenTextField
                    id='username'
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    fullWidth
                    variant='filled'
                    size='small'
                    label={t('Username')}
                  />
                </FormControl>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '10px',
                  }}
                >
                  <Button
                    color='primary'
                    style={{ width: '49%' }}
                    type='submit'
                    value='Submit'
                  >
                    {t('Submit')}
                  </Button>

                  <Button
                    color='secondary'
                    style={{ width: '49%' }}
                    onClick={() => setshowUserNameForm(false)}
                  >
                    {t('Cancel')}
                  </Button>
                </div>
              </form>
            )}
          </div>
        ) : (
          <div>
            <LatoText m={1} mt={3} fontSize='l' color='black'>
              {t('Your name')}:
            </LatoText>
            <LatoText m={1} mt={3} fontSize='m' color='lightgrey'>
              {user.displayName}
            </LatoText>
          </div>
        )}
      </section>

      {/* Email Section */}
      <section style={{ marginBottom: '20px' }}>
        <div>
          <InterStyled interStyle='Label1'>{t('Email Address')}</InterStyled>
        </div>

        {user.providerData[0].providerId === 'password' ? (
          <div>
            {/* TextField that shows the read only value */}
            {!showEmailForm ? (
              <div style={{ marginTop: '15px' }}>
                <TextField
                  variant='filled'
                  hiddenLabel
                  readOnly
                  fullWidth
                  size='small'
                  value={email}
                  onClick={() => setshowEmailForm(true)}
                  InputProps={{
                    className: classes.clickableInput,
                  }}
                ></TextField>
              </div>
            ) : (
              <form onSubmit={handleEmailChange}>
                <FormControl margin='normal' required fullWidth>
                  <GreenTextField
                    id='useremail'
                    name='email'
                    value={email}
                    fullWidth
                    variant='filled'
                    size='small'
                    label={t('Email-Address')}
                    onChange={handleEmailField}
                  />
                </FormControl>
                <FormControl margin='normal' required fullWidth>
                  <PasswordField
                    password={passwordConfirm}
                    setPassword={setPasswordConfirm}
                    label={t('Confirm Password')}
                  />
                </FormControl>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '10px',
                  }}
                >
                  <Button
                    color='primary'
                    style={{ width: '49%' }}
                    type='submit'
                    value='Submit'
                  >
                    {t('Submit')}
                  </Button>

                  <Button
                    color='secondary'
                    style={{ width: '49%' }}
                    onClick={() => setshowEmailForm(false)}
                  >
                    {t('Cancel')}
                  </Button>
                </div>
              </form>
            )}
          </div>
        ) : (
          <LatoText m={1} mt={3} fontSize='m' color='lightgrey'>
            {user.email}:
          </LatoText>
        )}
      </section>

      {/* Password Section */}

      <section style={{ marginBottom: '20px' }}>
        <div>
          <InterStyled interStyle='Label1'>{t('Password')}</InterStyled>
        </div>
        {user.providerData[0].providerId === 'password' ? (
          <div>
            {/* TextField that shows the read only value */}
            {!showPasswordForm ? (
              <div style={{ marginTop: '15px' }}>
                <TextField
                  variant='filled'
                  hiddenLabel
                  readOnly
                  fullWidth
                  size='small'
                  value={password}
                  type='password'
                  onClick={() => setshowPasswordForm(true)}
                  InputProps={{
                    className: classes.clickableInput,
                  }}
                ></TextField>
              </div>
            ) : (
              <form onSubmit={handlePasswordChange}>
                <FormControl margin='normal' required fullWidth>
                  <PasswordField
                    password={password}
                    setPassword={setPassword}
                    label={t('Current Password')}
                    greenInput={true}
                  />
                </FormControl>
                <FormControl margin='normal' required fullWidth>
                  <PasswordField
                    password={passwordNEW}
                    setPassword={setPasswordNEW}
                    label={t('New Password')}
                  />
                </FormControl>
                <FormControl margin='normal' required fullWidth>
                  <PasswordField
                    password={passwordNEW2}
                    setPassword={setPasswordNEW2}
                    label={t('Confirm New Password')}
                  />
                </FormControl>

                <Alert
                  mb={4}
                  severity='error'
                  className={`${classes.myAlert} ${!showAlert ? classes.hide : ''
                    }`}
                >
                  {alertMessage}
                </Alert>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '10px',
                  }}
                >
                  <Button
                    color='primary'
                    style={{ width: '49%' }}
                    type='submit'
                    value='Submit'
                  >
                    {t('Submit')}
                  </Button>

                  <Button
                    color='secondary'
                    style={{ width: '49%' }}
                    onClick={() => setshowPasswordForm(false)}
                  >
                    {t('Cancel')}
                  </Button>
                </div>
              </form>
            )}
          </div>
        ) : (
          <div>
            <LatoText m={1} mt={3} fontSize='l' color='black'>
              {t('Logged in via')}:
            </LatoText>
            <LatoText m={1} mt={3} fontSize='m' color='lightgrey'>
              {user.providerData[0].providerId}
            </LatoText>
          </div>
        )}
      </section>
    </ProfileSettingsContainer>
  );
}
