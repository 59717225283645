import React, { useContext, useEffect } from 'react';
import { OrderTypeType, LineItemType } from '../types';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../utils/providers/AuthProvider';
import firebase from '../firebase';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import InterText from '../styles/InterText';
import InterStyled from '../styles/InterStyled';
import DonutChart from './DonutChart';
import GreyBox from './GreyBox';
import LabelWithLineBtn from './LabelWithLineBtn';
import { Dialog, DialogContent } from '@material-ui/core';

declare module 'react' {
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  interface HTMLAttributes<T> {
    readonly fontSize?: string;
    readonly align?: string;
    readonly uppercase?: boolean;
  }
}

function build_item_string(item: LineItemType, order_id: string) {
  // id - variantID - Quantity
  return (
    order_id +
    '-splitter-' +
    item.id +
    '-splitter-' +
    item.variant_id +
    '-splitter-' +
    item.quantity.toString()
  );
}

export default function Order(props: {
  order_item: OrderTypeType;
  key: string;
  t: any; // translations
  IsOrderFeedbackOverview: boolean;
}) {
  const { t } = useTranslation();
  const { user, config } = useContext(AuthContext);
  const db = firebase.firestore();
  const navigate = useNavigate();

  const [draft, setDraft] = React.useState(false);
  const [checked, setChecked]: [
    { [key: string]: number },
    React.Dispatch<React.SetStateAction<{}>>,
  ] = React.useState({});

  const [showReturnQuestions, setShowReturnQuestions] = React.useState(false);
  const [returnFeedbackReceived, setReturnFeedBackreceived] =
    React.useState(1000);
  const [returnedItemsTotal, setReturnedItemsTotal]: [
    { id: string; variant_id: string; quantity: number }[],
    any,
  ] = React.useState([]);
  const [returnFeedbackIDs, setreturnFeedbackIDs]: [string[], any] =
    React.useState([]);

  // Return label dialog
  const [showReturnLabelDialog, setShowReturnLabelDialog] =
    React.useState(false);

  const progress =
    parseFloat(props.order_item.keepoalas) /
    (parseFloat(props.order_item.keepoalas_possible.toString()) +
      parseFloat(props.order_item.keepoalas));

  const keep_status_days_order =
    props.order_item.keep_status_days === undefined
      ? config.goal_keep_days
      : props.order_item.keep_status_days;
  const checkDateOnLabelExist = (): Date => {
    if (props.order_item.date_on_label !== '') {
      const split_date = props.order_item.date_on_label.split('-');
      return new Date(
        parseFloat(split_date[0]),
        parseFloat(split_date[1]) - 1,
        parseFloat(split_date[2]),
        0,
        0,
        0,
        0,
      );
    }
    return new Date(props.order_item.datenum * 1000);
  };
  const getRemainingDate = () => {
    let labelDate = checkDateOnLabelExist();
    let msDiff = new Date().getTime() - labelDate.getTime(); //current date - date_on_label
    let daysTill = Math.floor(msDiff / (1000 * 60 * 60 * 24));
    if (daysTill >= keep_status_days_order) {
      return keep_status_days_order;
    }
    return daysTill;
  };

  const handleReturn = (order: OrderTypeType) => {
    const params = {
      email:
        order?.shopify?.email !== undefined ? order.shopify.email : user.email,
      id: order?.shopify?.id !== undefined ? order?.shopify?.id : order.id,
      doc_id: order.key,
      name:
        order?.shopify?.name !== undefined ? order?.shopify?.name : order.name,
      shop: order.shop !== undefined ? order.shop : order.name,
      is_dhl: order.is_dhl,
      is_shopify:
        order.shopify !== undefined &&
        order.shopify.id !== undefined &&
        order.shopify.line_items !== undefined,
      with_nps: order.shopify !== undefined && order.shopify.id !== undefined,
    };

    navigate({
      pathname: '/returnfeedback',
      search: `?email=${params.email}&id=${params.id}&doc_id=${params.doc_id}&name=${params.name.replace('#','%23')}&shop=${params.shop}&is_dhl=${params.is_dhl}&is_shopify=${params.is_shopify}&with_nps=${params.with_nps}`,
    });
  };

  const handleKeptFeedback = (order: any) => {
    const email =
      order?.shopify?.email !== undefined ? order.shopify.email : user.email;
    const name =
      order?.shopify?.name !== undefined ? order?.shopify?.name : order.name;
    const id = order?.shopify?.id !== undefined ? order?.shopify?.id : order.id;
    const shop = order.shop !== undefined ? order.shop : order.name;
    navigate(
      `/orderfeedback?id=${id}&email=${email}&shop=${shop}&name=${name.replace('#','%23')}`,
    );
  };

  const handleReturnScan = () => {
    let create_dict = {
      returned: true,
      user_id: user.uid,
      id: props.order_item.key,
    };
    toast.info(t('Sending Order Change'));
    db.collection('OrderChange').add({
      type: 'change',
      dict: create_dict,
      config: config,
    });
  };

  useEffect(() => {
    if (props.order_item.shopify !== undefined) {
      if (
        props.order_item.shopify !== undefined &&
        props.order_item.shopify.id !== undefined &&
        props.order_item.shopify.id === 'unknown'
      ) {
        setDraft(true);
      }

      if (props.order_item.shopify.line_items !== undefined) {
        if (props.order_item.shopify.line_items.length > 0) {
          var checked_start: { [key: string]: number } = {};
          props.order_item.shopify.line_items.map((item) => {
            checked_start[build_item_string(item, props.order_item.id)] = 0;
            return null;
          });
          if (returnFeedbackReceived === 1000 || returnFeedbackReceived === 0) {
            setChecked(checked_start);
          }
        }
      }
    }

    // Upon finishing all return questions, submit the order as "returned" to DB
    if (returnFeedbackReceived === 0) {
      setShowReturnQuestions(false);
      db.collection('OrderChange')
        .add({
          type: 'change',
          dict: {
            id: props.order_item.key,
            user_id: user.uid,
            returned: true,
            return_questions_response_id: returnFeedbackIDs,
            return_questions: true,
            returned_items: returnedItemsTotal, //TODO: try this
          },
          config: config,
        })
        .then(() => {
          toast.info(t('Order returned, questions answered'));
        })
        .catch((e) => {
          console.log('Error: ', e);
          toast.error(t('Something went wrong'));
        });
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [returnFeedbackReceived]);

  return (
    <GreyBox>
      {/* Upper Section of the Order card */}
      {/* Name, Date and Chart */}
      <Grid
        container
        sx={{ marginBottom: '15px' }}
        justifyContent='center'
        alignItems='center'
        columnSpacing={1}
      >
        {/* Name and Date */}
        <Grid item xs>
          <div>
            <InterText
              size='xl'
              type='medium'
              color='greytext'
              transform='uppercase'
            >
              {props.order_item.name.replace(/ - /g, '\u000A')}
            </InterText>
          </div>
          <div style={{ marginTop: '10px' }}>
            {/* Change the date format */}
            <InterStyled interStyle='P2'>
              {new Date(props.order_item.date)
                .toLocaleDateString('en-GB')
                .replace(/\//g, '.')}
            </InterStyled>
          </div>
        </Grid>

        {/* Donut Chart */}
        <Grid item xs='auto'>
          <DonutChart
            value={progress * 100}
            keepoalas={props.order_item.keepoalas}
            totalKeepoalas={
              props.order_item.keepoalas_possible + props.order_item.keepoalas
            }
            t={props.t}
          />
        </Grid>
      </Grid>

      {/* Lower section of the Order card */}
      {/* Buttons and status */}
      <Grid container>
        {/* Status Section */}
        {(getRemainingDate() / keep_status_days_order) * 100 >= 100 &&
        !props.order_item.keep &&
        !props.order_item.returned ? (
          <Grid item xs={12}>
            <LabelWithLineBtn align='left' type='text'>
              {props.t('Will be set to kept by our service, soon')}
            </LabelWithLineBtn>
          </Grid>
        ) : null}

        {draft ? (
          <Grid item xs={12}>
            <LabelWithLineBtn align='left' type='text'>
              {props.t(
                'This order is currently getting checked by our service',
              )}
            </LabelWithLineBtn>
          </Grid>
        ) : null}

        {!props.order_item.keep && !props.order_item.returned && !draft ? (
          <Grid item xs={12}>
            <LabelWithLineBtn align='left' type='text'>
              {props.t('Keep this order and gain')}&nbsp;<b>{config?.keep}</b>{' '}
              {props.t('Keepoalas')}
            </LabelWithLineBtn>
          </Grid>
        ) : null}

        {props.order_item.return_questions ||
        props.order_item.keep ? null : !draft ? (
          <Grid item xs={12}>
            <LabelWithLineBtn align='left' type='text'>
              {props.t('If you need to return this, keep the possibility on')}
              &nbsp;<b>{config?.return_questions}</b>&nbsp;
              {props.t('Keepoalas, for your opinion on the return')}
            </LabelWithLineBtn>
          </Grid>
        ) : null}

        {/* Buttons Section */}

        {/* A feature To be added later */}
        {/* <Grid item xs={12}>
          <LabelWithLineBtn align='center' type='btn'>
            Mark as Kept
          </LabelWithLineBtn>
        </Grid> */}

        {/* Shopify orders return questions */}
        {props.IsOrderFeedbackOverview !== true &&
          (props.order_item.keep === undefined ||
            props.order_item.keep === false) && // no kept orders
          props.order_item.shopify !== undefined && // Shopify is defined
          props.order_item.shopify.line_items !== undefined && // line items exist on shopify
          !props.order_item.return_questions && ( // there is no return questions answered yet
            <Grid item xs={12}>
              <LabelWithLineBtn
                align='center'
                type='btn'
                onClick={() => handleReturn(props.order_item)}
              >
                {props.order_item.returned
                  ? props.t('Answer return questions')
                  : props.t('Start a return')}
              </LabelWithLineBtn>
            </Grid>
          )}

        {/* Non Shopify orders return questions */}
        {props.IsOrderFeedbackOverview !== true &&
          (props.order_item.shopify === undefined ||
            props.order_item.shopify?.line_items === undefined) && // Not from shopify
          !draft && // Not a draft
          props.order_item.returned && // Was set as returned
          !props.order_item.return_questions && ( // there is no return questions answered yet
            <Grid item xs={12}>
              <LabelWithLineBtn
                align='center'
                type='btn'
                onClick={() => handleReturn(props.order_item)}
              >
                {props.t('Answer return questions')}
              </LabelWithLineBtn>
            </Grid>
          )}

        {/* Non Shopify orders return Order */}
        {props.IsOrderFeedbackOverview !== true &&
          (props.order_item.shopify === undefined ||
            props.order_item.shopify?.line_items === undefined) && // Not from shopify
          !draft && // Not a draft
          !props.order_item.returned && ( // Was not set as returned
            <Grid item xs={12}>
              <LabelWithLineBtn
                align='center'
                type='btn'
                onClick={handleReturnScan}
              >
                {props.t('Return this')}
              </LabelWithLineBtn>
            </Grid>
          )}

        {/* RETURN LABEL QR CODE */}
        {props.IsOrderFeedbackOverview !== true &&
          props.order_item.returnLabel && (
            <Grid item xs={12}>
              <LabelWithLineBtn
                align='center'
                type='btn'
                onClick={() => setShowReturnLabelDialog(true)}
              >
                {props.t('Show QR Code for DHL Shop')}
              </LabelWithLineBtn>
            </Grid>
          )}

        {/* KEPT ORDER FEEDBACK */}
        {props.order_item.keep === true &&
          props.order_item.shopify?.line_items?.length !== 0 &&
          props.order_item.product_questions === false && (
            <Grid item xs={12}>
              <LabelWithLineBtn
                align='center'
                type='btn'
                onClick={() => handleKeptFeedback(props.order_item)}
              >
                {props.t('Tell us what you think about your order')}
              </LabelWithLineBtn>
            </Grid>
          )}

        {/* ORDER FEEDBACK OVERVIEW BUTTONS ONLY */}
        {/* RETURN ORDER FEEDBACK */}
        {props.IsOrderFeedbackOverview === true &&
          props.order_item.returned === true &&
          props.order_item.return_questions !== true && (
            <Grid item xs={12}>
              <LabelWithLineBtn
                align='center'
                type='btn'
                onClick={() => handleReturn(props.order_item)}
              >
                {props.t('Tell us why you returned this')}
              </LabelWithLineBtn>
            </Grid>
          )}
      </Grid>

      {/* Return Label Dialog */}
      <Dialog
        open={showReturnLabelDialog}
        onClose={() => setShowReturnLabelDialog(false)}
      >
        <DialogContent>
          <img
            src={
              props.order_item?.returnLabel?.qrCode ||
              props.order_item?.returnLabel?.qrCodeLink
            }
            alt='Return QR code'
          />
        </DialogContent>
      </Dialog>
    </GreyBox>
  );
}
