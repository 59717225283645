import React, { useEffect, useState } from "react";
import { Container as MuiContainer, FormControl as MUIFormControl } from "@material-ui/core";
import { shipping_label_email } from "../utils/helper/shipping_label_email";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import InterText from "../styles/Inter";
import { useTranslation } from "react-i18next";
import ThankYouContent from "./ThankYouContent";
import { ShopLogo } from "./ReturnFeedbackCheck";
import { ReturnProductFeedbackType } from "./ReturnFeedback";
import AlertError from "./Alert/AlertError";
import LoadingCircular from "./LoadingCircular";
import customerEmailCheck from "../utils/helper/payment/customerEmailCheck";
import EmailChangeForm from "./Label/EmailChangeForm";

const Container = styled(MuiContainer)`
  text-align: center;
`;
const ThankYouContainer = styled.div`
  padding: 10px;
  padding-top: 0px;
  justify-content: space-evenly;
`;
export default function NoLabel(props: {
  shop: string | undefined;
  name?: string;
  order_id?: string;
  isUser?: boolean;
  email?: string;
  address: string;
  productFeedback: ReturnProductFeedbackType[];
}) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [newEmailAdded, setNewEmailAdded] = useState("");

  // If the email contains `@customers.` this means it is not a correct email and that the user should be
  // Propmted to insert the correct email he wants, also he can download the file as a pdf
  const [hasCustomersEmail, setHasCustomersEmail] = useState(false);

  // Wheter the request to change email is sent or not
  const [firstEmailSent, setFirstEmailRequestSent] = useState(false);

  useEffect(() => {
    if (props.email !== undefined && !firstEmailSent) {
      // send out email from default Address once
      shipping_label_email(props.shop, props.order_id, props.email, "no_label", props.productFeedback)
        .then((response) => response.json())
        .then((data) => {
          if (data.test) {
            setFirstEmailRequestSent(true);
          } else {
            setFirstEmailRequestSent(true);
          }
        })
        .catch((error) => console.log(error));

      setHasCustomersEmail(customerEmailCheck(props.email));
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.email, firstEmailSent]);

  if (loading) {
    return (
      <Container maxWidth="sm">
        <LoadingCircular />
      </Container>
    );
  } else {
    return (
      <Container maxWidth="sm" style={{ marginTop: "10vh" }}>
        {errorMessage === undefined || errorMessage === "" ? (
          <div>
            <ShopLogo
              src={
                "https://storage.googleapis.com/" +
                process.env.REACT_APP_PROJECT_ID +
                "-public-data/shoplogos/" +
                props.shop +
                ".png"
              }
            />
            {/* If it is a customers email (fake email) we show the form to insert the correct email */}
            {hasCustomersEmail ? (
              <EmailChangeForm
                order_id={props.order_id ?? "id"}
                shop={props.shop ?? "shop"}
                setNewEmail={(x: string) => {
                  setNewEmailAdded(x);
                }}
                type="no_label"
                product_feedback={props.productFeedback}
              />
            ) : (
              // Regular case where the email is already there and sent to the correct address
              <>
                <InterText size="l" mb={3} mt={3} align="left">
                  {t("We sent all infos to")} <b>{props.email}</b>
                </InterText>
                <InterText size="l" mb={2} align="center">
                  {t("Please look also into your spam folder")}
                </InterText>
              </>
            )}
            {
              <>
                <InterText size="l" mb={2} mt={4} align="left">
                  {t("Thank you very much") +
                    ". " +
                    t("Your return is now registered and you can send your items back to us") +
                    ". " +
                    t("Your refund will appear within 5-10 working days after the return arrived") +
                    "."}
                </InterText>
                <InterText mt={0} mb={2} size={"l"}>
                  {t("Please send your items to the following address with your preferred shipping provider")}
                </InterText>
                <InterText mt={0} size="l" weight={600} align="center">
                  {props.address.split("\\n").map((x) => (
                    <span>
                      {x}
                      <br />
                    </span>
                  ))}
                </InterText>
              </>
            }

            {/* Thank you container for non users */}
            {props.isUser !== undefined && !props.isUser ? (
              <>
                <hr />
                <ThankYouContainer>
                  <ThankYouContent
                    email={newEmailAdded !== "" ? newEmailAdded : props.email}
                    id={props.order_id}
                    shop={props.shop}
                  />
                </ThankYouContainer>
              </>
            ) : null}
          </div>
        ) : (
          <AlertError errorMessage={errorMessage} />
        )}
      </Container>
    );
  }
}
