import styled from "styled-components";
import Colors from '../utils/helper/Colors';
import LatoText from '../styles/lato';
import { spacing } from '@material-ui/system';

const SeperatorDivPre = styled.div`
    display:flex;
    flexDirection: column;
    alignItems:center;
    justifyItems:center;
    width:100%;
    `
const SeperatorDiv = styled(SeperatorDivPre)(spacing);

    const SeperatorContent = styled.div`
    padding: 0 2em 0 2em;
    `
    const SeperatorBorder = styled.div`
    border-bottom: 1px solid ${Colors.keepoala.grey};
    width:100%;
    height:10px;
`
export default function Seperator(props){
    const {size, children, ...otherProps} = props;

    return(<SeperatorDiv {...otherProps}>
        <SeperatorBorder />
        <SeperatorContent>
            <LatoText fontSize={size !== undefined ? size : "l"}>{children}</LatoText></SeperatorContent>
        <SeperatorBorder />
        </SeperatorDiv>)
}