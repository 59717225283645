import * as React from 'react';
import Grid from '@mui/material/Grid';
import MuiSwitch from '@mui/material/Switch';
import styled from 'styled-components';
import InterStyled from '../../styles/InterStyled';
import Colors from '../../utils/helper/Colors';
import DiscreteSlider from '../DiscreteSlider';

const Switch = styled(MuiSwitch)`
  & .MuiSwitch-colorPrimary.Mui-checked {
    color: ${Colors.keepoala.keepoalaGreendefault};
  }
  & .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: ${Colors.keepoala.keepoalaGreendefault};
  }
`;

export default function VoucherStoreFilterMenu(props) {
  return (
    <Grid container style={{ padding: '20px', maxWidth: '400px' }}>
      {/* New Customer Switch */}
      <Grid item xs={12}>
        <div>
          <InterStyled
            color='keepoalaGreendark'
            type='medium'
            interStyle='P1'
            style={{ marginRight: '10px' }}
          >
            {props.t('New Customer')}
          </InterStyled>

          <Switch
            checked={props.neukunde}
            onChange={() => {
              props.setNeukunde(!props.neukunde);
            }}
            color='primary'
            name='checkedB'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
        <div>
          <InterStyled color='greydefault' interStyle='P2'>
            {props.t(
              'You can only use the voucher if you are new to the store',
            )}
          </InterStyled>
        </div>
      </Grid>

      <Grid item xs={12} style={{ marginTop: '20px' }}>
        <Grid container>
          <Grid
            item
            xs={3}
            sm='auto'
            style={{ marginRight: '50px', paddingTop: '10px' }}
          >
            <InterStyled
              color='keepoalaGreendark'
              type='medium'
              interStyle='P1'
            >
              {props.t('Keepoalas')}
            </InterStyled>
          </Grid>
          <Grid item xs={9} sm style={{ paddingRight: '20px' }}>
            <DiscreteSlider handleChange={props.setMaxKeepoalas} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
