/* eslint import/no-webpack-loader-syntax: off */
/**
 * Imports
 */

// Main Imports and Libraries
import React, { useEffect, useState, useContext } from 'react';
import { Grid, Slide } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import styled from 'styled-components';
import { db } from '../../firebase';
import IconButton from '@mui/material/IconButton';
import { AuthContext } from '../../utils/providers/AuthProvider';
import { default as Lato } from '../../styles/lato';
import { useTranslation } from 'react-i18next';
import ourusers from '../../utils/helper/ourusers';
import { analytics } from '../../firebase';
import CloseIcon from '@mui/icons-material/Close';
import Colors from '../../utils/helper/Colors';
import smallKeepoala from '../../assets/img/smallKeepoala.svg';
import { BoxSpaced } from '../Box';
import Button from '../../styles/Button';
import { Button as MuiButton } from '@material-ui/core';
import InterStyled from '../../styles/InterStyled';
import InterText from '../../styles/InterText';
import NewChip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import FilterListIcon from '@mui/icons-material/FilterList';
import Menu from '@mui/material/Menu';
import { useLocation } from 'react-router-dom';
import split_query from '../../utils/helper/split_query';
import LoadingCircular from '../LoadingCircular';

// Child Components
import VoucherStoreFilterMenu from './VoucherStoreFilterMenu';
import Voucher from './Voucher';
import VoucherDetail from './VoucherDetail';

/**
 * Main Code
 */
const MainDiv = styled.div(spacing);

const ExpandedGrid = styled(Grid)`
  @media only screen and (max-width: 600px) {
    flex-wrap: nowrap;
    overflow: auto;
  }
`;

const LatoText = styled(Lato)(spacing);

export default function VoucherStore() {
  const { t } = useTranslation();
  const { user, emailverify } = useContext(AuthContext);
  const location = useLocation();

  const [vouchers, setVouchers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeVouchers, setActiveVouchers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [maxKeepoalas, setMaxKeepoalas] = useState(-1);
  const [userKeepoalas, setUserKeepoalas] = useState(-1);
  const [userKeepoalasWaiting, setUserKeepoalasWaiting] = useState(-1);

  const [selectedVoucher, setSelectedVoucher] = useState();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [disableDialogOpen, setDisableDialogOpen] = useState(false);

  const [allVouchersDisabled, setAllVouchersDisabled] = useState(false);
  const [neukunde, setNeukunde] = useState(true);

  // State for opening and closing filters menu
  const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
  const isFilterMenuOpen = Boolean(filterAnchorEl);

  // Open and close filter menu functions
  const handleFilterMenuClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterMenuOpen = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const filterMenuId = 'filter-menu';

  function handleCategoryChange(categoryname) {
    // Check if this is the starting state
    let categories_tmp = undefined;

    // Toggle the selected category
    categories_tmp = categories.map((category) => {
      if (category.name === categoryname) {
        return {
          name: category.name,
          active: !category.active,
        };
      } else {
        return category;
      }
    });

    setCategories(categories_tmp);
  }

  function handleVoucherClick(voucher) {
    if (voucher.keepoalas <= userKeepoalas && !allVouchersDisabled) {
      setSelectedVoucher(voucher);
    } else if (allVouchersDisabled) {
      setDisableDialogOpen(true);
    } else {
      setDialogOpen(true);
    }
  }

  const handleClose = () => {
    setDialogOpen(false);
  };

  function handleBuyVoucher(voucherKeepoalas) {
    // Disable purchases until the backend processes the buying of the voucher
    setAllVouchersDisabled(true);
    setDisableDialogOpen(true);
    setSelectedVoucher(undefined);

    // Set the user keepoalas to the new value after buying
    setUserKeepoalas(userKeepoalas - voucherKeepoalas);

    // ReEnable the Voucher store after 5 seconds
    setTimeout(() => {
      setAllVouchersDisabled(false);
      getVouchers();
      setDisableDialogOpen(false);
    }, 5000);
  }

  function getVouchers() {
    // Get the Voucher partners
    db.collection('VoucherPartners')
      .get()
      .then((querySnapshot) => {
        const partnerlist = [];
        querySnapshot.forEach((documentSnapshot) => {
          partnerlist.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          });
        });

        // Get the vouchers
        db.collection('Vouchers')
          .orderBy('keepoalas', 'desc')
          .get()
          .then(
            (querySnapshot) => {
              const voucherList = [];
              querySnapshot.forEach((documentSnapshot) => {
                let document_data = documentSnapshot.data();
                voucherList.push({
                  ...document_data,
                  partner: partnerlist.filter(
                    (x) => x.id === document_data.partnerid,
                  )[0],
                  key: documentSnapshot.id,
                });
              });

              // Sort the Vouchers by Keepoalas
              voucherList.sort((a, b) => {
                if (a.keepoalas > b.keepoalas) {
                  return 1;
                } else {
                  return -1;
                }
              });
              setVouchers(voucherList);

              // Split Vouchers by Categories
              const splitVouchers = splitVouchersByCategory(voucherList);
              setActiveVouchers(splitVouchers);

              // Create Categories of Vouchers
              if (voucherList.length > 0) {
                function onlyUnique(value, index, self) {
                  return self.indexOf(value) === index;
                }
                let categorynames = voucherList.map(
                  (voucher) => voucher.category,
                );

                let categorynames_filtered = categorynames.filter(onlyUnique);

                let voucherCategories = categorynames_filtered.map((name) => {
                  return {
                    name: name,
                    active: true,
                  };
                });

                setCategories(voucherCategories);
              }
              setLoading(false);
            },
            (err) => {
              console.log(`Encountered error: ${err}`);
            },
          );
      });
  }

  function getUserKeepoalas() {
    db.collection('Aggregation')
      .doc(user.uid)
      .get()
      .then((document) => {
        const data = document.data();
        setUserKeepoalas(data.keepoalas.keepoalas);
        setUserKeepoalasWaiting(data.keepoalas.waiting);
      });
  }

  // Helper function
  // Splits the vouchers into smaller arrays that have the same category
  function splitVouchersByCategory(voucherlist) {
    // If voucherlist is empty return empty array
    if (voucherlist.length === 0) return [];

    // Copy the contents into new array and initialize new array
    voucherlist = [...voucherlist];

    // Reduce the vouchers to an object with the category as key for every array
    const splitVouchers = voucherlist.reduce((mainObject, voucher) => {
      // Create a key if it does not exist for category
      mainObject[voucher.category] = mainObject[voucher.category] || [];

      // Push the vocucher to the array corresponding to the key
      mainObject[voucher.category].push(voucher);

      // return the object
      return mainObject;

      // Initialize the object of the reducer with null
    }, Object.create(null));

    return splitVouchers;
  }

  // Helper Function
  // Looks at the query params and sets the Selected voucher to the one that is in the URL
  function openVoucherFromURL() {
    // If voucherlist is empty return
    if (vouchers.length === 0) return;

    // Check if there is an id in the query params
    const query_params = split_query(location.search);
    if (query_params.id === undefined) return;
    const voucherID = query_params.id;
    location.search = '';

    // Loop over the vouchers and set the voucher to selected Voucher
    vouchers.forEach((voucher) => {
      if (voucher.key === voucherID) {
        handleVoucherClick(voucher);
      }
    });
  }

  // Initial Run to initialize Vouchers and Keepoalas
  useEffect(() => {
    // Get Vouchers and User Keepoalas
    getVouchers();
    getUserKeepoalas();

    // Check if there is a voucher in the URL and set selected voucher
    openVoucherFromURL();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  // Changes the vouchers active depending on the filter values
  useEffect(() => {
    if (categories.length > 0 && maxKeepoalas > -1) {
      // Get vouchers that match the filters selected
      var tmp_vouchers = vouchers.map((voucher) => {
        // Find the category of the voucher from the current categories objects
        var voucher_category = categories.filter(
          (cat) => cat.name === voucher.category,
        )[0];

        // Check if category of voucher is selected then return it otherwise return null
        // and check if neukunde is set then we return it otherwise return null
        // null values will be filtered later
        if (voucher_category.active) {
          if (!neukunde) {
            return !voucher.neukunde ? voucher : null;
          } else {
            return voucher;
          }
        } else {
          return null;
        }
      });

      // Filter null values that resulted from the previous filtering
      tmp_vouchers = tmp_vouchers.filter((x) => x !== null);
      // Remove vouchers that have more than the max Keepoalas selected
      tmp_vouchers = tmp_vouchers.filter((x) => x.keepoalas <= maxKeepoalas);

      // Set split vouchers
      const splitVouchers = splitVouchersByCategory(tmp_vouchers);
      setActiveVouchers(splitVouchers);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [categories, maxKeepoalas, neukunde]);

  // Send event to GA
  useEffect(() => {
    if (!ourusers.includes(user.uid)) {
      analytics.logEvent('screen_view', {
        firebase_screen: 'Voucher',
        firebase_screen_class: 'WebView',
      });
    }
  }, [user.uid]);

  if (loading) {
    return <LoadingCircular />;
  }

  return (
    <MainDiv mt={5} mb={5}>
      {/* Background color of the page */}
      <style>{'body { background: #F2F2F2;}'}</style>

      {/* Dialogs for users prompts and info */}
      {/* No sufficient keepoalas dialog */}
      <Snackbar
        open={dialogOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <SnackbarContent
          sx={{ backgroundColor: '#fff', color: '#515959' }}
          message={t('You do not have sufficient Keepoalas')}
          action={
            <MuiButton color='primary' onClick={handleClose}>
              {t('OK')}
            </MuiButton>
          }
        />
      </Snackbar>

      {/* Purchase is being performed dialog */}
      <Snackbar
        open={disableDialogOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <SnackbarContent
          sx={{ backgroundColor: '#fff', color: '#515959' }}
          message={t('Waiting for purchase to be performed')}
        />
      </Snackbar>

      {/* Verify your email section */}
      {!emailverify && (
        <BoxSpaced mt={3} mb={5}>
          <LatoText ml={3} mt={3} mb={3} fontSize='l'>
            <b>{t('Action needed')}</b>
          </LatoText>
          <LatoText
            ml={3}
            mt={3}
            mb={3}
            fontSize='l'
            uppercase={false}
            color='black'
          >
            {t(
              'Please look into your Email Mailbox to verify your email address',
            )}
            . {t('You need to verify your email address to buy a voucher')}
          </LatoText>
        </BoxSpaced>
      )}

      {/* Display user keepoalas section */}
      {userKeepoalas > -1000 && (
        <Grid
          container
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          style={{ marginBottom: '1em' }}
        >
          <Grid item xs='auto' style={{ marginRight: '40px' }}>
            <div style={{ marginBottom: '12px' }}>
              <InterStyled interStyle='P2'> {t('Your')} Keepoalas </InterStyled>
            </div>
            <div>
              <Grid container alignItems='center'>
                <Grid item xs='auto' style={{ marginRight: '12px' }}>
                  <img
                    src={smallKeepoala}
                    alt='keepoala logo'
                    style={{ width: '30px', height: '45px' }}
                  ></img>
                </Grid>
                <Grid item xs='auto'>
                  <div>
                    <InterText
                      size='xl'
                      type='bold'
                      transform='uppercase'
                      color='keepoalaGreendefault'
                    >
                      {userKeepoalas} KEEPOALAS
                    </InterText>
                  </div>
                  <div>
                    <InterStyled interStyle='P2'>
                      +{userKeepoalasWaiting} {t('optional')}
                    </InterStyled>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}

      {/* Main view displaying vouchers */}
      {selectedVoucher === undefined && (
        <div>
          {/* Top bar of voucher containing details and filters */}
          <Grid
            container
            style={{ marginTop: '30px', marginBottom: '20px' }}
            alignItems='center'
          >
            {/* Voucher Filters Menu open/close button */}
            <Grid item xs='auto'>
              <IconButton
                aria-label='show more'
                aria-controls={filterMenuId}
                aria-haspopup='true'
                onClick={
                  !isFilterMenuOpen
                    ? handleFilterMenuOpen
                    : handleFilterMenuClose
                }
                color='inherit'
                style={{ marginRight: '10px' }}
              >
                {!isFilterMenuOpen ? (
                  <FilterListIcon
                    fontSize='large'
                    style={{ color: Colors.keepoala.keepoalaGreendefault }}
                  />
                ) : (
                  <CloseIcon
                    fontSize='large'
                    style={{ color: Colors.keepoala.keepoalaGreendefault }}
                  />
                )}
              </IconButton>
            </Grid>

            {/* Display voucher categories */}
            {categories.map((category) => {
              return (
                <Grid
                  item
                  xs='auto'
                  key={category.name}
                  style={{ padding: '10px 2px' }}
                >
                  <NewChip
                    sx={{
                      marginRight: '10px',
                      backgroundColor: category.active ? '#7FB2B1' : '#CFD1D1',
                      '&:hover': {
                        backgroundColor: category.active
                          ? '#7FB2B1'
                          : '#CFD1D1',
                      },
                      padding: '0px 8px',
                      height: '30px',
                    }}
                    deleteIcon={<CloseIcon />}
                    clickable={!category.active}
                    label={
                      <InterText
                        size='s'
                        type='medium'
                        transform='capitalize'
                        color={
                          category.active ? 'forestGreendefault' : 'mediumgrey'
                        }
                      >
                        {category.name}
                      </InterText>
                    }
                    onClick={
                      !category.active
                        ? (e) => {
                            e.preventDefault();
                            handleCategoryChange(category.name);
                          }
                        : null
                    }
                    onDelete={
                      category.active
                        ? (e) => {
                            e.preventDefault();
                            handleCategoryChange(category.name);
                          }
                        : null
                    }
                  />
                </Grid>
              );
            })}
          </Grid>

          {/* Mobile Menu */}
          <Menu
            anchorEl={filterAnchorEl}
            id={filterMenuId}
            keepMounted
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={isFilterMenuOpen}
            onClose={handleFilterMenuClose}
          >
            <VoucherStoreFilterMenu
              t={t}
              neukunde={neukunde}
              setNeukunde={setNeukunde}
              setMaxKeepoalas={setMaxKeepoalas}
            />
          </Menu>

          {/* Display all active vouchers selected by the filters */}
          <Grid container>
            {Object.entries(activeVouchers).map(([category, group]) => (
              <React.Fragment key={category}>
                {/* Print out the category */}
                <div style={{ marginTop: '25px', marginBottom: '25px' }}>
                  <InterStyled interStyle='H2' transform='uppercase'>
                    {category}
                  </InterStyled>
                </div>

                {/* Output the vouchers in the categories */}
                <ExpandedGrid
                  container
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='stretch'
                >
                  {group.map((voucher) => {
                    return (
                      <Grid
                        item
                        key={voucher.key}
                        xs='auto'
                        style={{ marginBottom: '20px' }}
                      >
                        <Voucher
                          voucher={voucher}
                          deactivate={voucher.keepoalas > userKeepoalas}
                          loadbar={userKeepoalas / voucher.keepoalas}
                          handler={handleVoucherClick}
                        />
                      </Grid>
                    );
                  })}
                </ExpandedGrid>
              </React.Fragment>
            ))}
          </Grid>
        </div>
      )}

      {/* Selected voucher detail view */}
      <Slide
        direction='left'
        in={selectedVoucher !== undefined}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <Button onClick={() => setSelectedVoucher(undefined)}>
            {t('back')}
          </Button>
          <VoucherDetail
            voucher={selectedVoucher}
            handleBuyVoucher={handleBuyVoucher}
          ></VoucherDetail>
        </div>
      </Slide>
    </MainDiv>
  );
}
