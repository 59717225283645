/* eslint import/no-webpack-loader-syntax: off */
import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Colors from '../utils/helper/Colors';
import InterText from '../styles/InterText';
import smallKeepoala from '!file-loader!../assets/img/smallKeepoala.svg';

/**
 *
 * Donut Chart for showing keepoalas
 * @param props
 */
export default function DonutChart(props) {
  // Check if keepoalas are negative then set to zero
  // Else set to the value
  const progress = props.keepoalas < 0 ? 0 : props.value;

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      {/* Grey Progress bar for second variant */}
      {props.keepoalas >= 0 && props.value < 100 && (
        <CircularProgress
          variant='determinate'
          value={100}
          sx={{
            color: Colors.keepoala.grey.light,
            strokeLinecap: 'round',
            position: 'absolute',
          }}
          size='75px'
          thickness={3}
        />
      )}

      <CircularProgress
        variant='determinate'
        value={progress}
        sx={{
          color: Colors.keepoala.keepoalaGreen.default,
          strokeLinecap: 'round',
        }}
        size='75px'
        thickness={3}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* The variant in which only the number is shown either negative or positive */}
        {/* When value is 100 or keepoalas is negative */}
        {(props.keepoalas < 0 || props.value === 100) && (
          <InterText color='keepoalaGreendefault' size='l' type='bold'>
            {props.value === 100 && props.keepoalas > 0 ? '+' : ''}
            {props.keepoalas}
          </InterText>
        )}

        {/* The variant in which the number of keepoalas is shown and the total number is shown as well */}
        {props.keepoalas >= 0 && props.value < 100 && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ textAlign: 'center' }}>
              <img
                style={{ height: '12px', width: '10px', marginRight: '4px' }}
                src={smallKeepoala}
                alt='Keepoala logo'
              />
              <InterText color='keepoalaGreendefault' size='l' type='bold'>
                {props.keepoalas}
              </InterText>
            </div>
            <InterText
              color='greytext'
              size='xxs'
              type='medium'
              style={{ opacity: '0.7', textAlign: 'center' }}
            >
              {props.t('of')} {props.totalKeepoalas}
            </InterText>
          </div>
        )}
      </Box>
    </Box>
  );
}
