/**
 * @category Theme
 */
const tintColorLight = '#2f8481';
/**
 * @category Theme
 */
// const tintColorDark = '#fff';

/**
 * Theme colors object to unify the colors across the app
 */
const Colors = {
  light: {
    text: '#000e1c',
    background: '#EFF2F2',
    tint: tintColorLight,
    tabIconDefault: '#2f8481',
    tabIconSelected: tintColorLight,
    color: '#000e1c',
    inactive: '#A3A3A3',
  },
  dark: {
    text: '#000e1c',
    background: '#EFF2F2',
    tint: tintColorLight,
    tabIconDefault: '#2f8481',
    tabIconSelected: tintColorLight,
    color: '#000e1c',
    inactive: '#A3A3A3',
  },
  keepoala: {
    main: '#2f8481',
    white: '#ffffff',
    light: '#dcffe6',
    bright: '#7deaa9',
    dark: '#204648',
    darktext: '#1A4B49',
    lightgrey: '#A3A3A3',
    mediumgrey: '#748080',
    greengrey: '#8f9b95',
    black: '#000e1c',
    transparent: '#ffffff00',
    greytext: '#515959',
    greydefault: '#B6BBBB',
    actionGreendefault: '#94E0A3',
    actionGreenlight: '#DEFFE5',
    actionGreendark: '#7DD29A',
    keepoalaGreendark: '#1E5957',
    forestGreendefault: '#204648',
    greyveryLight: '#F8F8F8',
    keepoalaGreendefault: '#2F8481',
    greymedium: '#748080',
    greylight: '#EFF2F2',
    grey: {
      light: '#EFF2F2',
      white: '#FFFFFF',
      defaultLight: '#CFD1D1',
    },
    keepoalaGreen: {
      default: '#2F8481',
      light: '#E3FCFC',
      default60: '#2F848199',
    },
    actionGreen: {},
    forestGreen: {
      dark: '#1A393B',
    },
  },
  google: {
    main: 'white',
  },
};

export default Colors;
