import React, { useState } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import Button from "../../styles/Button";
import { useTranslation } from "react-i18next";
import InterText from "../../styles/Inter";
import { label_price } from "./PaymentDecision";
import dhl_logo from "../../assets/img/dhl_logo.png";

export default function CheckoutForm(props: {
  label_info: label_price;
  item_number: number;
  order_id: string;
  email: string;
  shop: string;
}) {
  const stripe = useStripe();
  const elements = useElements();

  const { t } = useTranslation();

  const { item_number, label_info, shop, order_id, email } = props;

  const [error_message, setErrorMessage]: [string | null, any] = useState(null);

  const return_url =
    "http://" + window.location.host + `/paymentstatus?shop=${shop}&order_id=${order_id}&email=${email}`;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: return_url,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const labelTextGet = (item_number, parcel_weight) => {
    if (parcel_weight > 0) {
      return (
        <span>
          {item_number === 1
            ? t("It fits in a parcel with an approximate weight of")
            : t("They fit into a parcel with an approximate weight of")}{" "}
          <b>{label_info.parcel_weight.toFixed(1)} kg</b> {t("that goes to")}{" "}
        </span>
      );
    } else {
      return <span>{t("Your parcel will go to")}</span>;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <InterText size="l" align="center" mb={2}>
        {t("You will return")} {item_number} {item_number === 1 ? t("item") : t("items")}.{" "}
        {labelTextGet(item_number, label_info.parcel_weight)}
      </InterText>
      <InterText mt={0} size="l" weight={400} align="left" mb={2}>
        {label_info.to_address.split("\\n").map((x) => (
          <span>
            {x}
            <br />
          </span>
        ))}
      </InterText>
      <div style={{ display: "grid", gridTemplateColumns: "15% 10% 75%", marginBottom: "2em" }}>
        <div>
          <img src={dhl_logo} style={{ maxWidth: "100%" }} />
        </div>
        <div>&nbsp;</div>
        <div>
          <InterText align="left" size="l" mb={1}>
            {t("Carrier")}: <b>DHL / Post</b>
          </InterText>
          <InterText align="left" size="l">
            {t("Total Price")}:{" "}
            <b>
              {label_info.price.toFixed(2)} {label_info.currency}
            </b>
          </InterText>
        </div>
      </div>
      <div>
        <InterText size="m" mb={4}>
          <em>{t("Conditions")}:</em> {t("LABELCONDITIONS")}.<br />
          {t("You can find more information inside the")}{" "}
          <a
            href="https://www.dhl.de/dam/jcr:b2845bef-cd08-4139-a4a1-0a9253d0bfd1/dhl-agb-paket-express-national-de-052023.pdf"
            target="_blank">
            {t("Terms and conditions")} {t("of")} DHL.
          </a>
        </InterText>
      </div>
      <PaymentElement />
      <div style={{ height: 20 }}></div>
      {stripe && (
        <Button
          variant="contained"
          color="primary"
          mb={4}
          mt={2}
          ml={2}
          size="medium"
          id="submit"
          type="submit"
          value="Submit">
          {t("Pay")} {t("nowDE")} {label_info.price.toFixed(2)} {label_info.currency} {t("now")}
        </Button>
      )}
      {/* Show error message to your customers */}
      {error_message && <div>{error_message}</div>}
      <InterText size="m" align="center">
        {t("Your payment goes to")} <b>Keepoala GmbH</b>
      </InterText>
      <InterText size="m" align="center">
        <a href="/agb" target="_blank">
          {t("Terms and conditions")}
        </a>
        &nbsp;
        <a href="https://keepoala.com/impressum-keepoala-informationen-adresse-gmbh/" target="_blank">
          {t("Impressum")}
        </a>
        &nbsp;
      </InterText>
    </form>
  );
}
