function combine_y_m(year:number, month:number):string {
    return year.toString() + '-' + (month > 9 ? month.toString() : "0" + month.toString());
}
function split_y_m(year_month:string) {
  return year_month.split('-');
}
  
  export function get_upper_limit(
    history:{[key:string]: {value: number; possible_value: number}},
  ) {
    var history_arr:any[] = [];
    if (history !== undefined) {

      Object.keys(history).map((key:string) => 
        history_arr.push(history[key]?.possible_value)
      );
      var max = Math.max(...history_arr);
      if (max === Infinity || max === -Infinity) {
        return 0;
      } else {
        return max;
      }
    } else {
      return 0;
    }
  }
  export function aggregateHistory(
    unsparsed_history: {
      value: number;
      possible_value: number;
      month: string;
    }[],
  ): {value: number; possible_value: number} {
    return {
      value: unsparsed_history
        .map((dict) => {
          return dict.value;
        })
        .reduce((pv, cv) => pv + cv, 0),
      possible_value: unsparsed_history
        .map((dict) => {
          return dict.possible_value;
        })
        .reduce((pv, cv) => pv + cv, 0),
    };
  }
  
function cleanHistoryKeys(history: {[key: string]: {value: number; possible_value: number}}) {
  var history_arr:{[key: string]: {value: number; possible_value: number}} = {};
  Object.keys(history).map((key:string) => {

    let new_y_m = split_y_m(key).map((x) => (parseFloat(x)));
    let key_new = combine_y_m(new_y_m[0], new_y_m[1])
    history_arr[key_new] = history[key]
    return key
  });

  return history_arr;

}
  export default function unSparseHistory(
    history: {[key: string]: {value: number; possible_value: number}},
    start_month: string,
  ) {
    if (history === undefined) {
      history = {
        nokey: {
          value: 0,
          possible_value: 0,
        },
      };
    }

    history = cleanHistoryKeys(history);
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var year = dateObj.getUTCFullYear();
  
    var starting_year_num = parseInt(split_y_m(start_month)[0], 10);
    var starting_month_num = parseInt(split_y_m(start_month)[1], 10);
  
    var month_loop;
    var year_loop;
  
    var history_full: {
      [key: string]: {value: number; possible_value: number};
    } = {};
  
    for (year_loop = starting_year_num; year_loop < year + 1; year_loop++) {
      for (month_loop = 1; month_loop < 13; month_loop++) {
        if (year_loop === year) {
          if (month_loop <= month) {
            history_full[combine_y_m(year_loop, month_loop)] = {
              value: 0,
              possible_value: 0,
            };
          }
        } else {
          if (
            month_loop >= starting_month_num &&
            year_loop <= starting_year_num
          ) {
            history_full[combine_y_m(year_loop, month_loop)] = {
              value: 0,
              possible_value: 0,
            };
          } else if (year_loop > starting_year_num) {
            history_full[combine_y_m(year_loop, month_loop)] = {
              value: 0,
              possible_value: 0,
            };
          }
        }
      }
    }
    var new_history: {
      month: string;
      value: number;
      possible_value: number;
    }[] = [];

    

    Object.keys(history_full).map((key: string) => {
      history_full[key].value =
        history[key] === undefined ? 0 : history[key].value;
      history_full[key].possible_value =
        history[key] === undefined ? 0 : history[key].possible_value;
      new_history.push({
        month: key,
        value: history_full[key].value,
        possible_value: history_full[key].possible_value,
      });
      return null
    });
    return new_history;
  }
  