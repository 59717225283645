import hmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";
import getEndpointUrl from "../getEndpointUrl";

export async function shipping_label_purchase_get(email, payment_intent_id) {
  const queryParams = new URLSearchParams({
    payment_intent_id: payment_intent_id,
    email: email,
    // Add more parameters as needed
  });

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-requested-with": "application/json",
      "Keepoala-Cors-Header": "enabled",
      Signature: "sha256=" + Base64.stringify(hmacSHA256(payment_intent_id, process.env.REACT_APP_ENV_DHL_API_KEY)),
    },
  };
  return await fetch(getEndpointUrl() + `/shipping_label_purchase_get?${queryParams}`, requestOptions).then((value) => {
    if (value.status < 300) {
      return value.json();
    } else {
      return value.text().then((value_text) =>
        Promise.resolve({
          error: true,
          message: value_text,
        })
      );
    }
  });
}
