import styled from 'styled-components';
import Colors from '../utils/helper/Colors';
import Card from '@mui/material/Card';

const GreyBox = styled(Card)`
  background-color: ${Colors.keepoala.greyveryLight} !important;
  padding: 20px;
  padding-top: 28px;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.15) !important;
`;

export default GreyBox;
