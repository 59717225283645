import styled from "styled-components";
import { Link as MuiLink } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import Colors from '../utils/helper/Colors';
const ButtonPre = styled(MuiLink)`
&:hover{
    color: ${Colors.keepoala.main}
};
&:visited{
    color: ${Colors.keepoala.dark}
};
&:link{
    color: ${Colors.keepoala.main}
}
text-decoration: none;
`

const Link = styled(ButtonPre)(spacing)

export default Link