import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    FormControl,
    Input,
    InputLabel,
    Typography,
    Divider,
} from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { AuthContext } from "../utils/providers/AuthProvider";
import { useTranslation } from "react-i18next";
import Button from '../styles/Button'
import Link from '../styles/Link'
import TermsLinks from "./TermsLinks";
import Seperator from "./Seperator";
import SocialLogin from "./SocialLogin";

const useStyles = makeStyles({
    link: {
        margin: 5,
        textDecoration: "none",
    },
    linksWrapper: {
        display: "flex",
        marginTop: "auto",
    },
    myAlert: {
        marginTop: 15,
        width: "90%",
    },
    hide: {
        display: "none",
    },
    btn: {
        marginTop: 20,
    },
    forgotText: {
        marginTop: 10,
    },
    hidePassBtn: {
        width: 16,
        height: 16,
        margin: -8,
    },
    wrapper: {
        position: "absolute",
        top: "40%",
        marginTop: "5em",
        transform: "translateY(-40%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "50%",
    },
    wrap: {
        width: "50%",
        margin: "0 auto",
    },
    divider:{
        width:"100%",
        marginTop: "16px"
    }
});

function SignUp(){

    const classes = useStyles();

    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const { signUpWithEmail, authenticationError} = useContext(AuthContext);
    const [open, setOpen] = React.useState(false);
    const vertical = 'top';
    const horizontal = 'center';

    const {t, i18n} = useTranslation();

    useEffect(() => {
        if(authenticationError !== "empty"){
            setOpen(true);
        }            
        
    }, [authenticationError]);    

    const handleSubmit = (e:any) => {
        e.preventDefault(); 
        signUpWithEmail(email, password, username, i18n.language);
    };

    const handleUsernameChange = (e:any) => {
        setUsername(e.target.value);
    };

    const handleEmailChange = (e:any) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e:any) => {
        setPassword(e.target.value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event:any) => {
        event.preventDefault();
    };

    const handleClose = (event:any, reason:string) => {
        if (reason === 'clickaway') {
          return;
        }    
        setOpen(false);
      };

    return (
        <div className={classes.wrap}> 
        <div className={classes.wrapper}>    

            <Typography component="h2" variant="body1" align="center">
                {t('Registration')}
            </Typography>
            <form onSubmit={handleSubmit}>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="username">
                        {t('Username')}
                    </InputLabel>
                    <Input
                        id="username"
                        name="username"
                        value={username}
                        autoComplete="username"                        
                        onChange={handleUsernameChange}
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="email">
                        {t('Email Address')}
                    </InputLabel>
                    <Input
                        id="email"
                        name="email"
                        value={email}
                        autoComplete="email"                        
                        onChange={handleEmailChange}
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password">{t('Password')}</InputLabel>
                    <Input
                        name="password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        id="password"
                        autoComplete="current-password"
                        onChange={handlePasswordChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={
                                        handleMouseDownPassword
                                    }
                                >
                                    {showPassword ? (
                                        <Visibility
                                            className={
                                                classes.hidePassBtn
                                            }
                                        />
                                    ) : (
                                        <VisibilityOff
                                            className={
                                                classes.hidePassBtn
                                            }
                                        />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <Button
                    fullWidth={true}
                    variant="contained"
                    color="primary"
                    mb={2}
                    id="submit"
                    type="submit"
                    value="Submit"
                    className={classes.btn}
                    onClick={handleSubmit}
                    disabled={username === undefined || username === '' || email === undefined || email === '' || password === undefined || password === ''}
                >
                    {t('Sign up')}
                </Button>
                
                <Seperator>{t('or')}</Seperator>
                <SocialLogin />
            </form>

                <TermsLinks />
                <Divider className={classes.divider} variant="middle"/>
                <Typography
                    variant="body1"
                    component="h2"
                    align="center"
                    className={classes.forgotText}
                >
                    {t('Already have an account')},{" "}
                    <Link
                        href="/login"
                        style={{ textDecoration: "none" }}
                    >
                        {t('Login')}
                    </Link>
                </Typography>  
            
            
        </div>
            <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={6000} onClose={handleClose}> 
                <Alert onClose={(event) => handleClose(event, "error")} severity="error">
                    {authenticationError}
                </Alert>
            </Snackbar>
        </div>

);
}

export default SignUp;