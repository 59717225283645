import { Coloring } from "../../types";
import { get_return_label } from "./get_return_label";
import { shopify_get_label_price } from "./shopify_get_label_price";
import { shopify_get_pretty_shopname } from "./shopify_get_pretty_shopname";
import { shopify_get_shop_dhl_status } from "./shopify_get_shop_dhl_status";

export async function get_pretty_shopName(
  shop: string
): Promise<{ res: any; err: string }> {
  let err;
  const xx = await shopify_get_pretty_shopname(shop).catch((e: any) => {
    console.log(e);
  });

  if (xx !== undefined) {
    return xx
      .json()
      .then(async (res: { test: string }) => {
        const x = await res.test;
        return { res: x, err: "" };
      })
      .catch((e: any) => {
        err = "Problem connecting the Keepoala Server";
        return { res: undefined, err: err };
      });
  } else {
    return { res: undefined, err: "Problem connecting the Keepoala Server" };
  }
}

export type dhl_keep_days_special_single = {
  days: number;
  info: { de: string; en: string };
};

export type dhl_status_type = {
  test: boolean;
  support: { email: string; link: string | undefined } | undefined;
  coloring?: Coloring;
  no_label?: boolean;
  address?: string;
  order_hint?: string;
};

export async function get_shop_dhl_status(
  shop: string
): Promise<{ res: dhl_status_type | undefined; err: string }> {
  let err;
  const xx = await shopify_get_shop_dhl_status(shop).catch((e: any) => {
    console.log(e);
  });

  if (xx !== undefined) {
    return xx
      .json()
      .then(async (res: dhl_status_type) => {
        const x = await res;
        return { res: x, err: "" };
      })
      .catch((e: any) => {
        err = "Problem connecting the Keepoala Server";
        return { res: undefined, err: err };
      });
  } else {
    return { res: undefined, err: "Problem connecting the Keepoala Server" };
  }
}

export async function get_label_price(shop: string, id):Promise<{res:{
  price: number,
  textual_info: {
    [key: string]: string
  }} | undefined, err:string}> {
  let err;
  const xx = await shopify_get_label_price(shop, id).catch((e: any) => {
    console.log(e);
  });

  if (xx !== undefined) {
    return xx
    .json()
    .then(async (res: {
      price: number,
      textual_info: {
        [key: string]: string
      },
      error?: string
    }) => {
        const x = await res;
        if (x.error === undefined) {
          return {
            res: {
              price: x.price,
              textual_info: x.textual_info,
            },
            err: ''
          }
        } else {
          return {res: undefined, err: x.error};
        }
      })
      .catch((e: any) => {
        err = "Problem connecting the Keepoala Server";
        return { res: undefined, err: err };
      });
  } else {
    return { res: undefined, err: "Problem connecting the Keepoala Server" };
  }
}

export async function get_return_label_wrapper(
  shop: string,
  id: string
): Promise<{
  res:
    | {
        labelLink: string;
        labelData: string;
        order_id: string;
        shop: string;
        shop_logolink: string;
        qrCodeLink: string;
        tracking_number: string;
        email: string;
        carrier: string | undefined;
      }
    | undefined;
  err: string;
}> {
  let err;
  const xx = await get_return_label(shop, id).catch((e: any) => {
    console.log(e);
  });

  if (xx !== undefined) {
    if (xx.status === 401) {
      return xx
        .json()
        .then((res: { message: string }) => {
          return { res: undefined, err: res.message };
        })
        .catch(() => {
          return {
            res: undefined,
            err: "Contact the support at retourenportal@keepoala.com",
          };
        });
    } else {
      return xx
        .json()
        .then(
          async (
            res:
              | {
                  labelLink: string;
                  labelData: string;
                  order_id: string;
                  shop: string;
                  shop_logolink: string;
                  qrCodeLink: string;
                  tracking_number: string;
                  email: string;
                  message: string;
                  carrier: string | undefined;
                }
              | undefined
          ) => {
            if (res !== undefined) {
              if (res.message !== undefined) {
                if (xx.status === 200 || xx.status === 201) {
                  return { res: res, err: res.message };
                } else {
                  return { res: undefined, err: res.message };
                }
              } else {
                return { res: res, err: "" };
              }
            } else {
              return {
                res: undefined,
                err: "Problem connecting the Keepoala Server",
              };
            }
          }
        )
        .catch((e: any) => {
          err = "Problem connecting the Keepoala Server";
          return { res: undefined, err: err };
        });
    }
  } else {
    return { res: undefined, err: "Problem connecting the Keepoala Server" };
  }
}
