import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import InterText from '../styles/Inter';
import ArrowLeft from '../assets/img/arrowleft.png';
import ThankYouContent from './ThankYouContent';
import Button from '../styles/Button';
import { Link } from '@material-ui/core';

const FullWidth = styled.div`
  width: 100%;
`;
const Container = styled(FullWidth)`
  grid-template-columns: 10% 90%;
  grid-template-rows: 100px 300px;
  margin: auto;
  display: grid;
`;

const ArrowContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 2;
  & img {
    max-height: 120px;
    margin-top: 60px;
  }
`;

const RightBoxTop = styled.div`
  grid-column-start: 2;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 1;
`;
const RightBoxBtn = styled.div`
  grid-column-start: 2;
  grid-row-start: 2;
  grid-column-end: 2;
  grid-row-end: 2;
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export default function ThankYouLinkKeepoala(props: {
  shop: string;
  noarrow?: boolean;
  isUser?: boolean;
  extraLinkAddress?: string;
}) {
  const { t } = useTranslation();
  return (
    <Container>
      <ArrowContainer>
        <img src={ArrowLeft} alt='arrow keepoala' />
      </ArrowContainer>
      <RightBoxTop>
        <InterText align='center' size='l' weight={600}>
          {t('Thank you for making')} {props.shop} {t('better')}
        </InterText>
      </RightBoxTop>
      <RightBoxBtn>
        {props.isUser !== undefined && props.isUser ? (
          <>
            <Link href='/home'>
              <Button color='primary'>{t('back to Home')}</Button>
            </Link>
            {props.extraLinkAddress === 'returnmanager' ? (
              <Link href='/home'>
                <Button color='primary'>{t('Return Manager')}</Button>
              </Link>
            ) : props.extraLinkAddress === 'orderfeedbackoverview' ? (
              <Link href='/home'>
                <Button color='primary'>{t('Order Feedback Overview')}</Button>
              </Link>
            ) : null}
          </>
        ) : (
          <ThankYouContent />
        )}
      </RightBoxBtn>
    </Container>
  );
}
