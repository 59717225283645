import * as React from 'react';
import MuiSlider from '@mui/material/Slider';
import Colors from '../utils/helper/Colors';
import styled from 'styled-components';

const Slider = styled(MuiSlider)`
  color: ${Colors.keepoala.keepoalaGreendefault} !important;

  & .MuiSlider-thumbColorPrimary {
    color: ${Colors.keepoala.keepoalaGreendefault};
  }
  & .MuiSlider-markLabel {
    font-size: 10px;
    top: 30px;
  }
`;

const marks = [
  {
    value: 0,
    calcValue: 0,
    label: '0',
  },
  {
    calcValue: 20,
    value: 1,
    label: '20',
  },
  {
    calcValue: 100,
    value: 2,
    label: '100',
  },
  {
    calcValue: 500,
    value: 3,
    label: '500',
  },
  {
    calcValue: 1000,
    value: 4,
    label: '1000',
  },
  {
    value: 5,
    calcValue: 5000,
    label: '>1000',
  },
];

function calculateValue(value) {
  return marks.filter((x) => x.value === value)[0].calcValue;
}

function valuetext(value) {
  const filtered_marks = marks.filter((x) => x.calcValue === parseFloat(value));
  if (filtered_marks.length > 0) {
    return filtered_marks[0].label;
  } else {
    return value;
  }
}

export default function DiscreteSlider(props) {
  return (
    <Slider
      sx={{ width: '100%', colorPrimary: Colors.keepoala.keepoalaGreendefault }}
      aria-label='Restricted values'
      defaultValue={20}
      step={null}
      size='small'
      valueLabelDisplay='auto'
      marks={marks}
      valueLabelFormat={valuetext}
      max={5}
      scale={calculateValue}
      onChange={(event, newValue) => {
        if (typeof newValue === 'number') {
          props.handleChange(calculateValue(newValue));
        }
      }}
    />
  );
}
