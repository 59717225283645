import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

export default function get_signature(value, key) {

  const key_internal = key ?? process.env.REACT_APP_G_CLOUD_KEY

  return `sha256=${
    Base64.stringify(
      hmacSHA256(value, key_internal),
    )
  }`
}