import React from 'react';
import AppRoutes from './utils/router/Router';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import Footer from './components/Footer';
import ToastContainer from './components/ToastContainter';
import Colors from './utils/helper/Colors';
import './assets/fonts/index.css';
import './assets/styles/style.css';
require('typeface-lato');

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: Colors.keepoala.main,
      },
      secondary: {
        main: Colors.keepoala.dark,
      },
    },
  });

  return (
    <>
      <style>{'body { margin:0}'}</style>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider>
      <Footer />
    </>
  );
}

export default App;
