/* eslint import/no-webpack-loader-syntax: off */
import React, { useContext } from 'react';
import {ThemeProvider} from 'styled-components';
import { Container, Toolbar, Box, IconButton } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { AuthContext } from '../utils/providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import AppBar from '../styles/AppBar';
import Logo from '!file-loader!../assets/img/Keepoala.svg';
import { makeStyles } from '@material-ui/core/styles';
import ALink from '../styles/ALink';
import MUIRouterLink from '../styles/MUIRouterLink';
import Colors from '../utils/helper/Colors';
import QRCodeLogin from './QRCodeLogin';
import InterStyled from '../styles/InterStyled';
import NavigationMenuMobile from './NavigationMenuMobile';
import Button from '../styles/Button';
import split_query from '../utils/helper/split_query';
import { useLocation } from 'react-router-dom';
import { getThemeByShop } from '../utils/helper/Themes';


// Custom CSS for the components
const useStyles = makeStyles((theme) => ({
  toolbar: {
    minHeight: '80px',
    [theme.breakpoints.down('md')]: {
      minHeight: '64px',
    },
    paddingRight: '0px',
    paddingLeft: '0px',
  },
  logo: {
    maxWidth: '140px',
    height: '29px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
  },
  logo_img: {
    maxWidth: '140px',
    width: '100%',
    // test
  },
  flex: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
    },
  },
  sectionDesktopInner: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  menulink: {
    letterSpacing: '1px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  menulinkText: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  langlink: {
    marginLeft: '24px',
    marginRight: '24px',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
  },
}));

export default function NavigationBar() {
  const { search } = useLocation();
  const shop = split_query(search)['shop'];
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { user, handleSignOut } = useContext(AuthContext);

  const handleLogout = () => {
    handleSignOut();
  };

  const loginIcon =
    user != null ? (
      <MUIRouterLink to='/overview'>
        <IconButton color='inherit'>
          <AccountCircleIcon style={{ color: Colors.keepoala.greytext }} />
        </IconButton>
      </MUIRouterLink>
    ) : (
      <MUIRouterLink to='/login'>
        <ThemeProvider theme={getThemeByShop(shop, undefined)}>
          <Button style={{ width: '196px' }} color='primary'>
            {t('Login')}
          </Button>
        </ThemeProvider>
      </MUIRouterLink>
    );
  const qrlog = user != null ? <QRCodeLogin /> : null;

  const logoutText =
    user != null ? (
      <IconButton onClick={handleLogout} color='inherit'>
        <LogoutIcon style={{ color: Colors.keepoala.greytext }} />
      </IconButton>
    ) : null;

  /**
   * Main Navigation bar for the App
   */
  return (
    <AppBar
      position='sticky'
      style={{
        zIndex: 1400,
      }}
    >
      <Toolbar className={classes.toolbar}>
        <Container maxWidth='xl'>
          <Box flexDirection='row' className={classes.flex}>
            {/* Company Logo */}
            <MUIRouterLink to='/home' className={classes.logo}>
              <img src={Logo} alt='Keepoala' className={classes.logo_img} />
            </MUIRouterLink>

            {/* Desktop Navigation Menu */}
            <div className={classes.sectionDesktop}>
              {/* Logged out Users Menu */}
              {user == null ? (
                <div className={classes.sectionDesktopInner}>
                  <ALink
                    href='https://keepoala.com/keepoala-app-dein-digitaler-begleiter-fuers-onlineshopping/'
                    target='_blank'
                    className={classes.menulink}
                  >
                    <InterStyled
                      interStyle='MenuLink'
                      className={classes.menulinkText}
                    >
                      {t('App')}
                    </InterStyled>
                  </ALink>

                  <ALink
                    href='https://keepoala.com/werde-partner-beim-retourenexperten-und-reduziere-deine-retourenquote/'
                    target='_blank'
                    className={classes.menulink}
                  >
                    <InterStyled
                      interStyle='MenuLink'
                      className={classes.menulinkText}
                    >
                      {t('Partner')}
                    </InterStyled>
                  </ALink>

                  <ALink
                    href='https://www.keepoala.com/jobs'
                    target='_blank'
                    className={classes.menulink}
                  >
                    <InterStyled
                      interStyle='MenuLink'
                      className={classes.menulinkText}
                    >
                      {t('Jobs')}
                    </InterStyled>
                  </ALink>

                  <ALink
                    href='https://www.keepoala.com/blog'
                    target='_blank'
                    className={classes.menulink}
                  >
                    <InterStyled
                      interStyle='MenuLink'
                      className={classes.menulinkText}
                    >
                      {t('Blog')}
                    </InterStyled>
                  </ALink>
                </div>
              ) : (
                // Logged In Users Menu
                [
                  <MUIRouterLink
                    to='/'
                    className={classes.menulink}
                    key='overview'
                  >
                    <InterStyled
                      interStyle='MenuLink'
                      className={classes.menulinkText}
                    >
                      {t('Overview')}
                    </InterStyled>
                  </MUIRouterLink>,
                  <MUIRouterLink
                    to='/voucher'
                    className={classes.menulink}
                    key='voucher'
                  >
                    <InterStyled
                      interStyle='MenuLink'
                      className={classes.menulinkText}
                    >
                      {t('Voucher')}
                    </InterStyled>
                  </MUIRouterLink>,
                ]
              )}

              {/* Language change button */}
              <div className={classes.langlink}>
                <InterStyled
                  interStyle={
                    i18n.language === 'en' ? 'Button' : 'DisabledButton'
                  }
                  onClick={() =>
                    i18n.changeLanguage(i18n.language === 'en' ? 'de' : 'en')
                  }
                >
                  {t('DE')}
                </InterStyled>

                <span
                  style={{
                    fontSize: '22px',
                    color: Colors.keepoala.greydefault,
                  }}
                >
                  |
                </span>

                <InterStyled
                  interStyle={
                    i18n.language === 'de' ? 'Button' : 'DisabledButton'
                  }
                  onClick={() =>
                    i18n.changeLanguage(i18n.language === 'en' ? 'de' : 'en')
                  }
                >
                  {t('EN')}
                </InterStyled>
              </div>

              {/* Mobile QR code for logged in users */}
              {qrlog}

              {/* Login Button for signed out users and profile button for signed in users */}
              {loginIcon}

              {/* Log out button for signed in users */}
              {logoutText}
            </div>

            {/* Mobile Menu */}
            <NavigationMenuMobile />
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
}
