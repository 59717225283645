import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function JourneyRedirect() {
  const location = useLocation();
  const navigate = useNavigate();
  // Redirect to home from journey to match the app
  useEffect(() => {
    navigate(`/${location.search}`);
  }, [location.search, navigate]);
  return <div></div>;
}
