import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../utils/providers/AuthProvider';
import 'react-toastify/dist/ReactToastify.css';
import { db } from '../firebase';
import Order from './Order';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import LoadingCircular from './LoadingCircular';
import { Container } from '@material-ui/core';
import i18n from '../i18n/i18napp';
import InterStyled from '../styles/InterStyled';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Navigate } from 'react-router-dom';
import splitOrdersByMonth from '../utils/helper/splitOrdersByMonth';
// import { analytics } from '../firebase';

const Styleddiv = styled.div`
  background-color: transparent;
`;
const Divspacing = styled(Styleddiv)(spacing);

const WhiteBox = styled(Card)`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    20px 20px 50px 50px rgba(255, 255, 255, 0.8) !important;
  border-radius: 5px !important;
  padding: 20px;
`;

function OrderFeedbackOverview() {
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [apicrawled, setAPICrawled] = useState(false);
  //   const [loading, setLoading] = useState(true);

  function setsubscriber(mounted) {
    return db
      .collection('ShopsWithDHL')
      .get()
      .then((shops) => {
        let all_dhl_shops = [];
        shops.forEach((shop) => {
          const shop_data = shop.data();
          console.log(shop_data);
          all_dhl_shops.push(shop_data.name);
        });
        console.log(all_dhl_shops);
        return (
          db
            .collection('Orders')
            .orderBy('datenum', 'desc')
            // without the where clause this will not get any data
            .where('user_id', '==', user.uid)
            .onSnapshot(
              (querySnapshot) => {
                console.log('Getting orders');
                const orderlist = [];
                // push the data to the dictionary
                querySnapshot.forEach((documentSnapshot) => {
                  // Only add orders that have
                  // 1- Orders with shopify.line_items which are kept and “product_questions === false”
                  const condition_1 =
                    documentSnapshot.data().keep === true &&
                    documentSnapshot.data().shopify?.line_items?.length !== 0 &&
                    documentSnapshot.data().product_questions === false;
                  // 2- any Order that is returned and does NOT  contain “return_questions==true”
                  const condition_2 =
                    documentSnapshot.data().returned === true &&
                    documentSnapshot.data().return_questions !== true;
                  if (condition_1 || condition_2) {
                    orderlist.push({
                      ...documentSnapshot.data(),
                      key: documentSnapshot.id,
                    });
                  }
                });

                orderlist.forEach(function (part, index) {
                  this[index].is_dhl = all_dhl_shops.includes(this[index].shop);
                }, orderlist); // use arr as this

                // Check if this is mounted before setting anything
                if (mounted) {
                  // Split the orders according to their date
                  const monthSplitedOrders = splitOrdersByMonth(orderlist);
                  setOrders(monthSplitedOrders);
                  setAPICrawled(true);
                  //   setLoading(false);
                  if (orderlist.length > 0) {
                    // toast.info(t('Orders updated'))
                    orderlist.splice(0, orderlist.length);
                  }
                }
              },
              (err) => {
                console.log(`Encountered error: ${err}`);
              },
            )
        );
      });
  }

  useEffect(() => {
    var mounted = true;
    // Use a FireStore subscription
    // to derive the data from FireBase/FireStore
    setsubscriber(mounted);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  //   useEffect(() => {
  //     // Send GA pageview
  //     if (!ourusers.includes(user.uid)) {
  //       analytics.logEvent('screen_view', {
  //         firebase_screen: 'Journey',
  //         firebase_screen_class: 'WebView',
  //       });
  //     }
  //   }, [user.uid]);

  return (
    <div>
      <style>
        {'body { background: #F2F2F2; background-repeat: no-repeat; margin:0}'}
      </style>

      <div style={{ marginTop: '50px' }}>
        <Container size='md'>
          {/* Page title */}
          <div style={{ marginLeft: '30px' }}>
            <InterStyled interStyle='H2' color='forestGreendefault'>
              {t('ORDERFEEDBACK')}
            </InterStyled>
          </div>

          {/* Your opinion card */}
          <WhiteBox style={{ marginLeft: '30px', marginTop: '30px' }}>
            <div>
              <InterStyled interStyle='H3' color='greytext'>
                {t('Your Opinion Counts')}
              </InterStyled>
            </div>
            <div style={{ marginTop: '10px' }}>
              <InterStyled
                interStyle='P1'
                size='s'
                color='greytext'
                type='medium'
              >
                {t(
                  'Keepoala helps shops to improve products and product descriptions to reduce returns before they occur',
                )}
                . {t('Please help us on this way')}.
              </InterStyled>
            </div>
            <div style={{ marginTop: '20px' }}>
              <InterStyled
                interStyle='P1'
                size='s'
                color='greytext'
                type='medium'
              >
                {t(
                  'Here are all orders where you can still tell us what you liked or disliked',
                )}
                .
              </InterStyled>
            </div>
          </WhiteBox>

          {/* Orders is an array of arrays each array has a group of orders grouped by their month */}
          {orders.map((group, outerIndex) => (
            <React.Fragment key={outerIndex}>
              <div style={{ marginLeft: '30px', marginTop: '40px' }}>
                <InterStyled InterStyle='Label 1'>
                  {/* Output date according to currect locale either english or german */}
                  {new Date(group[0].date).toLocaleDateString(
                    i18n.language === 'en' ? 'en-EN' : 'de-DE',
                    {
                      month: 'long',
                      year: 'numeric',
                    },
                  )}
                </InterStyled>
              </div>

              <Grid
                container
                columnSpacing={{ xs: 1, md: 4 }}
                ml={1}
                mt={4}
                mb={8}
                sx={{ width: '100% !important', marginLeft: '0px !important' }}
              >
                {group.map((order, index) => (
                  <Grid item xs={12} md={6} key={index} mb={4}>
                    <Order
                      key={order.id}
                      order_item={order}
                      t={t}
                      IsOrderFeedbackOverview={true}
                    />
                  </Grid>
                ))}
              </Grid>
            </React.Fragment>
          ))}

          {orders.length === 0 && apicrawled ? (
            <Navigate to='/' />
          ) : orders.length === 0 ? (
            <LoadingCircular />
          ) : null}
        </Container>
      </div>
      <Divspacing mt={8}></Divspacing>
    </div>
  );
}

export default OrderFeedbackOverview;
