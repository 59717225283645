import React, { useEffect, useState, useContext } from 'react';
import firebase from '../firebase';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../utils/providers/AuthProvider';
import styled from 'styled-components';
import Button from '../styles/Button';

import { Alert as MuiAlert } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import { spacing } from '@material-ui/system';
import { default as NormalGreyBox } from './GreyBox';
import InterText from '../styles/InterText';
import InterStyled from '../styles/InterStyled';

const Alert = styled(MuiAlert)(spacing);
const GreyBox = styled(NormalGreyBox)(spacing);
const DivSpacing = styled.div(spacing);

/**
 * A return Portal for specific shop for logged in users
 *
 * Show a user only the orders they have from a specific shop
 * and allow them to return those, by selecting an order
 *
 * @param props
 *  * shop - Shop which orders to show
 *  * callback - Calls back to ReturnFeedback to set the right order
 *    there and continue the return process
 * @returns
 */
export default function ReturnFeedbackItemCheck(props: {
  shop: string;
  callback: (order_id: string, shop: string, name: string, email: string) => void;
}) {
  const db = firebase.firestore();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const [isUser, setIsUser] = useState(false);
  const [shop_orders, setShopOrders] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (user !== null && user !== undefined && user.uid !== undefined) {
      setIsUser(true);
      setError('');

      // Derive orders from this shop for the user
      db.collection('Orders')
        .where('shop', '==', props.shop)
        .where('user_id', '==', user.uid)
        .get()
        .then((snapshot: any) => {
          if (snapshot.size === 0) {
            setError(t('You do not have any order from') + ' ' + props.shop);
          } else {
            setError('');
            const current_orders: any = [];
            snapshot.forEach((doc: any) => {
              const doc_data = doc.data();
              if (
                doc_data.returned !== undefined &&
                doc_data.returned === false
              ) {
                const push_data = {
                  date: doc_data.date,
                  ...doc_data.shopify,
                };
                current_orders.push(push_data);
              }
            });
            setShopOrders(current_orders);
          }
        });
    } else {
      setError(t('Please Login'));
      setIsUser(false);
    }
  }, [user, db, props.shop, t]);

  return (
    <div>
      <InterStyled interStyle='H2'>
        {t('Which order do you wish to return') + '?'}
      </InterStyled>

      {error !== undefined && error !== '' ? (
        <Alert mb={4} mt={4} severity='error'>
          {error}
        </Alert>
      ) : (
        <DivSpacing mt={4} mb={4}>
          {shop_orders === undefined || shop_orders.length === 0 || !isUser ? (
            <CircularProgress />
          ) : (
            // Show each order as a GreyBox with a button to return it
            shop_orders.map(
              (
                order:
                  | { id: string; name: string; shop: string; date: string, email: string }
                  | undefined,
              ) => {
                if (order === undefined) {
                  return null;
                } else {
                  return (
                    <GreyBox key={order.id} mb={2}>
                      {/* Upper Section of the Order card */}
                      {/* Name, Date and Chart */}
                      <Grid
                        container
                        sx={{ marginBottom: '15px' }}
                        justifyContent='center'
                        alignItems='center'
                        columnSpacing={1}
                      >
                        {/* Name and Date */}
                        <Grid item xs>
                          <div>
                            <InterText
                              size='xl'
                              type='medium'
                              color='greytext'
                              transform='uppercase'
                            >
                              {order.name.replace(/ - /g, '\u000A')}
                            </InterText>
                          </div>
                          <div style={{ marginTop: '10px' }}>
                            {/* Change the date format */}
                            <InterStyled interStyle='P2'>
                              {new Date(order.date)
                                .toLocaleDateString('en-GB')
                                .replace(/\//g, '.')}
                            </InterStyled>
                          </div>
                        </Grid>
                        <Grid item xs='auto'>
                          <Button
                            variant='contained'
                            color='primary'
                            mb={2}
                            size='medium'
                            onClick={() => {
                              props.callback(
                                order.id,
                                props.shop,
                                order.name,
                                order.email,
                              );
                            }}
                          >
                            {t('Return this')}
                          </Button>
                        </Grid>
                      </Grid>
                    </GreyBox>
                  );
                }
              },
            )
          )}
        </DivSpacing>
      )}
      <InterStyled interStyle='H3'>
        {t('Order not found') + " ? " + t('Log out to use the standard return portal') + '.'}
      </InterStyled>
    </div>
  );
}
