import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

export async function shop_return_experience_rater(body) {
  const req_body = JSON.stringify(body);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'application/json',
      'Keepoala-Cors-Header': 'enabled',
      Signature:
        'sha256=' +
        Base64.stringify(
          hmacSHA256(req_body, process.env.REACT_APP_G_CLOUD_KEY),
        ),
    },
    body: req_body,
  };
  return await fetch(
    (window.location.hostname === 'localhost'
      ? `http://localhost:8100/europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net:443`
      : 'https://' +
        (process.env.REACT_APP_HOST !== undefined
          ? process.env.REACT_APP_HOST
          : 'app.keepoala.com') +
        '/europe-west1-' +
        process.env.REACT_APP_PROJECT_ID +
        '.cloudfunctions.net:443') + '/shop_return_experience_rater',
    requestOptions,
  );
}
