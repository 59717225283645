import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';

/**
 * MUI Link that acts as a React router Link for
 * Routing inside the App
 * @param {*} props
 * @returns
 */
export default function MUIRouterLink(props) {
  const { children, to, className, style } = props;
  return (
    <Link
      component={RouterLink}
      className={className}
      style={{ textDecoration: 'none', ...style }}
      to={to}
    >
      {children}
    </Link>
  );
}
