import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { create_payment } from "../../utils/helper/payment/create_payment";
import LoadingCircular from "../LoadingCircular";
import AlertError from "../Alert/AlertError";
import customerEmailCheck from "../../utils/helper/payment/customerEmailCheck";
import EmailChangeForm from "../Label/EmailChangeForm";
import { useTranslation } from "react-i18next";
import Button from "../../styles/Button";
import InterText from "../../styles/Inter";
import styled from "styled-components";
import { label_price } from "./PaymentDecision";

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

export default function PaymentView(props: {
  shop: string;
  order_id: string;
  email: string;
  label_info: label_price;
  item_number: number;
}) {
  const { shop, order_id, label_info, email, item_number } = props;

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [options, setOptions]: [any, any] = useState(true);
  const [hasCustomersEmail, setHasCustomersEmail] = useState(true);
  const [newEmail, setNewEmail] = useState(email);

  const stripe = useStripe();
  const { t } = useTranslation();

  useEffect(() => {
    setHasCustomersEmail(customerEmailCheck(email));
    // Set the email such that it is available in PaymentStatus view
    // to query Backend
    localStorage.setItem("email_label", email);
  }, [email]);

  useEffect(() => {
    setHasCustomersEmail(customerEmailCheck(newEmail));
    // Set the email such that it is available in PaymentStatus view
    // to query Backend
    localStorage.setItem("email_label", newEmail);
  }, [newEmail]);

  useEffect(() => {
    if (!hasCustomersEmail) {
      const body = {
        // transform price to cents (For Stripe)
        amount: label_info.price * 100,
        currency: label_info.currency,
        shop: props.shop,
        order_id: props.order_id,
        email: newEmail,
      };

      create_payment(body).then((response) => {
        response
          .json()
          .then((data) => {
            if (data.clientSecret !== undefined) {
              setOptions({
                clientSecret: data.clientSecret,
                appearance: {},
              });
              setLoading(false);
              setErrorMessage("");
            }
          })
          .catch((e) => {
            setErrorMessage(e);
            setLoading(false);
          });
      });
    }
  }, [hasCustomersEmail, newEmail, label_info, props.shop, props.order_id]);

  return (
    <Container maxWidth="sm" style={{ textAlign: "center" }}>
      {hasCustomersEmail ? (
        <>
          <EmailChangeForm
            shop={shop}
            order_id={order_id}
            helptext={
              t("We do not have any email address from you, yet") +
              ". " +
              t("To receive an invoice, please provide one first")
            }
            setNewEmail={(x: string) => {
              setNewEmail(x);
            }}
            type="just_order"
            product_feedback={[]}
          />
          <br />
          <FlexRow>
            <Button
              variant="contained"
              color="primary"
              mb={2}
              mt={2}
              ml={2}
              size="medium"
              onClick={() => setHasCustomersEmail(false)}>
              {t("Skip")}
            </Button>
            <InterText size="m">{t("You will not receive a valid email receipt")}!</InterText>
          </FlexRow>
        </>
      ) : loading ? (
        <LoadingCircular />
      ) : errorMessage === "" ? (
        <Elements stripe={stripe} options={options}>
          <CheckoutForm
            item_number={item_number}
            label_info={label_info}
            email={newEmail}
            order_id={props.order_id}
            shop={props.shop}
          />
        </Elements>
      ) : (
        <AlertError errorMessage={errorMessage} />
      )}
    </Container>
  );
}
