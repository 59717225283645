/* eslint import/no-webpack-loader-syntax: off */
import React from 'react';
import styled from 'styled-components';
import smallKeepoalagrey from '../../assets/img/smallKeepoalagrey.svg';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import InterStyled from '../../styles/InterStyled';

const WhiteBox = styled(Card)`
  background-color: #ffffff !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px;
  margin-right: 25px;
  width: 230px;
  opacity: ${(props) => (props.$deactivate ? 0.25 : 1)};
`;

export default function Voucher(props) {
  const { voucher, deactivate, handler } = props;

  return (
    <WhiteBox $deactivate={deactivate}>
      <CardActionArea onClick={() => handler(voucher)}>
        <div style={{ padding: '10px 20px' }}>
          {/* Voucher heading */}
          <div
            style={{
              marginTop: '10px',
              marginBottom: '10px',
              textAlign: 'center',
            }}
          >
            <InterStyled interStyle='H3'>{voucher.heading1}</InterStyled>
          </div>

          {/* Voucher Partner logo */}
          <div
            style={{
              margin: '40px auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '40px',
              width: '150px',
            }}
          >
            <img
              src={voucher.partner?.logo}
              style={{ maxWidth: '150px', maxHeight: '40px' }}
              alt='partner logo'
            />
          </div>
        </div>

        {/* Lower section */}
        <div style={{ padding: '6px 8px 8px', backgroundColor: '#F8F8F8' }}>
          {/* Set a height equal to 2 lines height of text */}
          <div style={{ height: '32px' }}>
            <InterStyled interStyle='P3'>{voucher.heading2}</InterStyled>
          </div>

          <div
            style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}
          >
            <img
              src={smallKeepoalagrey}
              style={{ marginRight: '6px', width: '15px', height: '20px' }}
              alt='keepoala grey logo'
            />
            <InterStyled interStyle='H2' color='greytext'>
              {voucher.keepoalas}
            </InterStyled>
          </div>
        </div>
      </CardActionArea>
    </WhiteBox>
  );
}
