import * as React from 'react';
import Button from '@mui/material/Button';
import Colors from '../utils/helper/Colors';
import InterStyled from '../styles/InterStyled';

/**
 * Renders an item with either button or plain text and a line above it
 * @param {*} props
 * @returns
 */
export default function LabelWithLineBtn(props) {
  const { children, className, style, align, type, ...otherProps } = props;
  return (
    //  Div that has the line
    <div
      style={{
        borderTop: `0.5px solid ${Colors.keepoala.greydefault}`,
      }}
    >
      {/* Button  */}
      {type === 'btn' && (
        <Button
          className={className}
          style={{ ...style, height: '45px', width: '100%', textAlign: align }}
          {...otherProps}
        >
          <InterStyled
            interStyle='P1'
            type='semi-bold'
            color='keepoalaGreendefault'
          >
            {children}
          </InterStyled>
        </Button>
      )}

      {/* Text */}
      {type === 'text' && (
        <div
          className={className}
          style={{
            ...style,
            width: '100%',
            textAlign: align,
            padding: '10px',
            paddingLeft: '0px',
          }}
        >
          <InterStyled interStyle='P1' type='medium' color='greytext'>
            {children}
          </InterStyled>
        </div>
      )}
    </div>
  );
}
