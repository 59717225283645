import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../utils/providers/AuthProvider';
import 'react-toastify/dist/ReactToastify.css';
import { db } from '../firebase';
import Order from './Order';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import LoadingCircular from './LoadingCircular';
import { Container } from '@material-ui/core';
import i18n from '../i18n/i18napp';
import InterStyled from '../styles/InterStyled';
import Grid from '@mui/material/Grid';
import FilterListIcon from '@mui/icons-material/FilterList';
import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import splitOrdersByMonth from '../utils/helper/splitOrdersByMonth';

const FilterButton = styled(Button)`
  padding: 6px 6px 6px 0px !important;
  width: 180px !important;
  justify-content: start !important;
`;
function ReturnManager() {
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [apicrawled, setAPICrawled] = useState(false);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [chosenFilter, setChosenFilter] = useState('returnpossible');

  // Helper Function
  // Filters the Orders according to the chosenFilter
  function filterOrders(orderList, filter) {
    const filteredOrders = [];

    orderList.forEach((order) => {
      if (filter === 'returnpossible') {
        if (order.keep !== true && order.returned !== true) {
          filteredOrders.push(order);
        }
      } else if (filter === 'answerquestions') {
        if (order.returned === true && order.return_questions !== true) {
          filteredOrders.push(order);
        }
      } else if (filter === 'returned') {
        if (order.returnLabel !== undefined) {
          filteredOrders.push(order);
        }
      }
    });

    console.log(filteredOrders);

    return filteredOrders;
  }

  // Change filter and call filterOrders to change orders thar are visible
  function changeChosenFilter(filter) {
    // Change chosen filter
    setChosenFilter(filter);

    // Filter Orders
    const FilteredOrdersTemp = filterOrders(orders, filter);

    // Split the orders according to their date
    const monthSplitedOrders = splitOrdersByMonth(FilteredOrdersTemp);

    // Change the orders visible
    setFilteredOrders(monthSplitedOrders);
  }

  function setsubscriber(mounted) {
    return db
      .collection('ShopsWithDHL')
      .get()
      .then((shops) => {
        let all_dhl_shops = [];
        shops.forEach((shop) => {
          const shop_data = shop.data();
          all_dhl_shops.push(shop_data.name);
        });
        return (
          db
            .collection('Orders')
            .orderBy('datenum', 'desc')
            // without the where clause this will not get any data
            .where('user_id', '==', user.uid)
            .onSnapshot(
              (querySnapshot) => {
                const orderlist = [];
                // push the data to the dictionary
                querySnapshot.forEach((documentSnapshot) => {
                  orderlist.push({
                    ...documentSnapshot.data(),
                    key: documentSnapshot.id,
                  });
                });

                orderlist.forEach(function (part, index) {
                  this[index].is_dhl = all_dhl_shops.includes(this[index].shop);
                }, orderlist); // use arr as this

                // Check if this is mounted before setting anything
                if (mounted) {
                  // Set orders that contain all of them
                  setOrders([...orderlist]);

                  // Filter Orders
                  const FilteredOrdersTemp = filterOrders(
                    orderlist,
                    chosenFilter,
                  );

                  // Split the orders according to their date
                  const monthSplitedOrders =
                    splitOrdersByMonth(FilteredOrdersTemp);

                  setFilteredOrders(monthSplitedOrders);
                  setAPICrawled(true);
                  //   setLoading(false);
                  if (orderlist.length > 0) {
                    // toast.info(t('Orders updated'))
                    orderlist.splice(0, orderlist.length);
                  }
                }
              },
              (err) => {
                console.log(`Encountered error: ${err}`);
              },
            )
        );
      });
  }

  useEffect(() => {
    var mounted = true;
    // Use a FireStore subscription
    // to derive the data from FireBase/FireStore
    setsubscriber(mounted);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <div>
      <style>
        {'body { background: #F2F2F2; background-repeat: no-repeat; margin:0}'}
      </style>

      <div style={{ marginTop: '50px' }}>
        <Container size='md'>
          {/* Filter Button */}
          <div style={{ marginLeft: '30px', marginTop: '30px' }}>
            <InterStyled
              interStyle='P1'
              size='s'
              color='greytext'
              type='medium'
            >
              {t('Filter your returns')}{' '}
              <IconButton
                onClick={() => {
                  setFilterDrawerOpen(true);
                }}
                aria-label='close'
              >
                <FilterListIcon />
              </IconButton>
            </InterStyled>
          </div>

          {/* Orders is an array of arrays each array has a group of orders grouped by their month */}
          {filteredOrders.map((group, outerIndex) => (
            <React.Fragment key={outerIndex}>
              <div style={{ marginLeft: '30px', marginTop: '40px' }}>
                <InterStyled InterStyle='Label 1'>
                  {/* Output date according to currect locale either english or german */}
                  {new Date(group[0].date).toLocaleDateString(
                    i18n.language === 'en' ? 'en-EN' : 'de-DE',
                    {
                      month: 'long',
                      year: 'numeric',
                    },
                  )}
                </InterStyled>
              </div>

              <Grid
                container
                columnSpacing={{ xs: 1, md: 4 }}
                ml={1}
                mt={4}
                mb={8}
                sx={{ width: '100% !important', marginLeft: '0px !important' }}
              >
                {group.map((order, index) => (
                  <Grid item xs={12} md={6} key={index} mb={4}>
                    <Order key={order.id} order_item={order} t={t} />
                  </Grid>
                ))}
              </Grid>
            </React.Fragment>
          ))}

          {filteredOrders.length === 0 && !apicrawled ? (
            <LoadingCircular />
          ) : null}
        </Container>
      </div>

      {/* Filters Drawer */}
      <SwipeableDrawer
        anchor='left'
        open={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        onOpen={() => setFilterDrawerOpen(true)}
      >
        <div style={{ padding: '80px 20px 20px 40px', width: '250px' }}>
          {/* Close Button */}
          <div style={{ textAlign: 'end' }}>
            <IconButton
              onClick={() => {
                setFilterDrawerOpen(false);
              }}
              aria-label='close'
            >
              <Close fontSize='large' />
            </IconButton>
          </div>

          {/* Filter Title */}
          <div style={{ marginTop: '20px' }}>
            <InterStyled interStyle='H2' color='greytext'>
              {t('RETURN MANAGER FILTER')}
            </InterStyled>
          </div>

          {/* Filter Buttons */}
          <div style={{ marginTop: '10px' }}>
            <FilterButton
              variant='text'
              onClick={() => changeChosenFilter('returnpossible')}
            >
              <InterStyled
                interStyle='P1'
                size='s'
                color={
                  chosenFilter === 'returnpossible'
                    ? 'keepoalaGreendefault'
                    : 'greytext'
                }
                type={chosenFilter === 'returnpossible' ? 'bold' : 'medium'}
              >
                {t('Return Possible')}
              </InterStyled>
            </FilterButton>
            <FilterButton
              variant='text'
              onClick={() => changeChosenFilter('answerquestions')}
            >
              <InterStyled
                interStyle='P1'
                size='s'
                color={
                  chosenFilter === 'answerquestions'
                    ? 'keepoalaGreendefault'
                    : 'greytext'
                }
                type={chosenFilter === 'answerquestions' ? 'bold' : 'medium'}
              >
                {t('Answer Questions')}
              </InterStyled>
            </FilterButton>
            <FilterButton
              variant='text'
              onClick={() => changeChosenFilter('returned')}
            >
              <InterStyled
                interStyle='P1'
                size='s'
                color={
                  chosenFilter === 'returned'
                    ? 'keepoalaGreendefault'
                    : 'greytext'
                }
                type={chosenFilter === 'returned' ? 'bold' : 'medium'}
              >
                {t('Returned')}
              </InterStyled>
            </FilterButton>
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
}

export default ReturnManager;
