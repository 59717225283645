import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import styled from 'styled-components';

import Colors from '../utils/helper/Colors';

export const Progress = styled(CircularProgress)`
  color: ${(props) => {return props.theme.main ?? Colors.keepoala.keepoalaGreendefault}};
`

export default function LoadingCircular(props: any) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      {props.textElement}
      <Progress size={150} thickness={1} />
    </Box>
  );
}
