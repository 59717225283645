import styled from "styled-components";
import Colors from "../utils/helper/Colors";

const ALink = styled.a`
    &:hover{
        color: ${Colors.keepoala.main}
    };
    &:visited{
        color: ${Colors.keepoala.dark}
    };
    &:link{
        color: ${Colors.keepoala.black}
    }
    text-decoration: none;
`
export default ALink