export default function getEndpointUrl() {
  return window.location.hostname === "localhost"
    ? process.env.REACT_APP_DEBUG === true || process.env.REACT_APP_DEBUG === "true"
      ? `http://localhost:8100/127.0.0.1:5001/${process.env.REACT_APP_PROJECT_ID}/europe-west1` // debug locally
      : "http://localhost:8100/europe-west1-" + process.env.REACT_APP_PROJECT_ID + ".cloudfunctions.net:443"
    : "https://" +
        (process.env.REACT_APP_HOST !== undefined ? process.env.REACT_APP_HOST : "app.keepoala.com") +
        "/europe-west1-" +
        process.env.REACT_APP_PROJECT_ID +
        ".cloudfunctions.net:443";
}
