import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../utils/providers/AuthProvider';
import { db } from '../firebase';
import CarbonSavings from './CarbonSavings';
import unSparseHistory, {
  aggregateHistory,
  get_upper_limit,
} from '../utils/helper/unSparseHistory';
import { useTranslation } from 'react-i18next';
import InterStyled from '../styles/InterStyled';
import { CarbonShortString } from '../utils/helper/CarbonCalc';

const StatsContainer = styled.div``;

export default function Stats() {
  const { t } = useTranslation();

  const { user, userDetail } = useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);
  /* eslint-disable-next-line no-unused-vars */
  const [userTrees, setUserTrees] = React.useState({ current: 0, subtrees: 0 });
  const [userKeepoalas, setUserKeepoalas] = React.useState({
    keepoalas: 0,
    total_collected: 0,
    waiting: 0,
    voucher: 'Feature coming soon...',
  });
  const [carbonHistory, setCarbonHistory] = React.useState([]);
  const [carbonMax, setCarbonMax] = React.useState(0);
  const [carbonSaved, setCarbonSaved] = React.useState(0);

  // Object.keys(user).map(key => console.log(key))
  // console.log(user.providerData)

  function setsubscriber() {
    return db
      .collection('Aggregation')
      .doc(user.uid)
      .onSnapshot(
        (documentsnapshot) => {
          if (documentsnapshot.exists) {
            const data = documentsnapshot.data();
            console.log('DATA', { ...data });
            setUserTrees(data.trees);
            data.keepoalas.voucher = 'Feature coming soon';
            setUserKeepoalas(data.keepoalas);
            if (userDetail !== undefined) {
              const start_month_arr = userDetail.registration_date.split('-');
              const start_month = start_month_arr[0] + '-' + start_month_arr[1];
              if (
                data.carbon_history !== undefined &&
                Object.keys(data.carbon_history).length > 0
              ) {
                setCarbonHistory(
                  unSparseHistory(data.carbon_history, start_month),
                );
                setCarbonMax(get_upper_limit(data.carbon_history));
                setCarbonSaved(
                  aggregateHistory(
                    unSparseHistory(data.carbon_history, start_month),
                  ),
                );
                console.log(carbonSaved); // TODO: CArbonsaved musst be shown anywhere
                console.log(carbonHistory); // TODO: CArbonsaved musst be shown anywhere
              }

              // carbonHistory.map((e) => {
              //     console.log('profile' + e.value)
              //     console.log('profile' + e.possible)
              //     console.log('profile' + e.month)
              //   });
              setLoading(false);
            } else {
              console.log('NODATA for user', user.uid);
            }
          } else {
            console.log('NODATA for user', user.uid);
          }
        },
        (err) => {
          console.log(`Encountered error in Profile Screen: ${err}`);
        },
      );
  }

  useEffect(() => {
    // Use a FireStore subscription
    // to derive the data from FireBase/FireStore
    var subscriber = setsubscriber();

    if (!loading) {
      //     // Unsubscribe from events when no longer in use
      //     // if (!loading) {
      return () => {
        subscriber();
      };
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [loading, userDetail]);

  return (
    <StatsContainer>
      {/* Keepoalas section */}
      <div style={{ marginBottom: '15px' }}>
        <div>
          <InterStyled interStyle='Label1'>{t('Keepoalas')}</InterStyled>
        </div>

        <div style={{ marginTop: '15px' }}>
          <InterStyled interStyle='Label2' style={{ marginRight: '10px' }}>
            {t('Current')}:
          </InterStyled>
          <InterStyled
            interStyle='Label1'
            style={{ marginRight: '20px' }}
            color='keepoalaGreendefault'
          >
            {userKeepoalas.keepoalas}
          </InterStyled>

          <InterStyled interStyle='Label2' style={{ marginRight: '10px' }}>
            {t('Waiting')}:
          </InterStyled>
          <InterStyled
            interStyle='Label1'
            style={{ marginRight: '20px' }}
            color='keepoalaGreendefault'
          >
            {userKeepoalas.waiting}
          </InterStyled>

          <InterStyled interStyle='Label2' style={{ marginRight: '10px' }}>
            {t('Used For Vouchers')}:
          </InterStyled>
          <InterStyled
            interStyle='Label1'
            style={{ marginRight: '20px' }}
            color='keepoalaGreendefault'
            transform='lowercase'
          >
            {userKeepoalas.total_collected - userKeepoalas.keepoalas}
          </InterStyled>
        </div>
      </div>

      {/* Carbon section */}
      <div style={{ margin: '15px 0px' }}>
        <div>
          <InterStyled interStyle='Label1'>{t('Carbon')}</InterStyled>
        </div>

        <div style={{ marginTop: '15px' }}>
          <InterStyled interStyle='Label2' style={{ marginRight: '25px' }}>
            {t('SAVED')}:
          </InterStyled>
          <InterStyled
            interStyle='Label1'
            style={{ marginRight: '40px' }}
            color='keepoalaGreendefault'
            transform='lowercase'
          >
            {CarbonShortString(carbonSaved.value)}
          </InterStyled>

          <InterStyled interStyle='Label2' style={{ marginRight: '10px' }}>
            {t('Possible to Save')}:
          </InterStyled>
          <InterStyled
            interStyle='Label1'
            style={{ marginRight: '20px' }}
            color='keepoalaGreendefault'
            transform='lowercase'
          >
            {CarbonShortString(carbonSaved.possible_value)}
          </InterStyled>

          <InterStyled interStyle='Label2' style={{ marginRight: '10px' }}>
            {t('Last Month')}:
          </InterStyled>
          <InterStyled
            interStyle='Label1'
            style={{ marginRight: '20px' }}
            color='keepoalaGreendefault'
            transform='lowercase'
          >
            {carbonHistory.length > 0
              ? CarbonShortString(carbonHistory[carbonHistory.length - 1].value)
              : null}
          </InterStyled>
        </div>
      </div>

      {/* Carbon flow section */}
      <div style={{ margin: '15px 0px' }}>
        <div>
          <InterStyled interStyle='Label1'>{t('Carbon flow')}</InterStyled>
        </div>

        <div style={{ marginTop: '15px' }}>
          {carbonHistory.length > 0 ? (
            <CarbonSavings data={carbonHistory} max={carbonMax} />
          ) : null}
        </div>
      </div>
    </StatsContainer>
  );
}
