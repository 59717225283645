import React, { useContext, useState } from "react";
import { useLocation } from 'react-router-dom';
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import {
    FormControl,
    Input,
    InputLabel,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import { AuthContext } from "../utils/providers/AuthProvider";
import {default as Lato} from '../styles/lato';
import split_query from "../utils/helper/split_query";
import Button from '../styles/Button';
import { useTranslation } from "react-i18next";
import Colors from '../utils/helper/Colors';

const Alert = styled(MuiAlert)(spacing);
const LatoText = styled(Lato)(spacing);

const useStyles = makeStyles((theme) => ({
    link: {
        margin: 5,
        textDecoration: "none",
    },
    linksWrapper: {
        display: "flex",
        marginTop: "auto",
    },
    myAlert: {
        marginTop: 15,
        width: "90%",
    },
    hide: {
        display: "none",
    },
    btn: {
        marginTop: 20,
    },
    forgotText: {
        marginTop: 10,
    },
    hidePassBtn: {
        width: 16,
        height: 16,
        margin: -8,
    },
    wrapper: {
        top: "40%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: '90%',
        margin: "0 auto",
        [theme.breakpoints.up('md')]: {
            width: "50%",
          },
    },
    wrap:{
        marginTop: "2em"
    },
   
}));

function ResetPassword() {
    const classes = useStyles();

    const [email, setEmail] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const { resetPassword } = useContext(AuthContext);

    const location = useLocation();
    const {t} = useTranslation()

    if (location.search.length > 2) {
        const query_params = split_query(location.search);
        if (query_params.order_id !== undefined) {
            localStorage.setItem('shopify_order_id', query_params.order_id);
            localStorage.setItem('shopify_shop', query_params.shop);
            location.search = "";
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault(); 
        resetPassword(email).then(() => {
            setSubmitSuccess(true);
        }).catch((e) => {
            setAlertMessage(e);
            setShowAlert(true);
        })
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };


    

    return (<div className={classes.wrap}>
                <div className={classes.wrapper}>    
                    <LatoText
                            variant="body1"
                            component="h2"
                            align="center"
                            className={classes.forgotText}
                            fontSize={"m"}
                        >
                            {t('This will send you a password reset link via email')}
                    </LatoText>

                    {!submitSuccess ? (
                        <form onSubmit={handleSubmit}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="email">
                                {t('Email Address')}
                            </InputLabel>
                            <Input
                                id="email"
                                name="email"
                                value={email}
                                autoComplete="email"
                                autoFocus
                                onChange={handleEmailChange}
                            />
                        </FormControl>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            mb={2}
                            size="medium"
                            id="submit"
                            type="submit"
                            value="Submit"
                            className={classes.btn}
                        >
                            {t('Reset password')}
                        </Button>
                    <Alert
                        mb={4}
                        severity="error"
                        className={`${classes.myAlert} ${
                            !showAlert ? classes.hide : ""
                        }`}
                    >
                       {alertMessage}
                    </Alert>
                    </form>
                    ) : (
                        <div>
                            <LatoText
                            variant="body1"
                            component="h2"
                            align="center"
                            className={classes.forgotText}
                            fontSize={"l"}
                            color={Colors.keepoala.dark}
                        >
                            {t('Please look into your emails')}&nbsp;
                            {t('You will find the reset link there')}
                    </LatoText>
                        </div>
                    )}

                    
                   
                    
                </div>

                
            </div>
       
    );
};
export default ResetPassword;
