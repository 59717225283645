import { useTranslation } from "react-i18next"
import LatoText from "../styles/lato";
import styled from 'styled-components';

const VerifyContainer = styled.div`margin-top:20px`

export default function VerifyEmail() {

    const {t} = useTranslation();
    return <VerifyContainer>
        <LatoText fontSize="xl">{t('Please verify your email first')}. {t('Go to your Emails and click on the latest verification link')}</LatoText>
    </VerifyContainer>
}