import React, { useEffect, useState } from "react";
import KeepoalaThemeProvider from "./KeepoalaThemeProvider";
import { Coloring } from "../types";
import { dhl_status_type, get_shop_dhl_status } from "../utils/helper/get_pretty_shopName";

export default function KeepoalaThemeGetter(props: { shop: string; children: any }) {
  const [coloring, setColoring]: [Coloring | undefined, any] = useState(undefined);

  useEffect(() => {
    get_shop_dhl_status(props.shop).then(
      (dhl_status: { res: dhl_status_type | undefined; err: string } | undefined) => {
        if (dhl_status !== undefined) {
          if (dhl_status.res !== undefined) {
            setColoring(dhl_status.res.coloring ?? undefined);
          }
        }
      }
    );
  }, [props.shop]);

  return (
    <KeepoalaThemeProvider shop={props.shop} coloring={coloring}>
      {props.children}
    </KeepoalaThemeProvider>
  );
}
